import {Button, Checkbox, Divider, Dropdown, Form, Grid, Header, Input, Segment} from 'semantic-ui-react'
import React, {useState} from "react";
import axiosInstance from "../../utils/axiosInstance";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {isIMEI} from "../../utils/imei-utils";
import {isAdmin} from "../../utils/authorities-utils";
import {UPDATE_PRODUCT_INFO} from "../../reducers/Product/product.types";

function ModelSelector({setBrandName, setModelName, setPictureUrl, setImei, setType, setIsEnabled}) {

    let initialBrandState = [
        {id:36,name:'Apple',image: { src: 'https://drpciyuuf9kdh.cloudfront.net/save/quote/quote-brand/brand-APPLE.svg'}},
        {id:14,name:'Samsung'},
        {id:1915,name:'Honor'},
        {id:37,name:'Huawei'},
        {id:2,name:'Nokia'},
        {id:3662,name:'OnePlus'},
        {id:612,name:'Oppo'},
        {id:61,name:'Sony'},
        {id:1923,name:'Xiaomi'},
        {id:299,name:'Wiko'},
    ];
    const [brandSearchResults,setBrandSearchResults] = useState(initialBrandState);
    const [modelSearchResults,setModelSearchResults] = useState([]);
    const [brandSearchQuery,setBrandSearchQuery] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedModel, setSelectedModel] = useState("");
    const [isEnabledValue, setIsEnabledValue] = useState(true);
    const [imeiError, setImeiError] = useState(false);

    const [isBrandLoading,setIsBrandLoading] = useState(false);
    const connectedUser = useSelector(state => state.user.user);
    const dispatch = useDispatch();
    const history = useHistory();

    const brandOptions = brandSearchResults.map(brand => {
        return {key: brand.id, text: brand.name, value: brand}
        // return {key: brand.id, text: brand.name, value: brand, image: { avatar: true, src: 'https://drpciyuuf9kdh.cloudfront.net/save/quote/quote-brand/brand-APPLE.svg'}}
    });
    const modelOptions = modelSearchResults.map(model => {
        return {key: model.id, text: model.name, value: model}
    });

    const handleBrandSearchChange = (e) => {
        setBrandSearchQuery(e.target.value);
        if (e.target.value === "" || e.target.value === undefined) {
            setBrandSearchResults(initialBrandState);
            return;
        }
        setIsBrandLoading(true);
        axiosInstance.get("/brands?search=" + e.target.value)
            .then((response) => {
                setBrandSearchResults(response.data);
                setIsBrandLoading(false);
            })
            .catch((error) => {
                setIsBrandLoading(false);
            });
    };

    const handleBrandChange = (e, { searchQuery, value }) => {
        setBrandSearchQuery(e.name);
        setSelectedBrand(value);
        setBrandName(value.name);
        dispatch({type: UPDATE_PRODUCT_INFO, payload: {brandName: value.name, enabled: true}});
        axiosInstance.get("/brand/" + value.id + "/models")
            .then((response) => {
                setModelSearchResults(response.data);
            });
    }

    const resetBrand = () => {
        setSelectedBrand("");
        setSelectedModel("");
        setBrandSearchResults(initialBrandState);
        setModelSearchResults([]);
        setBrandName("");
        setModelName("");
    }

    const resetModel = () => {
        setSelectedModel("");
        setModelName("");
    }

    const handleModelChange = (e, { searchQuery, value }) => {
        setSelectedModel(value);
        setModelName(value);
        setPictureUrl(value.pictureUrl);
        setType(value.type);
        dispatch({
            type: UPDATE_PRODUCT_INFO,
            payload: {brandName: selectedBrand.name, type: value.type, name: value.name, enabled: value.enabled}
        });
    }

    const handleIsEnabled = () => {
        setIsEnabledValue(!isEnabledValue)
        setIsEnabled(!isEnabledValue);
    }

    const getFromImei = (e, {value}) => {
        if (value === "") {
            setImeiError(false);
            return;
        }
        if (!isIMEI(value,isAdmin(connectedUser.authorities))) {
            setImeiError(true);
            return;
        } else {
            axiosInstance.get("/gsma/byimei/" + value)
                .then((response) => {
                    setSelectedModel({name: response.data.modelName});
                    setSelectedBrand({name: response.data.brandName});
                    setBrandName(response.data.brandName);
                    setModelName(response.data.model);
                    setPictureUrl(response.data.pictureUrl);
                    setType(response.data.type);
                    setImei(value);
                    dispatch({type: UPDATE_PRODUCT_INFO, payload: {brandName: response.data.brandName,name: response.data.model.name, enabled: response.data.model.enabled}});
                });
        }
    }

    const displayIsEnabledOrImei = () => {
        let  content= setIsEnabled ? <>
            <Form>
                <Form.Field>
                    <div className="ui toggle checkbox">
                        <Checkbox   label='Actif'
                                    checked={isEnabledValue}
                                    onChange={handleIsEnabled} />
                        {/*<input type="checkbox" name="isEnabled"*/}
                        {/*       onChange={(e, {name, value}) => setIsEnabled(value)}/>*/}
                        {/*<label>Actif</label>*/}
                    </div>
                </Form.Field> </Form></> :<><h4>IMEI</h4><Form>
                    <Form.Field>
                        <Form.Input error={imeiError} iconPosition='left' placeholder='Imei' >
                            <Input placeholder="IMEI du téléphone"
                                   onChange={(e,data)=>{getFromImei(e,data)}} />
                        </Form.Input>
                    </Form.Field>
                </Form> </>;
        return content;
        }

    return (
            <Segment>
                <Grid columns={2} relaxed='very' >
                    <Grid.Column verticalAlign='top'>
                        <Grid columns={2}  >
                            <Grid.Column>
                                {selectedBrand ? <><h4>Marque</h4><Header block style={{marginTop: '0px'}}>{selectedBrand.name}
                                        <Button icon='delete' size={"mini"} floated={"right"}
                                                onClick={() => resetBrand()}>
                                            </Button>
                                </Header></> :
                                    <>
                                        <h4>Marque</h4>
                                        <Dropdown
                                            placeholder='Marque'
                                            search
                                            fluid
                                            clearable
                                            options={brandOptions}
                                            selection
                                            onChange={handleBrandChange}
                                            onSearchChange={handleBrandSearchChange}
                                            searchQuery={brandSearchQuery}
                                            noResultsMessage={brandSearchQuery ? "Aucun résultat trouvé" : "Commencez à taper"}
                                            loading={isBrandLoading}
                                        />
                                    </>}
                            </Grid.Column>
                                <Grid.Column>
                                    {selectedModel? <><h4>Modèle</h4><Header block style={{marginTop: '0px'}}>{selectedModel.name}
                                            <Button icon='delete' size={"mini"} floated={"right"}
                                                    onClick={() => resetModel()}>
                                            </Button>
                                    </Header></> :
                                        <>
                                            <h4>Modèle</h4>
                                            <Dropdown
                                                search
                                                fluid
                                                clearable
                                                options={modelOptions}
                                                selection
                                                disabled={!selectedBrand ? true : false}
                                                noResultsMessage={brandSearchQuery ? "Aucun résultat trouvé" : "Commencez à taper"}
                                                onChange={handleModelChange}
                                            /></>
                                    }
                                </Grid.Column>
                            </Grid>
                    </Grid.Column>
                    <Grid.Column verticalAlign='bottom'>
                        {displayIsEnabledOrImei()}
                    </Grid.Column>
                </Grid>
                {setIsEnabled ? null : <Divider vertical>Ou</Divider>}
            </Segment>
    );

}

export default ModelSelector
