import {RESET_APP} from "../GlobalUi/globalUi.types";
import {INIT_CRMGP_DATAS, RESET_CRMGP_DATAS} from "./crmgp.types";

const INITIAL_STATE = {
    nomClient: "",
    prenomCl: "",
    mailContactCl: "",
    telContactCl: "",
    cuid:""
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case INIT_CRMGP_DATAS:
            return ({
                ...state,
                nomClient: action.nomClient,
                prenomCl: action.prenomCl,
                mailContactCl: action.mailContactCl,
                telContactCl: action.telContactCl,
                cuid: action.cuid
            })

        case RESET_CRMGP_DATAS:
            return INITIAL_STATE

        default:
            return state;
    }
};

export default reducer;
