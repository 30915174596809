import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    Item,
    List,
    Menu,
    Message,
    Segment
} from 'semantic-ui-react'
import batKo from "./../../img/bat_ko.png";
import batOk from "./../../img/bat_ok.png";
import bat1 from "./../../img/bat_menu_1.png";
import bat2 from "./../../img/bat_menu_2.png";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";



function BAT_ManualDiag(props) {

    const [activeItem, setActiveItem] = useState(1)
    const product = useSelector(state => state.product);

    const diagAction = function(testOk){
        props.nextDiag(testOk)
    }



    return (
            <Segment placeholder>
                <Grid columns={2} relaxed='very' stackable>
                    <Grid.Column>
                        <h3>La batterie du smartphone est-elle fonctionnelle ?</h3>

                        <Segment basic>
                            <Grid centered columns={2} relaxed='very'>
                                <Grid.Column verticalAlign='middle'>
                                    <Segment fluid className="diagchoice"  onClick={()=>diagAction(true)}>
                                        <Container style={{marginBottom: '10px'}}><Image centered alt="ecran_ok" src={batOk} style={{maxHeight: '150px'}} /></Container>
                                        <Icon size='huge' color='green' name='check circle outline'/>
                                        <br/><p>OUI</p>
                                    </Segment>
                                </Grid.Column>

                                <Grid.Column verticalAlign='middle' >
                                    <Segment fluid className="diagchoice" onClick={()=>diagAction(false)}>
                                        <Container style={{marginBottom: '10px'}}><Image centered alt="ecran_ok" src={batKo} style={{maxHeight: '150px'}} /></Container>
                                        <Icon size='huge' color='red' name='times circle outline'/>
                                        <br/><p>NON</p>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Segment>

                        {props.piceaEligible ?
                        <Button as={Link} to='/remoteDiag'  basic style={{marginTop: '45px'}} primary>Je souhaite faire le diagnostic avec Piceasoft</Button> : null}

                    </Grid.Column>
                    <Grid.Column verticalAlign='middle'>
                        <h3>Qu'est ce que cela signifie ?</h3>
                        <List bulleted>
                            <List.Item>L'autonomie de l'appareil est bonne</List.Item>
                            <List.Item>L'appareil ne surchauffe pas lors de son utilisation</List.Item>
                            <List.Item>La capacité maximum de la batterie est supérieure à 80%</List.Item>
                        </List>
                        {product.brand === 'APPLE' || product.brand === 'Apple' ? <>
                        <Divider />
                            <Message info>Sur iPhone, la batterie est considérée comme non fonctionnelle lorsque sa <b>capacité maximum</b> est <b>inférieure à 80%</b></Message>
                        <Menu fluid widths={2} attached='top' size='mini'>
                            <Menu.Item
                                style={{backgroundColor: '#00BFB2', color:'white'}}
                                disabled={activeItem === 1}
                                onClick={() => setActiveItem(1)}
                            ><Icon name='angle left'/>Précédent</Menu.Item>
                            <Menu.Item
                                style={{backgroundColor: '#00BFB2', color:'white'}}
                                disabled={activeItem === 2}
                                onClick={() => setActiveItem(2)}
                            > Suivant<Icon name='angle right'/> </Menu.Item>
                        </Menu>

                                    {activeItem === 1 ? <Segment attached='bottom'>
                                        <p style={{textAlign: 'center'}}>Dans les réglages de l'iPhone, naviguer dans <b>Batterie</b> puis sélectionner <b>Etat de la batterie</b>.</p>
                                            <Image centered size='medium' src={bat1} />
                                    </Segment> : null}
                                    {activeItem === 2 ? <Segment  attached='bottom'>
                                        <p style={{textAlign: 'center'}}>Vérifier la capacité maximum de la batterie.</p>
                                        <Image centered size='medium' src={bat2} />
                                    </Segment> : null}
                        </> : null}

                    </Grid.Column>
                </Grid>
                <Divider vertical/>

            </Segment>

    );

}

export default BAT_ManualDiag
