import {Container, Divider, Grid, Icon, Image, List, Segment} from 'semantic-ui-react'
import WorkflowStepper from "../../components/WorkflowStepper/WorkflowStepper";
import VisualDiagStepper from "../../components/WorkflowStepper/VisualDiagStepper";
import {useHistory} from 'react-router-dom';

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SavHeader from "../../components/SavHeader/SavHeader";
import {getModelAndDiag} from "../../reducers/Product/product.actions";

function VisualDiagPage({match}) {

    const {step} = match.params;

    const [currentStep, setCurrentStep] = useState(step === undefined ? 'SMA' : step);
    const history = useHistory();
    const dispatch = useDispatch();
    const product = useSelector(state => state.product);

    const showLcd = !product.selectedRepairs.includes('LCD');
    const showLBackCover = !product.selectedRepairs.includes('BACK_COVER');

    useEffect(() => {
        if (!showLcd) {
            dispatch({type: "ON_VISUAL_DIAG_UPDATE", payload: {isSmartphoneScreenDamaged: true}})
        }
        if (!showLBackCover) {
            dispatch({type: "ON_VISUAL_DIAG_UPDATE", payload: {isSmartphoneBackCoverDammaged: true}})
        }
    }, []);

    function stepUpdate(data, nextStep) {

        dispatch({type: "ON_VISUAL_DIAG_UPDATE", payload: data})
        if (nextStep !== "remoteDiag")
            setCurrentStep(nextStep);
        else {
            // if (product.isSmartphoneFunctional === false || data.isSmartphoneOxidized === true)
            //     history.push("/manualIdentification");
            if (product.name !=="" && product.brand !=="") {
                dispatch(getModelAndDiag(() => {history.push("/manualDiag")}))
            } else
                history.push("/manualIdentification");
        }
    }

    function getFirstStep() {
        return <Segment placeholder>
            <Grid columns={2} relaxed='very' stackable>
                <Grid.Column>
                    <h3>Le smartphone s'allume-t-il ?</h3>
                    <div>
                        <Segment basic>
                            <Grid centered columns={2} relaxed='very'>
                                <Grid.Column verticalAlign='middle'>
                                    <Segment className="diagchoice"
                                             onClick={() => stepUpdate({isSmartphoneFunctional: true}, (showLcd ? 'LCD' : (showLBackCover ? 'BACK_COVER' : 'DOX')))}>
                                        <Icon size='huge' color='green' name='check circle outline'/>
                                        <br/><p>Oui</p>
                                    </Segment>
                                </Grid.Column>

                                <Grid.Column verticalAlign='middle'>
                                    <Segment className="diagchoice"
                                             onClick={() => stepUpdate({isSmartphoneFunctional: false}, (showLcd ? 'LCD' : (showLBackCover ? 'BACK_COVER' : 'DOX')))}>
                                        <Icon size='huge' color='red' name='times circle outline'/>
                                        <br/><p>Non</p>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    </div>
                </Grid.Column>
                <Grid.Column verticalAlign='middle'>
                    <h3>Qu'est ce que cela signifie ?</h3>
                    <List bulleted>
                        <List.Item>Le produit s'allume normalement</List.Item>
                        <List.Item>Le smartphone vibre ou une diode s'allume</List.Item>
                    </List>
                </Grid.Column>
            </Grid>
            <Divider vertical/>
        </Segment>
    }

    // function getSecondStep() {
    //     return <Segment placeholder>
    //         <Grid columns={2} relaxed='very' stackable>
    //             <Grid.Column>
    //                 <h3>L'écran du smartphone est-il intact ?</h3>
    //                 <div>
    //
    //                     <Segment basic>
    //                         <Grid centered columns={2} relaxed='very'>
    //                             <Grid.Column verticalAlign='middle'>
    //                                 <Segment fluid className="diagchoice"
    //                                          onClick={() => stepUpdate({isSmartphoneScreenDamaged: false}, (showLBackCover ? 'BACK_COVER' : 'DOX'))}>
    //                                     <Container style={{marginBottom: '10px'}}><Image size={"small"} centered
    //                                                                                      alt="ecran_ok" src={ecranOk}/></Container>
    //                                     <Icon size='huge' color='green' name='check circle outline'/>
    //                                     <br/><p>Oui</p>
    //                                 </Segment>
    //                             </Grid.Column>
    //
    //                             <Grid.Column verticalAlign='middle'>
    //                                 <Segment fluid className="diagchoice"
    //                                          onClick={() => stepUpdate({isSmartphoneScreenDamaged: true}, (showLBackCover ? 'BACK_COVER' : 'DOX'))}>
    //                                     <Container style={{marginBottom: '10px'}}><Image size={"small"} centered
    //                                                                                      alt="ecran_ko" src={ecranKo}/></Container>
    //                                     <Icon size='huge' color='red' name='times circle outline'/>
    //                                     <br/><p>Non</p>
    //                                 </Segment>
    //                             </Grid.Column>
    //                         </Grid>
    //                     </Segment>
    //                 </div>
    //             </Grid.Column>
    //             <Grid.Column verticalAlign='middle'>
    //                 <h3>Qu'est ce que cela signifie ?</h3>
    //                 <List bulleted>
    //                     <List.Item>L'écran ne présente pas de chocs, rayures ou fissures sur la totalité de sa
    //                         surface</List.Item>
    //                     <List.Item>L'écran n'a pas de pixels morts (petits carrés noir)</List.Item>
    //                     <List.Item>L'écran n'a pas de bandes de couleur apparentes</List.Item>
    //                     <List.Item>Le tactile de l'appareil est fonctionnel</List.Item>
    //                 </List>
    //             </Grid.Column>
    //         </Grid>
    //         <Divider vertical/>
    //     </Segment>
    // }


    // function getThirdStep() {
    //     return <Segment placeholder>
    //         <Grid columns={2} relaxed='very' stackable>
    //             <Grid.Column>
    //                 <h3>La coque arrière du smartphone est-elle intacte ?</h3>
    //                 <div>
    //
    //                     <Segment basic>
    //                         <Grid centered columns={2} relaxed='very'>
    //                             <Grid.Column verticalAlign='middle'>
    //                                 <Segment fluid className="diagchoice"
    //                                          onClick={() => stepUpdate({isSmartphoneBackCoverDammaged: false}, 'DOX')}>
    //                                     <Container style={{marginBottom: '10px'}}><Image size={"small"} centered
    //                                                                                      alt="back_ok"
    //                                                                                      src={backOk}/></Container>
    //                                     <Icon size='huge' color='green' name='check circle outline'/>
    //                                     <br/><p>Oui</p>
    //                                 </Segment>
    //                             </Grid.Column>
    //
    //                             <Grid.Column verticalAlign='middle'>
    //                                 <Segment fluid className="diagchoice"
    //                                          onClick={() => stepUpdate({isSmartphoneBackCoverDammaged: true}, 'DOX')}>
    //                                     <Container style={{marginBottom: '10px'}}><Image size={"small"} centered
    //                                                                                      alt="back_ko"
    //                                                                                      src={backKo}/></Container>
    //                                     <Icon size='huge' color='red' name='times circle outline'/>
    //                                     <br/><p>Non</p>
    //                                 </Segment>
    //                             </Grid.Column>
    //                         </Grid>
    //                     </Segment>
    //                 </div>
    //             </Grid.Column>
    //             <Grid.Column verticalAlign='middle'>
    //                 <h3>Qu'est ce que cela signifie ?</h3>
    //                 <List bulleted>
    //                     <List.Item>La coque et le contour ne présentent pas d'impact ou de rayure profonde sur la
    //                         totalité de leurs surfaces</List.Item>
    //                 </List>
    //             </Grid.Column>
    //         </Grid>
    //         <Divider vertical/>
    //     </Segment>
    // }

    // function getFourthStep() {
    //     return <Segment placeholder>
    //         <Grid columns={2} relaxed='very' stackable>
    //             <Grid.Column>
    //                 <h3>L'appareil ne présente pas de traces d'oxydation ?</h3>
    //                 <div>
    //
    //                     <Segment basic>
    //                         <Grid centered columns={2} relaxed='very'>
    //                             <Grid.Column verticalAlign='middle'>
    //                                 <Segment fluid className="diagchoice"
    //                                          onClick={() => stepUpdate({isSmartphoneOxidized: false}, "remoteDiag")}>
    //                                     <Container style={{marginBottom: '10px'}}><Image size={"small"} centered
    //                                                                                      alt="dox_ok"
    //                                                                                      src={ecranOk}/></Container>
    //                                     <Icon size='huge' color='green' name='check circle outline'/>
    //                                     <br/><p>Oui</p>
    //                                 </Segment>
    //                             </Grid.Column>
    //
    //                             <Grid.Column verticalAlign='middle'>
    //                                 <Segment fluid className="diagchoice"
    //                                          onClick={() => stepUpdate({isSmartphoneOxidized: true}, "remoteDiag")}>
    //                                     <Container style={{marginBottom: '10px'}}><Image size={"small"} centered
    //                                                                                      alt="dox_ko"
    //                                                                                      src={doxKo}/></Container>
    //                                     <Icon size='huge' color='red' name='times circle outline'/>
    //                                     <br/><p>Non</p>
    //                                 </Segment>
    //                             </Grid.Column>
    //                         </Grid>
    //                     </Segment>
    //                 </div>
    //             </Grid.Column>
    //             <Grid.Column verticalAlign='middle'>
    //                 <h3>Qu'est ce que cela signifie ?</h3>
    //                 <List bulleted>
    //                     <List.Item>L’appareil a été en contact un liquide :
    //                         <List.Item style={{marginTop: '4px'}}> - De la transpiration</List.Item>
    //                         <List.Item style={{marginTop: '4px'}}> - Un choc thermique important (créant de la
    //                             condensation)</List.Item>
    //                         <List.Item style={{marginTop: '4px'}}> - Un endroit humide ou sous la pluie</List.Item>
    //                         <List.Item style={{marginTop: '4px'}}> - Une immersion dans de l'eau (évier, piscine, flaque
    //                             d'eau, toilettes...)</List.Item>
    //                         <List.Item style={{marginTop: '4px'}}> - L'humidité de la salle de bain</List.Item>
    //                         <List.Item style={{marginTop: '4px'}}> - Des éclaboussures quelconques</List.Item>
    //                     </List.Item>
    //                     <List.Item>Les indicateurs d’oxydation (pastilles blanches) sont de couleurs
    //                         rose/rouge.</List.Item>
    //                     <List.Item>Ces indicateurs sont généralement visibles lorsque l’on retire le tiroir Sim des
    //                         appareils.</List.Item>
    //                 </List>
    //                 <List>
    //                     Afficher Guide Apple : <a href="https://support.apple.com/fr-fr/HT204104"
    //                                               target="_blank">https://support.apple.com/fr-fr/HT204104</a>
    //                 </List>
    //             </Grid.Column>
    //         </Grid>
    //         <Divider vertical/>
    //     </Segment>
    // }

    function getCurrentStepContent() {
        if (currentStep === 'SMA')
            return getFirstStep();
        // if (currentStep === 'LCD')
        //     return getSecondStep();
        // if (currentStep === 'BACK_COVER')
        //     return getThirdStep();
        // if (currentStep === 'DOX')
        //     return getFourthStep();
    }


    return (<>
            <Container fluid>
                <SavHeader/>
                <WorkflowStepper step={1}/>
                {/*<VisualDiagStepper step={currentStep} setCurrentStep={setCurrentStep}/>*/}
                {getCurrentStepContent()}
            </Container></>
    );

}

export default VisualDiagPage
