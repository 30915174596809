import {axiosInstance} from "../../utils/axiosInstance";
import {isIE} from "react-device-detect";

export function printDeliverysheet(parcelId) {

    axiosInstance.get('/displayParcelDoc/' + parcelId, {responseType: 'blob'})
        .then(response => {

            //Create a Blob from the PDF Stream
            if (isIE) {
                var blob = new Blob([response.data], {
                    type: 'application/pdf'
                });
                window.navigator.msSaveOrOpenBlob(blob, "BL_" + parcelId + ".pdf");
            } else {
                const file = new Blob([response.data], {type: 'application/pdf'});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            }
        })
        .catch(error => {
            console.log(error);
        });
}