import {Button, Card, Container, Icon, Label, List, Message, Segment} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {axiosInstance} from "../../utils/axiosInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getRoles} from "@testing-library/dom";
import UserDetail from "./UserDetail";
import {isAdmin, isManager} from "../../utils/authorities-utils";

function ShopUsers() {

    const connectedUser = useSelector(state => state.user);
    const [shopUsers, setShopUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalOpen, setModalOpen] = useState(true);

    const dispatch = useDispatch();

    const getShopUsers = () => {
        axiosInstance.post("/users/",
            {})
            .then((response) => {
                setShopUsers(response.data.content)
                console.log(response);
            })
            .catch((error) => {
                console.log("error")
            })
    }

    useEffect(() => {
        getShopUsers();
    }, [])

    const onSuccess = () => {
        getShopUsers();
        setSelectedUser(null);
    }

    const onClose = () => {
        setSelectedUser(null);
    }

    const updateActivatedStatus = (user, activated) => {
        axiosInstance.post("/user/" + (activated ? "enable" : "disable"),
            {login: user.user.login})
            .then((response) => {
                getShopUsers();
            })
    }


    return (
        <>
            <Container fluid>

                <Message icon>
                    <Icon name='users'/>
                    <Message.Content>
                        <Message.Header>Utilisateurs associés à la boutique</Message.Header>
                        {shopUsers.length > 0 ? <>{shopUsers.length} comptes sont associés à votre boutique</> : null}
                    </Message.Content>
                </Message>


                    <Card.Group>

                        <Card style={{backgroundColor: '#F0FFF0', cursor: 'pointer'}}
                              onClick={() => setSelectedUser({user: {authorities: []}})}>

                            <Card.Content textAlign='center'>
                                <Icon name='user plus' size={"huge"} style={{marginTop: '20px'}}/>
                                <p>Créer un nouvel utilisateur<br/>pour cette boutique</p>
                            </Card.Content>
                        </Card>

                    {shopUsers.map((user => (
                        <Card  style={{backgroundColor: user.user.activated ? '#F0FFF0' : 'white'}}>

                        <Card.Content>

                            {isAdmin(user.user.authorities) || isManager(user.user.authorities) ?
                            <Label as='a' color='blue' corner='right' icon='star'></Label> : null}


                            <Card.Header>{user.user.login} </Card.Header>
                            <Card.Meta>{user.user.firstName} {user.user.lastName}</Card.Meta>
                            <Card.Meta>
                                {user.user.authorities.map(auth=>(<Label size={"tiny"}>{auth.name}</Label>))}
                            </Card.Meta>

                        </Card.Content>
                            <Card.Content extra>
                                <Icon name='mail' />
                                {user.user.email}
                            </Card.Content>
                        <Card.Content extra>
                            <div className='ui two buttons'>
                                <Button basic color='blue'  onClick={() => setSelectedUser(user)}>
                                    Editer
                                </Button>
                                {user.user.activated ?
                                    <Button basic color='red' onClick={() => updateActivatedStatus(user, false)}>
                                        Désactiver
                                    </Button> :
                                    <Button basic color='green' onClick={() => updateActivatedStatus(user, true)}>
                                        Activer
                                    </Button>
                                }
                            </div>
                        </Card.Content>
                    </Card>
                    )))}
                    </Card.Group>

                {selectedUser !== null ? <UserDetail onSuccess={onSuccess} onClose={onClose} selectedUser={selectedUser} setModalOpen={setModalOpen}
                                                     setSelectedUser={setSelectedUser} forceShop={true}/> : ""}


            </Container>

        </>
    );

}

export default ShopUsers
