import {Button, Checkbox, Icon, List, Table, Form, FormField} from 'semantic-ui-react'
import React from "react";
import {isAllowed} from "../../utils/authorities-utils";
import {useSelector} from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import update from 'react-addons-update';

function ShopGroupItem({shops,setShops,setSelectedShop}) {

    var content =[];

    function handleStuart(event,data,shop) {
        let shopUp = {...shop,useStuartProd:data.checked}
        axiosInstance.put("/external-shops",shopUp);

        const index = shops.findIndex((emp) => emp.id === shop.id);
        const updatedShops = update(shops, {$splice: [[index, 1, shopUp]]});  // array.splice(start, deleteCount, item1)
        setShops(updatedShops)
    }

    if(shops!==undefined)
        shops.map((shop)=>
        content.push(<Table.Row positive>
            <Table.Cell>
                <List>
                    {shop.name}
                </List>
            </Table.Cell>
            <Table.Cell>
                <List>
                    {shop.advCode}
                </List>
            </Table.Cell>

        </Table.Row>))







    return content;

}

export default ShopGroupItem
