import {Label} from 'semantic-ui-react'
import React from "react";
import {useSelector} from "react-redux";

function ParcelCounter() {

    const parcelCounter = useSelector(state => state.global.parcelCounter);

    return (
        <>
            <Label color='red' pointing='left'>{parcelCounter}</Label>
    </>
    );

}

export default ParcelCounter
