import React from "react";
import {Feed} from "semantic-ui-react";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function HistoricDiscountComponent({historicElement}) {

    return (
        <>
                   <Feed.Event>
                       <Feed.Label>
                           {<FontAwesomeIcon size={"2x"} style={{marginLeft: '5px'}} color={"rgba(0,0,0,.6)"} icon="percent" />}
                       </Feed.Label>
                       <Feed.Content>
                           <Feed.Date><Moment format="ddd DD MMM YYYY [à] HH:mm"
                                              locale="fr">{historicElement.date}</Moment> {historicElement.login ? 'par ' + historicElement.login : null}
                           </Feed.Date>
                           <Feed.Summary>
                               Le prix de votre réparation a été mis à jour. Il est passé de {historicElement.datas.priceTtcBeforeDiscount}€ à {historicElement.datas.priceTtcAfterDiscount}€ (réduction de {historicElement.datas.discountRate}%)
                           </Feed.Summary>
                      </Feed.Content>
                           </Feed.Event>
        </>
    );
}

export default HistoricDiscountComponent
