import {Message} from "semantic-ui-react";
import React from "react";

function HistoricCegidComponent({historicElement}) {

    return (
        <>
            {historicElement.type === 'TRANSFERT_CEGID_TICKET_SUCCESS' ?
                <Message success size={"tiny"} style={{cursor: 'pointer', paddingBottom: '1px', paddingTop: '1px'}}>
                    {historicElement.datas ? <>Ticket CEGID:  <a> {historicElement.datas.cegidKey} < /a></>:null}  </Message> : null}

            {historicElement.type === 'TRANSFERT_CEGID_TICKET_ERROR' ?
                <Message negative size={"tiny"} style={{cursor: 'pointer', paddingBottom: '1px', paddingTop: '1px'}}>
                    {historicElement.datas ? <>Erreur CEGID:  <a> {historicElement.datas.cegidKey} < /a></>:null}  </Message> : null}

         </>
    );
}

export default HistoricCegidComponent
