import {Grid, Header} from 'semantic-ui-react'

function Error404Page() {

    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 450}}>
                <Header as='h2' color='teal' textAlign='center'>
                    Erreur 404
                </Header>
            </Grid.Column>
        </Grid>
    );

}

export default Error404Page
