import {toast} from "react-toastify";

export function isDevEnv() {
    return process.env.REACT_APP_ENVIRONMENT === 'development';
}

export function isProdEnv() {
    return process.env.REACT_APP_ENVIRONMENT === 'production';
}

export function isPreprodEnv() {
    return process.env.REACT_APP_ENVIRONMENT === 'preproduction';
}

export default {
    isDevEnv, isPreprodEnv, isProdEnv
}

export function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
    toast.success('"'+text + '" copié dans le presse-papier', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: false,
    });
}