import {Button, Container, Menu, Segment} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import User from "./User";
import Shops from "./Shops";
import {toast} from "react-toastify";
import {isAdmin} from "../../utils/authorities-utils";
import {useSelector} from "react-redux";
import Logs from "./Logs";
import Pricings from "./Pricings";
import ShopsGroup from "./ShopsGroup";
import LogManager from "./LogManager";
import Models from "./Models";
import QuotePending from "./QuotePending";
import StuartAddressValidation from "./StuartAddressValidation";


function SettingPage() {

    const [tab, setTab] = useState('users');
    const [notificationText, setNotificationText] = useState('');
    const shopPartner = useSelector(state => state.shop.shopPartner);
    const connectedUser = useSelector(state => state.user.user);
    const [forceUpdate, setForceUpdate] = useState();
    const [kafkaState, setKafkaState] = useState({orangeConsumerRunning: false, shippingConsumerRunning: false});


    function getKafkaState() {

        axiosInstance.get("/admin/kafka/state").then((response) => {
            setKafkaState(response.data)

        });
    }

    function startKafka() {
        setForceUpdate(false);
        axiosInstance.get("/admin/kafka/startconsumer").then((response) => {
            toast.success("Start kafka")
            setTimeout(function () {
                setForceUpdate(true);
            }, 5000);
        });
    }

    function stopKafka() {
        setForceUpdate(false);
        axiosInstance.get("/admin/kafka/stopconsumer").then((response) => {
            toast.success("Stop kafka")
            setTimeout(function () {
                setForceUpdate(true);
            }, 5000);
        });
    }

    useEffect(() => getKafkaState, [forceUpdate]);


    function forceAutomaticPacking() {
        axiosInstance.get("/admin/batch/autoshipping").then((response) => {
            toast.success("Batch de colisage complété")
        });
    }

    function sendNotification() {
        axiosInstance.post("/notification/test", {
            subject: "Notification de test",
            content: notificationText,
            link: "https://preprod.orange.save.co",
            priority: "HIGH_PRIORITY"

        }).then((response) => {
            toast.success("Notification sended")
        });
    }


    function clearBoCache() {
        axiosInstance.get("/admin/emptybocache").then((response) => {
            toast.success("Les caches du backoffice ont étés vidés")
        });
    }

    function cancelAllInterventions() {
        axiosInstance.get("/intervention/cancelAll").then((response) => {
            toast.success("Les internvetions ont étés annulées dans Trepidai")
        });
    }

    function updateGsma() {
        axiosInstance.get("/gsma").then((response) => {
            toast.success("Mise à jour de GSMA")
        });
    }

    function quoteExpiration() {
        axiosInstance.get("/batch/quoteExpiration").then((response) => {
            toast.success("Les batchs ont étés expirés")
        });
    }

    function newQuoteExpiration() {
        axiosInstance.get("/batch/newQuoteExpiration").then((response) => {
            toast.success("Les batchs ont étés expirés")
        });
    }

    function pickupPlanning() {
        axiosInstance.get("/planning").then((response) => {
            toast.success("Calendrier mis à jour")
        });
    }

    function sendTransitSaveToOrangeMail() {
         axiosInstance.get("/intervention/transitSaveToOrangeMail").then((response) => {
            toast.success("Les boutiques ont été relancées")
        });
    }

    function npsMood() {
        axiosInstance.get("/batch/npsMood").then((response) => {
            toast.success("Le batch a été lancé")
        });
    }

    function relaunchPhoneInShop() {
        axiosInstance.get("/batch/relaunchPhoneInShop").then((response) => {
            toast.success("Le batch a été lancé")
        });
    }

    function launchAnonymizationBatch() {
        axiosInstance.get("/batch/anonymizationBatch").then((response) => {
            toast.success("Le batch a été lancé")
        });
    }

    function exportDataHubOrange() {
        axiosInstance.get("/batch/exportDataHubOrange").then((response) => {
            toast.success("Le batch a été lancé")
        });
    }

    function refreshChronopostStatus() {
        axiosInstance.get("/batch/chronoRefresh").then((response) => {
            toast.success("Le batch a été lancé")
        });
    }

    return (
        <>
            <Container fluid>
                <Menu attached='top' tabular>
                    <Menu.Item
                        active={tab === 'users'}
                        onClick={() => setTab('users')}
                    >Liste des comptes</Menu.Item>
                    <Menu.Item
                        active={tab === 'shops'}
                        onClick={() => setTab('shops')}
                    >Liste des boutiques</Menu.Item>
                    <Menu.Item
                        active={tab === 'do'}
                        onClick={() => setTab('do')}
                    >Liste des groupes ( DO )</Menu.Item>
                    {isAdmin(connectedUser.authorities) ? <Menu.Item
                        active={tab === 'pricing'}
                        onClick={() => setTab('pricing')}
                    >Pricing</Menu.Item> : ""}
                    {isAdmin(connectedUser.authorities) ? <Menu.Item
                        active={tab === 'models'}
                        onClick={() => setTab('models')}
                    >Modèles</Menu.Item> : ""}
                    {isAdmin(connectedUser.authorities) ? <Menu.Item
                        active={tab === 'admin'}
                        onClick={() => setTab('admin')}
                    >Admin</Menu.Item> : ""}
                    {isAdmin(connectedUser.authorities) ? <Menu.Item
                        active={tab === 'logs'}
                        onClick={() => setTab('logs')}
                    >Logs</Menu.Item> : ""}

                    {isAdmin(connectedUser.authorities) ? <Menu.Item
                        active={tab === 'quote_awaiting_validation'}
                        onClick={() => setTab('quote_awaiting_validation')}
                    >Devis en attente</Menu.Item> : ""}
                    {isAdmin(connectedUser.authorities) ? <Menu.Item
                        active={tab === 'logManager'}
                        onClick={() => setTab('logManager')}
                    >Configuration des logs</Menu.Item> : ""}

                    {isAdmin(connectedUser.authorities) ? <Menu.Item
                        active={tab === 'stuartAddressValidation'}
                        onClick={() => setTab('stuartAddressValidation')}
                    >Validation des adresses Stuart</Menu.Item> : ""}

                </Menu>
                <Segment attached='bottom'>
                    {tab === 'users' ? <User/> : null}
                    {tab === 'shops' ? <Shops/> : null}
                    {tab === 'do' ? <ShopsGroup/> : null}
                    {tab === 'pricing' ? <Pricings/> : null}
                    {tab === 'models' ? <Models/> : null}
                    {tab === 'admin' ?
                        <Segment>
                            <h2>Batchs</h2>
                            <Button primary onClick={forceAutomaticPacking}>Colisage automatique</Button>
                            <Button primary onClick={updateGsma}>Mis à jour GSMA</Button>
                            <Button primary onClick={quoteExpiration}>Expiration des anciens devis</Button>
                            <Button primary onClick={newQuoteExpiration}>Expiration des nouveaux devis</Button>
                            <Button primary onClick={pickupPlanning}>Planning des ramassage</Button>
                            <Button primary onClick={sendTransitSaveToOrangeMail}>Relancer la réception des colis</Button>
                            <Button primary onClick={refreshChronopostStatus}>Rafraîchir les statuts chronopost</Button>
                            <Button primary onClick={npsMood}>Nps mood</Button>
                            <Button primary onClick={relaunchPhoneInShop}>Relancer le retrait du produit</Button>
                            <Button primary onClick={launchAnonymizationBatch}>Lancer l'anonymisation</Button>
                            <Button primary onClick={exportDataHubOrange}>Export Data Hub Orange</Button>

                            <h2>Actions techniques</h2>
                            <Button primary onClick={clearBoCache}>Vider les caches</Button>
                            <Button primary onClick={startKafka}>Démarrer Kafka</Button>
                            <Button primary onClick={stopKafka}>Arrêter Kafka</Button>
                            <h2>Actions métiers</h2>
                            <Button primary onClick={cancelAllInterventions}>Mise à jour des interventions annulées dans Trepidai</Button>

                            <h2>Outils</h2>
                            <Button as='a' href={process.env.REACT_APP_BACKOFFICE_URL + "/admin/advredirect"}
                                    target={"_blank"}>ADV Redirect</Button>
                            <Button as='a' href={process.env.REACT_APP_BACKOFFICE_URL + "/admin/advsupportredirect"}
                                    target={"_blank"}>ADV Redirect Support</Button>

                            {/*</Segment>*/}
                            {/*<textarea name="" id="" cols="30" rows="10" onChange={(event)=>setNotificationText(event.target.value)}></textarea>*/}
                            {/*<Button primary onClick={sendNotification}> Send notification</Button>*/}
                            {/*<Label>orangeConsumerRunning : {kafkaState.orangeConsumerRunning?"True":"False"}</Label>*/}
                            {/*<Label>shippingConsumerRunning : {kafkaState.shippingConsumerRunning?"True":"False"}</Label>*/}

                        </Segment> : null}
                    {tab === 'logs' ? <Logs/> : null}
                    {tab === 'quote_awaiting_validation' ? <QuotePending/> : null}
                    {tab === 'logManager' ? <LogManager/> : null}
                    {tab === 'stuartAddressValidation' ? <StuartAddressValidation/> : null}
                </Segment>
            </Container>
        </>
    );

}

export default SettingPage
