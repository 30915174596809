import {
    FETCH_INTERVENTIONS_LIST,
    SELECT_INTERVENTION,
    FETCH_INTERVENTION,
    FETCH_INTERVENTION_SUCCESS,
    FETCH_INTERVENTION_HISTORIC,
    FETCH_INTERVENTION_HISTORIC_SUCCESS,
    ADD_HISTORIC_ELEMENT,
    RESET_FOLLOWUP,
    FETCH_INTERVENTIONS_LIST_SUCCESS,
    DELETED_INTER,
    FETCH_INTERVENTION_PARCEL_HISTORIC
} from "./followup.types";
import {RESET_APP} from "../GlobalUi/globalUi.types";
import {RESET_PRODUCT_INFO} from "../Product/product.types";

const INITIAL_STATE = {
    interventionList: [],
    historic:[],
    page: 0,
    hasMoreItems: false,
    selectedIntervention: null,
    parcelHistoric: null,
    totalElements : 0,
    isFollowupLoading: false,
    isInterventionLoading: false,
    isInterventionNotRepaired: false,
    isHistoricLoading: false,
    isAddHistoricLoading: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case RESET_FOLLOWUP: {
            return {
                ...state,
                interventionList: [],
                totalElements: 0,
                hasMoreItems: true,
                page: 0
            }
        }

        case FETCH_INTERVENTIONS_LIST: {
            return {...state,
                isFollowupLoading: true
            }
        }

        case FETCH_INTERVENTIONS_LIST_SUCCESS: {
            return {...state,
                interventionList: [...state.interventionList, ...action.interventions],
                hasMoreItems: state.interventionList.length < action.totalElements ? true : false,
                totalElements: action.totalElements,
                page: action.page,
                isFollowupLoading: false
            }
        }

        case SELECT_INTERVENTION: {
            return {...state,
                selectedIntervention: action.intervention,
                historic:[]
            }
        }

        case FETCH_INTERVENTION: {
            return {...state,
                isInterventionLoading: true,
                historic:[]
            }
        }

        case FETCH_INTERVENTION_SUCCESS: {
            return {...state,
                isInterventionLoading: false,
                selectedIntervention: action.intervention
            }
        }

        case FETCH_INTERVENTION_HISTORIC: {
            return {...state,
                isHistoricLoading: true,
                historic:[]
            }
        }

        case FETCH_INTERVENTION_HISTORIC_SUCCESS: {
            return {...state,
                isHistoricLoading: false,
                isAddHistoricLoading: false,
                historic: action.historic,
                isInterventionNotRepaired:action.historic.find(element => element.status === "PRODUCT_NOT_REPAIRED")
            }
        }

        case ADD_HISTORIC_ELEMENT: {
            return {...state,
                isAddHistoricLoading: true,
            }
        }

        case DELETED_INTER: {
            return {...state,
                selectedIntervention: null,
                historic: [],
                interventionList: state.interventionList.filter((inter) => inter.id !== action.interventionId)
            }
        }

        case FETCH_INTERVENTION_PARCEL_HISTORIC: {
            return {...state,
                parcelHistoric: action.parcelHistoric,
            }
        }

        case RESET_PRODUCT_INFO:{
            return INITIAL_STATE;
        }

        case RESET_APP:
            return INITIAL_STATE

        default:
            return state;
    }
};

export default reducer;
