import {Step,} from 'semantic-ui-react'
import React, {useState, useEffect} from "react";
import {getCategoryLabel} from "../../utils/repairLabels"

function StatedManuelDiagStepper(props) {

    const [csteps, setCsteps] = useState([])

    useEffect(() => {
        let temp = [];
        for (let diag of props.steps) {
            temp.push({
                key: diag.category,
                title: getCategoryLabel(diag.categoryLabel),
                active: props.currentDiag.category === diag.category
            })
            setCsteps(temp);
        }
    }, [props.currentDiag]);

    return (
        <Step.Group items={csteps} fluid/>
    );
}

export default StatedManuelDiagStepper;