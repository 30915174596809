import {Container, Divider, Header, Icon, Image, List, Segment} from 'semantic-ui-react'
import React from "react";
import {useSelector} from "react-redux";
import logoOrange from "../../components/HeaderMenu/logo-orange.png";
import logoMood from "../../components/HeaderMenu/logo-mood.png";

function ShopPartner() {

    const shopPartner = useSelector(state => state.shop.shopPartner);

    const trueAnswerRender = <><Icon name='check' color='green' />OUI</>;
    const falseAnswerRender = <><Icon name='remove' color='red'/>NON</>;

    return (
        <>
            <Container fluid>


                    <Header block as='h1' size='medium'>
                        <Image  src={shopPartner.type === 'MOOD' ? logoMood : logoOrange} />
                        <Header.Content>Boutique
                        <Header.Subheader>{shopPartner.name}</Header.Subheader>
                        </Header.Content>
                    </Header>
                <Segment>
                    <List>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.code}
                            </List.Content>
                            <List.Content>Code boutique</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.name}
                            </List.Content>
                            <List.Content>Nom de la boutique</List.Content>
                        </List.Item>
                        <Divider horizontal>
                            Adresse
                        </Divider>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.address.adressLine1}
                            </List.Content>
                            <List.Content>Adresse ligne 1</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.address.adressLine2}
                            </List.Content>
                            <List.Content>Adresse ligne 2</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.address.zipCode}
                            </List.Content>
                            <List.Content>Code Postal</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.address.city}
                            </List.Content>
                            <List.Content>Ville</List.Content>
                        </List.Item>

                        <Divider horizontal>Paramètres</Divider>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.pickupType==="COURSIER" ? trueAnswerRender : falseAnswerRender}
                            </List.Content>
                            <List.Content>Boutique éligible à l'enlèvement par coursier</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.pickupType==="BYSHOP" ? trueAnswerRender : falseAnswerRender}
                            </List.Content>
                            <List.Content>Boutique éligible à l'enlèvement par boutique Save</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.pickupType==="CHRONOPOST" ? trueAnswerRender : falseAnswerRender}
                            </List.Content>
                            <List.Content>Boutique éligible à Chronopost</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopPartner.automaticPacking===true ? trueAnswerRender : falseAnswerRender}
                            </List.Content>
                            <List.Content>Enlèvement automatique</List.Content>
                        </List.Item>
                    </List>
                </Segment>
            </Container>

        </>
    );

}

export default ShopPartner
