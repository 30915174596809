import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import {useSelector} from "react-redux";
import {Button, Checkbox, Dropdown, Input, Label, List, Menu, Pagination, Segment, Table} from "semantic-ui-react";
import Moment from "react-moment";
import parcelStatusMap from "../../utils/status/parcelStatus";
import {printDeliverysheet} from "../../reducers/DeliverySheet/deliverysheet.actions";
import {getTrackingInfos, printChronopostTracking} from "../../reducers/Parcel/parcel.actions";
import historicTypeMap from "../../utils/status/interventionStatus";
import {Link} from "react-router-dom";
import useDebounce from "../../utils/use-debounce";

function ParcelHistoric() {

    const shop = useSelector(state => state.shop.shopPartner);
    const global = useSelector(state => state.global);

    const [showDetail, setShowDetail] = useState(false);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [nbrPage, setNbrPage] = useState(1);
    const [search, setSearch] = useState();
    const [statusl, setStatusl] = useState();
    const [shipmentStype, setShipmentStype] = useState();
    const [parcels, setParcels] = useState([]);
    const [parcelOpenId, setParcelOpenId] = useState(0);

    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchTerm = useDebounce(searchQuery, 500);

    useEffect(() => {
        if (debouncedSearchTerm !== "") {
            setSearch(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm])

    const handleLevelSearchChange = (e, {name, value}) => {
        setSearchQuery(value);
    };

    useEffect(() => {
        axiosInstance.post("/parcels/",
            {
                page: page,
                size: size,
                shopPartnerId: shop.id,
                search: debouncedSearchTerm,
                status: statusl,
                shipmentStype: shipmentStype

            })
            .then((response) => {
                setNbrPage(response.data.totalPages)
                setParcels(response.data.content.filter((parcel)=>parcel.status !== 'CREATION'))
            })
            .catch((error) => {
            });
    }, [page, search, statusl, shipmentStype, global.refreshParcels, debouncedSearchTerm])

    const handlePageChange = ({activePage}) => {
        setPage(activePage - 1);
    }

    const getSpecificAction = (parcel) => {
        return <span><Button onClick={() => printDeliverysheet(parcel.id)}>Imprimer Bon d'enlèvement</Button>
            {parcel.shipmentType === "CHRONOPOST" && (parcel.status !== 'CHRONOPOST_SHIPMENT_FINISHED' && parcel.status !== 'RECOVERED_BY_SHOP') ?
                <Button onClick={() => printChronopostTracking(parcel.trackingCode)}>Afficher le bon</Button> : null}
        </span>
    }

    const shipmentDetail = (parcel) => {

        if (parcel.shipmentType == "CHRONOPOST") {
            return (<List.Item>
                <List.Icon name='box' size='large' verticalAlign='middle'/>
                <List.Content>
                    <List.Header>{parcel.parcelType === 'ORANGE_TO_SAVE' ? 'PAR_' + parcel.id : 'PAR_SAVE_' + parcel.saveParcelId}</List.Header>
                    <List.Description>Colis Chronopost</List.Description>
                </List.Content>
            </List.Item>)
        } else if (parcel.shipmentType == "COURSIER") {
            return (<List.Item>
                <List.Icon name='bicycle' size='large' verticalAlign='middle'/>
                <List.Content>
                    <List.Header>{parcel.parcelType === 'ORANGE_TO_SAVE' ? 'PAR_' + parcel.id : 'PAR_SAVE_' + parcel.saveParcelId}</List.Header>
                    <List.Description>Colis Coursier</List.Description>
                </List.Content>
            </List.Item>)
        } else {
            return (<List.Item>
                <List.Icon name='street view' size='large' verticalAlign='middle'/>
                <List.Content>
                    <List.Header>{parcel.parcelType === 'ORANGE_TO_SAVE' ? 'PAR_' + parcel.id : 'PAR_SAVE_' + parcel.saveParcelId}</List.Header>
                    <List.Description>Colis Save</List.Description>
                </List.Content>
            </List.Item>)
        }

    }

    const parseStatus = (parcel) => {
        var status = parcelStatusMap.get(parcel.status)
        if (status !== undefined)
            return status;
        return {color: "red", text: "Statut inconnu"}
    }

    return (
        <Segment>
            <Menu size='small'>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Input icon='search'
                               value={searchQuery}
                               onChange={handleLevelSearchChange}
                               placeholder='Rechercher...'/>
                    </Menu.Item>
                </Menu.Menu>

            </Menu>

            <Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Référence interne</Table.HeaderCell>
                        <Table.HeaderCell>Référence externe</Table.HeaderCell>
                        <Table.HeaderCell collapsing>Nombre de produits</Table.HeaderCell>
                        <Table.HeaderCell>Statut</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {parcels.map(parcel => (
                        <>
                            <Table.Row positive>
                                <Table.Cell collapsing>
                                    <Checkbox toggle checked={parcelOpenId === parcel.id}
                                              onChange={(event, self) => setParcelOpenId(parcel.id === parcelOpenId ? 0 : parcel.id)}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <List>
                                        <List.Item>
                                            <List.Icon name='calendar alternate outline' size='large'
                                                       verticalAlign='middle'/>
                                            <List.Content>
                                                <List.Header>Le <Moment
                                                    format="dddd Do MMMM YYYY">{parcel.sealedDate ? parcel.sealedDate : parcel.creationDate}</Moment></List.Header>
                                                <List.Description>à <Moment
                                                    format="HH:mm">{parcel.sealedDate ? parcel.sealedDate : parcel.creationDate}</Moment>,
                                                    par {parcel.creatorLogin}</List.Description>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Table.Cell>
                                <Table.Cell>
                                    <List>
                                        {shipmentDetail(parcel)}
                                    </List>
                                </Table.Cell>
                                <Table.Cell>
                                    {parcel.trackingCode}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <List>
                                        <List.Item>
                                            <Label>{parcel.parcelItems.length}</Label>
                                        </List.Item>
                                    </List>
                                </Table.Cell>
                                <Table.Cell>
                                    <Label color={parseStatus(parcel).color} horizontal>
                                        {parseStatus(parcel).icon}
                                        {parseStatus(parcel).text}
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>
                                    {parcel.trackingUrl !== null ?
                                        <Button href={parcel.trackingUrl} target="_blank">Suivre le colis</Button> : ""}
                                    {getSpecificAction(parcel)}
                                </Table.Cell>
                            </Table.Row>
                            {parcelOpenId === parcel.id ? <>

                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.Cell colSpan='6'>
                                        <Table compact='very' collapsing>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Dossier</Table.HeaderCell>
                                                    <Table.HeaderCell>Client</Table.HeaderCell>
                                                    <Table.HeaderCell>Produit</Table.HeaderCell>
                                                    <Table.HeaderCell>Imei</Table.HeaderCell>
                                                    <Table.HeaderCell>Statut</Table.HeaderCell>
                                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>

                                                {parcel.parcelItems.map((parcelItem) => (

                                                    <Table.Row>
                                                        <Table.Cell>ORG_{parcelItem.intervention.id}</Table.Cell>
                                                        <Table.Cell>
                                                            <List>
                                                                <List.Item>
                                                                    <List.Icon name='user' size='large'
                                                                               verticalAlign='middle'/>
                                                                    <List.Content>
                                                                        <List.Header>{parcelItem.intervention.customer.firstName} {parcelItem.intervention.customer.lastName}</List.Header>
                                                                        <List.Description>{parcelItem.intervention.customer.email}</List.Description>
                                                                    </List.Content>
                                                                </List.Item>
                                                            </List>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <List>
                                                                <List.Item>
                                                                    <List.Icon name='mobile alternate' size='large'
                                                                               verticalAlign='middle'/>
                                                                    <List.Content>
                                                                        <List.Header>{parcelItem.intervention.product.brandName}</List.Header>
                                                                        <List.Description>{parcelItem.intervention.product.name}</List.Description>
                                                                    </List.Content>
                                                                </List.Item>
                                                            </List>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {parcelItem.intervention.product.imei}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Label color='orange' horizontal>
                                                                {historicTypeMap.has(parcelItem.intervention.status) ? historicTypeMap.get(parcelItem.intervention.status).label : "??"}
                                                            </Label>
                                                        </Table.Cell>
                                                        <Table.Cell>

                                                            <Link
                                                                to={"/followup?intervention=" + parcelItem.intervention.id}>
                                                                <Button size='small' basic fluid>Voir</Button>
                                                            </Link>

                                                        </Table.Cell>
                                                    </Table.Row>))
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Table.Cell>
                                </Table.Row>

                            </> : null}
                        </>

                    ))}
                </Table.Body>

            </Table>
            <Pagination totalPages={nbrPage} activePage={page + 1}
                        onPageChange={(e, pageEvent) => handlePageChange(pageEvent)} defaultActivePage={0}/>
        </Segment>
    )


}

export default ParcelHistoric