import React from "react";

const errorMessageMap = new Map();

errorMessageMap.set(
    "CANT_GEOCODE_ADDRESS", {
        text: 'Adresse hors scope Stuart'
    }
);

export default errorMessageMap;
