import {Container, Menu} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import ParcelPreparation from "./ParcelPreparation";
import LastParcelComponent from "./LastParcelComponent";
import ParcelHistoric from "./ParcelHistoric";
import RestockComponent from "./RestockComponent";

function ParcelPage({match}) {

    const { tab } = match.params;
    const [activeItem,setActiveItem] = useState('home')

    useEffect(() => {
        if(tab === 'history')
            setActiveItem('histo')
    }, []);

    return (
        <>
        <Container fluid>
            <div>
                <Menu pointing>
                    <Menu.Item
                        active={activeItem === 'home'}
                        onClick={() => setActiveItem('home')}
                    >Accueil</Menu.Item>
                    <Menu.Item
                        name='histo'
                        active={activeItem === 'histo'}
                        onClick={() => setActiveItem('histo')}
                    >Historique</Menu.Item>
                    <Menu.Item
                        name='logistic'
                        active={activeItem === 'logistic'}
                        onClick={() => setActiveItem('logistic')}
                    >Logistique</Menu.Item>
                </Menu>
            </div>

            {activeItem === 'home' ? <>
                <ParcelPreparation/>
                <LastParcelComponent />
            </> : null}

            {activeItem === 'histo' ?
                <ParcelHistoric/> : null}

            {activeItem === 'logistic' ?
                <RestockComponent/> : null}

        </Container>

    </>
    );

}

export default ParcelPage
