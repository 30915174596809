import {Button, Grid, Modal, Segment} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import axiosInstance from "../../utils/axiosInstance";
import {useHistory} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Signature({uuid, openModal, validationHandler, cancelHandler, type, onClose}) {



    const [signInProgress, setSignInProgress] = useState(false)
    const [loadingSavingSign, setLoadingSavingSign] = useState(false)


    const [selectedHistoric, setSelectedHistoric] = useState(null);

    const history = useHistory();

    const followup = useSelector(state => state.followup);
    const connectedUser = useSelector(state => state.user.user);
    const dispatch = useDispatch();


    const {register, handleSubmit, setValue} = useForm();


    useEffect(() => {
        register({name: "docid"});
        register({name: "docurl"});
        register({name: "resulturl"});
        register({name: "cmd_option"});
        register({name: "open_doc"});
    }, [register]);

    const getDocument = () => {
        setLoadingSavingSign(true);

        axiosInstance.get(process.env.REACT_APP_SIGN_PAD_WEB_SERVER + "/rest/v5/documents/" + uuid, {responseType: 'blob'})
            .then((response) => {

                console.log(response);

                let data = new FormData()

                data.append('name', 'image')
                data.append('file', response.data)
                data.append('uuid', uuid)

                let config = {
                    header: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                axiosInstance.post("/pdf/saveSignedDocument", data, config).then(response => {
                    if (validationHandler !== undefined) {
                        console.log(validationHandler !== undefined)
                        validationHandler();
                    }
                    onClose();
                    setLoadingSavingSign(false);
                }).catch(error => {
                    console.log('error', error)
                    setLoadingSavingSign(false);
                })

            })
            .catch((error) => {
                // setIsLoading(false);
                console.log("error")
                setLoadingSavingSign(false);
            });
    }


    let form = null;
    if (type === 'HANDLE_REPORT') {
        form = <form action={process.env.REACT_APP_SIGN_PAD_WEB_SERVER + "/load/byupload"} method="post"
                     encType="multipart/form-data" onSubmit={() => setSignInProgress(true)}
                     id="thisform" target="_none">
            <input type="hidden" name="docid" value={uuid}></input>
            <input type="hidden" name="docurl"
                   value={process.env.REACT_APP_BACKOFFICE_URL + "/admin/handleReportUuid/" + uuid}></input><br></br>
            <input type="hidden" name="resulturl"
                   value={process.env.REACT_APP_SIGN_PAD_WEB_SERVER + "/result/index?showoptions=true"}></input>
            <input type="hidden" name="cmd_option"
                   value="name=sig1|page=1|type=formfield|subtype=signature|bottom=350|left=350|width=180|height=50"></input>
        </form>
    } else if (type === 'INTERVENTION_REPORT') {
        form = <form action={process.env.REACT_APP_SIGN_PAD_WEB_SERVER + "/load/byupload"} method="post"
                     encType="multipart/form-data" onSubmit={() => setSignInProgress(true)}
                     id="thisform" target="_none">
            <input type="hidden" name="docid" value={uuid}></input>
            <input type="hidden" name="docurl"
                   value={process.env.REACT_APP_BACKOFFICE_URL + "/admin/interventionReportUuid/" + uuid}></input><br></br>
            <input type="hidden" name="resulturl"
                   value={process.env.REACT_APP_SIGN_PAD_WEB_SERVER + "/result/index?showoptions=true"}></input>
            <input type="hidden" name="cmd_option"
                   value="name=sig1|page=1|type=formfield|subtype=signature|bottom=210|left=225|width=180|height=50"></input>
        </form>
    }

    return (
        <Modal
            onClose={onClose}
            open={openModal}
            closeOnDimmerClick={type === 'INTERVENTION_REPORT' ? true : false}

        >
            <Modal.Header>Signature du document</Modal.Header>
            <Modal.Content>

                <Modal.Description>
                    <Segment>
                        {form}
                        {!signInProgress ?
                            <div className={"ui container center aligned"}>
                                <Grid columns='two' divided>
                                    <Grid.Column>
                                        <Button primary size={"massive"} type="button"
                                                onClick={cancelHandler}><FontAwesomeIcon style={{marginRight: '5px'}}
                                                                                         icon="mouse"/>Signature manuelle</Button>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button primary size={"massive"} type="submit" form="thisform"> <FontAwesomeIcon
                                            style={{marginRight: '5px'}} icon="file-signature"/>Signature via
                                            PAD</Button>
                                    </Grid.Column>
                                </Grid>
                            </div> : <div className={"ui container center aligned"}>
                                <Grid columns='three' divided>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Button primary size={"massive"} type="button"
                                                    onClick={cancelHandler}><FontAwesomeIcon
                                                style={{marginRight: '5px'}} icon="file-signature"/>Manuel</Button>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button primary size={"massive"}
                                                    style={{'height': '100%', 'fontSize': '1.514286rem'}} type="submit"
                                                    form="thisform"><FontAwesomeIcon style={{marginRight: '5px'}}
                                                                                     icon="redo-alt"/>Recommencer</Button>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button primary size={"massive"} type="button" loading={loadingSavingSign} disabled={loadingSavingSign}
                                                    onClick={getDocument}><FontAwesomeIcon style={{marginRight: '5px'}}
                                                                                           icon="save"/>Enregistrer</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>}

                    </Segment>
                </Modal.Description>
            </Modal.Content>
        </Modal>


    );

}

export default Signature
