import {Step,} from 'semantic-ui-react'
import React from "react";

function WorkflowStepper(props) {

    const steps = [
        // {
        //     key: 'diag',
        //     icon: 'eye',
        //     active: props.step === 1,
        //     disabled: props.step < 1,
        //     completed: props.step > 1,
        //     title: 'Diagnostic visuelzzz',
        //     description: 'Evaluation du produit',
        // },
        // {
        //     key: 'picea',
        //     icon: 'cloud',
        //     active: props.step === 2,
        //     disabled: props.step < 2,
        //     completed: props.step > 2,
        //     title: 'Diagnostic technique',
        //     description: 'Evaluation du produit',
        // },
        // {
        //     key: 'quote',
        //     icon: 'euro',
        //     active: props.step === 4,
        //     disabled: props.step < 4,
        //     completed: props.step > 4,
        //     title: 'Devis',
        //     description: 'Calcul du prix et infos clients'
        // },
        // {
        //     key: 'resume',
        //     active: props.step === 5,
        //     disabled: props.step < 5,
        //     completed: props.step === 5,
        //     icon: 'paperclip',
        //     title: 'Résumé',
        //     description: 'Récapitulatif de la prise en charge'
        // }
    ]

    const steps2 = [
        {
            key: 'diag',
            icon: 'eye',
            active: props.step === 1,
            disabled: props.step < 1,
            completed: props.step > 1,
            title: 'Diagnostic visuel',
            description: 'Evaluation du produit',
        },
        {
            key: 'picea',
            icon: 'cloud',
            active: props.step === 3,
            disabled: props.step < 3,
            completed: props.step > 3,
            title: 'Diagnostic technique',
            description: 'Evaluation du produit',
        },
        {
            key: 'quote',
            icon: 'euro',
            active: props.step === 4,
            disabled: props.step < 4,
            completed: props.step > 4,
            title: 'Devis',
            description: 'Calcul du prix et infos clients'
        },
        {
            key: 'resume',
            active: props.step === 5,
            disabled: props.step < 5,
            completed: props.step === 5,
            icon: 'paperclip',
            title: 'Résumé',
            description: 'Récapitulatif de la prise en charge'
        }
    ]

    return (
        <Step.Group items={props.smaKO ? steps2 : steps} fluid/>
    );
}


export default WorkflowStepper;
