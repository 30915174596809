import {Divider, Grid, Header, Icon, Image, Message, Segment} from 'semantic-ui-react'
import {LOGGIN} from '../reducers/User/user.types';
import {getUser, logUser, setToken} from '../reducers/User/user.actions';
import React, {useEffect, useState} from 'react';
import logoOrange from './img/logo-orange.png';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import * as QueryString from "query-string";
import {axiosInstance} from "../utils/axiosInstance";
import {RESET_PRODUCT_INFO} from "../reducers/Product/product.types";
import {INIT_CRMGP_DATAS} from "../reducers/Crmgp/crmgp.types";

function AdvLoginPage() {

    const [login, setLogin] = useState("admin");
    const [password, setPassword] = useState("admin");
    const history = useHistory();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const location = useLocation();
    const params = QueryString.parse(location.search);

    let token = params.token;
    let errorCode = params.errorCode;

    let nomClient = params.nomClient;
    let prenomCl = params.prenomCl;
    let mailContactCl = params.mailContactCl;
    let telContactCl = params.telContactCl;
    let interventionId = params.interventionId;
    let cuid = params.cuid;

    useEffect(() => {

        if (token !== undefined) {
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            dispatch(setToken(token));
            dispatch(getUser())

            if(nomClient || prenomCl || mailContactCl || telContactCl || cuid) {
                dispatch({
                    type: INIT_CRMGP_DATAS,
                    nomClient:nomClient,
                    prenomCl:prenomCl,
                    mailContactCl:mailContactCl,
                    telContactCl:telContactCl,
                    cuid: cuid
                });
            }

        }

        if(user.isLogged && interventionId && interventionId > 0) {
            dispatch({type: RESET_PRODUCT_INFO});
            history.push("/followup?intervention=" + interventionId)
        } else if (user.isLogged) {
            history.push("/");
        }
    }, [user.isLogged]);


    useEffect(() => {
        if (user.token) {
            dispatch(getUser())
        }
        // else if (localStorage.getItem('token_sav') !== undefined) {
        //     dispatch({
        //         type: SET_TOKEN,
        //         token: localStorage.getItem('token_sav'),
        //     });
        // }
    }, [user.token]);

    const logAction = () => {
        dispatch(logUser({type: LOGGIN, login: login, password: password}))
    }

    return (
        errorCode === "UNKNONW_ADV" ? (
                <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                    <Grid.Column style={{maxWidth: 450}}>
                        <Header as='h2' color='teal' textAlign='center'>

                            <Segment>
                                <Grid basic columns={1}>
                                    {/*<Grid.Column verticalAlign='middle'>*/}
                                    {/*    <Image src={logoSave} size='tiny' centered/>*/}
                                    {/*</Grid.Column>*/}
                                    <Grid.Column verticalAlign='middle'>
                                        <Image src={logoOrange} size='tiny' centered/>
                                    </Grid.Column>
                                </Grid>
                                <Divider vertical/>
                            </Segment>
                        </Header>


                        <Message warning><Icon name='warning'/>La boutique n’est pas habilitée à accéder à cette application</Message>
                    </Grid.Column>
                </Grid>)
            :
            ""


    );

}

export default AdvLoginPage
