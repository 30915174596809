import {Container, Form, Grid, Header, Input, Menu, Pagination, Segment, Table} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import ShopItem from "./ShopItem";
import useDebounce from "../../utils/use-debounce";
function Shops() {

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [totalPageNumber, setTotalPageNumber] = useState(1)
    const [search, setSearch] = useState();
    const [shops, setShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null);
    const debouncedSearchTerm = useDebounce(search, 500);

    useEffect(() => {
        axiosInstance.post("/shops",
            {
                page: page,
                size: size,
                search: debouncedSearchTerm,
            })
            .then((response) => {
                console.log(response);
                setTotalPageNumber(response.data.totalPages)
                setShops(response.data.content)
            })
            .catch((error) => {
                console.log("error")
            });
    }, [page, debouncedSearchTerm, selectedShop])

    const handlePageChange = (e, activePage) => {
        setPage(activePage.activePage - 1);
    }

    const handleSearchChange = (e, { name, value }) => {
        setSearch(value);
    }

    return (
        <>
            <Container fluid>

                <Menu>
                    <Menu.Item>
                        <h2>Liste des boutiques</h2>
                    </Menu.Item>
                    <Menu.Item position='right' >
                            <Input className='icon' icon='search' placeholder='Recherche...' value={search} onChange={handleSearchChange}/>
                    </Menu.Item>
                </Menu>

                <Table compact celled definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Code</Table.HeaderCell>
                            <Table.HeaderCell>Nom</Table.HeaderCell>
                            <Table.HeaderCell>Adresse</Table.HeaderCell>
                            <Table.HeaderCell>Save</Table.HeaderCell>
                            <Table.HeaderCell>Logistique</Table.HeaderCell>
                            <Table.HeaderCell>Workflow</Table.HeaderCell>
                            <Table.HeaderCell>Ramassage</Table.HeaderCell>
                            {/*<Table.HeaderCell textAlign="center">Colisage auto ?</Table.HeaderCell>*/}
                            {/*<Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>*/}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <ShopItem shops={shops} setShops={setShops} setSelectedShops={setSelectedShop}/>

                    </Table.Body>

                </Table>
                {/*selectedShop!==null?<UserDetail selectedShop={selectedShop} setModalOpen={setModalOpen} setSelectedShop={setSelectedShop}/>:""*/}

            </Container>

            <Pagination
                activePage={page + 1}
                style={{width: "100%", float: "right"}}
                onPageChange={(e, pageEvent) => handlePageChange(e, pageEvent)}
                defaultActivePage={0}
                totalPages={totalPageNumber}
                style={{marginTop: '10px'}}/>

        </>
    );

}

export default Shops
