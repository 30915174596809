import React, {useState} from "react";
import {Message} from "semantic-ui-react";
import Moment from "react-moment";

function HistoricUpdateCustomerComponent({historicElement}) {

    const [expanded, setExpanded] = useState(false);

    console.log(historicElement)

    if(!expanded) {
        return (
            <Message success size={"tiny"} onClick={() => setExpanded(true)}
                     style={{cursor: 'pointer',paddingBottom: '1px', paddingTop: '1px'}}>
                Modification du client <a onClick={() => setExpanded(true)}>(voir détails)</a></Message>
        );
    }

    if(expanded) {
        return (
            <Message success size={"tiny"} onClick={() => setExpanded(false)}
                     style={{ cursor: 'pointer', paddingBottom: '1px', paddingTop: '1px'}}>
                <Message.Header>Modification du client le <Moment format="ddd DD MMM YYYY [à] HH:mm"
                                                                  locale="fr">{historicElement.date}</Moment> par {historicElement.login} </Message.Header>
                <ul>
                    <li>Prénom :<b>{historicElement.datas ? historicElement.datas.oldFirstName: null} </b> modifié en <b> {historicElement.datas ? historicElement.datas.newFirstName: null}</b></li>
                    <li>Nom : <b> {historicElement.datas ? historicElement.datas.oldLastName : null} </b>  modifié en <b> {historicElement.datas ? historicElement.datas.newLastName: null}</b></li>
                    <li>Email :<b> {historicElement.datas ? historicElement.datas.oldEmail : null} </b>  modifié en <b> {historicElement.datas ? historicElement.datas.newEmail: null}</b></li>
                    <li>Tel.Contact :<b> {historicElement.datas ? historicElement.datas.oldPhone : null} </b>  modifié en <b> {historicElement.datas ? historicElement.datas.newPhone: null}</b></li>
                </ul>

                <a onClick={() => setExpanded(false)}>(moins de détails)</a></Message>
        );
    }
}

export default HistoricUpdateCustomerComponent
