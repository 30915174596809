import {
    ON_ERROR,
    REFRESH_MENU,
    REFRESH_PARCELS,
    RESET_APP,
    GO_TO_INTERVENTION,
    SHOW_ABANDON_POPUP,
    CLOSE_ABANDON_POPUP,
    SHOW_BAT_POPUP,
    CLOSE_BAT_POPUP,
    INCREMENT_PARCEL_COUNTER,
    DECREASE_PARCEL_COUNTER, RESET_PARCEL_COUNTER, SET_PARCEL_COUNTER, RESET_APP_WITHOUT_PARCEL_COUNTER
} from './globalUi.types'

const INITIAL_STATE = {
    errorCode: "",
    errorMessage: "",
    refreshMenu: 0,
    refreshParcels: 0,
    showAbandonPopup: false,
    interventionId: 0,
    showBatPopup: false,
    parcelCounter : 0,
    batPricings: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ON_ERROR:{
            return ({...state,
                errorCode:action.payload.errorCode,
                errorMessage:action.payload.errorMessage
            })
        }

        case REFRESH_MENU:
            return ({...state,
                refreshMenu: state.refreshMenu + 1
            })

        case REFRESH_PARCELS:
            return ({...state,
                refreshParcels: state.refreshParcels + 1
            })

        case SHOW_ABANDON_POPUP:
            return ({...state,
                showAbandonPopup: true
            })

        case CLOSE_ABANDON_POPUP:
            return ({...state,
                showAbandonPopup: false
            })

        case SHOW_BAT_POPUP:
            return ({...state,
                showBatPopup: true,
                batPricings:action.payload.batPricings
            })

        case SET_PARCEL_COUNTER:
            return ({...state,
                parcelCounter:action.payload.parcelCounter
            })

        case INCREMENT_PARCEL_COUNTER:
            return ({...state,
                parcelCounter:state.parcelCounter +1
            })

        case DECREASE_PARCEL_COUNTER:
            return ({...state,
                parcelCounter:state.parcelCounter -1
            })

        case RESET_PARCEL_COUNTER:
            return ({...state,
                parcelCounter: 0
            })

        case CLOSE_BAT_POPUP:
            return ({...state,
                showBatPopup: false,
                batPricings:[]
            })

        case GO_TO_INTERVENTION:
            return ({...state,
                interventionId: action.interventionId
            })

        case RESET_APP_WITHOUT_PARCEL_COUNTER:
            return ({...state,
                errorCode: "",
                errorMessage: "",
                refreshMenu: 0,
                refreshParcels: 0,
                showAbandonPopup: false,
                interventionId: 0,
                showBatPopup: false,
                batPricings: []
            })

        case RESET_APP:
            return INITIAL_STATE

        default:
            return state;
    }
};

export default reducer;
