import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import {useSelector} from "react-redux";
import {Button, Grid, Header, List, Message, Segment} from "semantic-ui-react";
import Moment from "react-moment";
import {printDeliverysheet} from "../../reducers/DeliverySheet/deliverysheet.actions";
import parcelStatusMap from "../../utils/status/parcelStatus";
import {printChronopostTracking} from "../../reducers/Parcel/parcel.actions";

function LastParcelComponent() {

    const shop = useSelector(state => state.shop.shopPartner);
    const global = useSelector(state => state.global);

    const [lastParcel, setLastParcel] = useState(null)
    const [parcelItems, setParcelItems] = useState([]);
    const [parcels,setParcels] = useState([]);

    useEffect(() => {
        axiosInstance.post("/parcels/",
            {page: 0, size: 2, shopPartnerId: shop.id})
            .then((response) => {
                setParcels(response.data.content)
                for (const parcel of response.data.content) {
                    if (parcel.status !== 'CREATION') {
                        setLastParcel(parcel);
                        axiosInstance.get("/parcel/" + parcel.id)
                            .then((response) => {
                                setParcelItems(response.data.parcelItems);
                            })
                            .catch((error) => {
                            });
                        break;
                    }
                }
            })
            .catch((error) => {
            });
    }, [global.refreshParcels]);

    const parseStatus = (parcel)=>{
        var status  = parcelStatusMap.get(parcel.status)
        if(status !==undefined)
            return status;
        return {color: "red",text: "Statut inconnu"}
    }

    return (
        <>
            <Header block as='h4' attached='top'>
                {lastParcel && (lastParcel.status === 'WAIT_COURIER_PICKUP' || lastParcel.status === 'WAIT_SAVE_PICKUP' || lastParcel.status === 'WAIT_TO_POST' || lastParcel.status === 'WAIT_COURIER_ASSIGNATION') ? "Prochain enlèvement" : "Dernier colis édité"}
            </Header>
            <Segment attached>
                {lastParcel ?
                    <>
                        <Grid columns={2}>
                            <Grid.Column >
                                Le <b><Moment format="dddd Do MMMM YYYY">{lastParcel.sealedDate ? lastParcel.sealedDate : lastParcel.creationDate}</Moment></b> à <b><Moment format="HH:mm">{lastParcel.sealedDate ? lastParcel.sealedDate : lastParcel.creationDate}</Moment></b>
                                <List>
                                    <List.Item>
                                        <List.Content floated='right'>
                                            <b>PAR_{lastParcel.id}</b>
                                        </List.Content>
                                        <List.Content>Référence interne :</List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content floated='right'>
                                            <b>{lastParcel.trackingCode}</b>
                                        </List.Content>
                                        <List.Content>Référence externe :</List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Content floated='right'>
                                            {parcelItems.length}
                                        </List.Content>
                                        <List.Content>Nombre de produits :</List.Content>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <Message centered color={parseStatus(lastParcel).color} style={{textAlign: 'center'}}>
                                    {parseStatus(lastParcel).icon}
                                    {parseStatus(lastParcel).text}
                                </Message>
                                <Button.Group widths='3'>
                                    {lastParcel.trackingUrl!==null?<Button href={lastParcel.trackingUrl} target="_blank">Suivre le colis</Button>:null}
                                    {lastParcel.shipmentType==="CHRONOPOST" && (
                                        lastParcel.status !== 'CHRONOPOST_SHIPMENT_FINISHED'
                                        && lastParcel.status !== 'RECOVERED_BY_SHOP'
                                    ) ?
                                        <Button onClick={()=>printChronopostTracking(lastParcel.trackingCode)}>Afficher le bon</Button> : null
                                    }

                                    <Button onClick={()=>printDeliverysheet(lastParcel.id)} >Imprimer le bon d'enlèvement</Button>
                                </Button.Group>

                            </Grid.Column>
                        </Grid>
                    </> : null
                }
            </Segment>

        </>
    )
}

export default LastParcelComponent
