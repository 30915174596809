import {
    Button,
    Container,
    Form,
    Grid, GridRow,
    Icon,
    Image,
    Input,
    Item,
    Label,
    Message,
    Placeholder,
    Popup,
    Segment
} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {useHistory} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {isAdmin} from "../../utils/authorities-utils";
import Moment from "react-moment";
import {axiosInstance} from "../../utils/axiosInstance";
import {fetchIntervention, fetchInterventionHistoric} from "../../reducers/FollowUp/followup.actions";
import {isIMEI} from "../../utils/imei-utils";
import {toast} from "react-toastify";
import {copyToClipboard} from "../../utils/ui-utils";
import colorStatusMap from "../../utils/status/colorStatus";

function ProductDetails({intervention}) {

    const followup = useSelector(state => state.followup);
    const connectedUser = useSelector(state => state.user.user);

    const dispatch = useDispatch();
    const history = useHistory();

    const {register, handleSubmit, setValue} = useForm();
    const [editMode, setEditMode] = useState(false);

    let admin = isAdmin(connectedUser.authorities);

    const onSubmit = data => {

        if(!isIMEI(data.imei,isAdmin(connectedUser.authorities))) {
            toast.error("L'imei " + data.imei + " est invalide !", {
                position: "top-center"
            });
            return;
        }

        data.id = intervention.product.id;
        axiosInstance.patch("/intervention/"+intervention.id+"/product/", data)
            .then((response) => {
                dispatch(fetchIntervention(intervention.id));
                dispatch(fetchInterventionHistoric(intervention.id));
                setEditMode(false);
            })
            .catch((error) => {
            })
    };

    return (<Segment>
            <Label attached='top'>Produit</Label>

            <Container>

                {followup.isInterventionLoading || !intervention.product ?
                    <Placeholder>
                        <Placeholder.Header image>
                            <Placeholder.Line length='medium'/>
                            <Placeholder.Line length='full'/>
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                            <Placeholder.Line length='full'/>
                            <Placeholder.Line length='medium'/>
                        </Placeholder.Paragraph>
                    </Placeholder>
                    :
                    <>
                        <Grid  style={{marginTop: '7px'}}>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <Container style={{height: '100px'}}><Image centered  src={intervention.product.model.pictureUrl?intervention.product.model.pictureUrl:"https://s3-eu-west-1.amazonaws.com/trepidai-fr/model/default.png"} style={{maxHeight: '100%',maxWidth:'100%'}} /></Container>
                                </Grid.Column>

                                {admin && editMode ?
                                    <Grid.Column width={13}>
                                        <Form size={'tiny'}>
                                        <Form.Field>
                                            <label htmlFor="brandName">Marque</label>
                                            <input label='Marque' name="brandName" ref={register}
                                                   defaultValue={intervention.product.brandName}/>
                                        </Form.Field>
                                            <Form.Field>
                                                <label htmlFor="modelName">Modèle</label>
                                                <input label='Modèle' name="name" ref={register}
                                                       defaultValue={intervention.product.name}/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label htmlFor="imei">IMEI</label>
                                                <input label='IMEI' name="imei" ref={register}
                                                       defaultValue={intervention.product.imei}/>
                                            </Form.Field>
                                        </Form>
                                        {admin && editMode ?
                                            <Button style={{marginTop:'5px'}} color={"red"} compact size={"mini"} onClick={() => setEditMode(false)}>Annuler</Button> : null}
                                        {admin && editMode ?
                                            <Button style={{marginTop:'5px'}} color={"green"} compact size={"mini"} onClick={handleSubmit(onSubmit)}>Enregistrer</Button> : null}
                                    </Grid.Column>
                                    :
                                    <Grid.Column width={13}>
                                        <Container style={{
                                            fontSize: '18px',
                                            fontWeight: 'bold'
                                        }}>{intervention.product.brandName}</Container>
                                        <Container style={{
                                            fontSize: '16px',
                                            marginBottom: '3px'
                                        }}>{intervention.product.name}</Container>

                                        <Container style={{
                                            fontSize: '16px',
                                            marginBottom: '3px'
                                        }}>
                                            {intervention.product.color ?
                                            <><button
                                                color={'4CAF50'} style={{
                                                backgroundColor: colorStatusMap.get(intervention.product.color).color,
                                                marginRight: '3px',
                                                marginBottom: '3px',
                                                paddingTop: '18px',
                                                paddingRight: '18px',
                                                border: 'none',
                                                cursor: 'pointer',
                                                borderColor: '#E4EDED'
                                            }}></button> {colorStatusMap.get(intervention.product.color).iconText}<br/></> :null}
                                        </Container>

                                        <Popup position='right center' size={"mini"} content='Cliquer pour copier' trigger={
                                            <Label onClick={() => copyToClipboard(intervention.product.imei)} style={{cursor: 'pointer'}}>
                                                IMEI
                                                <Label.Detail>{intervention.product.imei}</Label.Detail>

                                            </Label>
                                        } />

                                        {intervention.status !== 'QUOTE_SENDED' ?
                                            <><br/>
                                                <Label style={{marginTop: '3px'}}>
                                                    Date estimée :
                                                    <Label.Detail><Moment format="ddd DD MMM"
                                                                          locale="fr">{intervention.previsionalReturnDate}</Moment></Label.Detail>
                                                </Label></> : null}

                                        {admin && !editMode ?<><br/>
                                            <Button style={{marginTop:'5px'}} compact size={"mini"} primary onClick={() => setEditMode(true)}>Editer</Button></> : null}
                                    </Grid.Column>
                                }
                            </Grid.Row>

                        {/*<Grid.Row>*/}
                        {/*    <Grid.Column width={3}>*/}
                        {/*    </Grid.Column>*/}
                        {/*    <Grid.Column width={13}>*/}
                        {/*        {admin && !editMode ?*/}
                        {/*            <Button compact size={"mini"} primary onClick={() => setEditMode(true)}>Editer</Button> : null}*/}
                        {/*        {admin && editMode ?*/}
                        {/*            <Button color={"red"} compact size={"mini"} onClick={() => setEditMode(false)}>Annuler</Button> : null}*/}
                        {/*        {admin && editMode ?*/}
                        {/*            <Button color={"green"} compact size={"mini"} onClick={handleSubmit(onSubmit)}>Enregistrer</Button> : null}*/}
                        {/*    </Grid.Column>*/}
                        {/*</Grid.Row>*/}
                        </Grid>

                    </>
                }
            </Container>

        </Segment>
    );

}

export default ProductDetails
