import {Icon} from "semantic-ui-react";
import React from "react";

const parcelStatusMap = new Map();


parcelStatusMap.set(
    "CREATION", {
        icon: <Icon name='box'/>,
        color:'orange',
        iconText: 'box',
        text: 'Colis en préparation',
    }
);

parcelStatusMap.set(
    "COURIER_REQUESTED", {
        icon: <Icon name='bicycle'/>,
        color:'orange',
        iconText: 'bicycle',
        text: 'Recherche d\'un livreur',
    }
);

parcelStatusMap.set(
    "COURIER_INCOMING", {
        icon: <Icon name='bicycle'/>,
        color:'orange',
        iconText: 'bicycle',
        text: 'Le livreur arrive',
    }
);

parcelStatusMap.set(
    "COURIER_SHIPMENT_INPROGRESS", {
        icon: <Icon name='bicycle'/>,
        color:'green',
        iconText: 'bicycle',
        text: 'Colis récupéré par coursier',
    }
);

parcelStatusMap.set(
    "COURIER_SHIPMENT_FINISHED", {
        icon: <Icon name='bicycle'/>,
        color:'green',
        iconText: 'bicycle',
        text: 'Colis livré à la boutique',
    }
);


parcelStatusMap.set(
    "SAVE_REQUESTED", {
        icon: <Icon name='street view'/>,
        color:'orange',
        iconText: 'street view',
        text: 'Un sauveteur a été prévenu du ramassage',
    }
);

parcelStatusMap.set(
    "SAVE_INCOMING", {
        icon: <Icon name='street view'/>,
        color:'orange',
        iconText: 'street view',
        text: 'Un sauveteur arrive',
    }
);

parcelStatusMap.set(
    "SAVE_SHIPMENT_INPROGRESS", {
        icon: <Icon name='bicycle'/>,
        color:'green',
        iconText: 'bicycle',
        text: 'Colis récupéré par un sauveteur',
    }
)
parcelStatusMap.set(
    "SAVE_SHIPMENT_BACK_INPROGRESS", {
        icon: <Icon name='bicycle'/>,
        color:'green',
        iconText: 'bicycle',
        text: 'Colis déposé par un sauveteur',
    }
);

parcelStatusMap.set(
    "SAVE_SHIPMENT_FINISHED", {
        icon: <Icon name='bicycle'/>,
        color:'green',
        iconText: 'bicycle',
        text: 'Colis livré en boutique',
    }
);

parcelStatusMap.set(
    "CHRONOPOST_REQUESTED", {
        icon: <Icon name='box'/>,
        color:'orange',
        iconText: 'box',
        text: 'Colis en attente de ramassage Chronopost',
    }
);

parcelStatusMap.set(
    "CHRONOPOST_SHIPMENT_INPROGRESS", {
        icon: <Icon name='box'/>,
        color:'grenn',
        iconText: 'box',
        text: 'Colis pris en charge par chronopost',
    }
);

parcelStatusMap.set(
    "CHRONOPOST_SHIPMENT_FINISHED", {
        icon: <Icon name='box'/>,
        color:'grenn',
        iconText: 'box',
        text: 'Colis livré en boutique',
    }
);

parcelStatusMap.set(
    "CANCELED", {
        icon: <Icon name='bicycle'/>,
        color:'red',
        iconText: 'bicycle',
        text: 'Commande annulée',
    }
);

parcelStatusMap.set(
    "RECOVERED_BY_SHOP", {
        icon: <Icon name='shipping fast'/>,
        color:'green',
        iconText: 'shipping fast',
        text: 'Colis acquitté par save',
    }
);



export default parcelStatusMap;
