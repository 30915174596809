import React from "react";

const repairLabelMap = new Map();
repairLabelMap.set("DOX", {categoryLabel: 'Désoxydation',repairLabel: 'Désoxydation',sortOrder: 999});
repairLabelMap.set("LCD", {categoryLabel: 'Ecran',repairLabel: 'Réparation écran',sortOrder: 1});
repairLabelMap.set("LCD_ORI", {categoryLabel: 'Ecran',repairLabel: 'Réparation écran d\'origine',sortOrder: 1});
repairLabelMap.set("SWAP", {categoryLabel: 'Swap',repairLabel: 'Echange standard',sortOrder: 999});
repairLabelMap.set("BAT", {categoryLabel: 'Batterie',repairLabel: 'Remplacement batterie',sortOrder: 2});
repairLabelMap.set("BAT_ORI", {categoryLabel: 'Batterie d\'origine',repairLabel: 'Remplacement batterie d\'origine',sortOrder: 2});
repairLabelMap.set("BTN_VOL", {categoryLabel: 'Bouton Volume',repairLabel: 'Remplacement bouton volume',sortOrder: 999});
repairLabelMap.set("BTN_POW", {categoryLabel: 'Bouton On/Off',repairLabel: 'Remplacement bouton On/Off',sortOrder: 999});
repairLabelMap.set("BTN_HOME", {categoryLabel: 'Bouton Home',repairLabel: 'Remplacement bouton home',sortOrder: 999});
repairLabelMap.set("BTN_VIB", {categoryLabel: 'Vibreur',repairLabel: 'Remplacement vibreur',sortOrder: 999});
repairLabelMap.set("VIB", {categoryLabel: 'Vibreur',repairLabel: 'Remplacement vibreur',sortOrder: 999});
repairLabelMap.set("VIBREUR", {categoryLabel: 'Vibreur',repairLabel: 'Remplacement vibreur',sortOrder: 999});
repairLabelMap.set("PCONNECTOR", {categoryLabel: 'Connecteur de charge',repairLabel: 'Remplacement connecteur de charge',sortOrder: 5});
repairLabelMap.set("CON", {categoryLabel: 'Connecteur de charge',repairLabel: 'Remplacement connecteur de charge',sortOrder: 999});
repairLabelMap.set("CAM_REAR", {categoryLabel: 'Caméra arrière',repairLabel: 'Remplacement caméra arrière',sortOrder: 3});
repairLabelMap.set("CAM_FRONT", {categoryLabel: 'Caméra avant',repairLabel: 'Remplacement caméra avant',sortOrder: 4});
repairLabelMap.set("RNL", {categoryLabel: 'Mise à jour logiciel',repairLabel: 'Mise à jour logiciel',sortOrder: 999});
repairLabelMap.set("BACK_COVER", {categoryLabel: 'Face arrière',repairLabel: 'Réparation face arrière',sortOrder: 999});
repairLabelMap.set("TCT", {categoryLabel: 'Tactile',repairLabel: 'Remplacement tactile',sortOrder: 999});
repairLabelMap.set("HAUT_PARLEUR", {categoryLabel: 'Haut parleur',repairLabel: 'Remplacement haut parleur',sortOrder: 999});
repairLabelMap.set("HP", {categoryLabel: 'Haut parleur',repairLabel: 'Remplacement haut parleur',sortOrder: 999});
repairLabelMap.set("DIAG", {categoryLabel: 'Diagnostic',repairLabel: 'Diagnostic',sortOrder: 999});
repairLabelMap.set("SIMTR", {categoryLabel: 'Support Sim',repairLabel: 'Support Sim',sortOrder: 999});
repairLabelMap.set("ANT", {categoryLabel: 'Antenne réseau',repairLabel: 'Remplacement Antenne réseau',sortOrder: 999});
repairLabelMap.set("ECO", {categoryLabel: 'Ecouteur interne',repairLabel: 'Remplacement Ecouteur interne',sortOrder: 999});
repairLabelMap.set("MIC", {categoryLabel: 'Micro',repairLabel: 'Remplacement Micro',sortOrder: 999});
repairLabelMap.set("SMA_KO", {categoryLabel: 'Smartphone non fonctionnel',repairLabel: 'Smartphone non fonctionnel',sortOrder: 999});
repairLabelMap.set("DOX", {categoryLabel: 'Smartphone oxydé',repairLabel: 'Smartphone oxydé',sortOrder: 999});
repairLabelMap.set("CLEAN_SIMPLE", {categoryLabel: 'Nettoyage sans démontage',repairLabel: 'Nettoyage sans démontage',sortOrder: 999});
repairLabelMap.set("CLEAN_COMPLEX", {categoryLabel: 'Nettoyage avec démontage',repairLabel: 'Nettoyage avec démontage',sortOrder: 999});
repairLabelMap.set("TEMPORARY_RESTORATION", {categoryLabel: 'Remise en état temporaire',repairLabel: 'Remise en état temporaire',sortOrder: 999});

export function getRepairLabel(cat) {
    if(!cat) return ""
    let isOriginal = cat.includes("_ORI");
    cat = cat.replace("_ORI","");
    let catString = repairLabelMap.has(cat) ? repairLabelMap.get(cat).repairLabel : cat;
    return isOriginal ?catString.concat(" d'origine") : catString ;
}

export function getRepairLabelByType(cat, price) {
    if(!cat || !price) {
        return ""
    }
    cat = cat.replace("_ORI","");
    let catString = repairLabelMap.has(cat) ? repairLabelMap.get(cat).repairLabel : cat;
    return price.repairType === 'ORIGINAL' ? catString.concat(" d'origine") : catString
}
export function getCategoryLabel(cat) {
    return repairLabelMap.has(cat) ? repairLabelMap.get(cat).categoryLabel : cat;
}

export function getRepairOrder(cat) {
    return repairLabelMap.has(cat) ? repairLabelMap.get(cat).sortOrder : 999;
}
