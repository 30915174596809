import {Button, Checkbox, Icon, List, Table, Form, FormField} from 'semantic-ui-react'
import React from "react";
import {isAllowed} from "../../utils/authorities-utils";
import {useSelector} from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import update from 'react-addons-update';
import Moment from "react-moment";

function ShopItem({shops,setShops,setSelectedShop}) {

    var content =[];

    function handleStuart(event,data,shop) {
        let shopUp = {...shop,useStuartProd:data.checked}
        axiosInstance.put("/external-shops",shopUp);

        const index = shops.findIndex((emp) => emp.id === shop.id);
        const updatedShops = update(shops, {$splice: [[index, 1, shopUp]]});  // array.splice(start, deleteCount, item1)
        setShops(updatedShops)
    }






    if(shops!==undefined)
        shops.map((shop)=>
        {
            var dates = [];
            if(shop.automatickPickUpDate !== undefined && shop.automatickPickUpDate.length !==0)
            {
                for (const element of  shop.automatickPickUpDate)

         dates.push(<li><Moment format="HH:mm" locale="fr">{element.automaticPickingDate}</Moment></li>);

            }



        content.push(<Table.Row positive>
            <Table.Cell>
                <List>
                    {shop.code}
                </List>
            </Table.Cell>
            <Table.Cell>
                <List>
                    {shop.name}
                </List>
            </Table.Cell>
            <Table.Cell>
                <List>
                    {shop.address.adressLine1}
                    {shop.address.adressLine2}
                    <br/>{shop.address.zipCode} {shop.address.city}
                </List>
            </Table.Cell>
            <Table.Cell>
                <List>
                    {shop.shopSave.code}
                    <br/>{shop.shopSave.name}
                </List>
            </Table.Cell>
            <Table.Cell>
                <List>
                    {shop.pickupType}
                </List>
            </Table.Cell>
            <Table.Cell>
                <List>
                    {shop.workflowType === 0 ? 'DIAG to PRICE' : 'PRICE to DIAG'}
                </List>
            </Table.Cell>
            <Table.Cell>
                {shop.pickupType === 'COURSIER' ?
                <ul>
                {dates}
                </ul> : null}
            </Table.Cell>



            {/*<Table.Cell textAlign='center'>*/}
            {/*    {shop.automaticPacking === true ? <Icon name="check"/> : <Icon name="close"/>}*/}
            {/*</Table.Cell>*/}
            {/*<Table.Cell textAlign="center">*/}

            {/*    <FormField>*/}
            {/*        <Checkbox checked={shop.useStuartProd} id={shop.code + "STUART"}*/}
            {/*                  onChange={(event, data) => handleStuart(event, data, shop)} shop={shop} slider={true}*/}
            {/*                  label={'Stuart Prod'}/>*/}
            {/*    </FormField>*/}

            {/*</Table.Cell>*/}
        </Table.Row>)})







    return content;

}

export default ShopItem
