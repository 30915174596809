import {Container, Divider, Header, Icon, Image, List, Segment} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import logoSave from "../../components/HeaderMenu/logo-save-mini.png";
import {updateShopSaveSettingsData} from "../../reducers/Shop/shop.actions";

function ShopSave() {

    const shopSave = useSelector(state => state.shop.shopSave);
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateShopSaveSettingsData(shopSave.code));
        setLoaded(true);
    }, [loaded]);

    return (
        <>
            <Container fluid>


                <Header block as='h1' size='medium'>
                    <Image src={logoSave}/>
                    <Header.Content>Boutique
                        <Header.Subheader>{shopSave.name}</Header.Subheader>
                    </Header.Content>
                </Header>
                <Segment fluid>
                    <List>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopSave.code}
                            </List.Content>
                            <List.Content>Code boutique</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopSave.name}
                            </List.Content>
                            <List.Content>Nom de la boutique</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopSave.appleAccredited ? "Oui" : "Non"}
                            </List.Content>
                            <List.Content>Pièces d'oringes APPLE</List.Content>
                        </List.Item>
                        <Divider horizontal>
                            Adresse
                        </Divider>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopSave.addressDto.adressLine1}
                            </List.Content>
                            <List.Content>Adresse ligne 1</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopSave.addressDto.adressLine2}
                            </List.Content>
                            <List.Content>Adresse ligne 2</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopSave.addressDto.zipCode}
                            </List.Content>
                            <List.Content>Code Postal</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {shopSave.addressDto.city}
                            </List.Content>
                            <List.Content>Ville</List.Content>
                        </List.Item>
                    </List>
                </Segment>
            </Container>

        </>
    );

}

export default ShopSave
