import {Container, Menu, Segment} from 'semantic-ui-react'
import React, {useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import MyAccount from "./MyAccount";
import ShopPartner from "./ShopPartner";
import ShopSave from "./ShopSave";


function SettingPage() {

    const [tab, setTab] = useState('user');
    const [notificationText, setNotificationText] = useState('');
    const connectedUser = useSelector(state => state.user.user);

    function handleCacheEvict() {
        axiosInstance.get("/clearcache").then((response) => {
            toast.success("Caches nettoyés")
        });
    }

    function forceAutomaticPacking() {
        axiosInstance.get("/admin/automaticpacking").then((response) => {
            toast.success("Batch de colisage complété")
        });
    }

    function sendNotification() {
            axiosInstance.post("/admin/send-notification",{
                subject: "Notification de test",
                content: notificationText,
                link:"https://preprod.orange.save.co",
                priority:"HIGH_PRIORITY"

            }).then((response) => {
            toast.success("Notification sended")
        });
    }

    function startKafka() {
        axiosInstance.get("/admin/kafka/startconsumer").then((response) => {
            toast.success("Start kafka")
        });
    }

    return (
        <>
            <Container fluid>
                <Menu attached='top' tabular>
                    <Menu.Item
                        active={tab === 'user'}
                        onClick={() => setTab('user')}
                    >Mon compte</Menu.Item>
                    <Menu.Item
                        active={tab === 'shopPartner'}
                        onClick={() => setTab('shopPartner')}
                    >Ma boutique</Menu.Item>
                    <Menu.Item
                        active={tab === 'shopSave'}
                        onClick={() => setTab('shopSave')}
                    >Boutique Save</Menu.Item>
                </Menu>
                <Segment attached='bottom'>
                    {tab === 'user' ? <MyAccount/> : null}
                    {tab === 'shopPartner' ? <ShopPartner/> : null}
                    {tab === 'shopSave' ? <ShopSave/> : null}
                </Segment>
            </Container>
        </>
    );

}

export default SettingPage
