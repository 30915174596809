import {Container, Divider, Grid, Header, Icon, Image, List, Segment,Button} from 'semantic-ui-react'

import camA_ko from "./../../img/camA_ko.png";
import camA_ok from "./../../img/camA_ok.png";
import camF_ko from "../../img/camF_ko.png";
import camF_ok from "../../img/camF_ok.png";
import React from "react";
import {Link} from "react-router-dom";

function ManualDiag(props) {
    const diagAction = function(testOk){
        props.nextDiag(testOk)
    }
    return (


            <Segment placeholder>
                <Grid columns={2} relaxed='very' stackable>
                    <Grid.Column>
                        <h3>La caméra arrière du smartphone est-elle fonctionnelle ?</h3>
                        <div>
                            <Segment basic>
                                <Grid centered columns={2} relaxed='very'>
                                    <Grid.Column verticalAlign='middle'>
                                        <Segment fluid className="diagchoice"  onClick={()=>diagAction(true)}>
                                            <Container style={{marginBottom: '10px'}}><Image centered alt="ecran_ok" src={camA_ok} style={{maxHeight: '150px'}} /></Container>
                                            <Icon size='huge' color='green' name='check circle outline'/>
                                            <br/><p>OUI</p>
                                        </Segment>
                                    </Grid.Column>

                                    <Grid.Column verticalAlign='middle' >
                                        <Segment fluid className="diagchoice" onClick={()=>diagAction(false)}>
                                            <Container style={{marginBottom: '10px'}}><Image centered alt="ecran_ok" src={camA_ko} style={{maxHeight: '150px'}} /></Container>
                                            <Icon size='huge' color='red' name='times circle outline'/>
                                            <br/><p>NON</p>
                                        </Segment>
                                    </Grid.Column>
                                </Grid>
                            </Segment>

                            {props.piceaEligible ?
                                <Button as={Link} to='/remoteDiag'  basic style={{marginTop: '45px'}} primary>Je souhaite faire le diagnostic avec Piceasoft</Button> : null}

                        </div>
                    </Grid.Column>
                    <Grid.Column verticalAlign='middle'>
                        <h3>Qu'est ce que cela signifie ?</h3>
                        <List bulleted>
                            <List.Item>L'autofocus fonctionne correctement</List.Item>
                            <List.Item>Les photos prises ne sont pas floues</List.Item>
                            <List.Item>La caméra ne surchauffe pas lors de son utilisation</List.Item>
                            <List.Item>La lentille de la caméra est intacte</List.Item>
                        </List>
                    </Grid.Column>
                </Grid>
                <Divider vertical/>
            </Segment>

    );

}

export default ManualDiag
