import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import {
    Button,
    Checkbox,
    Form,
    List,
    Pagination,
    Table
} from "semantic-ui-react";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";
import {toast} from "react-toastify";

function QuotePending() {

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [nbrPage, setNbrPage] = useState(1);
    const [interventionOpenId, setInterventionOpenId] = useState(0);
    const [submittedPrice, setSubmittedPrice] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [pricesByIntervention, setPricesByIntervention] = useState(new Map());

    const setPriceHt = (e, category, intervention) => {
        if (e.target.value > 10) {
            e.target.value = e.target.value.slice(0, 10);
        }

        if (pricesByIntervention.has(intervention)) {
            const item = pricesByIntervention.get(intervention).get(category);
            const newItem = {
                ...item,
                priceHt: e.target.value,
                priceTtc: "",
                priceB2BHt: "",
                priceB2BTtc: "",
                priceB2BHtLab: "",
                priceB2BTtcLab: ""
            }
            pricesByIntervention.get(intervention).set(category, newItem)
            setSubmittedPrice(e.target.value)
        }
    }

    const isConfirmDisplayed = (intervention) => {
        if (pricesByIntervention.has(intervention)) {
            let itemsByCategory = pricesByIntervention.get(intervention);
            let itemsNbr = itemsByCategory.size;
            let validatedItems = 0;
            for (const items of itemsByCategory.keys()) {
                let item = itemsByCategory.get(items);
                if (item.priceTtc !== "" && item.priceTtc !== 0) {
                    validatedItems++;
                }
            }
            return validatedItems === itemsNbr;
        }
    }

    const confirmPricings = (intervention) => {
        setIsLoading(true);
        const items = Array.from(pricesByIntervention.get(intervention).values());
        axiosInstance.post("/pricing/validatePricings/",
            {
                interventionId: intervention.id,
                items: items
            })
            .then((response) => {
                loadInterventions();
                toast.success("L'intervention ORG_"+response.data.id + " a été validée ");
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            });

    }

    useEffect(() => {
        loadInterventions();
    }, [page])

    const loadInterventions = () => {
        axiosInstance.post("/interventions/quotePending/",
            {
                page: page,
                size: size
            })
            .then((response) => {
                setNbrPage(response.data.totalPages)
                let map = new Map();
                for (const intervention of response.data.content) {
                    let itemsByCategory = new Map();
                    for (const item of intervention.items) {
                        itemsByCategory.set(item.name, item)
                    }
                    map.set(intervention, itemsByCategory);
                }
                setPricesByIntervention(new Map(map));
            })
            .catch((error) => {
            });
    }

    const handleOnBlurSubmittedPrice = (intervention, category) => {
        axiosInstance.post("/pricing/calculatePricings/",
            {
                interventionItemId: pricesByIntervention.get(intervention).get(category).id,
                priceProposalHt: pricesByIntervention.get(intervention).get(category).priceProposalHt,
                priceHt: submittedPrice,
            })
            .then((response) => {
                if (pricesByIntervention.has(intervention)) {
                    pricesByIntervention.get(intervention).set(category, response.data);
                }
                setPricesByIntervention(new Map(pricesByIntervention));
                setNbrPage(response.data.totalPages)
            })
            .catch((error) => {
            })
        ;
    };

    const displayPrice = (intervention, category, fieldName) => {
        if (pricesByIntervention.has(intervention)) {
            let price = pricesByIntervention.get(intervention).get(category)[fieldName]
            if (price === 0) {
                return "";
            } else {
                return price;
            }
        } else {
            return "";
        }
    };

    const handlePageChange = ({activePage}) => {
        setPage(activePage - 1);
    }

    const resetSubmittedPrice = (intervention) => {
        setSubmittedPrice('');
        setInterventionOpenId(intervention.id === setInterventionOpenId ? 0 : intervention.id)

    }

    return (
        <>
            <Table compact celled definition>
                <Table.Header>
                    <Table.Row positive>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>Dossier</Table.HeaderCell>
                        <Table.HeaderCell>Client</Table.HeaderCell>
                        <Table.HeaderCell>Produit</Table.HeaderCell>
                        <Table.HeaderCell>Imei</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {pricesByIntervention.size !== 0 ? Array.from(pricesByIntervention.keys()).map(intervention => (
                        <>
                            <Table.Row>
                                <Table.Cell collapsing>
                                    <Checkbox toggle checked={interventionOpenId === intervention.id}
                                              onChange={(event, self) => resetSubmittedPrice(intervention)}/>
                                </Table.Cell>
                                <Table.Cell>ORG_{intervention.id}</Table.Cell>
                                <Table.Cell>
                                    <List>
                                        <List.Item>
                                            <List.Icon name='user' size='large'
                                                       verticalAlign='middle'/>
                                            <List.Content>
                                                <List.Header>{intervention.customer.firstName} {intervention.customer.lastName}</List.Header>
                                                <List.Description>{intervention.customer.email}</List.Description>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Table.Cell>
                                <Table.Cell>
                                    <List>
                                        <List.Item>
                                            <List.Icon name='mobile alternate' size='large'
                                                       verticalAlign='middle'/>
                                            <List.Content>
                                                <List.Header>{intervention.product.brandName}</List.Header>
                                                <List.Description>{intervention.product.name}</List.Description>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Table.Cell>
                                <Table.Cell>
                                    {intervention.product.imei}
                                </Table.Cell>
                                <Table.Cell>
                                    <Link>
                                        <Button disabled={!isConfirmDisplayed(intervention) && isLoading} color={"green"}
                                                onClick={() => confirmPricings(intervention)}
                                                loading={isLoading}
                                                size='small' fluid>Confirmer</Button>
                                    </Link>

                                </Table.Cell>
                            </Table.Row>
                            {interventionOpenId === intervention.id ? <>

                                <Table.Row>
                                    <Table.HeaderCell/>
                                    <Table.Cell colSpan='8'>
                                        <Table compact celled definition>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Réparation </Table.HeaderCell>
                                                    <Table.HeaderCell>Prix d’achat HT</Table.HeaderCell>
                                                    <Table.HeaderCell>HT B2C</Table.HeaderCell>
                                                    <Table.HeaderCell>TTC B2C</Table.HeaderCell>
                                                    <Table.HeaderCell>HT STORE</Table.HeaderCell>
                                                    <Table.HeaderCell>TTC STORE</Table.HeaderCell>
                                                    <Table.HeaderCell>HT LAB</Table.HeaderCell>
                                                    <Table.HeaderCell>TTC LAB</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>

                                                {Array.from(pricesByIntervention.get(intervention).keys()).map(category => (
                                                    <Table.Row>
                                                        <Table.Cell>{category}</Table.Cell>
                                                        <Table.Cell>
                                                            <NumberFormat
                                                                value={pricesByIntervention.get(intervention).get(category).priceProposalHt}
                                                                displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                decimalSeparator=','
                                                                suffix=' €'
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>

                                                            {<Form><input
                                                                type="number"
                                                                onChange={(e) => setPriceHt(e, category, intervention)}
                                                                min={0}
                                                                max={999999}
                                                                onBlur={() => handleOnBlurSubmittedPrice(intervention, category, pricesByIntervention.get(intervention).get(category).priceProposalHt)}
                                                                value={pricesByIntervention.get(intervention).get(category).priceHt === 0 ? ""
                                                                    : pricesByIntervention.get(intervention).get(category).priceHt}
                                                                placeholder="Veuillez indiquer une valeur"
                                                            /></Form>}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <NumberFormat
                                                                value={displayPrice(intervention, category, "priceTtc")}
                                                                displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                decimalSeparator=','
                                                                suffix=' €'
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <NumberFormat
                                                                value={displayPrice(intervention, category, "priceB2BHt")}
                                                                displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                decimalSeparator=','
                                                                suffix=' €'
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <NumberFormat
                                                                value={displayPrice(intervention, category, "priceB2BTtc")}
                                                                displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                decimalSeparator=','
                                                                suffix=' €'
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <NumberFormat
                                                                value={displayPrice(intervention, category, "priceB2BHtLab")}
                                                                displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                decimalSeparator=','
                                                                suffix=' €'
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <NumberFormat
                                                                value={displayPrice(intervention, category, "priceB2BTtcLab")}
                                                                displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                decimalSeparator=','
                                                                suffix=' €'
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>
                                    </Table.Cell>
                                </Table.Row>

                            </> : null}
                        </>

                    )) : null}
                </Table.Body>

            </Table>
            <Pagination totalPages={nbrPage} activePage={page + 1}
                        onPageChange={(e, pageEvent) => handlePageChange(pageEvent)} defaultActivePage={0}/>
        </>
    )
}

export default QuotePending




