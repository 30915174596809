import {
    ADD_REMOVE_FROM_QUOTE, ADD_REMOVE_FROM_SAV,
    ADD_REMOVE_SERVICE_FROM_QUOTE, DEFAULT_PRICING,
    GET_DIAGNOSIS_INFORMATION, LOAD_PRODUCT,
    ON_IDENTIFICATION_PICEASOFT,
    ON_MANUAL_DIAG_UPDATE,
    ON_MANUEL_MODEL_SELECT,
    RESET_PRODUCT_INFO, UPDATE_COLOR,
    UPDATE_GEOLOC,
    UPDATE_IMEI,
    UPDATE_PRODUCT_INFO
} from './product.types';
import {ON_PICEASOFT_NEW_DIAG} from "../Diagnostic/diagnostic.types";
import update from 'react-addons-update';
import {RESET_APP} from "../GlobalUi/globalUi.types";

const INITIAL_STATE = {
      enabled: true,
      color: "",
      isIdentifiedByPiceasoft:false,
      isIdentifiedByBackckOffice:false,
      piceasoftName:"",
      piceasoftBrand:"",
      piceasoftImei:"",
      piceasoftTechnicalName:"",
      piceasoftSerialNumber:"",
      piceasoftImgUrl:"",
      piceasoftAca:[],
      name:"",
      brand:"",
      imei:"",
      imgUrl:"",
      type:"",
      isSmartphoneFunctional:null,
      isSmartphoneScreenDamaged:null,
      isSmartphoneBackCoverDammaged:null,
      isSmartphoneOxidized: null,
      piceasoftSetting:{},
      isAllDiagDone:false,
      pricingResult : [],
    geolocDisabled: false,
    services: [],
    selectedRepairs: []
   /* services: [
        {
            id: 0,
            code: "RECOVER",
            label: "Protection écran reCover",
            price: 29.90,
            selected: false
        },
        {
            id: 1,
            code: "BACKUP",
            label: "Sauvegarde de données",
            price: 49.90,
            selected: false
        }
    ]*/

};

/*

 */

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_DIAGNOSIS_INFORMATION:{

            //Sync visualDiag if any
            let syncDiag =[]
            for( let diag of action.payload.pricingDetails){
                if(diag.category === "LCD")
                {
                    if(state.isSmartphoneScreenDamaged===true) {
                        diag = {...diag,visualDiagKo : true,allSubDiagOk:false,isAllSubDiagDone:true}
                    } else {
                        diag = {...diag,visualDiagKo : false,allSubDiagOk:true,isAllSubDiagDone:true}
                    }
                }
                else if(diag.category==="BACK_COVER")
                {
                    if(state.isSmartphoneBackCoverDammaged===true) {
                        diag = {...diag,allSubDiagOk:false,isAllSubDiagDone:true}
                    }
                     else {
                        diag = {...diag,allSubDiagOk:true,isAllSubDiagDone:true}
                    }
                }
                else if(diag.category==="DOX")
                {
                    if(state.isSmartphoneOxidized===true) {
                        diag = {...diag,allSubDiagOk:false,isAllSubDiagDone:true}
                    }
                    else {
                        diag = {...diag,allSubDiagOk:true,isAllSubDiagDone:true}
                    }
                }
                else if(diag.category==="SMA_KO") {
                    if(state.isSmartphoneFunctional===false) {
                        diag = {...diag,allSubDiagOk:false,isAllSubDiagDone:true}
                    } else {
                        diag = {...diag,allSubDiagOk:true,isAllSubDiagDone:true}
                    }
                } else {
                    diag = {...diag,allSubDiagOk:true,isAllSubDiagDone:true}
                }

                syncDiag.push(diag);
            }
            return {...state, pricingResult:syncDiag, piceasoftSetting:action.payload.piceasoftSetting}
        }
        case "ON_VISUAL_DIAG_UPDATE":
        {
            return {...state,...action.payload}
        }
        case ON_IDENTIFICATION_PICEASOFT:
        {
                       return {...state,

                                   isIdentifiedByPiceasoft:true,
                                   piceasoftName:action.result.model_name,
                                   piceasoftBrand: action.result.manufacturer,
                                   piceasoftImei: action.result.imei,
                                   piceasoftTechnicalName: action.result.model_code,
                                   piceasoftSerialNumber:action.result.serial_number,
                                   piceasoftImgUrl:action.result.device_img,
                                   imgUrl:action.result.device_img,
                                   piceasoftAca:action.result.aca,
                                   name:action.result.model_name,
                           brand: action.result.manufacturer,
                           imei: action.result.imei
                               }


        }
        case ON_MANUAL_DIAG_UPDATE:{
            let indexMain = -1;
            for (let diagContainer of state.pricingResult) {
                indexMain=indexMain+1;
                if(action.category === diagContainer.category)
                {
                    break;

                }
            }
            const coll = update(state.pricingResult,{[indexMain]:{$merge:{'allSubDiagOk':action.diagOk,'isAllSubDiagDone':true,comment:action.comment}}});

            return {...state,pricingResult:coll}

        }
        case ADD_REMOVE_FROM_QUOTE:{
            // Enable or disable category
            let indexMain = -1;
            for (let diagContainer of state.pricingResult) {
                indexMain=indexMain+1;
                if(action.category === diagContainer.category)
                {
                    break;
                }
            }
            var coll = update(state.pricingResult,{[indexMain]:{$merge:{'allSubDiagOk':action.addToQuote}}});

            // If exists, disable compatible category
            indexMain = -1;
            if(action.category.includes("_ORI") && !action.addToQuote) {
                let compatibleCategory = action.category.replace("_ORI","");
                for (let diagContainer of coll) {
                    indexMain=indexMain+1;
                    if(compatibleCategory === diagContainer.category)
                    {
                        break;
                    }
                }
                if(indexMain != -1) {
                    coll =  update(coll,{[indexMain]:{$merge:{'allSubDiagOk':!action.addToQuote}}});
                }
            }

            // If exists, disable original category
            if(!action.category.includes("_ORI") && !action.addToQuote) {
                let originalCategory = action.category.concat("_ORI","");
                for (let diagContainer of coll) {
                    indexMain=indexMain+1;
                    if(originalCategory === diagContainer.category)
                    {
                        break;
                    }
                }
                if(indexMain != -1) {
                    coll =  update(coll,{[indexMain]:{$merge:{'allSubDiagOk':!action.addToQuote}}});
                }
            }

            return {...state,pricingResult:coll}
        }

        case ADD_REMOVE_FROM_SAV: {

            let indexMain = -1;
            for (let diagContainer of state.pricingResult) {
                indexMain=indexMain+1;
                if(action.category === diagContainer.category)
                {
                    break;
                }
            }

            var pricings = update(state.pricingResult, {[indexMain]: {$merge: {'status': !state.pricingResult[indexMain].status, 'allSubDiagOk': !state.pricingResult[indexMain].status}}})
            return {...state, pricingResult: pricings}
        }

        case ADD_REMOVE_SERVICE_FROM_QUOTE: {
            var services = update(state.services, {[action.serviceIndex]: {$merge: {'selected': !state.services[action.serviceIndex].selected}}})
            return {...state, services: services}
        }
        case RESET_PRODUCT_INFO:{
            return INITIAL_STATE;
        }
        case UPDATE_PRODUCT_INFO:{
            return {...state,
                color: action.payload.color,
                name:action.payload.name,
                brand:action.payload.brandName,
                enabled: action.payload.enabled,
                imei:action.payload.imei,
                imgUrl:action.payload.imgUrl,
                selectedRepairs:action.payload.selectedRepairs,
                type:action.payload.type,
                pricingResult: action.payload.pricingResult
            }
        }

        case ON_PICEASOFT_NEW_DIAG:
        {


            //Index of the pricingResult
            let indexMain = -1;
            //Index of the pricingResult.diagnosticSettingDtos
            let indexSub = -1;
            //Flag if all sub category have been tested
            let isAllSubDiagOk = true;
            let isAllSubDiagDone = true;
            //Flag if all sub category have been tested
            let isAllDiagDone = true;
            //Current category
            let cat = null;

            //Set the main and sub index
            for (let diagContainer of state.pricingResult) {
                if(indexSub != -1)
                    break;
                indexMain = indexMain +1;

                for(let diag of diagContainer.diagnosticSettingDtos) {
                    if(diag.picesoftTestId === action.result.case_id)
                        {
                            for(let diagFromMain of diagContainer.diagnosticSettingDtos) {
                                cat=diag.category;
                                indexSub = indexSub+1;
                                if(diagFromMain.picesoftTestId === action.result.case_id)
                                {

                                    break;
                                }

                            }

                        }
                        }
            }

            let success=action.result.status ==1;

            if(indexSub===-1)
                return {...state};

            //Update case of immutable
            const coll = update(state.pricingResult,{[indexMain]:{diagnosticSettingDtos:{[indexSub]:{$merge:{sucess:success}}}}});

            //Scan all sub category and set all Ok or Not
            for (let diagContainer of coll)
            {

                //
                for (let diag of diagContainer.diagnosticSettingDtos)
                {
                    if(diag.sucess !== true && cat===diag.category)
                         {
                             isAllSubDiagOk=false;
                             break;
                         }


                    if(diagContainer.visualDiagKo !==undefined && diagContainer.visualDiagKo === true && cat===diag.category) {
                        isAllSubDiagOk = false;
                        break;
                    }

                }
                for (let diag of diagContainer.diagnosticSettingDtos)
                {
                    if(diag.sucess === undefined && cat===diag.category)
                    {
                        isAllSubDiagDone=false;
                        break;
                    }
                }
            }
            const coll2 = update(coll,{[indexMain]:{$merge:{'allSubDiagOk':isAllSubDiagOk,'isAllSubDiagDone':isAllSubDiagDone}}});

            //Scan all category and set Ok or Not
            for (let diagContainer of coll2) {
                if (diagContainer.isAllSubDiagDone===false || diagContainer.isAllSubDiagDone === undefined) {
                    isAllDiagDone=false;
                    break;
                }

            }




            return {...state,isAllDiagDone:isAllDiagDone,pricingResult:coll2}
        }

        case ON_MANUEL_MODEL_SELECT:{
            console.log(action)
            return {...state,
                name:action.modelName,
                brand:action.brandName,
                imgUrl: action.imgUrl,
            }
        }
        case LOAD_PRODUCT:
            return action.payload;
        case UPDATE_IMEI:
            return {...state,imei: action.payload}
        case UPDATE_GEOLOC:
            return {...state, geolocDisabled: action.payload}
        case UPDATE_COLOR:
            return {...state, color: action.payload}
        case RESET_APP:
            return INITIAL_STATE
        case DEFAULT_PRICING:
            return {...state,    pricingResult: [
                    {
                        label: null,
                        category: 'BAT',
                        categoryLabel: 'BAT',
                        amountHT: 0,
                        amountTTC: 0,
                        amountB2BHT: 0,
                        amountB2BTTC: 0,
                        allSubDiagOk: true,
                        comment: null,
                    },
                    {
                        label: null,
                        category: 'LCD',
                        categoryLabel: 'LCD',
                        amountHT: 0,
                        amountTTC: 0,
                        amountB2BHT: 0,
                        amountB2BTTC: 0,
                        allSubDiagOk: true,
                        comment: null,
                        visualDiagKo: false,
                        isAllSubDiagDone: true
                    },
                    {
                        label: null,
                        category: 'CAM_REAR',
                        categoryLabel: 'CAM_REAR',
                        amountHT: 0,
                        amountTTC: 0,
                        amountB2BHT: 0,
                        amountB2BTTC: 0,
                        allSubDiagOk: true,
                        comment: null,
                    },
                    {
                        label: null,
                        category: 'CAM_FRONT',
                        categoryLabel: 'CAM_FRONT',
                        amountHT: 0,
                        amountTTC: 0,
                        amountB2BHT: 0,
                        amountB2BTTC: 0,
                        allSubDiagOk: true,
                        comment: null,
                    },
                    {
                        label: null,
                        category: 'BACK_COVER',
                        categoryLabel: 'BACK_COVER',
                        amountHT: 0,
                        amountTTC: 0,
                        amountB2BHT: 0,
                        amountB2BTTC: 0,
                        allSubDiagOk: true,
                        comment: null,
                        isAllSubDiagDone: true
                    },
                    {
                        label: null,
                        category: 'HP',
                        categoryLabel: 'HP',
                        amountHT: 0,
                        amountTTC: 0,
                        amountB2BHT: 0,
                        amountB2BTTC: 0,
                        allSubDiagOk: true,
                        comment: null,
                    },
                    {
                        label: null,
                        category: 'ECO',
                        categoryLabel: 'ECO',
                        amountHT: 0,
                        amountTTC: 0,
                        amountB2BHT: 0,
                        amountB2BTTC: 0,
                        allSubDiagOk: true,
                        comment: null,
                    },
                    {
                        label: null,
                        category: 'MIC',
                        categoryLabel: 'MIC',
                        amountHT: 0,
                        amountTTC: 0,
                        amountB2BHT: 0,
                        amountB2BTTC: 0,
                        allSubDiagOk: true,
                        comment: null,
                    },
                    {
                        label: null,
                        category: 'PCCONNECTOR',
                        categoryLabel: 'PCCONNECTOR',
                        amountHT: 0,
                        amountTTC: 0,
                        amountB2BHT: 0,
                        amountB2BTTC: 0,
                        allSubDiagOk: true,
                        comment: null,
                    }
                ]}

        default:
            return state;
    }
};

export default reducer;
