import {Button, Checkbox, Form, Grid, Icon, List, Message, Modal, Segment} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {isIMEI} from "../../utils/imei-utils";
import update from 'react-addons-update';
import {axiosInstance} from "../../utils/axiosInstance";
import {CREATE_UPDATE_CUSTOMER_SUCCESS} from "../../reducers/Customer/customer.types";
import PasswordStrengthBar from "react-password-strength-bar/dist";
import {isAdmin, isManager, isSuperviser, isSupport} from "../../utils/authorities-utils";
import {useSelector} from "react-redux";

function UserDetail(
    {selectedUser, onSuccess, onClose, forceShop}) {

    const {register, handleSubmit, watch, setError, errors, clearErrors} = useForm({
        defaultValues:
            {
                id: selectedUser.user.id,
                firstName: selectedUser.user.firstName,
                lastName: selectedUser.user.lastName,
                email: selectedUser.user.email,
                login: selectedUser.user.login,
                password: "",
                repeatPassword: "",
                activated: selectedUser.user.activated,
                hasAdminRole: isAdmin(selectedUser.user.authorities),
                hasManagerRole: isManager(selectedUser.user.authorities),
                hasSupportRole: isSupport(selectedUser.user.authorities),
                hasSuperviserRole: isSuperviser(selectedUser.user.authorities),
            }
    });

    const user = useSelector(state => state.user.user);
    const shopPartner = useSelector(state => state.shop.shopPartner);

    const [backOfficeError, setBackOfficeError] = useState(null);
    const [passwordStrongEnougth, setPasswordStrongEnougth] = useState(true);
    const [password, setPassword] = useState(true);
    const [shops, setShops] = useState([]);

    useEffect(() => {
        if(!forceShop) {
            axiosInstance.get("/shops/",
            )
                .then((response) => {
                    setShops(response.data.content)
                })
                .catch((error) => {
                });
        }
    }, [])


    const getShopOpt = () => {
        let result = [];
        for (let shop of shops) {
            result.push(<option value={shop.id}>{shop.code + " - " + shop.name}</option>);
        }
        return result
    }

    const onSubmit = (data) => {

        clearErrors("password");
        clearErrors("repeatPassword");

        if(selectedUser.user.login) {
            axiosInstance.request({
                method: 'put',
                url:"/user",
                data: data,
            }).then((response) => {
                    onSuccess();
                    setBackOfficeError(null);
                }
            ).catch(function (error) {
                if(error.response.status === 400) {
                    setBackOfficeError(error.response.data)
                }
            });
        } else {
            axiosInstance.request({
                method: 'post',
                url:"/user",
                data: data,
            }).then((response) => {
                    onSuccess();
                    setBackOfficeError(null);
                }
            ).catch(function (error) {
                if(error.response != undefined && error.response.status === 400) {
                    setBackOfficeError(error.response.data)
                }
            });
        }
    }

    const displayPassWordError = () => {
        let errors ="";
        for (let error of backOfficeError) {
            errors += error +'\n';
        }
        return <Message error>{errors.split('\n').map((item, i) => <p key={i}>{item}</p>)}</Message>
    }

    const resetPassword = (data) => {
        axiosInstance.request({
            method: 'put',
            url:"/user/reset",
            data: data,
        }).then((response) => {
                onSuccess();
                setBackOfficeError(null);
            }
        );
    }

    function checkScore(score) {

        if (password === "")
            setPasswordStrongEnougth(true)
        if (score < 4)
            setPasswordStrongEnougth(false);
        if (score >= 4)
            setPasswordStrongEnougth(true)
    }

    if(selectedUser == undefined)
        return null;

    return (
                <Modal
                    onClose={() => {onClose();}}
                    open={selectedUser !== undefined}
                    closeOnDimmerClick={true}
                >
                    {selectedUser.user.login ?
                        <Modal.Header>Modification de l'utilisateur : {selectedUser.user.login}</Modal.Header> :
                        <Modal.Header>Création d'un nouvel utilisateur</Modal.Header>
                    }
                    <Modal.Content>

                        {backOfficeError !== null ? displayPassWordError() : null}

                        <Form onSubmit={handleSubmit(onSubmit)}>

                            <input type="hidden" ref={register()} name="id"/>

                            <Grid>

                                <Grid.Row stretched>

                                    <Grid.Column>


                                        <Form.Group widths='equal'>
                                            <Form.Field required={true}>
                                                <label htmlFor="userLogin">Login</label>
                                                <input fluid name="login"
                                                       readOnly={selectedUser.userLogin !== undefined}
                                                       ref={register({required: true})} label='Prénom'
                                                       placeholder='Prénom'/>
                                            </Form.Field>
                                            <Form.Field required={true}>
                                                <label htmlFor="email">Email</label>
                                                <input fluid name="email"
                                                       ref={register()} label='Email'
                                                       placeholder='Email'/>
                                            </Form.Field>
                                        </Form.Group>


                                        <Form.Group widths='equal'>
                                            <Form.Field required={true}>
                                                <label htmlFor="firstName">Prénom</label>
                                                <input fluid name="firstName"
                                                       ref={register({required: true})} label='Prénom'
                                                       placeholder='Prénom'/>
                                            </Form.Field>
                                            <Form.Field required={true}>
                                                <label htmlFor="lastName">Nom</label>
                                                <input fluid name="lastName"
                                                       ref={register({required: true})} label='Nom'
                                                       placeholder='Nom'/>
                                            </Form.Field>
                                        </Form.Group>


                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row stretched>
                                    <Grid.Column>
                                        {!forceShop ?
                                            <Form.Group widths='equal'>
                                                <Form.Field required={true}>
                                                    <label htmlFor="shopPartnerId">Boutique de rattachement</label>
                                                    <select name="shopPartnerId" ref={register()}>
                                                        {getShopOpt()}
                                                    </select>
                                                </Form.Field>
                                            </Form.Group> :
                                            <Form.Group widths='equal'>
                                                <Form.Field>
                                                    <label htmlFor="ss">Boutique de rattachement</label>
                                                    <input fluid readOnly label='Boutique de rattachement'
                                                           value={shopPartner.code + " - " + shopPartner.name}/>
                                                </Form.Field>
                                                <input type="hidden" ref={register()} name="shopPartnerId"
                                                       value={shopPartner.id}/>
                                            </Form.Group>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={8}>


                                        <Form.Field>
                                            <div className="ui toggle checkbox">
                                                <input type="checkbox" name="activated" ref={register()}/>
                                                <label>Compte activé</label>
                                            </div>
                                        </Form.Field>


                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        {isAdmin(user.authorities) ?
                                            <><Form.Field>
                                                <div className="ui toggle checkbox">
                                                    <input type="checkbox" name="hasAdminRole" ref={register()}/>
                                                    <label>Compte Administrateur</label>
                                                </div>
                                            </Form.Field>
                                                <Form.Field>
                                                    <div className="ui toggle checkbox">
                                                        <input type="checkbox" name="hasSupportRole" ref={register()}/>
                                                        <label>Compte Support</label>
                                                    </div>
                                                </Form.Field>
                                                <Form.Field>
                                                    <div className="ui toggle checkbox">
                                                        <input type="checkbox" name="hasSuperviserRole" ref={register()}/>
                                                        <label>Compte Superviser</label>
                                                    </div>
                                                </Form.Field>
                                            </> :
                                            <>
                                                <input type="hidden" ref={register()} name="hasAdminRole"
                                                       value={false}/>
                                                <input type="hidden" ref={register()} name="hasSupportRole"
                                                       value={false}/>
                                                <input type="hidden" ref={register()} name="hasSuperviserRole"
                                                       value={false}/>
                                            </>
                                        }

                                        {isManager(user.authorities) ?
                                            <Form.Field>
                                                <div className="ui toggle checkbox">
                                                    <input type="checkbox" name="hasManagerRole" ref={register()}/>
                                                    <label>Compte Manager</label>
                                                </div>
                                            </Form.Field> :
                                            <input type="hidden" ref={register()} name="hasManagerRole" value={false}/>
                                        }
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                        </Form>


                    </Modal.Content>
                    <Modal.Actions>
                        {selectedUser.user.login ?
                            <Button color='blue' onClick={handleSubmit(resetPassword)}>
                                <Icon/>Réinitialiser le mot de passe
                            </Button> : null }
                        <Button color='red' onClick={onClose}>
                            <Icon name='remove'/> Annuler
                        </Button>
                        <Button color='green' onClick={handleSubmit(onSubmit)}>
                            <Icon name='checkmark'/> Valider
                        </Button>
                    </Modal.Actions>
                </Modal>
    );

}

export function Select({register, options, name, ...rest}) {
    return (
        <select name={name} ref={register} {...rest}>
            {options.map(value => (
                <option key={value} value={value}>
                    {value}
                </option>
            ))}
        </select>
    );
}

export default UserDetail
