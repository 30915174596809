import {Button, Container, Form, Grid, Header, Icon, Message, Segment} from 'semantic-ui-react'
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";
import {updateShopSettingsData} from "../../reducers/Shop/shop.actions";
import {axiosInstance} from "../../utils/axiosInstance";
import {isMoodShop} from "../../utils/shop-utils";

function ShopSetting() {

    const dispatch = useDispatch();
    const shopPartner = useSelector(state => state.shop.shopPartner);

    const {register, handleSubmit, watch, setError, errors, clearErrors} = useForm({
        defaultValues:
            {
                mailCommunicationEnabled: shopPartner.mailCommunicationEnabled,
                smsCommunicationEnabled: shopPartner.smsCommunicationEnabled,
                notificationCommunicationEnabled: shopPartner.notificationCommunicationEnabled,
                mailCommunicationEmails: shopPartner.mailCommunicationEmails,
                smsCommunicationPhoneNumbers: shopPartner.smsCommunicationPhoneNumbers,
                automaticPacking: shopPartner.automaticPacking,
                npsLink: shopPartner.npsLink,
                codePdl: shopPartner.codePdl,
                codeEdo: shopPartner.codeEdo,
                shopInShop: shopPartner.shopInShop,
                useWacomSignPad: shopPartner.useWacomSignPad,
            }
    });

    const mailCommunicationEnabled = watch("mailCommunicationEnabled");
    const smsCommunicationEnabled = watch("smsCommunicationEnabled");


    const onSubmit = (data) => {
        console.log(data)
        dispatch(updateShopSettingsData(data))
    }

    const handleCommunicationTest = ()=> {
        axiosInstance.get("/shop/communication/test");
    }

    return (
        <>
            <Container fluid>

                <Message icon>
                    <FontAwesomeIcon size={"3x"}  style={{marginRight: '15px'}} icon="store-alt"/>

                    <Message.Content>
                        <Message.Header>Paramétrage de votre boutique</Message.Header>
                        {shopPartner.name}
                    </Message.Content>
                </Message>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Grid columns={2}>
                        <Grid.Column>
                            <Header block as='h4' icon='plug' attached={'top'}>Canaux de communications
                                <Button onClick={()=>handleCommunicationTest()} style={{marginTop: '-5px'}} basic size={"mini"} floated={"right"} primary>tester</Button></Header>
                    <Segment attached>

                        <Header as='h3' dividing>
                            Mail
                        </Header>
                        <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="checkbox" name="mailCommunicationEnabled" ref={register()}/>
                                <label>Communication par Emails</label>
                            </div>
                        </Form.Field>
                        <Form.Field required={mailCommunicationEnabled}>
                            <label htmlFor="email">Emails (séparés par des ; )</label>
                            <input fluid name="mailCommunicationEmails"
                                   ref={register()} label='mailCommunicationEmails'
                                   placeholder='Emails'/>
                        </Form.Field>

                        <Header as='h3' dividing>
                            SMS
                        </Header>
                        <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="checkbox" name="smsCommunicationEnabled" ref={register()}/>
                                <label>Communication par SMS</label>
                            </div>
                        </Form.Field>
                        <Form.Field required={smsCommunicationEnabled}>
                            <label htmlFor="email">Numéros de téléphones (séparés par des ; )</label>
                            <input fluid name="smsCommunicationPhoneNumbers"
                                   ref={register()} label='smsCommunicationPhoneNumbers'
                                   placeholder='Numéros de portable'/>
                        </Form.Field>

                        {isMoodShop(shopPartner) ?
                        <><Header as='h3' dividing> NPS </Header>
                        <Form.Field required={smsCommunicationEnabled}>
                            <label htmlFor="email">Lien NPS</label>
                            <input fluid name="npsLink"
                                   ref={register()} label='npsLink'
                                   placeholder='Lien NPS'/>
                        </Form.Field> </>:null}

                        <Header as='h3' dividing>
                            Notifications
                        </Header>
                        <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="checkbox" name="notificationCommunicationEnabled" ref={register()}/>
                                <label>Communication par Notifications</label>
                            </div>
                        </Form.Field>



                    </Segment>
                </Grid.Column>

                <Grid.Column>
                    <Header block as='h4' icon='box' content='Colisage' attached={'top'}/>
                    <Segment attached>
                        <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="checkbox" name="automaticPacking" ref={register()}/>
                                <label>Colisage automatique</label>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="checkbox" name="shopInShop" ref={register()}/>
                                <label>Shop In Shop (colisage immédiat)</label>
                            </div>
                        </Form.Field>
                    </Segment>

                    <Header block as='h4' icon='box' content='Pad de Signature' attached={'top'}/>
                    <Segment attached>
                        <Form.Field>
                            <div className="ui toggle checkbox">
                                <input type="checkbox" name="useWacomSignPad" ref={register()}/>
                                <label>Utilisation du pad de signature Wacom</label>
                            </div>
                        </Form.Field>
                    </Segment>

                    <Header block as='h4' content='Codes PDL / EDO' attached={'top'}/>
                    <Segment attached>
                        <label htmlFor="codePdl">Code PDL</label>
                        <input fluid name="codePdl"
                               ref={register()}/>
                    </Segment>
                    <Segment attached>
                        <label htmlFor="codeEdo">Code EDO</label>
                        <input fluid name="codeEdo"
                               ref={register()}/>
                    </Segment>

                </Grid.Column>
                    </Grid>
                </Form>

                <Button style={{marginTop: '10px'}}fluid primary color='green' onClick={handleSubmit(onSubmit)}>
                    <Icon name='checkmark'/> Valider
                </Button>
            </Container>

        </>
    );
}

export default ShopSetting
