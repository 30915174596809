import {ON_PICEASOFT_NEW_DIAG} from "../Diagnostic/diagnostic.types";
import {
    CREATE_INTERVENTION,
    CREATE_INTERVENTION_SUCCESS,
    INIT_INTERVENTION_SUCCESS,
    LOAD_INTERVENTION, RESET_INTERVENTION,
    START_SAV
} from "../Intervention/intervention.types";
import {RESET_APP} from "../GlobalUi/globalUi.types";
import {SIGNED} from "./intervention.types";

const INITIAL_STATE = {
    customer:{
        firstName:"",
        lastName:"",
    },
    diagResult:
        {
            screenOk:"",
            batterieOk:""
        }
    ,
    pricingResult:{},
    intervention:"",
    interventionSav:"",
    isCreationLoading: false,
    isQuoteLoading: false

};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case CREATE_INTERVENTION:{
            return {...state,
                isCreationLoading: !action.isQuote,
                isQuoteLoading: action.isQuote
            }
        }

        case LOAD_INTERVENTION:{
            return {...state,intervention:action.intervention}
        }

        case CREATE_INTERVENTION_SUCCESS:{
            return {...state,intervention:action.intervention,isCreationLoading: false,isQuoteLoading: false}
        }
        case INIT_INTERVENTION_SUCCESS:{
                return {...state,intervention:action.intervention}
            }
        case START_SAV:{
            return {...state,interventionSav:action.intervention}
        }
        case SIGNED :{
            return {...state,intervention:{...state.intervention,signed: true}}
        }
        case ON_PICEASOFT_NEW_DIAG:{

            if(action.result.case_id == 144)
            {
                if(action.result.status ==1)
                {
                    return {...state,diagResult:{...state.diagResult,batterieOk: true}}
                }
                else
                    return {...state,diagResult:{...state.diagResult,batterieOk: false}}

            }
            else if(action.result.case_id == 48)
            {
                if(action.result.status ==1)
                {
                    return {...state,diagResult:{...state.diagResult,screenOk: true}}
                }
                else
                    return {...state,diagResult:{...state.diagResult,screenOk: false}}

            }
            else
            {
                return {...state}
            }

        }

        case RESET_INTERVENTION:
            return INITIAL_STATE

        case RESET_APP:
            return INITIAL_STATE

        default:
            return state;
    }
};

export default reducer;
