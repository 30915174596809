import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import {useSelector} from "react-redux";
import WaitingProducts3 from "../WaitingProducts3";

function ParcelPreparation() {

    const shop = useSelector(state => state.shop.shopPartner);

    const [lastParcel, setLastParcel] = useState(null)
    const [parcelItems, setParcelItems] = useState([]);
    const [parcels,setParcels] = useState([]);

    useEffect(() => {
        axiosInstance.post("/parcels/",
            {page: 0, size: 2, shopPartnerId: shop.id})
            .then((response) => {
                setParcels(response.data.content)
                for (const parcel of response.data.content) {
                    if (parcel.status !== 'CREATION') {
                        setLastParcel(parcel);
                        axiosInstance.get("/parcel/" + parcel.id)
                            .then((response) => {
                                setParcelItems(response.data.parcelItems);
                            })
                            .catch((error) => {
                            });
                        break;
                    }
                }
            })
            .catch((error) => {
            });
    }, []);

    return (
        <>
        <WaitingProducts3/>
        </>
    )


}

export default ParcelPreparation