export const ON_ERROR = 'SHOP_DATA_LOADED';
export const RESET_APP = 'RESET_APP';
export const REFRESH_MENU = 'REFRESH_MENU';
export const REFRESH_PARCELS = 'REFRESH_PARCELS';
export const GO_TO_INTERVENTION = 'GO_TO_INTERVENTION';
export const SHOW_ABANDON_POPUP = 'SHOW_ABANDON_POPUP';
export const CLOSE_ABANDON_POPUP = 'CLOSE_ABANDON_POPUP';
export const SHOW_BAT_POPUP = 'SHOW_BAT_POPUP';
export const CLOSE_BAT_POPUP = 'CLOSE_BAT_POPUP';
export const INCREMENT_PARCEL_COUNTER='INCREMENT_PARCEL_COUNTER';
export const DECREASE_PARCEL_COUNTER='DECREASE_PARCEL_COUNTER';
export const RESET_PARCEL_COUNTER= 'RESET_PARCEL_COUNTER';
export const SET_PARCEL_COUNTER='SET_PARCEL_COUNTER';
export const RESET_APP_WITHOUT_PARCEL_COUNTER='RESET_APP_WITHOUT_PARCEL_COUNTER';
