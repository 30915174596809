import {Button, Card, Container, Grid, Image, Input, Label, Loader, Segment, Step} from 'semantic-ui-react'
import WorkflowStepper from "../../components/WorkflowStepper/WorkflowStepper";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../utils/axiosInstance";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {ON_MANUEL_MODEL_SELECT, UPDATE_IMEI} from "../../reducers/Product/product.types";
import {getModelAndDiag} from "../../reducers/Product/product.actions";
import {isIMEI} from "../../utils/imei-utils";
import SavHeader from "../../components/SavHeader/SavHeader";
import {isAdmin} from "../../utils/authorities-utils";

function ManualIdentification() {

    const product = useSelector(state => state.product);
    const connectedUser = useSelector(state => state.user.user);
    const history = useHistory();
    const dispatch = useDispatch();

    const [brands,setBrands] = useState([]);
    const [models,setModels] = useState([]);
    const [selectedModel,setSelectedModel] = useState("");
    const [modelFromImei,setModelFromImei] = useState({});
    const [imeiError,setImeiError] = useState(false);
    const [brandSearchQuery,setBrandSearchQuery] = useState("");
    const [modelSearchQuery,setModelSearchQuery] = useState("");
    const [selectedBrand,setSelectedBrand] = useState("");

    useEffect(() => {

        if(product.name !=="" && product.brand !=="") {
            setSelectedBrand(product.brand)
            setSelectedModel(product.name);
            dispatch(getModelAndDiag(() => {history.push("/manualDiag")}))
        }

        axiosInstance.get("/pricing/brands")
            .then((response) => {
                setBrands(response.data)
            });
    }, [])


    useEffect(()=>{
        if(product.pricingResult.length!==0 && product.name !=="" && product.brand !=="") {
            history.push("/manualDiag");
        }
    },[product.pricingResult])


    useEffect(()=>{
    if(product.name!=="" && product.brand !=="")
    {
        setSelectedBrand(product.brand)
        setSelectedModel(product.name);
        dispatch(getModelAndDiag())
    }},[product.name,product.brand])

    const handleBrandChange = (value) => {
        setSelectedBrand(value);
        axiosInstance.get("/pricing/models?productBrand="+value)
            .then((response) => {
                setModels(response.data)
            });
    }

    const handleModelChange = (value) => {
        setSelectedModel(value.modelName);
        dispatch({
            type: ON_MANUEL_MODEL_SELECT,
            brandName: selectedBrand,
            modelName: value.modelName,
            imgUrl:value.modelImgUrl,
        });
        dispatch(getModelAndDiag())
    }

    const handleModelChangeByImei = (model,brand) => {
        setSelectedModel(model);
        setSelectedBrand(brand);
        dispatch({
            type: ON_MANUEL_MODEL_SELECT,
            brandName: brand,
            modelName: model
        });
        dispatch(getModelAndDiag())
    }

    const handleModelFilterChange = (e,{value}) => {
        setModelSearchQuery(value);
    }
    const getFromImei = (e,{value}) => {
        if(value==="")
        { setImeiError(false);
            return;
        }
        if(!isIMEI(value,isAdmin(connectedUser.authorities))) {
         setImeiError(true);
            return;
        }
        if(value.length>=8)
        {
            axiosInstance.get("/gsma/byimei/"+value)
                .then((response) => {
                    setSelectedModel(response.data.modelName);
                    setSelectedBrand(response.data.brandName);
                    setModels([{
                    // brandImgUrl: "https://drpciyuuf9kdh.cloudfront.net/save/quote/quote-brand/brand-APPLE.svg",
                    brandName: response.data.brandName,
                    modelImgUrl: response.data.pictureUrl ? response.data.pictureUrl : "https://s3-eu-west-1.amazonaws.com/trepidai-fr/model/default.png",
                    modelName: response.data.modelName}]);

                    dispatch({type:UPDATE_IMEI,payload:value});

                });
        }
    }


    const rows = [];

const displayBrands = ()=>{
    if(brands.length!==0)
    for (const brand of brands) {
        rows.push(
            <Card style={{minWidth: '160px', maxWidth: '160px', maxHeight: '170px'}} onClick={()=>handleBrandChange(brand.brandName)}>
                <Image centered style={{maxWidth: '120px', maxHeight: '120px', padding: '5px', background: 'white'}} src={brand.brandImgUrl} fluid  />
                <Card.Content>
                    <Card.Meta textAlign='center'>{brand.brandName}</Card.Meta>
                </Card.Content>
            </Card>
        )
    }
    else
        return <Loader/>
    return rows;
}

    const displayModels = ()=>{
        if(models.length!==0)
        for (const model of models) {
            if(modelSearchQuery===""||model.modelName.toLowerCase().includes(modelSearchQuery.toLowerCase()))
            rows.push(
                    <Card style={{maxWidth: '120px', maxHeight: '170px'}} onClick={()=>handleModelChange(model)}>
                        <Image centered style={{width: 'auto', maxWidth: '80px', maxHeight: '80px', padding: '5px',background: 'white'}} src={model.modelImgUrl ? model.modelImgUrl : "https://s3-eu-west-1.amazonaws.com/trepidai-fr/model/99657-WIKO-Stairway-51f6ac9e.jpg"} fluid  />

                        <Card.Content>
                            <Card.Meta textAlign='center'>{model.modelName}</Card.Meta>
                        </Card.Content>
                    </Card>
            )
        }
        else
        return <Loader/>
        return rows;
    }




    return (
        <>
        <Container fluid>
            <SavHeader/>
            <WorkflowStepper step={3} smaKO={true} />

            {/*<Header as='h2'>Identification du produit</Header>*/}

            <Step.Group attached='top'>
                <Step completed={selectedBrand ? true : false} active={!selectedBrand ? true : false}>
                    <Step.Content>
                        <Step.Title>Marque</Step.Title>
                    </Step.Content>
                </Step>
                <Step completed={!selectedBrand ? true : false} active={selectedBrand ? true : false}>
                    <Step.Content>
                        <Step.Title>Modèle</Step.Title>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Segment placeholder>

                {selectedBrand!==""?<Segment textAlign={'left'} >
                    <Grid columns={3} relaxed='very' stackable>
                        <Grid.Column>
                        <Button content={selectedBrand} icon='left arrow' floated='left' labelPosition='left' onClick={()=>{setSelectedBrand("")}} ></Button>
                    </Grid.Column>
                        <Grid.Column>

                            <Input autoFocus focus={true} select={true} placeholder='Filtrer' style={{    width: "100%",
                                maxWidth: "none"}} onChange={(e,value)=>handleModelFilterChange(e,value)}/>
                        </Grid.Column>
                        <Grid.Column>
                            <Label style={{float: 'right', cursor: 'pointer'}}basic color='orange' key={'orange'} onClick={()=>history.push("/manualIdentificationGsma")}>
                                Modèle non présent ? Cliquer ici !
                            </Label>
                        </Grid.Column>
                    </Grid>

                    <div>


                    </div>
                </Segment>:
                    <Segment textAlign={'left'} >
                    <b>Sélectionner une marque ou opter pour une identification rapide via IMEI : </b>
                    <Input placeholder='Imei' onChange={(e,data)=>{getFromImei(e,data)}} error={imeiError} style={{marginLeft:'10px'}}/>

                    <Label style={{float: 'right', cursor: 'pointer'}}basic color='orange' key={'orange'} onClick={()=>history.push("/manualIdentificationGsma")}>
                        Marque non présente ? Cliquer ici !
                    </Label>
                </Segment>}

                {(Object.keys(modelFromImei).length === 0 && modelFromImei.constructor === Object)?"":
                    <Segment textAlign={'left'} >

                        <Button style={{display:"-web-kit-box"}}  onClick={()=>handleModelChangeByImei(modelFromImei.modelName,modelFromImei.brandName)}
                                content='Valider'
                                icon='check'
                                label={{ as: 'a', basic: true, pointing: 'right', content: modelFromImei.brandName +"-"+modelFromImei.modelName }}
                                labelPosition='left'
                        />
                    </Segment>}

                <Card.Group centered>
                    {selectedBrand===""?displayBrands():displayModels()}
                </Card.Group>
            </Segment>
        </Container>
            </>
    );

}

export default ManualIdentification
