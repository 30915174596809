import {Button, Dimmer, Grid, Icon, Message, Segment} from 'semantic-ui-react'
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import DiagnosticResult from "../poc/component/DiagnosticResult";
import {useHistory} from 'react-router-dom';
import {ON_PICEASOFT_DIAG_STARTED} from "../../reducers/Diagnostic/diagnostic.types";

function RemoteDiagAfterIdentification(props) {
    const product = useSelector(state => state.product);
    const diagnostic = useSelector(state => state.diagnostic);
    const history = useHistory();
    const dispatch = useDispatch();

    const launchDiag = () => {
        dispatch({type: ON_PICEASOFT_DIAG_STARTED})
        props.launchDiag();
    }

    return (
        <Grid.Column width={12}>
            <Message icon>
                <Icon name='mobile alternate'/>
                <Message.Content>
                    <Message.Header>{product.pipiceasoftBrand} {product.piceasoftName} ({product.piceasoftTechnicalName})</Message.Header>
                    IMEI : <b>{product.piceasoftImei}</b>
                </Message.Content>
            </Message>

            <Dimmer.Dimmable as={Segment}
                             dimmed={props.piceasoftIdentification != null && (!diagnostic.diagStarted || diagnostic.diagFinished)}>

                <DiagnosticResult/>
                <Dimmer
                    active={props.piceasoftIdentification != null && !diagnostic.diagStarted}
                >
                    <Button fluid color='teal' fluid size='large' onClick={launchDiag}>
                        Lancer le diagnostic
                    </Button>

                </Dimmer>
                <Dimmer
                    active={props.piceasoftIdentification != null && diagnostic.diagFinished}
                >
                    {product.isAllDiagDone === true ?
                        <Button fluid color='teal' onClick={() => history.push("/quotation")}>Générer le devis</Button>
                        :
                        <Button fluid color='teal' onClick={() => history.push("/manualDiag")}>Continuer avec le complément de test
                            manuels</Button>
                    }
                </Dimmer>
            </Dimmer.Dimmable>

        </Grid.Column>

    );

}

export default RemoteDiagAfterIdentification
