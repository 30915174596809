import {Grid, Header, Segment} from 'semantic-ui-react'

import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import MenuSideBar from "../components/MenuSideBar/MenuSideBar";
import HomePage from "../views/HomePage";
import {Route, Switch} from "react-router-dom";
import WaitingProducts from "../views/WaitingProducts";
import Followup from "../views/Followup";
import React from "react";
import VisualDiagPage from "../views/visualdiag/VisualDiagPage";
import RemoteDiag from "../views/remoteDiag/RemoteDiag";
import ManualDiag from "../views/manualDiag/ManualDiag";
import Quotation from "../views/quote/Quotation";
import ManualIdentificationGsma from "../views/manualIdentificationGsma/ManualIdentificationGsma";
import HelpPage from "../views/HelpPage";
import Footer from "../components/Footer/Footer";
import ManualIdentification from "../views/manualIdentification/ManualIdentification";
import ParcelPage from "../views/parcel/ParcelPage";
import SettingPage from "../views/setting/SettingPage";
import AdminPage from "../views/setting/AdminPage";
import ManagerPage from "../views/setting/ManagerPage";
import AlternateWorkflow from "../views/AlternateWorkflow";
import PrerequisitePage from "../views/prerequisite/PrerequisitePage";
import FollowupSupport from "../views/FollowupSupport";
import AdvSupportLoginPage from "../views/AdvSupportLoginPage";


function SupportLayout({className,title}) {



    return (

        <>
            <Grid padded>
                <HeaderMenu/>
            </Grid>

            <Grid padded>

                <Grid.Column width={16} id="content" floated="right" >
                    {title!==undefined?<Header className={"titleHeader"} as='h2' attached='top'>
                        {title}
                    </Header>:""}
                    <Segment attached id={title===undefined?"main-segment":"main-segment-with-attached"} className={className!==undefined?className:"homeaa"}>
                        <Switch>
                            <Route path="/" exact component={FollowupSupport}/>
                            <Route path="/advsupportlogin" exact component={AdvSupportLoginPage}/>
                        </Switch>
                    </Segment>
                </Grid.Column>
            </Grid>

            <Footer/>
        </>
    );

}

export default SupportLayout
