import {Container, Form, Input, Segment} from 'semantic-ui-react'
import React, {useState} from "react";


function SMA_KO_ManualDiag(props) {

    const diagAction = function (testOk) {
        props.nextDiag(testOk, comment)
    }

    const [comment, setComment] = useState();

    const updateCommebt = (e, value) => {
        setComment(value.value);
    }

    return (
        <Segment>
            <h3>Le smartphone est endommagé et ne s'allume pas? </h3>
            <Segment fluid>
                <Form fluid>
                    <Form.Field>
                        <Form.TextArea fluid
                                       label="Indiquez nous plus précisément les causes du dysfonctionnement de l'appareil"
                                       placeholder='Expliquez les causes et les caractéristiques de la panne...'/>
                    </Form.Field>

                    <Form.Button onClick={() => diagAction(false)} fluid primary
                                 style={{marginTop: '20px'}}>Valider</Form.Button>
                </Form>
            </Segment>
        </Segment>

    );

}

export default SMA_KO_ManualDiag
