import {Button, Card, Icon, Image, Modal, Popup} from 'semantic-ui-react'
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CLOSE_BAT_POPUP} from "../../reducers/GlobalUi/globalUi.types";
import {useHistory} from "react-router-dom";
import {getRepairLabel} from "../../utils/repairLabels";
import {ON_MANUAL_DIAG_UPDATE, UPDATE_PRODUCT_INFO} from "../../reducers/Product/product.types";
import {getModelAndDiag} from "../../reducers/Product/product.actions";


function BatPopup() {

    const isShow = useSelector(state => state.global.showBatPopup);
    const product = useSelector(state => state.product);
    const dispatch = useDispatch();
    const history = useHistory();

    function goToQuotation() {
        history.push("/quotation")
    }

    const confirm = () => {
        dispatch(getModelAndDiag(() => setDiag()))
        dispatch({type: CLOSE_BAT_POPUP})
    }

    function setDiag() {
        for (let diag of product.selectedRepairs) {
            let cat = diag.repairType === 'ORIGINAL' ?diag.category.concat('_ORI') : diag.category;
            dispatch({type: ON_MANUAL_DIAG_UPDATE, category: cat, diagOk: false});
            continue;
        }
        history.push("/quotation")
    }

    const closeOnDimmerClick = () => {
        dispatch({type: CLOSE_BAT_POPUP})
    }

    return (
        <>
            <Modal closeOnDimmerClick={true}
                   open={isShow}
                   size={"small"}
                   onClose={closeOnDimmerClick}>
                <Modal.Header>Risque de perte de garantie </Modal.Header>
                <Modal.Content>
                    Attention, vous avez sélectionné une réparation de pièce d'origine et une autre avec pièce compatible.
                        <br/>Si le produit est toujours sous garantie, celle-ci sera perdue.
                        <br/>Souhaitez vous confirmer la prise en charge ?
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={confirm}>
                        <Icon name='checkmark'/> Oui
                    </Button>
                    <Button color='red' onClick={closeOnDimmerClick}>
                        <Icon name='remove'/> Non
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default BatPopup

