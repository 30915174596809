import {Container, Header, List, Pagination, Table} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import Moment from "react-moment";
import LogSelector from "./LogSelector";

function Logs() {

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [level, setLevel] = useState("ERROR");
    const [search, setSearch] = useState("");
    const [totalPageNumber, setTotalPageNumber] = useState(1)
    const [logs, setLogs] = useState([]);


    const fetchLogs = () => {
        axiosInstance.post("/logs/",
            {
                page: page,
                size: size,
                level:level,
                search: search
            })
            .then((response) => {
                console.log(response);
                setTotalPageNumber(response.data.totalPages)
                setLogs(response.data.content)
            })
            .catch((error) => {
                console.log("error")
            });
    }


    useEffect(() => {
        fetchLogs()
    }, [page, level, search])

    const handlePageChange = (e, activePage) => {
        setPage(activePage.activePage - 1);
    }

    return (
        <>
            <Container fluid>
                <Header block as='h2'>Logs</Header>

                <LogSelector setLevel={setLevel} setSearch={setSearch}></LogSelector>
                <Table compact celled definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell collapsing>Date</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Niveau</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Code</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Utilisateur</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Boutique</Table.HeaderCell>
                            <Table.HeaderCell>Label</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {logs.map(log => (<Table.Row positive>
                            <Table.Cell collapsing>
                                <List>
                                    <Moment format="DD/MM/YYYY HH:mm:ss "
                                            locale="fr">{log.creationDate}</Moment>
                                </List>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <List>
                                    <span>{log.level}</span>
                                </List>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <List>
                                    <span>{log.code}</span>
                                </List>
                            </Table.Cell>

                            <Table.Cell collapsing>
                                <List>
                                    <span>{log.login}</span>
                                </List>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <List>
                                    <span>{log.shopPartnerCode}</span>
                                </List>
                            </Table.Cell>
                            <Table.Cell>
                                <List>
                                    <span>{log.label}</span>
                                </List>
                            </Table.Cell>
                        </Table.Row>))}
                    </Table.Body>

                </Table>

            </Container>

            <Pagination
                activePage={page + 1}
                style={{width: "100%", float: "right"}}
                onPageChange={(e, pageEvent) => handlePageChange(e, pageEvent)}
                defaultActivePage={1}
                totalPages={totalPageNumber}
                style={{marginTop: '10px'}}/>

        </>
    );

}

export default Logs
