import React, {useEffect, useState} from 'react'
import {Button, Dropdown, Form, Modal} from 'semantic-ui-react'
import axiosInstance from "../../utils/axiosInstance";
import {useDispatch, useSelector} from "react-redux";
import {isAdmin} from "../../utils/authorities-utils";
import {fetchIntervention, fetchInterventionHistoric} from "../../reducers/FollowUp/followup.actions";
import {isDiscountEligible} from "../../utils/intervention-utils";

function DiscountPopup() {

    const connectedUser = useSelector(state => state.user.user);
    const intervention = useSelector(state => state.followup.selectedIntervention);
    const followup = useSelector(state => state.followup);
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [discountRate, setDiscountRate] = useState();
    const [allowed, setAllowed] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        setDiscountRate("");
        if (isDiscountEligible(intervention, followup.historic)) {
            setAllowed(true);
        } else {
            setAllowed(false);
        }
    }, [intervention])

    const handleConfirm = (discountRate) => {
        axiosInstance.get("/intervention/" + intervention.id + '/discount/' + discountRate)
            .then((response) => {
                setAllowed(false);
                dispatch(fetchIntervention(intervention.id));
                dispatch(fetchInterventionHistoric(intervention.id))
            }).catch((error) => {
        });
        setOpen(false);
    }

    const handleCancel = () => {
        setOpen(false);
        setDiscountRate("");
    }

    return (
        <div>
            {isAdmin(connectedUser.authorities) ? <>
                <Dropdown.Item onClick={() => setOpen(allowed)} title={errorMsg}
                               disabled={!allowed}>Appliquer une réduction</Dropdown.Item>
            </> : null}

            <Modal
                onClose={() => setOpen(false)}
                open={open}
                onSubmit={() => handleConfirm(discountRate)}
                as={Form}>
                <Modal.Header>Appliquer une promotion (%)</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form.Input type='text'
                                    name='discountRate'
                                    value={discountRate}
                                    min={1}
                                    pattern='(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)?$)'
                            // pattern={ (^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)?$)}
                                    max={100}
                                    maxLength="3"
                                    onChange={(e, {name, value}) => setDiscountRate(value)}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    placeholder="Veuillez indiquer le pourcentage de la promotion"
                            //error={percentage > 100 ? 'Veuillez indiquer une valeur inférieur ou égal à 100' : ''}
                        />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => handleCancel()}>
                        Annuler
                    </Button>
                    <Button primary
                            type='submit'> Confirmer </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default DiscountPopup

