import React from 'react'
import {useSelector} from "react-redux";


function OrangeMoodLabel(props) {

    const shopType = useSelector(state => state.shop.shopPartner.type);

    if (shopType === "MOOD") {
        switch (props.label) {
            case "Orange":
                return <>Mood</>
            case "orange":
                return <>mood</>
            case "Téléphones":
                return <>Produits</>
            case "téléphones":
                return <>produits</>
            case "téléphone":
                return <>produit</>
            case "moins de 24 mois":
                return <>moins de 12 mois</>
            default:
                return <>{props.label}</>
        }
    } else {
        return <>{props.label}</>
    }


}

export default OrangeMoodLabel

