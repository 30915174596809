import React, {useEffect, useState} from 'react'
import {Button, Confirm, Dropdown, Icon, Select} from 'semantic-ui-react'
import axiosInstance from "../../utils/axiosInstance";
import {DELETED_INTER} from "../../reducers/FollowUp/followup.types";
import {useDispatch} from "react-redux";
import {RESET_APP} from "../../reducers/GlobalUi/globalUi.types";
import {useHistory} from 'react-router-dom';

/**
 *
 * @param interventionId
 * @param isButton display a button or a item list
 * @param isAllowed force authorization
 * @returns {*}
 * @constructor
 */
function DismissPopup({interventionId, isButton, isAllowed, smallVersion}) {

    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [allowed, setAllowed] = useState(isAllowed);
    const [reason, setReason] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    let selectInput = null;


    useEffect(() => {
        if (isAllowed === undefined) {
            axiosInstance.get("/intervention/" + interventionId + '/simu/cancelValidation')
                .then((response) => {
                    setErrorMsg("");
                    setAllowed(true);
                }).catch((error) => {
                setErrorMsg(error.response.data);
                setAllowed(false);
            })
        } else {
            setAllowed(isAllowed);
            setErrorMsg("");
        }
setReason(null);

    }, [interventionId])

    const handleConfirm = () => {

        if(reason === null)
        {
            return;
        }

        setOpen(false);

        if (errorMsg === "")
            axiosInstance.get("/intervention/" + interventionId + '/simu/cancel/' + reason)
                .then((response) => {
                    dispatch({type: DELETED_INTER, interventionId: interventionId})
                    if (isAllowed) {
                        history.push("/");
                        dispatch({type: RESET_APP});
                    }
                }).catch((error) => {
                setErrorMsg(error);

            });


    }

    const handleCancel = () => {
        setOpen(false);

    }


    const handleChange = (e, {name, value}) => {
        setReason(value);
    }


    const reasonOptions = [
        {
            key: 'customer_wait_for_the_proposal',
            value: 'customer_wait_for_the_proposal',
            text: 'Le client trouve les délais trop longs'
        },
        {key: 'too_expensive', value: 'too_expensive', text: 'Le client trouve la réparation trop chère'},
        {key: 'no_time', value: 'no_time', text: 'Le client n\'a pas le temps'},
        {key: 'customer_think', value: 'customer_think', text: 'Le client souhaite réfléchir à la proposition de devis'}
    ]

    const SelectExample = ({errorMsg}) => (

        <Select value={reason} onChange={handleChange} style={{"width": "100%"}} ref={(select) => { selectInput = select; }}
                placeholder="Sélectionnez la raison d'abandon" options={reasonOptions}/>)


    return (
        <div>
            {isButton !== undefined ? ( smallVersion !== undefined && smallVersion ?
                <Button icon size={"mini"} style={{paddingBottom: '2px', paddingTop: '1px'}} color={"red"}
                        onClick={() => setOpen(allowed)}><Icon
                    name="delete" style={{paddingBottom: '2px', paddingTop: '1px'}}/>Abandon</Button> :
                <Button fluid color={"red"} onClick={() => setOpen(allowed)} title={errorMsg}
                        disabled={!allowed}>Refuser</Button> ):
                <Dropdown.Item onClick={() => setOpen(allowed)} title={errorMsg}
                               disabled={!allowed}>Annuler</Dropdown.Item>}
            {/*<Label>{errorMsg}</Label>*/}
            <Confirm
                open={open}
                header='Êtes vous sûr de vouloir abandonner l’intervention ?'
                content={<div style={{"padding": "5px"}}><SelectExample/></div>}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
            />
        </div>
    )

}

export default DismissPopup

