import {Dropdown, Form, Grid, Input, Menu, Segment} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import useDebounce from "../../utils/use-debounce";
import {axiosInstance} from "../../utils/axiosInstance";

function LogSelector({setLevel, setSearch}) {

    let levels = [{key: "ERROR", text: "ERROR", value: "ERROR"}, {key: "INFO", text: "INFO", value: "INFO"}];
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchTerm = useDebounce(searchQuery, 500);

    useEffect(() => {
        if (debouncedSearchTerm !== "") {
            setSearch(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm])

    const handleLevelSearchChange = (e, {name, value}) => {
        setSearchQuery(value);
    };
    const handleLevelChange = (e, {searchQuery, value}) => {
        setLevel(value);
    }
    return (
        <Segment>
            <Grid columns={2} relaxed='very'>
                <Grid.Column verticalAlign='top'>
                    <Grid columns={2}>
                        <Grid.Column>
                            <>
                                <h4>Niveau de log</h4>
                                <Dropdown
                                    fluid
                                    clearable
                                    options={levels}
                                    selection
                                    defaultValue="ERROR"
                                    onChange={handleLevelChange}
                                />
                            </>
                        </Grid.Column>

                        <Grid.Column verticalAlign='bottom'>
                            <>
                                <Form>
                                    <Form.Field>
                                        <Form.Input>
                                            <Input className='icon' icon='search' placeholder='Recherche...'
                                                   value={searchQuery} onChange={handleLevelSearchChange}/>
                                        </Form.Input>
                                    </Form.Field>
                                </Form>
                            </>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

export default LogSelector
