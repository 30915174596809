import {RESET_SHOP_DATA, SHOP_DATA_LOADED, UPDATE_SHOP_SAVE_SETTINGS, UPDATE_SHOP_SETTINGS} from './shop.types'
import {LOGGIN} from "../User/user.types";

const INITIAL_STATE = {
    shopPartner: null,
    shopSave: null,
    extranetUrl:null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGGIN: {
            return ({
                ...state,
                shopPartner: action.shopPartner,
                shopSave: action.shopSave,
                extranetUrl:action.extranetUrl
            })
        }
        case UPDATE_SHOP_SETTINGS: {
            return ({
                    ...state,
                    shopPartner: {
                        ...state.shopPartner,
                        notificationCommunicationEnabled: action.payload.notificationCommunicationEnabled,
                        mailCommunicationEnabled: action.payload.mailCommunicationEnabled,
                        mailCommunicationEmails: action.payload.mailCommunicationEmails,
                        smsCommunicationEnabled: action.payload.smsCommunicationEnabled,
                        smsCommunicationPhoneNumbers: action.payload.smsCommunicationPhoneNumbers,
                        npsLink: action.payload.npsLink,
                        automaticPacking: action.payload.automaticPacking,
                        shopInShop: action.payload.shopInShop,
                        useWacomSignPad: action.payload.useWacomSignPad,
                        codePdl: action.payload.codePdl,
                        codeEdo: action.payload.codeEdo
                    }
                }
            )
        }

        case UPDATE_SHOP_SAVE_SETTINGS: {
            return ({
                    ...state,
                    shopSave: action.payload
                }
            )
        }

        case RESET_SHOP_DATA:
            return INITIAL_STATE

        default:
            return state;
    }
};

export default reducer;
