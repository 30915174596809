import {
    LOGGIN,
    LOGGIN_ERROR,
    LOGOUT,
    SET_TOKEN,
    SET_USER_NOTIFICATION_TOKEN,
    START_LOGGIN, SUPERVISER_SELECT_SHOP
} from './user.types';


const INITIAL_STATE = {
    user: null,
    userAdditionalInformations: null,
    token: "",
    isLogged: false,
    isLoading: false,
    loginErrorMessage: "",
    notificationToken:"",
    superviserHasSelectedShop: false
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case START_LOGGIN: {
            return {
                ...state,
                isLoading: true,
                loginErrorMessage: ""
            }
        }
        case SET_TOKEN: {
            return {
                ...state,
                token: action.token,
                loginErrorMessage: ""
            }
        }
        case LOGGIN: {
            return {
                ...state,
                user: action.user,
                userAdditionalInformations: action.userAdditionalInformations,
                isLogged: true,
                loginErrorMessage: "",
                isLoading: false
            }
        }
        case LOGGIN_ERROR: {
            return {
                ...state,
                loginErrorMessage: action.loginErrorMessage,
                isLoading: false
            }
        }
        case SET_USER_NOTIFICATION_TOKEN: {
            return {
                ...state,
                notificationToken: action.payload
            }
        }
        case SUPERVISER_SELECT_SHOP: {
            return {
                ...state,
                superviserHasSelectedShop: true,
            }
        }

        case LOGOUT: {
            return {
                ...INITIAL_STATE,
                loginErrorMessage: action.loginErrorMessage
            }
        }

        // case RESET_APP:
        //     return INITIAL_STATE

        default:
            return state;
    }
};

export default reducer;
