import {
    Button,
    Container,
    Header,
    Icon,
    Message,
    Pagination,
    Progress,
    Segment,
    Table
} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {axiosInstance, axiosInstanceLongTreatment} from "../../utils/axiosInstance";
import NumberFormat from "react-number-format";
import ModelSelector from "./ModelSelector";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Pricings() {

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [totalPageNumber, setTotalPageNumber] = useState(1)
    const [totalElements, setTotalElements] = useState(0)
    const [pricings, setPricings] = useState([]);
    const [brandName, setBrandName] = useState("");
    const [modelName, setModelName] = useState("");
    const [pictureUrl, setPictureUrl] = useState("");
    const [type, setType] = useState("");
    const [pricingUploading, setPricingUploading] = useState(false);
    const [showUpdateSegment, setShowUpdateSegment] = useState(false);
    const [uploadResultMessage, setUploadResultMessage] = useState("");
    const [isExporting, setIsExporting] = useState(false);

    function fetchPricings() {
        axiosInstance.post("/pricings/",
            {
                page: page,
                size: size,
                brandName: brandName,
                modelName: modelName.name
            })
            .then((response) => {
                console.log(response);
                setTotalPageNumber(response.data.totalPages)
                setTotalElements(response.data.totalElements)
                setPricings(response.data.content)
            })
            .catch((error) => {
                console.log("error")
            });
    }

    useEffect(() => {
        fetchPricings();
    }, [page, brandName, modelName])

    const handlePageChange = (e, activePage) => {
        console.log(activePage)
        setPage(activePage.activePage - 1);
    }

    function UploadButton({label, onUpload}) {
        let fileInput = null;
        const uid = Math.random().toString(36).substring(7);
        return (
            <span>
                  <label htmlFor={uid} className="ui icon button fluid">
                    <i className="upload icon"></i>&nbsp;&nbsp;{label}
                  </label>
                  <input type="file" id={uid} style={{display: "none"}} disabled={pricingUploading}
                         onChange={() => {
                             onUpload(fileInput.files[0]);
                         }}
                         ref={input => {
                             fileInput = input;
                         }}
                  />
            </span>
        );
    }


    function excelPricingExport() {
        setIsExporting(true)
        axiosInstanceLongTreatment.get("admin/pricing/export",  { responseType: 'blob' })
            .then((response) => {
                setIsExporting(false)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "export_pricing.xlsx");
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                setIsExporting(false)
            });
    }

    function updatePricing(file) {

        let data = new FormData()
        data.append('file', file, file.name)

        setPricingUploading(true);
        axiosInstanceLongTreatment.post("admin/pricing/upload2", data)
            .then((response) => {
                setPricingUploading(false);
                setPage(0);
                console.log(response)
                setUploadResultMessage(response.data ? response.data : "Le fichier a bien été intégré.")
                fetchPricings();
            })
            .catch((error) => {
                setUploadResultMessage("Une erreur est survenue");
                setPricingUploading(false);
            });
    }

    const removePrices = (pricing) => {
        axiosInstance.delete("pricing?ids=" + pricing.id).then((response) => {
            setIsExporting(false)
            toast.success("Le prix sélectionné a été supprimé");
            fetchPricings();
        });
    };

    function displayPricings() {
        return <>
            <ModelSelector setBrandName={setBrandName} setModelName={setModelName} setPictureUrl={setPictureUrl}
                           setType={setType}/>

            {totalElements} éléments trouvés {brandName ? 'pour' : null} {brandName} {modelName.name}
            <Table compact celled structured size='small'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell rowSpan='2'>Marque</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2'>Modèle</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2'>Réparation</Table.HeaderCell>
                        <Table.HeaderCell collapsing colSpan='2'>B2C</Table.HeaderCell>
                        <Table.HeaderCell collapsing colSpan='2'>B2B Store</Table.HeaderCell>
                        <Table.HeaderCell collapsing colSpan='2'>B2B Lab</Table.HeaderCell>
                        <Table.HeaderCell rowSpan='2' >Actions</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell collapsing textAlign={"right"}>HT</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign={"right"}>TTC</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign={"right"}>HT</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign={"right"}>TTC</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign={"right"}>HT</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign={"right"}>TTC</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {pricings.map(pricing => (
                        <Table.Row positive>
                            <Table.Cell collapsing>
                                {pricing.brandName}
                            </Table.Cell>
                            <Table.Cell collapsing>{pricing.modelName}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {pricing.category}
                            </Table.Cell>
                            <Table.Cell collapsing textAlign={"right"}>
                                <NumberFormat
                                    value={pricing.priceHt}
                                    displayType={'text'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=','
                                    suffix=' €'
                                />
                            </Table.Cell>
                            <Table.Cell collapsing textAlign={"right"}>
                                <NumberFormat
                                    value={pricing.priceTtc}
                                    displayType={'text'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=','
                                    suffix=' €'
                                />
                            </Table.Cell>
                            <Table.Cell collapsing textAlign={"right"}>
                                <NumberFormat
                                    value={pricing.priceB2BHt}
                                    displayType={'text'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=','
                                    suffix=' €'
                                />
                            </Table.Cell>
                            <Table.Cell collapsing textAlign={"right"}>
                                <NumberFormat
                                    value={pricing.priceB2BTtc}
                                    displayType={'text'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=','
                                    suffix=' €'
                                />
                            </Table.Cell>
                            <Table.Cell collapsing textAlign={"right"}>
                                <NumberFormat
                                    value={pricing.priceB2BHtLab}
                                    displayType={'text'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=','
                                    suffix=' €'
                                />
                            </Table.Cell>
                            <Table.Cell collapsing textAlign={"right"}>
                                <NumberFormat
                                    value={pricing.priceB2BTtcLab}
                                    displayType={'text'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=','
                                    suffix=' €'
                                />
                            </Table.Cell>
                            <Table.Cell collapsing textAlign={"left"}>
                                <Button size={"mini"} color={'red'} onClick={() => removePrices(pricing)}><FontAwesomeIcon icon="trash"/></Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>;
    }

    return (
        <>
            <Container fluid>
                <Header block as='h2'>Pricings
                </Header>

                <Button icon size={"mini"} primary onClick={() => setShowUpdateSegment(!showUpdateSegment)}><Icon
                    name='download'/>&nbsp;&nbsp;Import des pricings</Button>
                <Button disabled={isExporting} loading={isExporting} icon size={"mini"} primary
                        onClick={() => excelPricingExport()}><Icon name='file excel'/>&nbsp;&nbsp;Export des
                    pricings</Button>
                {showUpdateSegment && <Segment raised>
                    {!pricingUploading ?
                        <>
                            <p><u>L'upload de pricing fonctionne de la manière suivante :</u>
                                <ul>
                                    <li>le modèle doit exister dans le référenciel gsma</li>
                                    <li>si la clé existe alors on mets à jours tous les prix, sinon on créer la ligne
                                    </li>
                                    <li>il n'y a jamais de suppression</li>
                                </ul>
                                La clé est la composition de la marque, du modèle et de la catégorie
                            </p>
                            <UploadButton label="Upload d'un fichier de pricing" onUpload={updatePricing}/>
                        </>
                        :
                        <Message success>Mise à jour des pricings en cours<Progress percent={100}
                                                                                    indicating/></Message>}
                    {uploadResultMessage ? <Message>{uploadResultMessage}</Message> : null}
                </Segment>}

                {displayPricings()}
            </Container>


            <Pagination
                activePage={page + 1}
                style={{width: "100%", float: "right"}}
                onPageChange={(e, pageEvent) => handlePageChange(e, pageEvent)}
                defaultActivePage={0}
                totalPages={totalPageNumber}
                style={{marginTop: '10px'}}/>

        </>
    );

}

export default Pricings
