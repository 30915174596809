
export function isAllowed(currentAuth,userAuth){

    if(isAdmin(currentAuth)) {
        if (isAdmin(userAuth)) {
            return true;
        }
    }
     else if(isManager(currentAuth)) {
         if (isAdmin(userAuth)) {
             return true;
         }
     }
     else if(isUser(currentAuth)||isReadOnly(currentAuth)) {
         if (isAdmin(userAuth) || isManager(userAuth)) {
             return true;
         }
     }
         return false;


}

export function isAdmin(userAuth){
    for(let auth of userAuth)
    {
        if(auth.name === "ROLE_ADMIN"|| auth === "ROLE_ADMIN")
            return true;
    }
    return false
}


export function isManager(userAuth){
    for(let auth of userAuth)
    {
        if(auth.name === "ROLE_MANAGER" || auth === "ROLE_MANAGER")
            return true;
    }
    return false
}

export function isSuperviser(userAuth){
    for(let auth of userAuth)
    {
        if(auth.name === "ROLE_SUPERVISER" || auth === "ROLE_SUPERVISER")
            return true;
    }
    return false
}

export function isSupport(userAuth){
    for(let auth of userAuth)
    {
        if(auth.name === "ROLE_SUPPORT" || auth === "ROLE_SUPPORT")
            return true;
    }
    return false
}


export function isUser(userAuth){
    for(let auth of userAuth)
    {
        if(auth.name === "ROLE_USER" || auth === "ROLE_USER")
            return true;
    }
    return false
}

export function isReadOnly(userAuth){
    for(let auth of userAuth)
    {
        if(auth.name === "ROLE_READONLY" || auth === "ROLE_READONLY")
            return true;
    }
    return false
}
