import { Confirm, Select} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isAllowed} from "../../utils/authorities-utils";
import axiosInstance from "../../utils/axiosInstance";
import {DELETED_INTER} from "../../reducers/FollowUp/followup.types";
import {CLOSE_ABANDON_POPUP, RESET_APP} from "../../reducers/GlobalUi/globalUi.types";
import {useHistory, useLocation} from "react-router-dom";


function AbandonPopup() {

    const [isOpened, setIsOpened] = useState(true);
    const product = useSelector(state => state.product);
    const phone = useSelector(state => state.customer.orangePhone);
    const isShow = useSelector(state => state.global.showAbandonPopup);
    const intervention = useSelector(state => state.intervention.intervention);
    const selectedIntervention = useSelector(state => state.followup.selectedIntervention);
    const [reason, setReason] = useState(null);
    const [errorMsg, setErrorMsg] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let selectInput = null;

    const reasonOptions = [
        {key: 'ABN_ANO', value: 'ABN_ANO', text: 'Anomalie (annulation, erreur, régulation...)'},
        {
            key: 'ABN_PRICE',
            value: 'ABN_PRICE',
            text: 'Devis trop cher'
        },
        {key: 'ABN_DELAY', value: 'ABN_DELAY', text: 'Délai trop long'},
        {key: 'ABN_SIMU', value: 'ABN_SIMU', text: 'Simulation devis (Assurance, Reprise...)'},
        {key: 'ABN_TEST', value: 'ABN_TEST', text: 'Test formation boutique'}
    ]

    const close = () => {
        setReason(null)
        dispatch({type: CLOSE_ABANDON_POPUP})
    }


    const handleConfirm = () => {
        if (reason === null) {
            return;
        }
        setIsOpened(false);

        let repairs = [];
        const pricings = product.pricingResult;
        if (typeof pricings !== "undefined") {
            const result = pricings.filter(price => price.allSubDiagOk === false);
            for (let price of result) {
                repairs.push(price.category)
            }
        }
        if(typeof product.selectedRepairs !== "undefined") {
            repairs =  product.selectedRepairs.map(function(obj) {return obj.category;}).concat(repairs);
        }


        let interventionId = null;
        if (typeof intervention.id == "undefined") {
            if (selectedIntervention != null) {
                 interventionId = selectedIntervention.id;
            }
        } else {
            interventionId =  intervention.id;
        }

        axiosInstance.post("/intervention/cancel/",
            {
                interventionId:interventionId,
                brandName: product.brand,
                modelName: product.name,
                contactPhone: phone,
                reason: reason,
                selectedRepairs: repairs
            }).then((response) => {
                    dispatch({type: DELETED_INTER, interventionId: interventionId})
                    if(isFollowupPage()) {
                        history.push("/followup");
                        close();
                    } else if (isAllowed) {
                        history.push("/");
                        dispatch({type: RESET_APP});
                    }
                }).catch((error) => {
                console.log("error")
                setErrorMsg(error);
            });
    }

    const isFollowupPage = () => {
        if(location.pathname === '/followup') {
            return true;
        }
    }

    const handleChange = (e, {name, value}) => {
        setReason(value);
    }

    const SelectExample = ({errorMsg}) => (
        <Select value={reason} onChange={handleChange} style={{"width": "100%"}} ref={(select) => { selectInput = select; }}
                placeholder="Sélectionnez la raison d'abandon" options={reasonOptions}/>)

    return (
        <>
            <div>
                <Confirm
                    open={isShow}
                    header='Êtes vous sûr de vouloir abandonner l’intervention ?'
                    content={<div style={{"padding": "5px"}}><SelectExample/></div>}
                    onCancel={() => close()}
                    onConfirm={handleConfirm}
                />
            </div>
        </>
    );
}

export default AbandonPopup

