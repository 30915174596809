import {
    Button,
    Card,
    Checkbox,
    Confirm,
    Container,
    Grid,
    Header,
    Icon,
    Label,
    Message,
    Modal, Progress,
    Segment
} from 'semantic-ui-react'
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import Moment from "react-moment";
import {axiosInstance} from "../../utils/axiosInstance";
import cgvOrange from "../img/cgv_orange.pdf";
import cgvMood from "../img/cgv_mood.pdf";
import {isIE} from "react-device-detect";
import SignWacom from "../sign/RemoteDiag";
import {SIGNED} from "../../reducers/Intervention/intervention.types";
import Signature from "../../components/Signature/Signature";
import {RESET_PRODUCT_INFO} from "../../reducers/Product/product.types";
import OrangeMoodLabel from "../../components/OrangeMoodLabel";
import {INCREMENT_PARCEL_COUNTER} from "../../reducers/GlobalUi/globalUi.types";
import {RESET_CUSTOMER_INFO} from "../../reducers/Customer/customer.types";

function QuotationResume() {

    const customer = useSelector(state => state.customer);
    const shop = useSelector(state => state.shop);
    const intervention = useSelector(state => state.intervention.intervention);
    const interventionLoading = useSelector(state => state.intervention.isCreationLoading);
    const [useWacom, setUseWacom] = useState(shop.shopPartner.useWacomSignPad !== undefined ? shop.shopPartner.useWacomSignPad : false);
    const [sigPad, setSigPad] = useState(null);
    const [sigBase64, setSigBase64] = useState(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const [cgvModal, setCgvModal] = useState(false);
    const [cgvOk, setCgvOk] = useState(false);
    const [signatureModal, setSignatureModal] = useState(true);
    const [confirmState, setConfirmState] = useState(false);
    const [loading, setLoading] = useState(false);
    const product = useSelector(state => state.product);

    const dispatch = useDispatch();
    const history = useHistory();
    const isSignedIntervention = (intervention && intervention.signed);

    const showWish = (wish) => {
        switch (wish) {
            case 'wish1' :
                return <>Le téléphone contient des données personnelles. Le client décharge <OrangeMoodLabel label={"Orange"}/> de toute responsabilité relative à la perte de celles-ci, notamment si la réparation requiert leur effacement.</>
            case 'wish2' :
                return <>Le client certifie avoir supprimé l'ensemble des données présentes sur son appareil avant dépôt pour réparation.</>
        }
    }

    const initSigPad = (ref) => {
        setSigPad(ref);
    }

    const onDraw = () => {
        setIsEmpty(sigPad.isEmpty())
    }

    const erase = () => {
        sigPad.clear();
        setIsEmpty(true)
    }


    const setAsDevis = () => {

        axiosInstance.get("/intervention/" + intervention.id + "/setDevis"
        ).then(() => history.push("/followup?intervention=" + intervention.id)).catch(
            console.log("Not saved")
        );
    }

    const validSignature = () => {
        setLoading(true);
        axiosInstance.post("/intervention/" + intervention.id + "/signature", {sigBase64 : sigPad.toDataURL(), withPAD: false}).then((response) => {
                setConfirmState(false)
                setSigBase64(sigPad.toDataURL());
                sigPad.off();
                dispatch({type: SIGNED});
                dispatch({type: INCREMENT_PARCEL_COUNTER})
                setLoading(false);
            }
        ).catch(function (error) {
            setConfirmState(false);
            setLoading(false);
            console.log("Not saved");
        })
    }

    const validNoSignature = () => {
        setConfirmState(false)
        axiosInstance.post("/intervention/" + intervention.id + "/signature", {
            withPAD: true
        }).then((response) => {
                console.log(response.data)
                setSigBase64("sdfsdfsdf");
                dispatch({type: SIGNED});
                dispatch({type: INCREMENT_PARCEL_COUNTER})
                //sigPad.off();
            }
        ).catch(
            console.log("Not saved")
        );
    }


    const notFound = () => {
        setUseWacom(false)
    }

    const validSignatureWacom = () => {
        setConfirmState(false);
        var signatureImage = document.getElementById("signatureImage");
        axiosInstance.post("/intervention/" + intervention.id + "/signature",
            signatureImage.src).then((response) => {
                console.log(response.data)
                setSigBase64(signatureImage.src);
                //sigPad.off();
            }
        ).catch(
            console.log("Not saved")
        );
    }
    const updateCgvOk = (e, {value}) => {
        const updateValue = !cgvOk
        setCgvOk(updateValue)
    }

    const displayHandle = () => {
        let intId = intervention.id;
        axiosInstance.get('/handleReport/' + intId + '?isQuote=false', {responseType: 'blob'})
            .then(response => {
//Create a Blob from the PDF Stream

                if (isIE) {
                    var blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    window.navigator.msSaveOrOpenBlob(blob, "Prise en charge ORG_" + intId + ".pdf");
                } else {

                    const file = new Blob(
                        [response.data],
                        {type: 'application/pdf'});
//Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                }

            })
            .catch(error => {
                console.log(error);
            });
    }

    const signatureValidationHAndler = ()=>{
        setSignatureModal(false);
        validNoSignature();
    }

    const forceNormalSign = ()=>{
        setUseWacom(false);
        setSignatureModal(false);
        setSigBase64(null);
    }

    return (
        // if(isSignedIntervention) {
        //     console.log("signed")
        // } else {
        //     console.log("not signed")
        // }
        <>
        {/*{isSignedIntervention ? null :null}*/}
        <Grid.Column>

            <Grid columns={3}>
                <Grid.Column width={!sigBase64 ? 9 : 0}>
                    <Segment>
                        <Header as='h3' block>
                            <Message warning> L'accord et la signature du client sont obligatoires pour continuer</Message>
                        </Header>
                        <Container style={{marginBottom: '5px'}}>
                            <p>
                                Le client accepte que les réparations mentionnées dans le présent devis soient
                                exécutées.
                            </p>
                        </Container>
                        <Container style={{marginBottom: '5px'}}>
                            <p>
                                {showWish(customer.rgpdWish)}
                            </p>
                        </Container>
                        <Container style={{marginBottom: '5px'}}>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column verticalAlign={"middle"} width={1}>
                                        {!sigBase64 ? <Checkbox
                                                value={cgvOk}
                                                onChange={(e, value) => updateCgvOk(e, value)}/>
                                            : <Checkbox checked={true}/>}
                                    </Grid.Column>

                                    <Grid.Column width={14}>



                                        <span style={{fontWeight: 'bold', color: 'red'}}>Le client certifie avoir pris connaissance des conditions générales de ventes</span>
                                        <a style={{cursor: 'pointer'}}
                                           href={shop.shopPartner.type === 'MOOD' ? cgvMood : cgvOrange} target={"_blank"} > (afficher les
                                            CGV)</a>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Container>

                        {cgvOk ? <>{}
                            <Container style={{marginBottom: '5px'}}>
                                <p>
                                    <b>« Bon pour accord »</b> <br/> le <Moment format="DD MMMM YYYY"
                                                                                locale="fr">{new Date()}</Moment> à {shop.city}
                                </p>
                            </Container>
                            {!useWacom ?
                                <Segment style={{backgroundColor: 'grey'}}>
                                    { <SignaturePad onEnd={onDraw} ref={(ref) => {
                                        initSigPad(ref)
                                    }} canvasProps={{className: 'sigPad2', width: 220, height: 100}}/>}


                                </Segment> :(
                                    <>
                                        {/*<Segment style={{backgroundColor: 'grey', width: "auto", height: 240, margin: "auto"}}>
                                    <SignWacom onValidation={validSignatureWacom} notFound={notFound}/>
                                </Segment>*/}
                                        <Signature openModal={cgvOk && signatureModal} uuid={intervention.interventionHandlingDocument.documentUuid} validationHandler={signatureValidationHAndler}
                                                   cancelHandler={forceNormalSign} type="HANDLE_REPORT"></Signature>
                                    </>)
                            }

                            {/*Save and cancel button not use when using wacom*/}
                            {!sigBase64 && !useWacom ? <><Button.Group fluid style={{marginBottom: '6px'}}>
                                <Button basic color='red' onClick={erase}>
                                    Effacer
                                </Button>
                                <Button basic color='green' onClick={() => setConfirmState(true)} disabled={isEmpty && product.color === ''}>
                                    Enregistrer
                                </Button>
                            </Button.Group>

                                {/*<Button basic color='blue' fluid center*/}
                                {/*        onClick={() => {history.push("/followup?intervention=" + intervention.id)}}>*/}
                                {/*    Signature à distance*/}
                                {/*</Button>*/}
                            </> : null}
                        </> : null}


                        {useWacom && cgvOk && intervention.status != 'QUOTE_INIT' && !sigBase64 ?<Button onClick={()=>setSignatureModal(true)}>Relancer la signature</Button>:null}

                    </Segment>
                </Grid.Column>
                <Grid.Column width={!sigBase64 ?7 :0}>
                    <Segment>
                        <Header as='h3' block>
                            Données du client
                        </Header>

                        <Card fluid>
                            <Card.Content>
                                <Card.Header>{customer.firstName} {customer.lastName} <Label color='teal' size={'mini'}
                                                                                             style={{float: 'right'}}>{customer.type === "CUSTOMER" ? "Particulier" : "Professionel"}</Label></Card.Header>
                                {customer.companyName ? <Card.Header>{customer.companyName}</Card.Header> : null}
                                <Card.Meta><Icon name='at' style={{marginRight: '5px'}}/>{customer.email}</Card.Meta>
                                <Card.Meta>Téléphone de contact : {customer.contactPhone}</Card.Meta>
                                {shop.shopPartner.type === 'ORANGE' ?
                                    <Card.Meta>Téléphone {customer.customerOrangeType === 'PUBLIC' || customer.customerOrangeType === 'PRO' ? "Orange" : ""} : {customer.orangePhone}</Card.Meta> : null}
                            </Card.Content>
                        </Card>

                    </Segment>
                </Grid.Column>
                {!sigBase64 ? null :  <Grid.Column>
                    <Segment>
                        <Header as='h3' block>
                            Actions
                        </Header>
                        {sigBase64 ?
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header>N° de dossier : ORG_{intervention.id}</Card.Header>
                                    <Card.Meta>&nbsp;</Card.Meta>

                                    <Card.Content extra>
                                        <Button basic color='green' fluid center onClick={displayHandle}>
                                            Bon de prise en charge
                                        </Button>
                                    </Card.Content>
                                    <Card.Meta>&nbsp;</Card.Meta>

                                    <Card.Content extra>
                                        <Button basic color='green' fluid center
                                                onClick={() => {
                                                    dispatch({type: RESET_PRODUCT_INFO});
                                                    history.push("/followup?intervention=" + intervention.id);
                                                }}>
                                            Ouvrir le dossier dans le suivi
                                        </Button>
                                    </Card.Content>
                                    <Card.Meta>&nbsp;</Card.Meta>

                                    {shop.shopPartner.pickupType === "CHRONOPOST" ?
                                        <Card.Content extra>
                                            <Button basic color='red' fluid center
                                                    onClick={() => {
                                                        dispatch({type: RESET_PRODUCT_INFO});
                                                        history.push("/colisage");
                                                    }}>
                                                Valider le colisage
                                            </Button>
                                        </Card.Content> :null}

                                </Card.Content>
                            </Card> : <Message warning>La signature est obligatoire pour pouvoir continuer</Message>}
                        {/*sigBase64===null && intervention.status !== "QUOTE_INITIAL_WAITING_CUSTOMER" ?<Message warning>Le client ne souhaite pas laisser son appareil pour le moment
                                <Button fluid primary color={"blue"} basic onClick={setAsDevis}>Valider le dossier sous forme de devis</Button>
                            </Message>:""*/}

                    </Segment>
                </Grid.Column> }

            </Grid>

            {/*<Confirm*/}
            {/*    open={confirmState}*/}
            {/*    onCancel={() => setConfirmState(false)}*/}
            {/*    onConfirm={validSignature}*/}
            {/*    content='Etes-vous sûr de valider définitivement la prise en charge ?'*/}
            {/*    cancelButton='Annuler'*/}
            {/*    confirmButton="Valider"*/}
            {/*/>*/}

            <Modal
                open={confirmState}
                onClose={() => setConfirmState(false)}
                onOpen={() => setConfirmState(true)}
            >
                <Modal.Header>Validation</Modal.Header>
                <Modal.Content>
                    {!loading ?
                        <p>Etes-vous sûr de valider définitivement la prise en charge ?</p> :
                        <Progress percent={100} indicating><p>Enregistrement en cours...</p></Progress>}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => setConfirmState(false)} disabled={loading}>
                        <Icon name='remove'/> Annuler
                    </Button>
                    <Button color='green' onClick={() => validSignature()} disabled={loading}>
                        <Icon name='checkmark'/> Valider
                    </Button>
                </Modal.Actions>
            </Modal>


            <Modal
                open={cgvModal}
                onClose={() => setCgvModal(false)}
                onOpen={() => setCgvModal(true)}
                size='large'
            >
                <Modal.Header>Conditions générales de ventes</Modal.Header>
                <Modal.Content image scrolling>

                    <Modal.Description>
                        <iframe style={{minWidth: '1100px', minHeight: '1200px'}} src={shop.shopPartner.type === 'MOOD' ? cgvMood : cgvOrange}/>
                    </Modal.Description>
                </Modal.Content>
            </Modal>

        </Grid.Column>
        </>
    );

}

export default QuotationResume
