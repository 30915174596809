import React from "react";

const colorStatusMap = new Map();

colorStatusMap.set(
    "WHITE", {
        color: '#F3F9F9',
        iconText: 'Blanc',
    }
);

colorStatusMap.set(
    "BLACK", {
        color: '#090808',
        iconText: 'Noir',
    }
);

colorStatusMap.set(
    "SILVER", {
        color: '#C0C0C0',
        iconText: 'Argent',
    }
);

colorStatusMap.set(
    "GREY", {
        color: '#808080',
        iconText: 'Gris',
    }
);

colorStatusMap.set(
    "BLUE", {
        color: '#2372C6',
        iconText: 'Bleu',
    }
);

colorStatusMap.set(
    "RED", {
        color: '#F21111',
        iconText: 'Rouge',
    }
);

colorStatusMap.set(
    "GOLD", {
        color: '#FFD700',
        iconText: 'Or',
    }
);

colorStatusMap.set(
    "GREEN", {
        color: '#06a206',
        iconText: 'Vert',
    }
);

colorStatusMap.set(
    "PINK", {
        color: '#FF007F',
        iconText: 'Rose',
    }
);

colorStatusMap.set(
    "ORANGE", {
        color: '#ff7f00',
        iconText: 'Orange',
    }
);

colorStatusMap.set(
    "PURPLE", {
        color: '#8F00FF',
        iconText: 'Violet',
    }
);

colorStatusMap.set(
    "YELLOW", {
        color: '#EBF20A',
        iconText: 'Jaune',
    }
);

colorStatusMap.set(
    "BROWN", {
        color: '#582900',
        iconText: 'Marron',
    }
);

colorStatusMap.set(
    "PINKGOLD", {
        color: '#F2D6CE',
        iconText: 'Or rose',
    }
);

colorStatusMap.set(
    "ANTHRACITE", {
        color: '#303030',
        iconText: 'Anthracite',
    }
);

export default colorStatusMap;
