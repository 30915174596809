import {Container, Dimmer, Header, List, Loader, Segment, Table} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import { axiosInstanceLongTreatment} from "../../utils/axiosInstance";

function StuartAddressValidation() {

    const [errors, setErrors] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        loadAddressValidation()
    }, [])

    function loadAddressValidation() {
        setLoader(true);
        axiosInstanceLongTreatment.get("admin/stuart/addressValidation")
            .then((response) => {
                setLoader(false);
                setErrors(response.data);
            })
            .catch((error) => {
                setLoader(false);
                console.log("error")
            });

    }
    return (
        <>
            <Container fluid>
                <Header block as='h2'>Stuart : Adresses invalides</Header>
                <Table compact celled definition >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell  collapsing>Code magasin</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Type magasin</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Adresse</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Erreur</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {errors.map(error => (<Table.Row positive>
                    <Table.Cell style={{backgroundColor: '#F8DDDD '}} collapsing>
                    <List>
                   <span>{error.shopCode}</span>
                 </List>
                   </Table.Cell>
                   <Table.Cell style={{backgroundColor: '#F8DDDD'}}  collapsing>
                   <List>
                      <span>{error.shopType ==='SHOP_PARTNER' ? 'Magasin Orange' : 'Magasin SAVE'}</span>
                       </List>
                  </Table.Cell>
                     <Table.Cell style={{backgroundColor: '#F8DDDD '}}  collapsing>
                         <List>
                              <span>{error.address}</span>
                        </List>
                     </Table.Cell>
                      <Table.Cell style={{backgroundColor: '#F8DDDD'}} collapsing>
                       <List>
                           <span>{error.error}</span>
                        </List>
                    </Table.Cell>
                   </Table.Row>))}
                    </Table.Body>
                </Table>
            </Container>

            {loader ?
            <Segment>
            <Dimmer active>
                <Header  inverted>Chargement en cours...<Loader/></Header>
            </Dimmer>
            </Segment> : null }
        </>
    );
}

export default StuartAddressValidation
