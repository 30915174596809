import React, {useEffect, useState} from "react";
import {Button, Divider, Feed, FeedUser, Form, Icon, Image, Message, Modal} from "semantic-ui-react";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import {fetchParcelHistoric} from "../../reducers/FollowUp/followup.actions";
import parcelStatusMap from "../../utils/status/parcelStatus";
import shipmentStatusMap from "../../utils/status/shipmentStatus";
import {axiosInstance} from "../../utils/axiosInstance";
import {isAdmin} from "../../utils/authorities-utils";
import moment from "moment";

function InterventionParcelHistoric({parcelId, parcelCode}) {

    const parcelHistoric = useSelector(state => state.followup.parcelHistoric);
    const pickupType = useSelector(state => state.shop.shopPartner.pickupType);
    const [stuartDetail, setStuartDetail] = useState(null);
    const dispatch = useDispatch();
    const connectedUser = useSelector(state => state.user.user);

    useEffect(() => {
        dispatch(fetchParcelHistoric(parcelId));
    }, [parcelId])

    const getParcelStuartDetail = (parcelRef) => {
        axiosInstance.get("/parcel/stuart/"+parcelRef)
            .then((response) => {
                console.log(response.data)
                setStuartDetail(JSON.stringify(response.data, null, 2))
            })
            .catch((error) => {
            });
    }

    const refreshChronoDetail = (tracking) => {
        axiosInstance.get("/parcel/chrono/"+tracking+"/refresh")
            .then((response) => {
                console.log(response.data)
                dispatch(fetchParcelHistoric(parcelId));
            })
            .catch((error) => {
            });
    }



    const displayParcelHistoricInformations = () => {
        let content = !parcelHistoric ? null : <Feed.Event>
                <Feed.User>
                    <Image style={{marginTop: "18px", width: "60px", height: "60px"}} avatar
                           src={shipmentStatusMap.get(parcelHistoric.shipmentType).logo} size={"mini"}/>
                </Feed.User>

                <Feed.Content
                    style={{
                        marginTop: "0.5em",
                        marginRight: "0px",
                        marginBottom: "0.357143em",
                        marginLeft: "1.14286em"
                    }}>
                    <Feed.Summary
                        style={{color: "#4183c4"}}>{shipmentStatusMap.get(parcelHistoric.shipmentType).textHistoric}</Feed.Summary>
                    <Feed.Event><b>Référence interne :</b> {parcelCode} </Feed.Event>
                    <Feed.Event><b>Référence externe :</b> {parcelHistoric.trackingCode} </Feed.Event>
                    <Feed.Event><b>Expéditeur :</b> <u>{parcelHistoric.senderAddress.companyName}</u></Feed.Event>
                    <Feed.Event ><em>{parcelHistoric.senderAddress.stuartOneLineAdress}</em></Feed.Event>
                    <Feed.Event><b>Destinataire :</b> <u>{parcelHistoric.recipientAddress.companyName}</u></Feed.Event>
                    <Feed.Event ><em>{parcelHistoric.recipientAddress.stuartOneLineAdress}</em></Feed.Event>
                    {parcelHistoric.shipmentType === "COURSIER" || parcelHistoric.shipmentType === "CHRONOPOST" ?
                        <Feed.Meta>
                            <Button basic={true} color={"blue"} size={"tiny"} href={parcelHistoric.trackingUrl}
                                    target="_blank">Suivre le colis</Button>
                        </Feed.Meta> : null}
                    {parcelHistoric.shipmentType === "COURSIER" && isAdmin(connectedUser.authorities) ?
                        <Feed.Meta>
                            <Button basic={true} color={"red"} size={"tiny"}
                                    onClick={() => getParcelStuartDetail(parcelCode)}
                                    target="_blank">Stuart</Button>
                        </Feed.Meta> : null}
                    {parcelHistoric.shipmentType === "CHRONOPOST" && isAdmin(connectedUser.authorities) ?
                        <Feed.Meta>
                            <Button basic={true} color={"red"} size={"tiny"}
                                    onClick={() => refreshChronoDetail(parcelHistoric.trackingCode)}
                                    target="_blank">Rafraichir</Button>
                        </Feed.Meta> : null}
                </Feed.Content>
            </Feed.Event>;
        return content;
    }

    return (
        <>
            <Feed>
                {displayParcelHistoricInformations()}
                {parcelHistoric && parcelHistoric.parcelHistoricDTOList && parcelHistoric.parcelHistoricDTOList.length != 0 ? parcelHistoric.parcelHistoricDTOList.map((parcel) => (
                    parcel.status === 'CREATION' ? null : <Feed.Event>
                        <Feed.Label>
                            {shipmentStatusMap.get(parcel.shipmentType) ? shipmentStatusMap.get(parcel.shipmentType).icon :
                                <Icon name='inbox'/>}
                        </Feed.Label>
                        <Feed.Content>
                            <Feed.Date><Moment format="ddd DD MMM YYYY [à] HH:mm"
                                               locale="fr">{parcel.date}</Moment>{parcel.login ? ' par ' + parcel.login : null}
                            </Feed.Date>
                            <Feed.Summary>
                                {parcelStatusMap.get(parcel.status) ? parcelStatusMap.get(parcel.status).text : parcel.information.label}
                            </Feed.Summary>
                            <Feed.Label>
                                {parcel.information && parcel.information.cancellation && parcel.information.cancellation.canceledBy  ?
                                    'Par:'+ parcel.information.cancellation.canceledBy : null}
                            </Feed.Label>
                            <Feed.Label>
                                {parcel.information && parcel.information.cancellation && parcel.information.cancellation.reasonKey  ? 'Motif:'+ parcel.information.cancellation.reasonKey : null}
                            </Feed.Label>
                        </Feed.Content>
                    </Feed.Event>
                )) : <Message info>La liste est vide</Message>}
            </Feed>

            <Modal
                onClose={() => setStuartDetail(null)}
                open={stuartDetail}

            >
                <Modal.Header>Détails Stuart du colis</Modal.Header>
                <Modal.Content scrolling>

                    <Modal.Description>
                        <pre style={{ overflowX: 'auto' }}>
                        {stuartDetail}
                        </pre>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setStuartDetail(null)}>
                        Fermer
                    </Button>
                </Modal.Actions>
            </Modal>

        </>
    );
}

export default InterventionParcelHistoric
