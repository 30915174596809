import {Container, Divider, Grid, Header, Icon, Image, List, Segment,Button} from 'semantic-ui-react'
import hp_ko from "./../../img/hp_ko.png";
import hp_ok from "./../../img/hp_ok.png";
import React from "react";
import ecou_ko from "../../img/ecou_ko.png";
import ecou_ok from "../../img/ecou_ok.png";
import {Link} from "react-router-dom";

function ECO_ManualDiag(props) {
    const diagAction = function(testOk){
        props.nextDiag(testOk)
    }
    return (


            <Segment placeholder>
                <Grid columns={2} relaxed='very' stackable>
                    <Grid.Column>
                        <h3>Le haut parleur du smartphone est-il fonctionnel ?</h3>
                        <div>
                            <Segment basic>
                                <Grid centered columns={2} relaxed='very'>
                                    <Grid.Column verticalAlign='middle'>
                                        <Segment fluid className="diagchoice"  onClick={()=>diagAction(true)}>
                                            <Container style={{marginBottom: '10px'}}><Image centered alt="ecran_ok" src={hp_ok} style={{maxHeight: '150px'}} /></Container>
                                            <Icon size='huge' color='green' name='check circle outline'/>
                                            <br/><p>OUI</p>
                                        </Segment>
                                    </Grid.Column>

                                    <Grid.Column verticalAlign='middle' >
                                        <Segment fluid className="diagchoice" onClick={()=>diagAction(false)}>
                                            <Container style={{marginBottom: '10px'}}><Image centered alt="ecran_ok" src={hp_ko} style={{maxHeight: '150px'}} /></Container>
                                            <Icon size='huge' color='red' name='times circle outline'/>
                                            <br/><p>NON</p>
                                        </Segment>
                                    </Grid.Column>
                                </Grid>
                            </Segment>

                            {props.piceaEligible ?
                                <Button as={Link} to='/remoteDiag'  basic style={{marginTop: '45px'}} primary>Je souhaite faire le diagnostic avec Piceasoft</Button> : null}


                        </div>
                    </Grid.Column>
                    <Grid.Column verticalAlign='middle'>
                        <h3>Qu'est ce que cela signifie ?</h3>
                        <List bulleted>
                            <List.Item>Vous entendez le son de l'appareil lors de la sonnerie ou l'écoute de musique (ou appel en mode haut-parleur)
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid>
                <Divider vertical/>
            </Segment>

    );

}

export default ECO_ManualDiag
