import {combineReducers} from 'redux';

import userReducer from '../reducers/User/user.reducer';
import shopReducer from '../reducers/Shop/shop.reducer';
import diagnosticReducer from '../reducers/Diagnostic/diagnostic.reducer';
import interventionReducer from '../reducers/Intervention/intervention.reducer';
import productReducer from '../reducers/Product/product.reducer';
import globalUiReducer from '../reducers/GlobalUi/globalUi.reducer';
import customerReducer from '../reducers/Customer/customer.reducer';
import followupReducer from '../reducers/FollowUp/followup.reducer';
import crmgpReducer from '../reducers/Crmgp/crmgp.reducer';

const rootReducer = combineReducers({
    user: userReducer,
    shop: shopReducer,
    diagnostic: diagnosticReducer,
    intervention: interventionReducer,
    product: productReducer,
    global: globalUiReducer,
    customer: customerReducer,
    followup: followupReducer,
    crmgp: crmgpReducer
});

export default rootReducer;
