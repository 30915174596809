import {axiosInstance} from "../../utils/axiosInstance";
import {
    FETCH_INTERVENTION,
    FETCH_INTERVENTION_HISTORIC,
    FETCH_INTERVENTION_HISTORIC_SUCCESS,
    FETCH_INTERVENTION_SUCCESS,
    FETCH_INTERVENTIONS_LIST,
    ADD_HISTORIC_ELEMENT,
    ADD_HISTORIC_ELEMENT_SUCCESS,
    RESET_FOLLOWUP,
    FETCH_INTERVENTIONS_LIST_SUCCESS,
    FETCH_INTERVENTION_PARCEL_HISTORIC
} from "./followup.types";

export function fetchInterventionsList(page, size, search,type) {
    return async function (dispatch, getState) {

        dispatch({type: FETCH_INTERVENTIONS_LIST});
        if(page===0) {dispatch({type: RESET_FOLLOWUP})}

        axiosInstance.post("/interventions/",
            {
                page: page,
                size: size,
                search: search.search,
                firstName: search.firstName,
                lastName: search.lastName,
                mobile: search.mobile,
                imei: search.imei,
                claimRef: search.claimRef,
                zipCode: search.zipCode,
                email: search.email,
                type: type,
                pickupType: getState().shop.shopPartner.pickupType,
            })
            .then((response) => {
                dispatch({
                    type: FETCH_INTERVENTIONS_LIST_SUCCESS,
                    interventions: response.data.content,
                    totalElements: response.data.totalElements,
                    page: response.data.pageable.pageNumber
                })
            })
            .catch((error) => {
            });

    }
}

export function fetchIntervention(interventionId) {

    return async function (dispatch, getState) {
        dispatch({
            type: FETCH_INTERVENTION
        })
        axiosInstance.get("/intervention/" + interventionId)
            .then((response) => {

                dispatch({
                    type: FETCH_INTERVENTION_SUCCESS,
                    intervention: response.data
                })

            })
            .catch((error) => {
            });
    }
}

export function fetchInterventionHistoric(interventionId) {

    return async function (dispatch, getState) {
        dispatch({
            type: FETCH_INTERVENTION_HISTORIC
        })
        if(interventionId !== undefined) {
            axiosInstance.get("/intervention/" + interventionId + '/historic')
                .then((response) => {

                    dispatch({
                        type: FETCH_INTERVENTION_HISTORIC_SUCCESS,
                        historic: response.data
                    })

                })
                .catch((error) => {
                });
        }
    }
}

export function addInterventionHistoric(interventionId, comment) {
    return async function (dispatch, getState) {
        dispatch({
            type: ADD_HISTORIC_ELEMENT
        })
        axiosInstance.post("/intervention/" + interventionId + '/historic/add',{comment: comment})
            .then((response) => {

                dispatch({
                    type: ADD_HISTORIC_ELEMENT_SUCCESS,
                })
                dispatch({
                    type: FETCH_INTERVENTION_HISTORIC_SUCCESS,
                    historic: response.data
                })

            })
            .catch((error) => {
            });
    }
}

export function fetchParcelHistoric(parcelId) {
    return async function (dispatch) {
        axiosInstance.get("/parcel/" + parcelId + '/historic')
            .then((response) => {
                dispatch({
                    type: FETCH_INTERVENTION_PARCEL_HISTORIC,
                    parcelHistoric: response.data
                })
            })
            .catch((error) => {
            });
    }
}

