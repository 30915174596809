import {Grid, Icon} from 'semantic-ui-react'
import React from 'react';
import SelectShop from "../components/SelectShop/SelectShop";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {LOGOUT} from "../reducers/User/user.types";
import {RESET_SHOP_DATA} from "../reducers/Shop/shop.types";

function SuperviserPage() {

    const user = useSelector(state => state.user);
    const history = useHistory();
    const dispatch = useDispatch();

    const logout = () => {
        localStorage.removeItem('token_sav')
        dispatch({type: LOGOUT})
        dispatch({type: RESET_SHOP_DATA});
        history.push("/")
    }

    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 1000}}>

                <SelectShop admin={false}/>

                <span style={{
                    float: 'right',
                    color: 'grey',
                }}>
                    <span style={{floated: 'right'}}>
                        <Icon name='user'/>connecté en tant que <b>{user.user.firstName} {user.user.lastName}</b> ({user.user.login})
                        <a style={{marginLeft: '8px', cursor: 'pointer'}} onClick={logout}>(se déconnecter)</a>
                    </span>
                    </span>
            </Grid.Column>
        </Grid>
    );

}

export default SuperviserPage
