import {
    Button, Checkbox,
    Container, Dropdown,
    Header,
    Icon,
    Message,
    Pagination,
    Progress,
    Segment, Select,
    Table
} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {axiosInstance, axiosInstanceLongTreatment} from "../../utils/axiosInstance";
import {toast} from "react-toastify";
import ModelSelector from "./ModelSelector";

function Models() {

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [totalPageNumber, setTotalPageNumber] = useState(1)
    const [totalElements, setTotalElements] = useState(0)
    const [models, setModels] = useState([]);
    const [brandName, setBrandName] = useState("");
    const [modelName, setModelName] = useState("");
    const [pictureUrl, setPictureUrl] = useState("");
    const [isEnabled, setIsEnabled] = useState(true);
    const [type, setType] = useState("");
    const [modelUploading, setModelUploading] = useState(false);
    const [showUpdateSegment, setShowUpdateSegment] = useState(false);
    const [uploadResultMessage, setUploadResultMessage] = useState("");
    const [isExporting, setIsExporting] = useState(false);

    const classificationOptions = [
        { key: '1', value: 'A', text: 'A' },
        { key: '2', value: 'B', text: 'B' },
        { key: '3', value: 'C', text: 'C' },
        { key: '4', value: 'par défaut', text: 'par défaut' },
    ]


    function fetchModels() {
        axiosInstance.post("/models/",
            {
                page: page,
                size: size,
                brandName: brandName,
                modelName: modelName.name,
                isEnabled: isEnabled
            })
            .then((response) => {
                console.log(response);
                setTotalPageNumber(response.data.totalPages)
                setTotalElements(response.data.totalElements)
                setModels(response.data.content)
            })
            .catch((error) => {
                console.log("error")
            });
    }

    function enableModels() {
        axiosInstance.patch("/models/",
            {
                page: page,
                size: size,
                brandName: brandName,
                modelName: modelName.name,
                isEnabled: isEnabled
            })
            .then((response) => {
                let mesage = isEnabled ? 'désactivé(s)' : 'activé(s)';
                fetchModels();
                toast.success("Le(s) modèle(s) sélectionné(s) a/ont été "+  mesage);
            })
            .catch((error) => {
                console.log("error")
            });
    }

    useEffect(() => {
        fetchModels();
    }, [page, brandName, modelName, isEnabled])

    const handlePageChange = (e, activePage) => {
        console.log(activePage)
        setPage(activePage.activePage - 1);
    }

    const enableOrDisableModel = (model) => {
        model.enabled = !model.enabled;
        axiosInstance.patch("/model/", model)
            .then((response) => {
                fetchModels();
                let mesage = model.enabled ? 'activé' : 'désactivé';
                toast.success("Le modèle "+ model.name+" est "+ mesage);
            })
            .catch((error) => {
            })
    };

    const handleClassificationChange = (model, value) => {
        model.classification = value ;
        axiosInstance.patch("/model/", model)
            .then((response) => {
                fetchModels();
                toast.success("La classification du modèle "+ model.name+ " a été changée");
            })
            .catch((error) => {
            })

    }

    function UploadButton({label, onUpload}) {
        let fileInput = null;
        const uid = Math.random().toString(36).substring(7);
        return (
            <span>
                  <label htmlFor={uid} className="ui icon button fluid">
                    <i className="upload icon"></i>&nbsp;&nbsp;{label}
                  </label>
                  <input type="file" id={uid} style={{display: "none"}} disabled={modelUploading}
                         onChange={() => {
                             onUpload(fileInput.files[0]);
                         }}
                         ref={input => {
                             fileInput = input;
                         }}
                  />
            </span>
        );
    }


    function excelModelExport() {
        setIsExporting(true)
        axiosInstanceLongTreatment.get("admin/models/export?brandName="+ brandName +"&&?modelName="+modelName.name +"&&isEnabled="+isEnabled,  { responseType: 'blob' })

            .then((response) => {
                setIsExporting(false)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "export_models.xlsx");
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                setIsExporting(false)
            });
    }

    function updateModels(file) {

        let data = new FormData()
        data.append('file', file, file.name)

        setModelUploading(true);
        axiosInstanceLongTreatment.post("admin/models/upload", data)
            .then((response) => {
                setModelUploading(false);
                setPage(0);
                console.log(response)
                setUploadResultMessage(response.data ? response.data : "Le fichier a bien été intégré.")
                fetchModels();
            })
            .catch((error) => {
                setUploadResultMessage("Une erreur est survenue");
                setModelUploading(false);
            });
    }

    return (
        <>
            <Container fluid>
                <Header block as='h2'>Liste de modèles
                </Header>
                <Button  icon size={"mini"} primary onClick={() => enableModels()}><Icon  />{isEnabled ? 'Désactiver':'Activer'} les modéles</Button>
                <Button  icon size={"mini"} primary onClick={() => setShowUpdateSegment(!showUpdateSegment)}><Icon name='download' />&nbsp;&nbsp;Import des modèles</Button>
                <Button disabled={isExporting} loading={isExporting} icon size={"mini"} primary onClick={() => excelModelExport()}><Icon name='file excel' />&nbsp;&nbsp;Export des modèles</Button>

                {showUpdateSegment && <Segment raised>
                    {!modelUploading ?
                        <>
                            <p><u>La mise à des modèles fonctionne de la manière suivante :</u>
                                <ul>
                                    <li>Le modèle et la marque doivent exister dans le référenciel GSMA</li>
                                    <li>Si la clé est composée de la marque uniquement on mets à jours tous les modèles associés</li>
                                    <li>Si aucune valeur n'est renseignée pour la colonne "CLASSIFICATION", la classification "par défaut" est appliquée</li>
                                    <li>Si aucune valeur n'est renseignée pour la colonne "ACTIF", le modèle est activé par défaut </li>
                                </ul>
                                La clé est la composition de la marque et du modèle
                            </p>
                            <UploadButton label="Upload d'un fichier de modéles" onUpload={updateModels}/>
                        </>
                        :
                        <Message success>Mise à jour des modèles en cours<Progress percent={100}
                                                                                    indicating/></Message>}
                    {uploadResultMessage ? <Message>{uploadResultMessage}</Message> : null}
                </Segment>}

                <ModelSelector setBrandName={setBrandName} setModelName={setModelName} setPictureUrl={setPictureUrl} setType={setType} setIsEnabled={setIsEnabled}/>
                {totalElements} éléments trouvés {brandName ? 'pour' : null} {brandName} {modelName.name}
                <Table compact celled structured size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2'>Marque</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>Modèle</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>Type</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>Classification</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>Actif</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>

                        {models.map(model => (
                            <Table.Row positive>
                               <Table.Cell collapsing>
                                {model.brand}
                                  </Table.Cell>
                                <Table.Cell collapsing>
                                    {model.name}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {model.type}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Dropdown
                                        value={model.classification}
                                        fluid
                                        selection
                                        onChange={(e, value) => handleClassificationChange(model, value.value)}
                                        options={classificationOptions}
                                    />
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <div className="ui toggle checkbox">
                                        <Checkbox toggle checked={model.enabled}
                                                  onChange={(e,data) => enableOrDisableModel(model)}
                                        />
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}

                    </Table.Body>

                </Table>
            </Container>


            <Pagination
                activePage={page + 1}
                style={{width: "100%", float: "right"}}
                onPageChange={(e, pageEvent) => handlePageChange(e, pageEvent)}
                defaultActivePage={0}
                totalPages={totalPageNumber}
                style={{marginTop: '10px'}}/>

        </>
    );

}

export default Models
