export const ON_IDENTIFICATION_PICEASOFT = 'ON_IDENTIFICATION_PICEASOFT';
export const GET_DIAGNOSIS_INFORMATION = 'GET_PRICING';
export const RESET_PRODUCT_INFO = 'RESET_PRODUCT_INFO';
export const UPDATE_PRODUCT_INFO = 'UPDATE_PRODUCT_INFO';
export const ON_MANUAL_DIAG_UPDATE ='ON_MANUAL_DIAG_UPDATE';
export const ADD_REMOVE_FROM_QUOTE ='ADD_REMOVE_FROM_QUOTE';
export const ADD_REMOVE_FROM_SAV ='ADD_REMOVE_FROM_SAV';
export const ADD_REMOVE_SERVICE_FROM_QUOTE ='ADD_REMOVE_SERVICE_FROM_QUOTE';
export const ON_MANUEL_MODEL_SELECT ='ON_MANUEL_MODEL_SELECT';
export const UPDATE_IMEI ='UPDATE_IMEI';
export const UPDATE_GEOLOC ='UPDATE_GEOLOC';
export const UPDATE_COLOR ='UPDATE_COLOR';
export const LOAD_PRODUCT ='LOAD_PRODUCT';
export const DEFAULT_PRICING ='DEFAULT_PRICING';



