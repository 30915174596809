import {Container, Menu, Segment} from 'semantic-ui-react'
import React, {useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import ShopUsers from "./ShopUsers";
import ShopSetting from "./ShopSetting";


function SettingPage() {

    const [tab, setTab] = useState('users');
    const [notificationText, setNotificationText] = useState('');
    const connectedUser = useSelector(state => state.user.user);

    function handleCacheEvict() {
        axiosInstance.get("/clearcache").then((response) => {
            toast.success("Caches nettoyés")
        });
    }

    function forceAutomaticPacking() {
        axiosInstance.get("/admin/automaticpacking").then((response) => {
            toast.success("Batch de colisage complété")
        });
    }

    function sendNotification() {
            axiosInstance.post("/admin/send-notification",{
                subject: "Notification de test",
                content: notificationText,
                link:"https://preprod.orange.save.co",
                priority:"HIGH_PRIORITY"

            }).then((response) => {
            toast.success("Notification sended")
        });
    }

    function startKafka() {
        axiosInstance.get("/admin/kafka/startconsumer").then((response) => {
            toast.success("Start kafka")
        });
    }

    return (
        <>
            <Container fluid>
                <Menu attached='top' tabular>
                    <Menu.Item
                        active={tab === 'users'}
                        onClick={() => setTab('users')}
                    >Liste des comptes</Menu.Item>
                    <Menu.Item
                        active={tab === 'shop'}
                        onClick={() => setTab('shop')}
                    >Paramétrage de la boutique</Menu.Item>
                </Menu>
                <Segment attached='bottom'>
                    {tab === 'users' ? <ShopUsers/> : null}
                    {tab === 'shop' ? <ShopSetting/> : null}
                </Segment>
            </Container>
        </>
    );

}

export default SettingPage
