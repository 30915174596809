import {axiosInstance} from "../../utils/axiosInstance";
import {UPDATE_SHOP_SAVE_SETTINGS, UPDATE_SHOP_SETTINGS} from "./shop.types";
import {toast} from "react-toastify";

export function updateShopSettingsData(data) {
    return async function (dispatch, getState) {
        axiosInstance.put("/shop/settings", data)
            .then((response) => {
                toast.success("Paramètres mis à jours")
                dispatch({type: UPDATE_SHOP_SETTINGS, payload: data})
            })
            .catch((error) => {
                console.log("error")
            });
        console.log("success")
    }
}

export function updateShopSaveSettingsData(shopCode) {
    return async function (dispatch, getState) {
        axiosInstance.get("/shop-saves/"+shopCode)
            .then((response) => {
                console.log(response.data)
                dispatch({type: UPDATE_SHOP_SAVE_SETTINGS, payload: response.data})
            })
            .catch((error) => {
            });
    }
}



