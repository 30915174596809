import {Button, Dropdown, Form, Grid, Icon, Input, Label, Message, Segment} from 'semantic-ui-react'
import {axiosInstance} from "../../utils/axiosInstance";
import {ErrorMessage} from '@hookform/error-message';
import React, {useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {useHistory} from 'react-router-dom';
import {CREATE_UPDATE_CUSTOMER_SUCCESS} from "../../reducers/Customer/customer.types";
import {ImeiContext} from "./Quotation";
import {isIMEI} from "../../utils/imei-utils";
import {RESET_APP, SHOW_ABANDON_POPUP} from "../../reducers/GlobalUi/globalUi.types";
import DismissPopup from "../../components/DismissPopup/DismissPopup";
import {isAdmin} from "../../utils/authorities-utils";
import OrangeMoodLabel from "../../components/OrangeMoodLabel";

function QuotationCustomer(props) {
    const customer = useSelector(state => state.customer);
    const crmgp = useSelector(state => state.crmgp);
    const shopPartner = useSelector(state => state.shop.shopPartner);
    const [customerType, setCustomerType] = useState("CUSTOMER");
    const [isLoading, setIsLoading] = useState(false);
    const [isSmaKO, setIsSmaKO] = useState(false);
    const [isQuoteLoading, setIsQuoteLoading] = useState(false);
    const [isQuote, setIsQuote] = useState(false);
    const [personalDatasInPhone, setPersonalDatasInPhone] = useState(null);
    const [cosmeticDefault, setCosmeticDefault] = useState(null);
    const customerOrangeDropDown = useRef(null);
    const [customerOrangeType, setCustomerOrangeType] = useState(customer ? customer.customerOrangeType : "");
    const [customerWish, setCustomerWish] = useState("");
    const [comments, setComments] = useState("");
    const [cosmeticDefaultComments, setCosmeticDefaultComments] = useState("");
    const context = useContext(ImeiContext);
    const {setImeiError, imeiError, imeiInputRef, noDiagSelected} = context;
    const history = useHistory();

    const product = useSelector(state => state.product);
    const connectedUser = useSelector(state => state.user.user);

    const intervention = useSelector(state => state.intervention);


    let isSav = intervention.intervention.interventionSavOriginId != null;

    const {register, formState: {errors}, handleSubmit} = useForm({
        defaultValues: {
            id: customer.id,
            firstName: (crmgp.prenomCl ? crmgp.prenomCl : customer.firstName),
            lastName: (crmgp.nomClient ? crmgp.nomClient : customer.lastName),
            companyName: customer.companyName,
            email: (crmgp.mailContactCl ? crmgp.mailContactCl : customer.email),
            numVat: customer.numVat,
            orangePhone: customer.orangePhone,
            contactPhone: (crmgp.telContactCl ? crmgp.telContactCl : customer.contactPhone),
            adressLine1: customer.adressLine1,
            adressLine2: customer.adressLine2,
            zipCode: customer.zipCode,
            city: customer.city,
            country: customer.country,
            type: customer.type,
            customerOrangeType: customer.customerOrangeType
        }
    });


    const dispatch = useDispatch();

    useEffect(() => {
        let found = false;
        for (const item of product.pricingResult) {
            if (item.category === "SMA_KO" && !item.allSubDiagOk)
                found = true;
        }
        setIsSmaKO(found);
    }, [product.pricingResult])

    const onSubmit = (data, {additonalData}) => {
        var isOnError = false;
        if (!isIMEI(product.imei, isAdmin(connectedUser.authorities))) {
            setImeiError(true);
            isOnError = true;

            imeiInputRef.current.scrollIntoView()
        }

        if (product.brand.toUpperCase() === 'APPLE' && !product.geolocDisabled) {
            isOnError = true;
        }

        if (isOnError)
            return;

        const extendedData = {
            ...data,
            id: intervention.intervention.customer.id,
            type: customerType,
            customerOrangeType: customerOrangeType ? customerOrangeType : null,
            firstName: data.firstName,
            lastName: data.lastName,
            companyName: data.companyName,
            numVat: data.numVat,
            email: data.email,
            contactPhone: data.contactPhone,
            orangePhone: data.orangePhone,
            rgpdWish: customerWish,
            address: {
                type: "CUSTOMER",
                firstName: data.firstName,
                lastName: data.lastName,
                companyName: data.companyName,
                adressLine1: data.adressLine1,
                adressLine2: data.adressLine2,
                zipCode: data.zipCode,
                city: data.city,
                phone: data.phone,
                orangePhone: data.orangePhone,
                email: data.email,
            }
        }


        if (additonalData !== undefined && additonalData.isQuote) {
            setIsQuoteLoading(true);
        } else {
            setIsLoading(true);
        }


        axiosInstance.post("/customer",
            extendedData).then((response) => {
                dispatch({type: CREATE_UPDATE_CUSTOMER_SUCCESS, customer: response.data})
                props.createIntervention(response.data.id, comments, additonalData, cosmeticDefaultComments);
                // if (additonalData !== undefined && additonalData.isQuote) {
                //     history.push("/");
                //     dispatch({type: RESET_APP});
                // }
                if (additonalData !== undefined && additonalData.isQuote) {
                    setIsQuoteLoading(false);
                } else {
                    setIsLoading(false);
                }
            }
        ).catch((e) => {
                console.log("Not saved")
                if (additonalData !== undefined && additonalData.isQuote) {
                    setIsQuoteLoading(false);
                } else {
                    setIsLoading(false);
                }
            }
        )
    };

    const handleChange = (e, {name, value}) => {
        switch (name) {
            case 'customerOrangeType' : {
                setCustomerOrangeType(value);
                break;
            }
            case 'customerType' : {
                setCustomerType(value);
                break;
            }
            case 'comments' : {
                setComments(value);
                break;
            }
            case 'cosmeticDefaultComments' : {
                setCosmeticDefaultComments(value);
                break;
            }
        }
    }

    const rgpdChoice = (value) => {
        setCustomerWish(value ? 'wish1' : 'wish2');
        setPersonalDatasInPhone(value);
    }

    const cosmeticDefaultChoice = (value) => {
        setCosmeticDefault(value);
    }

    const customerOrangeTypeOptions = [
        {key: "NO", text: "Non Orange", value: "NO"},
        {key: "PUBLIC", text: "Orange Grand Public", value: "PUBLIC"},
        {key: "PRO", text: "Orange Entreprise", value: "PRO"}
    ];

    const customerMoodTypeOptions = [
        {key: "ORANGE", text: "Orange", value: "ORANGE"},
        {key: "SFR", text: "SFR", value: "SFR"},
        {key: "BT", text: "Bouygues Télécom", value: "BT"},
        {key: "FREE", text: "Free", value: "FREE"},
        {key: "OTHER", text: "Autres", value: "OTHER"}
    ];

    const setAsDevis = () => {
        setIsQuote(true);
        handleSubmit(onSubmit)({additonalData: {isQuote: true}});
    }

    const openAbandonPopup = () => {
        dispatch({type: SHOW_ABANDON_POPUP})
    }

    console.log(errors);
    return (

        <Grid.Column>
            <Segment>
                <Label as='a' color='teal' ribbon>
                    Informations du client
                </Label>


                <Form>
                    <Form.Group inline>

                        <label>Type de client</label>
                        <Form.Radio
                            label='Particulier'
                            value='CUSTOMER'
                            name='customerType'
                            checked={customerType === 'CUSTOMER'}
                            onChange={handleChange}
                        />
                        <Form.Radio
                            label='Société'
                            value='COMPANY'
                            name='customerType'
                            checked={customerType === 'COMPANY'}
                            onChange={handleChange}
                        />

                        <Dropdown placeholder={shopPartner.type === 'MOOD' ? 'Type de client' : 'Type de client Orange'}
                                  ref={customerOrangeDropDown}
                                  name='customerOrangeType'
                                  value={customerOrangeType}
                                  selection
                                  options={shopPartner.type === 'MOOD' ? customerMoodTypeOptions : customerOrangeTypeOptions}
                                  onChange={handleChange}/>

                    </Form.Group>

                    <Grid columns={1}>
                        <Grid.Row stretched>
                            <Grid.Column>
                                <Segment>
                                    <Form.Group widths='equal'>
                                        <Form.Field required={true} error={errors.lastName}>
                                            <label htmlFor="lastName">Nom</label>
                                            <input fluid name="lastName"
                                                   ref={register({required: 'Le Nom est obligatoire'})}
                                                   label='Nom'
                                                   placeholder='Nom'/>
                                            {errors.lastName && errors.lastName.message}

                                        </Form.Field>
                                        <Form.Field required={true} error={errors.firstName}>
                                            <label htmlFor="firstName">Prénom</label>
                                            <input fluid name="firstName"
                                                   ref={register({required: 'Le Prénom est obligatoire'})}
                                                   label='Prénom'
                                                   placeholder='Prénom'/>
                                            {errors.firstName && errors.firstName.message}

                                        </Form.Field>
                                        {customerType === "COMPANY" ?

                                            <Form.Field required={customerType === "COMPANY"}>
                                                <label htmlFor="company">Société</label>
                                                <input fluid name="company"
                                                       ref={register({required: customerType === "COMPANY"})}
                                                       label='Société'
                                                       placeholder='Société'/>
                                            </Form.Field> : null
                                        }

                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field required={true} error={errors.email}><label
                                            htmlFor="email">Email</label><input fluid name="email"
                                                                                ref={register({
                                                                                    required: 'L\'email est obligatoire',
                                                                                    pattern: {
                                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                                        message: 'Mauvais format d\'email'
                                                                                    }
                                                                                })} label='email' placeholder='Email'/>
                                            {errors.email && errors.email.message}
                                        </Form.Field>
                                    </Form.Group>

                                    <Form.Group widths='equal'>
                                        {shopPartner.type === 'ORANGE' ?
                                            <Form.Field required={false} error={errors.orangePhone}>
                                                <label htmlFor="orangePhone">Numéro de
                                                    téléphone {customerOrangeType === 'PUBLIC' || customerOrangeType === 'PRO' ? "Orange" : ""}</label>
                                                <input fluid name="orangePhone"
                                                       ref={register({
                                                           pattern: {
                                                           value: (/^[+]*(\d!*){10,}/i) || (/^0\d{9}$/i),
                                                           message: 'Format attendu XXXXXXXXXX ou +33XXXXXXXXX'
                                                       } })}
                                                       label='orangePhone'
                                                       placeholder='Format attendu XX XX XX XX XX   '/>
                                                {errors.orangePhone && errors.orangePhone.message}
                                            </Form.Field> : null}
                                        <Form.Field required={true} error={errors.contactPhone}><label
                                            htmlFor="contactPhone">Numéro de téléphone de contact</label><input fluid
                                                                                                                name="contactPhone"
                                                                                                                ref={register({
                                                                                                                    required: 'Le numéro de téléphone de contact',
                                                                                                                    pattern: {
                                                                                                                        value: (/^[+]*(\d!*){10,}/i) || (/^0\d{9}$/i),
                                                                                                                        message: 'Format attendu XXXXXXXXXX ou +33XXXXXXXXX'
                                                                                                                    }
                                                                                                                })}
                                                                                                                label='contactPhone'
                                                                                                                placeholder='Format attendu XX XX XX XX XX'/>
                                            {errors.contactPhone && errors.contactPhone.message}
                                        </Form.Field>
                                    </Form.Group>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Label as='a' color='teal' ribbon style={{marginTop: '5px'}}>
                        Constatez-vous un défaut esthétique sur le produit ?
                    </Label>

                    <Segment basic>
                        <Grid centered columns={2} relaxed='very'>
                            <Grid.Column verticalAlign='middle'>
                                <Button basic={cosmeticDefault != null && cosmeticDefault ? false : true}
                                        fluid content='oui'
                                        primary onClick={() => cosmeticDefaultChoice(true)}>Oui</Button>
                            </Grid.Column>
                            <Grid.Column verticalAlign='middle'>
                                <Button basic={cosmeticDefault != null && !cosmeticDefault ? false : true}
                                        fluid content='oui'
                                        primary onClick={() => cosmeticDefaultChoice(false)}>Non</Button>
                            </Grid.Column>
                        </Grid>
                    </Segment>

                    {cosmeticDefault && <Form.Field required={cosmeticDefault}>
                        <Form.TextArea name='cosmeticDefaultComments'
                                       placeholder={"Défaut(s) esthétique(s) au dépôt..."}
                                       onChange={handleChange}
                                       value={cosmeticDefaultComments}/>
                    </Form.Field>}

                    <Form.Field required={true}>
                        <Label htmlFor="comments" as='a' color='teal' ribbon
                               style={{marginTop: '5px', marginBottom: '5px'}}>
                            Commentaires {isSmaKO || isSav ? <p style={{color: "red", display: 'inline'}}>*</p> : null}
                        </Label>
                        <ErrorMessage errors={errors} name="comments"/>
                        <textarea fluid name='comments'
                                  ref={register({
                                      maxLength: {
                                          value: 255,
                                          message: <p style={{color: "red", display: 'inline'}}>Ce champs ne doit pas
                                              dépasser 255 caractères</p>
                                      }
                                  })}
                                  placeholder={isSmaKO ? "Indiquez nous plus précisément les causes du dysfonctionnement de l'appareil" : "Commentaire sur les réparations demandées..."}
                            // onChange={handleChange}
                                  onChange={(event) => setComments(event.target.value)}
                                  value={comments}
                        />

                    </Form.Field>

                    <Label as='a' color='teal' ribbon style={{marginTop: '5px'}}>
                        Le produit du client comporte t'il des données personnelles ?
                    </Label>

                    <Segment basic>
                        <Grid centered columns={2} relaxed='very'>
                            <Grid.Column verticalAlign='middle'>
                                <Button basic={personalDatasInPhone != null && personalDatasInPhone ? false : true}
                                        fluid content='oui'
                                        primary onClick={() => rgpdChoice(true)}>Oui</Button>
                            </Grid.Column>
                            <Grid.Column verticalAlign='middle'>
                                <Button basic={personalDatasInPhone != null && !personalDatasInPhone ? false : true}
                                        fluid content='oui'
                                        primary onClick={() => rgpdChoice(false)}>Non</Button>
                            </Grid.Column>
                        </Grid>
                    </Segment>

                    {personalDatasInPhone != null && personalDatasInPhone && isSav ?
                        <Message negative icon>
                            <Icon size='small' name='warning sign'/>
                            Pour toutes demandes de SAV, le smartphone devra être remis à l’état d’usine et n’avoir
                            aucun code et/ou schéma de verrouillage actif
                        </Message>
                        : null}

                    {personalDatasInPhone != null && personalDatasInPhone && !isSav ?
                        <Message negative icon><Icon size='small' name='warning sign'/>Le client ne souhaite pas
                            supprimer ses données personnelles
                            et décharge <OrangeMoodLabel label={"Orange"}/> de toute responsabilité relative à
                            celles-ci.
                            Le client accepte le risque de perte de ses données personnelles si la réparation le
                            requiert</Message> : null}


                    {personalDatasInPhone != null && !personalDatasInPhone ?
                        <Message positive icon><Icon size='small' name='check'/>
                            Le client certifie avoir supprimé l'ensemble des données présentes sur son appareil avant
                            son dépôt pour réparation.
                        </Message> : null}

                    {!isSav ? <Grid centered columns={3}>
                            <Grid.Column verticalAlign='middle'>
                                <Button onClick={handleSubmit(onSubmit)} color={"green"}
                                        loading={isLoading || intervention.isCreationLoading}
                                        disabled={product.color === '' || cosmeticDefault === null || personalDatasInPhone === null || (isSmaKO && comments.length === 0) || (cosmeticDefault && cosmeticDefaultComments.length === 0) || isLoading || intervention.isQuoteLoading || intervention.isCreationLoading || imeiError || noDiagSelected || (product.brand.toUpperCase() === 'APPLE' && !product.geolocDisabled)}
                                        fluid>Réparer</Button>
                            </Grid.Column>
                            <Grid.Column verticalAlign='middle'>
                                <Button onClick={setAsDevis} color={"orange"}
                                        loading={isQuoteLoading || intervention.isQuoteLoading}
                                        disabled={props.totalTTC === 0 || personalDatasInPhone === null || isQuoteLoading || intervention.isQuoteLoading || intervention.isCreationLoading || imeiError || noDiagSelected || (product.brand.toUpperCase() === 'APPLE' && !product.geolocDisabled)}
                                        fluid>Envoyer Devis</Button>
                            </Grid.Column>
                            <Grid.Column verticalAlign='middle'>
                                <Button fluid color={"red"} onClick={() => openAbandonPopup()}>Refuser</Button>
                            </Grid.Column>
                        </Grid> :
                        <Grid centered columns={2}><Grid.Column verticalAlign='middle'><Button
                            onClick={handleSubmit(onSubmit)} color={"green"}
                            loading={isLoading || intervention.isCreationLoading}
                            disabled={(product.color === '' || isSav && personalDatasInPhone) || personalDatasInPhone === null || comments.length === 0 || isLoading || intervention.isQuoteLoading || intervention.isCreationLoading || noDiagSelected || (product.brand.toUpperCase() === 'APPLE' && !product.geolocDisabled)}
                            fluid>Réparer</Button></Grid.Column>
                            <Grid.Column verticalAlign='middle'>
                                <DismissPopup interventionId={intervention.intervention.id} isButton={true}
                                              isAllowed={true}/>
                            </Grid.Column>
                        </Grid>
                    }

                </Form>

            </Segment>
        </Grid.Column>

    );

}

export default QuotationCustomer
