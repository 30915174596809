import {Icon} from "semantic-ui-react";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OrangeMoodLabel from "../../components/OrangeMoodLabel";

// icon="mouse"

const historicTypeMap = new Map();

historicTypeMap.set(
    "QUOTE_INIT", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="file-invoice"/>,
        text: 'Devis web généré',
        label: 'Dossier init'
    }
);

historicTypeMap.set(
    "QUOTE_SENDED", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="file-invoice"/>,
        text: 'Devis en magasin',
        label: 'Devis'
    }
);
historicTypeMap.set(
    "QUOTE_VALIDATED", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="inbox"/>,
        text: <>Prise en charge du produit en boutique <OrangeMoodLabel label={"Orange"}/></>,
        label: 'Dossier ouvert'
    }
);

historicTypeMap.set(
    "TRANSIT_ORANGE_SAVE", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="biking"/>,
        text: 'En transit vers la boutique Save',
        label: 'En transit aller'
    }
);

historicTypeMap.set(
    "PRODUCT_RECEIVED_SAVE", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="box-open"/>,
        text: 'Produit bien reçu dans le centre de réparation',
        label: 'En cours'
    }
);

historicTypeMap.set(
    "PRODUCT_REPLACED", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="repeat"/>,
        text: 'Produit remplacé',
        label: 'Produit remplacé'
    }
);

historicTypeMap.set(
    "PRODUCT_ANOMALY", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="bug"/>,
        text: 'Produit en anomalie',
        label: 'Produit en anomalie'
    }
);

historicTypeMap.set(
    "PRODUCT_ABANDONED", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="exclamation-triangle"/>,
        text: 'Le client ne souhaite pas venir récupérer son téléphone',
        label: 'Le client ne souhaite pas venir récupérer son téléphone'
    }
);

historicTypeMap.set(
    "NEW_QUOTE_PROPOSAL", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"}  icon="ballot" color={"orange"}/>,
        text: 'Un nouveau diagnostic a été établi par le centre de réparation',
        label: 'Nouveau diagnostic'
    }
);

historicTypeMap.set(
    "NEW_QUOTE_ACCEPTED", {
        icon: <FontAwesomeIcon size={"2x"} icon="ballot-check" color={"green"}/>,
        text: 'Le client a accepté le nouveau devis',
        label: 'Devis accepté'
    }
);

historicTypeMap.set(
    "NEW_QUOTE_REFUSED", {
        icon: <FontAwesomeIcon size={"2x"}  icon="ballot-check" color={"red"}/>,
        text: 'Le client a refusé le nouveau devis',
        label: 'Devis refusé'
    }
);

historicTypeMap.set(
    "REPAIR_IN_PROGRESS", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="clinic-medical"/>,
        text: 'Produit en cours de réparation',
        label: 'En cours'
    }
);

historicTypeMap.set(
    "NEW_QUOTE_UNSIGNED", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="file-signature"/>,
        text: 'Devis non signé',
        label: 'Devis non signé'
    }
);

historicTypeMap.set(
    "QUOTE_PENDING", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="clinic-medical"/>,
        text: 'Intervention en attente de validation',
        label: 'En cours'
    }
);

historicTypeMap.set(
    "PRODUCT_REPAIRED", {
        icon: <FontAwesomeIcon size={"2x"} icon={["fas", "tools"]} color={"green"}/>,
        text: 'Le téléphone a été réparé avec succès',
        label: 'Réparé'
    }
);

historicTypeMap.set("PRODUCT_NOT_REPAIRED",
    {
        icon: <FontAwesomeIcon size={"2x"} icon="tools" color={"red"}/>,
        text: "Le téléphone n'a pas pu être réparé",
        label: 'Non Réparé'
    }
);

historicTypeMap.set(
    "TRANSIT_SAVE_ORANGE", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="inbox"/>,
        text: <>En transit vers la boutique <OrangeMoodLabel label={"Orange"}/></>,
        label: 'En transit retour'
    }
);

historicTypeMap.set(
    "PRODUCT_RECEIVED_ORANGE", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="inbox-in"/>,
        text: 'Le téléphone a été reçu dans votre magasin',
        label: 'A restituer'
    }
);

historicTypeMap.set(
    "CANCELED", {
        icon: <FontAwesomeIcon size={"2x"} color={"orange"} icon="exchange"/>,
        text: 'Dossier annulé',
        label: 'Dossier annulé par Orange'
    }
);

historicTypeMap.set(
    "FINISHED", {
        icon: <FontAwesomeIcon size={"2x"}  icon="mobile-alt" color={"green"}/>,
        text: 'Dossier clos',
        label: 'Clos'
    }
);

historicTypeMap.set(
    "SAV_CONTROL_REFUSED", {
        icon: <FontAwesomeIcon size={"2x"}  icon="mobile-alt" color={"orange"}/>,
        text: 'Contrôle Sav refusé',
        label: 'Contrôle Sav refusé'
    }
);

historicTypeMap.set(
    "EXPIRED", {
        icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="hourglass-end"/>,
        text: 'Devis expiré',
        label: 'Devis expiré'
    }
);

// Fin des status

// Actions
// TODO : deplacer les actions dans un autre fichier

historicTypeMap.set(
    "NEW_REPAIR_DATE", {
        icon: <Icon name='handshake outline' color='orange'/>,
        text: 'La date de retour de votre téléphone a changé',
        label: 'Delais depassé'
    }
);

historicTypeMap.set(
    "NEW_QUOTE_PROPOSAL_RELANCE", {
            icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="mail"/>,
            text: 'Relance par email/sms pour nouveau devis',
            label: 'Relance'
    }
);

historicTypeMap.set(
    "RECEIVED_PRODUCT_IN_SHOP_RELANCE", {
            icon: <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="mail"/>,
            text: 'Relance par email/sms pour produit disponible',
            label: 'Relance'
    }
);

historicTypeMap.set(
    "TRANSFERT_CEGID_TICKET_SUCCESS", {
        icon: <Icon name='check' color={"green"}/>,
        text: 'Le transfert vers la caisse a réussi',
        label: 'Le transfert vers la caisse a réussi'
    }
);

historicTypeMap.set(
    "TRANSFERT_CEGID_TICKET_ERROR", {
        icon: <Icon name='check' color={"red"}/>,
        text: 'Le transfert vers la caisse a échoué',
        label: 'Le transfert vers la caisse a échoué'
    }
);

historicTypeMap.set(
    "SAV_REQUESTED", {
        icon: <Icon name='exchange' color='orange'/>,
        text: 'Demande de SAV',
        label: 'Demande de SAV'
    }
);

historicTypeMap.set(
    "SAV_CREATION", {
        icon: <Icon name='exchange' color='orange'/>,
        text: 'Dossier en SAV',
        label: 'Le dossier est passé en SAV'
    }
);

historicTypeMap.set(
    "QUOTE_CREATED", {
        icon: <Icon name='exchange' color='orange'/>,
        text: 'Devis généré',
        label: 'Devis généré'
    }
);

// Fin des actions


// Actions liés aux colis
// TODO : ne devrait plus exister ici, voir si on peux faire un batch de migration pour transférer dans parcel_historic

historicTypeMap.set(
    "PACKED", {
        icon: <Icon name='inbox'/>,
        text: 'Prise en charge du dossier, devis :',
        label: 'Prêt pour envoi'
    }
);
historicTypeMap.set(
    "COURIRER_SHIPMENT_REQUESTED", {
        icon: <Icon name='box'/>,
        text: 'Intégré au colis ',
        label: 'Prêt pour l\'enlèvement'
    }
);
historicTypeMap.set(
    "COURIRER_SHIPMENT_HANDLED", {
        icon: <Icon name='bicycle'/>,
        text: 'Coursier en approche',
        label: 'Coursier en approche'
    }
);

historicTypeMap.set(
    "SHIPMENT_IN_PROGRESS", {
        icon: <Icon name='bicycle'/>,
        text: 'Transport en cours',
        label: 'Transport en cours'
    }
);


historicTypeMap.set(
    "BY_SHOP_SHIPMENT_REQUESTED", {
        icon: <Icon name='street view'/>,
        text: 'La boutique save a reçu la demande d`enlèvement ',
        label: 'La boutique save a reçu la demande d`enlèvement'
    }
);

historicTypeMap.set(
    "BY_SHOP_SHIPMENT_HANDLED", {
        icon: <Icon name='user'/>,
        text: 'Le sauveteur Save arrive',
        label: 'Save en approche'
    }
);
historicTypeMap.set(
    "CHRONOPOST_SHIPMENT_REQUESTED", {
        icon: <Icon name='box'/>,
        text: 'Bon chronopost édité',
        label: 'Bon chronopost édité'
    }
);
historicTypeMap.set(
    "CHRONOPOST_SHIPMENT_HANDLED", {
        icon: <Icon name='truck'/>,
        text: 'En cours de livraison vers le centre de réparation',
        label: 'En cours de livraison'
    }
);

historicTypeMap.set(
    "SENDED_BACK_BY_COURIER_TRANSITING", {
        icon: <Icon name='bicycle' flipped='horizontally'/>,
        text: 'Le livreur est en route, il rapporte le produit dans votre magasin',
        label: 'Transit retour'
    }
);

historicTypeMap.set(
    "SENDED_BACK_BY_COURIER_RECEIVED", {
        icon: <Icon name='bicycle' flipped='horizontally'/>,
        text: 'Le téléphone est en magasin',
        label: 'Transit retour'
    }
);

historicTypeMap.set(
    "SENDED_BACK_BY_SHOP_TRANSITING", {
        icon: <Icon name='bicycle' flipped='horizontally'/>,
        text: 'Le sauveteur est en route, il rapporte le produit dans votre magasin',
        label: 'Transit retour'
    }
);
historicTypeMap.set(
    "SENDED_BACK_BY_CRHONOPOST_TRANSITING", {
        icon: <Icon name='truck' flipped='horizontally'/>,
        text: 'En cours de livraison vers votre boutique',
        label: 'Transit retour'
    }
);

export default historicTypeMap;
