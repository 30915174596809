import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {Button, Form, Grid, Input, Segment, Table} from "semantic-ui-react";
import shipmentStatusMap from "../../utils/status/shipmentStatus";
import {axiosInstance, axiosInstanceAdmin} from "../../utils/axiosInstance";
import useDebounce from "../../utils/use-debounce";
import {RESET_PRODUCT_INFO} from "../../reducers/Product/product.types";
import {SUPERVISER_SELECT_SHOP} from "../../reducers/User/user.types";
import {isAdmin} from "../../utils/authorities-utils";


function SelectShop({admin}) {

    const user = useSelector(state => state.user);
    const interventionId = useSelector(state => state.global.interventionId);
    const [isShopFinderLoading, setIsShopFinderLoading] = useState(false);
    const [shopFinderValue, setShopFinderValue] = useState("");
    const [orgFinderValue, setOrgFinderValue] = useState("");
    const [shopFinderResults, setShopFinderResults] = useState([]);
    const debouncedSearchTerm = useDebounce(shopFinderValue, 500);
    const debouncedOrgSearchTerm = useDebounce(orgFinderValue, 500);

    const [nomClient, setNomClient] = useState("");
    const [prenomCl, setPrenomCl] = useState("");
    const [mailContactCl, setMailContactCl] = useState("");
    const [telContactCl, setTelContactCl] = useState("");
    const [cuid, setCuid] = useState("");

    const advredirecturl = process.env.REACT_APP_BACKOFFICE_URL + "/admin/advredirect"

    const history = useHistory();
    const dispatch = useDispatch();


    useEffect(() => {
        if (interventionId && interventionId > 0) {
            selectItem(interventionId);
        }
    }, [interventionId])

    const selectItem = (interventionId) => {
        dispatch({type: RESET_PRODUCT_INFO});
        history.push("/followup?intervention=" + interventionId)
    }

    const goToShop = (selectedShop, role) => {
        let url ="";
        if(role === 'ADMIN')
            url = "/admin/advredirectadmin";
        else if (role === "SUPERVISER")
            url = "/admin/advredirectsuperviser";
        else
            url = "/admin/advredirect";

        axiosInstance.post(url,
            {
                advCode: selectedShop.advCode,
                nomClient: nomClient,
                prenomCl: prenomCl,
                mailContactCl: mailContactCl,
                telContactCl: telContactCl,
                cuid: cuid,
                interventionId: orgFinderValue,
            })
            .then((response) => {
                dispatch({type: SUPERVISER_SELECT_SHOP});
                history.push(response.data);
            })
            .catch((error) => {
            });
    }

    const goToNationalSupport = () => {
        axiosInstance.post("/admin/advredirectnationalsupport",
            {})
            .then((response) => {
                history.push(response.data);
            })
            .catch((error) => {
            });
    }

    const goToNationalSupportAdmin = () => {
        axiosInstance.post("/admin/advredirectnationalsupportadmin",
            {})
            .then((response) => {
                history.push(response.data);
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        setIsShopFinderLoading(true)
        axiosInstance.post("/shops/find/",
            {
                page: 0,
                size: 10,
                search: debouncedSearchTerm,
            })
            .then((response) => {
                setShopFinderResults(response.data);
                setIsShopFinderLoading(false)
            })
            .catch((error) => {
                setIsShopFinderLoading(false)
            });
    }, [debouncedSearchTerm])


    useEffect(() => {
        setIsShopFinderLoading(true)
        if (debouncedOrgSearchTerm) {
            axiosInstance.get("/shops/find/intervention/" + debouncedOrgSearchTerm)
                .then((response) => {
                    setShopFinderResults(response.data);
                    setIsShopFinderLoading(false)
                })
                .catch((error) => {
                    setIsShopFinderLoading(false)
                });
        }
    }, [debouncedOrgSearchTerm])


    const shopFinderChange = (e, data) => {
        setShopFinderValue(data.value)
    }
    const orgFinderChange = (e, data) => {
        setOrgFinderValue(data.value.replace("ORG_", ""))
    }

    return (
        <Segment>
            <Form style={{marginTop: '7px'}}>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <Input fluid onChange={shopFinderChange} value={shopFinderValue}
                               placeholder='Code ou nom de boutique Orange ou Save'
                               label={{basic: true, content: 'Rechercher'}}/>
                    </Form.Field>
                    {<Form.Field>
                        <Input fluid onChange={orgFinderChange} value={orgFinderValue}
                               placeholder='Uniquement les chiffres'
                               label={{basic: true, content: 'Recherche par ORG'}}/>
                    </Form.Field>}
                </Form.Group>
            </Form>


            <Table compact celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Boutique Orange</Table.HeaderCell>
                        <Table.HeaderCell>Boutique Save</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell colSpan='3'>Se connecter</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {Array.isArray(shopFinderResults) && shopFinderResults.map((foundShop) => (
                        foundShop && <Table.Row>
                            <Table.Cell><b>{foundShop.code}</b> - {foundShop.name}</Table.Cell>
                            <Table.Cell><b>{foundShop.shopSave.code}</b> - {foundShop.shopSave.name}
                            </Table.Cell>
                            <Table.Cell><b>{shipmentStatusMap.get(foundShop.pickupType).icon}</b></Table.Cell>
                            {admin && <Table.Cell collapsing={true}>
                                <Grid>
                                    <Grid.Column width={8}>
                                        <Form method="post" action={advredirecturl} encType="multipart/form-data">
                                            <input type="hidden" name="advCode" value={foundShop.advCode}/>
                                            <input type="hidden" name="login" value={user.user.login}/>
                                            <input type="hidden" name="cuid" value={cuid}/>
                                            <input type="hidden" name="nomClient" value={nomClient}/>
                                            <input type="hidden" name="prenomCl" value={prenomCl}/>
                                            <input type="hidden" name="mailContactCl" value={mailContactCl}/>
                                            <input type="hidden" name="telContactCl" value={telContactCl}/>
                                            <Button size={"tiny"} type="submit" primary>Connexion</Button>
                                        </Form>
                                    </Grid.Column>

                                </Grid>
                            </Table.Cell>}
                            {!admin && <Table.Cell collapsing={true}>

                                <Grid.Column width={8}><Button size={"tiny"} color='green'
                                                               onClick={() => goToShop(foundShop, "SUPERVISER")}>Connexion</Button></Grid.Column>
                            </Table.Cell>}
                            {admin && <Table.Cell collapsing={true}>

                                <Grid.Column width={8}><Button size={"tiny"} color='red'
                                                               onClick={() => goToShop(foundShop, "ADMIN")}>Admin</Button></Grid.Column>
                            </Table.Cell>}
                        </Table.Row>
                    ))}
                    {admin && <Table.Row>
                        <Table.Cell positive={true} colSpan='3'>Vision Support National</Table.Cell>
                        <Table.Cell collapsing={true} colSpan='2'>
                            <Button size={"tiny"} primary fluid
                                    onClick={() => goToNationalSupport()}>Connexion</Button>
                            {/*<Button size={"tiny"} color='red' onClick={() => goToNationalSupportAdmin()}>Admin</Button>*/}
                        </Table.Cell>
                    </Table.Row>}
                </Table.Body>

            </Table>

            {(isAdmin(user.user.authorities)) && <Form style={{marginTop: '7px'}}>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Nom</label>
                        <Input value={nomClient} onChange={(e, value) => setNomClient(value.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Prénom</label>
                        <Input value={prenomCl} onChange={(e, value) => setPrenomCl(value.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Email</label>
                        <Input value={mailContactCl} onChange={(e, value) => setMailContactCl(value.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Téléphone</label>
                        <Input value={telContactCl} onChange={(e, value) => setTelContactCl(value.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Cuid</label>
                        <Input value={cuid} onChange={(e, value) => setCuid(value.value)}/>
                    </Form.Field>
                </Form.Group>
            </Form>}
        </Segment>
    )

}

export default SelectShop

