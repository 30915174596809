import React from "react";
import {Feed, Icon} from "semantic-ui-react";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function HistoricCommentComponent({historicElement, setSelectedHistoric}) {

    return (

        <Feed.Event>
            <Feed.Label style={{marginTop: '6px', textAlign:'center'}} onClick={() => setSelectedHistoric(historicElement)}>
                <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="comments"/>
            </Feed.Label>

            <Feed.Content>
                <Feed.Date>
                    <Moment format="ddd DD MMM YYYY [à] HH:mm"
                            locale="fr">{historicElement.date}</Moment>
                    {historicElement.login ? 'par ' + historicElement.login : null}
                </Feed.Date>

                {historicElement.comment ?
                    <Feed.Meta>
                        <Feed.Like><Icon name='quote left'/>{historicElement.comment}&nbsp;<Icon
                            name='quote right'/></Feed.Like>
                    </Feed.Meta> : null}

            </Feed.Content>
        </Feed.Event>
    );
}

export default HistoricCommentComponent
