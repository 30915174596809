export const FETCH_INTERVENTIONS_LIST = 'FETCH_INTERVENTIONS_LIST';
export const FETCH_INTERVENTIONS_LIST_SUCCESS = 'FETCH_INTERVENTIONS_LIST_SUCCESS';
export const SELECT_INTERVENTION = 'SELECT_INTERVENTION';
export const FETCH_INTERVENTION = 'FETCH_INTERVENTION';
export const FETCH_INTERVENTION_HISTORIC = 'FETCH_INTERVENTION_HISTORIC';
export const FETCH_INTERVENTION_PARCEL_HISTORIC = 'FETCH_INTERVENTION_PARCEL_HISTORIC';
export const FETCH_INTERVENTION_SUCCESS = 'FETCH_INTERVENTION_SUCCESS';
export const FETCH_INTERVENTION_HISTORIC_SUCCESS = 'FETCH_INTERVENTION_HISTORIC_SUCCESS';
export const ADD_HISTORIC_ELEMENT = 'ADD_HISTORIC_ELEMENT';
export const ADD_HISTORIC_ELEMENT_SUCCESS = 'ADD_HISTORIC_ELEMENT_SUCCESS';
export const RESET_FOLLOWUP = 'RESET_FOLLOWUP';
export const DELETED_INTER = 'DELETED_INTER';
export const CANCEL_INTER = 'CANCEL_INTER';


