

export function isChronoShop(pickupType) {
    if (pickupType === 'CHRONOPOST') {
        return true;
    }
    return false;
}

export function isMoodShop(shop) {
    if (shop.type === 'MOOD') {
        return true;
    }
    return false;
}

export function isIrpAppleShop(shopSave) {
    if (shopSave.appleAccredited) {
        return true;
    }
    return false;
}

export function isByShop(pickupType) {
    if (pickupType === 'BYSHOP') {
        return true;
    }
    return false;
}

export function isCoursierShop(pickupType) {
    if (pickupType === 'COURSIER') {
        return true;
    }
    return false;
}
