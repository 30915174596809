import {Container, Menu} from 'semantic-ui-react'
import WorkflowStepper from "../../components/WorkflowStepper/WorkflowStepper";
import StatedManualDiagStepper from "../../components/WorkflowStepper/StatedManualDiagStepper";
import React, {useEffect, useState} from "react";
import {RemoteDiagAfterIdentification} from '../../reducers/Product/product.util'
import BAT_ManualDiag from "./component/BAT_ManualDiag";
import CAM_FRONT_ManualDiag from "./component/CAM_FRONT_ManualDiag";
import CAM_REAR_ManualDiag from "./component/CAM_REAR_ManualDiag";
import ECO_ManualDiag from "./component/ECO_ManualDiag";
import HP_ManualDiag from "./component/HP_ManualDiag";
import MIC_ManualDiag from "./component/MIC_ManualDiag";
import PCONNECTOR_ManualDiag from "./component/PCONNECTOR_ManualDiag";
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {ON_MANUAL_DIAG_UPDATE} from "../../reducers/Product/product.types";
import SMA_KO_ManualDiag from "./component/SMA_KO_ManualDiag";
import BTN_HOME_ManualDiag from "./component/BTN_HOME_ManualDiag";
import SavHeader from "../../components/SavHeader/SavHeader";


function ManualDiag() {

    let  [initDiag,setInitDiag ] = useState(null);
    let [currentDiag,setCurrentDiag] = useState(null)
    let [index,setIndex] = useState(0)
    const history = useHistory();
    const dispatch = useDispatch();
    const product = useSelector(state => state.product);

    useEffect(() => {

            let listDiagToTreatManually=[];
            for(let diag of product.pricingResult)
            {
                //skip visual test
                if("BACK_COVER" === diag.category)
                    continue;

                if(product.selectedRepairs && product.selectedRepairs.includes(diag.category)) {
                    dispatch({type:ON_MANUAL_DIAG_UPDATE,category:diag.category,diagOk:false});
                    continue;
                }

                if(diag.isAllSubDiagDone !==true || diag.category === 'DOX')
                {
                    listDiagToTreatManually.push(diag);
                }
            }

            let smaKo = listDiagToTreatManually.filter(diag => diag.category === 'SMA_KO');
            let oxidized = listDiagToTreatManually.filter(diag => diag.category === 'DOX');

            if(smaKo.length > 0 && oxidized.length > 0) {
                listDiagToTreatManually = listDiagToTreatManually.filter(diag => diag.category  !== 'DOX');
            }

        if(listDiagToTreatManually.length === 0)
            history.push("/quotation")

        setInitDiag(listDiagToTreatManually);
        setCurrentDiag(listDiagToTreatManually[0]);
    }, [product.pricingResult.length]);

    const  getDiagContent = function(){
        if(currentDiag) {
            let piceaEligible = currentDiag.diagnosticSettingDtos && currentDiag.diagnosticSettingDtos.length > 0;
            switch (currentDiag.category) {
                case "BAT":
                    return <BAT_ManualDiag piceaEligible={piceaEligible} nextDiag={nextDiag}/>
                case "CAM_FRONT":
                    return <CAM_FRONT_ManualDiag piceaEligible={piceaEligible} nextDiag={nextDiag}/>
                case "CAM_REAR":
                    return <CAM_REAR_ManualDiag piceaEligible={piceaEligible} nextDiag={nextDiag}/>
                case "ECO":
                    return <ECO_ManualDiag piceaEligible={piceaEligible} nextDiag={nextDiag}/>
                case "HP":
                    return <HP_ManualDiag piceaEligible={piceaEligible} nextDiag={nextDiag}/>
                case "MIC":
                    return <MIC_ManualDiag piceaEligible={piceaEligible} nextDiag={nextDiag}/>
                case "PCONNECTOR":
                    return <PCONNECTOR_ManualDiag piceaEligible={piceaEligible} nextDiag={nextDiag}/>
                case "DOX":
                    return <SMA_KO_ManualDiag piceaEligible={piceaEligible} nextDiag={nextDiag}/>
                case "BTN_HOME":
                    return <BTN_HOME_ManualDiag piceaEligible={piceaEligible} nextDiag={nextDiag}/>

            }
        }
    }


    const nextDiag = function(diagOk,comment){
        dispatch({type:ON_MANUAL_DIAG_UPDATE,category:currentDiag.category,diagOk:diagOk,comment:comment});
        if(index+1<initDiag.length)
        {
            setIndex(index +1);
            setCurrentDiag(initDiag[index +1])
        }
        else
            history.push("/quotation")
    }


    return (
        <>
            <Container fluid>
                <SavHeader/>
                <WorkflowStepper step={3} smaKO={true}/>
                {initDiag ? <StatedManualDiagStepper steps={initDiag} currentDiag={currentDiag}/> : null}
                {getDiagContent(nextDiag)}
            </Container>
        </>
    );

}

export default ManualDiag
