import {Divider, Grid, Header, Icon, Image, List, Message, Segment} from 'semantic-ui-react'
import {useSelector} from "react-redux";
import logoOrange from '../components/HeaderMenu/logo-orange.png';
import logoSave from '../components/HeaderMenu/logo-save-mini.png';
import logoMood from "../components/HeaderMenu/logo-mood.png";
import React from "react";

function HelpPage() {

    const shopPartner = useSelector(state => state.shop.shopPartner);
    const shopSave = useSelector(state => state.shop.shopSave);
    const user = useSelector(state => state.user.user);

    const trueAnswerRender = <><Icon name='check' color='green'/>OUI</>;
    const falseAnswerRender = <><Icon name='remove' color='red'/>NON</>;

    return (

        <>
            <Grid columns={2}>
                <Grid.Column>
                    <Segment fluid>
                        <Header block as='h1' size='small'>
                            <Image  src={shopPartner.type === 'MOOD' ? logoMood : logoOrange} /> Boutique {shopPartner.type === 'MOOD' ? "Mood" : "Orange"}
                        </Header>
                        <List>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.code}
                                </List.Content>
                                <List.Content>Code boutique</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.name}
                                </List.Content>
                                <List.Content>Nom de la boutique</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.externalShopGroup}
                                </List.Content>
                                <List.Content>Groupe ( DO )</List.Content>
                            </List.Item>
                            <Divider horizontal>
                                Adresse
                            </Divider>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.address.adressLine1}
                                </List.Content>
                                <List.Content>Adresse ligne 1</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.address.adressLine2}
                                </List.Content>
                                <List.Content>Adresse ligne 2</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.address.zipCode}
                                </List.Content>
                                <List.Content>Code Postal</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.address.city}
                                </List.Content>
                                <List.Content>Ville</List.Content>
                            </List.Item>

                            <Divider horizontal>Paramètres</Divider>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.pickupType === "COURSIER" ? trueAnswerRender : falseAnswerRender}
                                </List.Content>
                                <List.Content>Boutique éligible à l'enlèvement par coursier</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.pickupType === "BYSHOP" ? trueAnswerRender : falseAnswerRender}
                                </List.Content>
                                <List.Content>Boutique éligible à l'enlèvement par boutique Save</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.pickupType === "CHRONOPOST" ? trueAnswerRender : falseAnswerRender}
                                </List.Content>
                                <List.Content>Boutique éligible à Chronopost</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopPartner.automaticPacking === true ? trueAnswerRender : falseAnswerRender}
                                </List.Content>
                                <List.Content>Enlèvement automatique</List.Content>
                            </List.Item>

                        </List>
                    </Segment>

                </Grid.Column>

                <Grid.Column>


                    <Segment fluid>
                        <Message
                            attached
                            header='Boutique Save de rattachement'
                        />
                        <List>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopSave.code}
                                </List.Content>
                                <List.Content>Code boutique</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopSave.name}
                                </List.Content>
                                <List.Content>Nom de la boutique</List.Content>
                            </List.Item>
                            <Divider horizontal>
                                Adresse
                            </Divider>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopSave.addressDto.adressLine1}
                                </List.Content>
                                <List.Content>Adresse ligne 1</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopSave.addressDto.adressLine2}
                                </List.Content>
                                <List.Content>Adresse ligne 2</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopSave.addressDto.zipCode}
                                </List.Content>
                                <List.Content>Code Postal</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {shopSave.addressDto.city}
                                </List.Content>
                                <List.Content>Ville</List.Content>
                            </List.Item>
                        </List>
                    </Segment>

                    <Segment fluid>
                        <Message
                            attached
                            header='Contact support'
                        />
                        <List>
                            <List.Item>
                                <List.Content floated='right'>
                                    01 85 60 04 70
                                </List.Content>
                                <List.Content>Numéro de téléphone</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    orange@save.store
                                </List.Content>
                                <List.Content>Mail</List.Content>
                            </List.Item>
                        </List>
                    </Segment>

                </Grid.Column>

                <Grid.Column>
                    <Segment fluid>
                        <Message
                            attached
                            header='Environnement'
                        />
                        <List>
                            <List.Item>
                                <List.Content floated='right'>
                                    {process.env.REACT_APP_ENVIRONMENT}
                                </List.Content>
                                <List.Content>Environnement</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {process.env.REACT_APP_BACKOFFICE_URL}
                                </List.Content>
                                <List.Content>BackOffice</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    0.2.1
                                </List.Content>
                                <List.Content>Version</List.Content>
                            </List.Item>
                        </List>
                    </Segment>
                </Grid.Column>

                <Grid.Column>
                    <Segment fluid>
                        <Message
                            attached
                            header='Utilisateur'
                        />
                        <List>
                            <List.Item>
                                <List.Content floated='right'>
                                    {user.login}
                                </List.Content>
                                <List.Content>Login</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    {user.authorities.map((role) => <span key={role.name}
                                                                          style={{marginLeft: '5px'}}>{role.name}</span>)}
                                </List.Content>
                                <List.Content>Rôle</List.Content>
                            </List.Item>
                        </List>
                    </Segment>


                </Grid.Column>

            </Grid>


        </>
    );

}

export default HelpPage
