import React, {useEffect, useState} from "react";
import {Container, Icon, Label, Menu, Segment} from "semantic-ui-react";
import InterventionHistoric from "./InterventionHistoric";
import {fetchInterventionHistoric} from "../../reducers/FollowUp/followup.actions";
import {useDispatch, useSelector} from "react-redux";
import InterventionParcelHistoric from "./InterventionParcelHistoric";
import {isAdmin, isSupport, isUser} from "../../utils/authorities-utils";

function HistoricMenu({interventionId, supportMode}) {

    const [tab, setTab] = useState('interventionHistoric');
    const [parcelId, setParcelId] = useState(null);
    const [parcelCode, setParcelCode] = useState(null);
    const connectedUser = useSelector(state => state.user.user);
    const selectedIntervention = useSelector(state => state.followup.selectedIntervention);
    const historic = useSelector(state => state.followup.historic);
    const dispatch = useDispatch();
    const followup = useSelector(state => state.followup);

    useEffect(() => {
        setTab('interventionHistoric');
    }, [selectedIntervention]);

    const selectParcelTab = (parcelId, parcelCode) => {
        setTab('interventionParcelHistoric_' + parcelCode)
        setParcelId(parcelId);
        setParcelCode(parcelCode);
    }

    let parcels = [];
    for (const historicElement of historic) {
        if (historicElement.datas && historicElement.datas.parcelId) {
            let parcelId = historicElement.datas.parcelId;
            parcels.push(
                <Menu.Item
                    active={tab === 'interventionParcelHistoric_' + historicElement.datas.parcelCode}
                    onClick={() => selectParcelTab(historicElement.datas.parcelId, historicElement.datas.parcelCode)}>
                    Colis {historicElement.datas.parcelCode}
                </Menu.Item>
            );
        }
    }


    return (
        <>
            <Container fluid>
                <Menu attached='top'>
                    <Menu.Item
                        active={tab === 'interventionHistoric'}
                        onClick={() => setTab('interventionHistoric')}
                    >Historique du dossier
                        <span style={{marginLeft: '10px'}}><Icon name='refresh' loading={followup.isHistoricLoading}
                                                                 onClick={() => dispatch(fetchInterventionHistoric(interventionId))}/></span>
                    </Menu.Item>
                    {parcels}
                </Menu>
                <Segment attached='bottom'>
                    {tab === 'interventionHistoric' ?
                        <InterventionHistoric supportMode={supportMode} interventionId={interventionId}
                                              selectParcelTab={selectParcelTab}/> : null}
                    {tab.includes('interventionParcelHistoric') && (isSupport(connectedUser.authorities) || isAdmin(connectedUser.authorities) || isUser(connectedUser.authorities) ) ?
                        <InterventionParcelHistoric parcelId={parcelId} parcelCode={parcelCode}/> : null}
                </Segment>
            </Container>
        </>
    );
}

export default HistoricMenu
