import {Container, Header, Pagination, Table} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import ShopItem from "./ShopItem";
import ShopGroupItem from "./ShopGroupItem";

function ShopsGroup() {

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [totalPageNumber, setTotalPageNumber] = useState(1)
    const [search, setSearch] = useState();
    const [shops, setShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null);

    useEffect(() => {
        axiosInstance.get("/shopGroup/",
            {
                page: page,
                size: size,
                search: search,

            })
            .then((response) => {
                console.log(response);
                setTotalPageNumber(response.data.totalPages)
                setShops(response.data.content)
            })
            .catch((error) => {
                console.log("error")
            });
    }, [page, search, selectedShop])

    const handlePageChange = ({activePage}) => {
        setPage(activePage - 1);
    }

    const handleSearchChange = ({value}) => {
        setSearch(value);
    }

    return (
        <>
            <Container fluid>
                <Header block as='h2'>Liste des Goupe</Header>

                <Table compact celled definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Nom</Table.HeaderCell>
                            <Table.HeaderCell>Code</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <ShopGroupItem shops={shops} setShops={setShops} setSelectedShops={setSelectedShop}/>

                    </Table.Body>

                </Table>
                {/*selectedShop!==null?<UserDetail selectedShop={selectedShop} setModalOpen={setModalOpen} setSelectedShop={setSelectedShop}/>:""*/}

            </Container>

            <Pagination
                activePage={page + 1}
                style={{width: "100%", float: "right"}}
                onPageChange={(e, pageEvent) => handlePageChange(e, pageEvent)}
                defaultActivePage={1}
                totalPages={totalPageNumber}
                style={{marginTop: '10px'}}/>

        </>
    );

}

export default ShopsGroup
