import React, {useState} from "react";
import {Icon, Message} from "semantic-ui-react";
import Moment from "react-moment";

function HistoricUpdateProductComponent({historicElement}) {

    const [expanded, setExpanded] = useState(false);

    if(!expanded) {
        return (
            <Message success size={"tiny"} onClick={() => setExpanded(true)}
                     style={{cursor: 'pointer',paddingBottom: '1px', paddingTop: '1px'}}>
                Modification du produit <a onClick={() => setExpanded(true)}>(voir détails)</a></Message>
        );
    }

    if(expanded) {
        return (
            <Message success size={"tiny"} onClick={() => setExpanded(false)}
                     style={{ cursor: 'pointer', paddingBottom: '1px', paddingTop: '1px'}}>
                <Message.Header>Modification du produit le <Moment format="ddd DD MMM YYYY [à] HH:mm"
                                                                  locale="fr">{historicElement.date}</Moment> par {historicElement.login}</Message.Header>
                <ul>
                    <li>Marque : <b> {historicElement.datas ? historicElement.datas.oldBrandName: null}</b> modifié en <b> {historicElement.datas ? historicElement.datas.newBrandName: null}</b></li>
                    <li>Modéle : <b> {historicElement.datas ? historicElement.datas.oldName : null} </b> modifié en <b>  {historicElement.datas ? historicElement.datas.newName: null}</b></li>
                    <li>IMEI : <b> {historicElement.datas ? historicElement.datas.oldImei : null} </b> modifié en <b>  {historicElement.datas ? historicElement.datas.newImei: null}</b></li>
                </ul>

                <a onClick={() => setExpanded(false)}>(moins de détails)</a></Message>
        );
    }
}

export default HistoricUpdateProductComponent
