import {axiosInstance} from "../../utils/axiosInstance";

export function printChronopostTracking(parcelChronopstTrackingCode) {

    axiosInstance.get("/chronopost/display/" + parcelChronopstTrackingCode,
    )
        .then((response) => {
            const raw = window.atob(response.data);
            const rawLength = raw.length;
            const blobArray = new Uint8Array(new ArrayBuffer(rawLength));
            for (let i = 0; i < rawLength; i++) {
                blobArray[i] = raw.charCodeAt(i);
            }
            const blob = new Blob([blobArray], {type: 'application/pdf'});
            const toto = window.URL.createObjectURL(blob)
            window.open(toto, "_blank")
        })
        .catch((error) => {
            console.log("error")
        });
}

export function getTrackingInfos(chronopostTracking) {

    axiosInstance.get("/chronopost/tracking/" + chronopostTracking,
    )
        .then((response) => {

            console.log('yeah')
        })
        .catch((error) => {
            console.log("error")
        });
}