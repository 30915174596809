import React from "react";
import {getRepairLabel} from "./repairLabels";

var excludedModelsForCompatibleBAT = ['iPhone 5', 'iPhone 5C', 'iPhone 5S', 'iPhone 6', 'iPhone 6 Plus','iPhone 6S','iPhone 6S Plus','iPhone 7','iPhone 7 Plus','iPhone 8','iPhone 8 Plus','iPhone SE'];

var excludedModelsForCompatibleCAM_REAR_AND_LCD = ['iPhone 5', 'iPhone 5C', 'iPhone 5S', 'iPhone 6', 'iPhone 6 Plus','iPhone 6S','iPhone 6S Plus','iPhone 7',
    'iPhone 7 Plus','iPhone 8','iPhone 8 Plus','iPhone SE','iPhone SE (2nd generation)','iPhone X','iPhone XR','iPhone XS','iPhone XS Max','iPhone 11','iPhone 11 Pro',  'iPhone 11 Pro Max'];

var excludedModelsForCompatibleCAM_FRONT = ['iPhone 5', 'iPhone 5C', 'iPhone 5S', 'iPhone 6', 'iPhone 6 Plus','iPhone 6S','iPhone 6S Plus','iPhone 7','iPhone 7 Plus','iPhone 8','iPhone 8 Plus','iPhone SE','iPhone SE (2nd generation)'];

export function isSmartphone(type) {
    if (!type) {
        return true;
    }
    if (type === 'Smartphone' || type === 'Mobile Phone/Feature phone' || type === 'Handheld') {
        return true;
    }
    return false;
}

export function isWatch(type) {
    if (!type) {
        return false;
    }
    if (type === 'Wearable') {
        return true;
    }
    return false;
}

export function isTablet(type) {
    if (!type) {
        return false;
    }
    if (type === 'Tablet') {
        return true;
    }
    return false;
}

export function isRepairNotExist(model, repairs, type) {
    if (isSmartphone(type) || isTablet(type)) {
        if(model.brand.toUpperCase() === 'APPLE') {
            if (repairs.category === 'BACK_COVER') {
                let isBackOverWithOutPrice = repairs.pricings.filter(o => o.priceHt === '').length > 0;
                return isBackOverWithOutPrice;
            } else if (repairs.category === 'BTN_HOME') {
                let isBtnHomeWithOutPrice = repairs.pricings.filter(o => o.priceHt === '').length > 0;
                return isBtnHomeWithOutPrice;
            } else {
                return false;
            }
        }
    }
    return false;
}

export function isCompatibleCategory(model, pricing) {
    if (!model.brand  || !pricing.category) {
        return false;
    }

    if (model.brand && model.brand.toUpperCase() === 'APPLE') {
        if ((pricing.category === 'CAM_REAR' || pricing.category === 'LCD') && !excludedModelsForCompatibleCAM_REAR_AND_LCD.includes(model.name)) {
            return true;
        }
        if (pricing.category === 'CAM_FRONT' && !excludedModelsForCompatibleCAM_FRONT.includes(model.name)) {
            return true;
        }

        if (pricing.category === 'BAT' && !excludedModelsForCompatibleBAT.includes(model.name)) {
            return true;
        }
    }
    return false;

}

export function getTooltipLabel(model, pricing, type) {

    if (isRepairNotExist(model, pricing, type)) {
        return "La prestation de " + "\"" + getRepairLabel(pricing.category) + "\"" + " n'est pas éligible à la réparation pour ce modèle.";
    }
    if (model.brand.toUpperCase() === 'APPLE') {
        if (pricing.category === 'BAT' && !excludedModelsForCompatibleBAT.includes(model.name)) {
            return " Le remplacement de la batterie par une batterie compatible entraine l'affichage d'un message indiquant que la pièce remplacée n'est pas d'origine."+
                " Ce message n'impacte en rien le bon fonctionnement du produit et de la pièce remplacée." +
                " La durée d'affichage de ce message est de 15 jours."
        }
        if (pricing.category === 'CAM_REAR' && !excludedModelsForCompatibleCAM_REAR_AND_LCD.includes(model.name)) {
            return "Le remplacement de la caméra arrière par une caméra arrière compatible entraine l'affichage d'un message indiquant que la pièce remplacée n'est pas d'origine." +
                " Ce message n'impacte en rien le bon fonctionnement du produit et de la pièce remplacée." +
                " La durée d'affichage de ce message est de 15 jours."
        }
        if (pricing.category === 'LCD' && !excludedModelsForCompatibleCAM_REAR_AND_LCD.includes(model.name)) {
            return "Le remplacement de l'écran par un écran compatible entraine l'affichage d'un message indiquant que la pièce remplacée n'est pas d'origine."+
                " Ce message n'impacte en rien le bon fonctionnement du produit et de la pièce remplacée." +
                " La durée d'affichage de ce message est de 15 jours."
        }
        if (pricing.category === 'CAM_FRONT' && !excludedModelsForCompatibleCAM_FRONT.includes(model.name)) {
            return "Le remplacement de la caméra avant par une caméra avant compatible peut entrainer des dysfonctionnements de la fonction Face ID."
        }
    }
    return "";
}

export function isMultiplePricing(pricings, category) {
    for (const repair of pricings.repairs) {
        if(repair.category === category && repair.pricings.length === 2) {
            return true
        }
    }
    return false;
}



