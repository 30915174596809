import React, {useState} from "react";
import {Button, Feed, Icon, Label, Message} from "semantic-ui-react";
import statusMap from "../../utils/status/interventionStatus";
import Moment from "react-moment";
import {getCategoryLabel} from "../../utils/repairLabels";
import NumberFormat from "react-number-format";
import {useHistory} from "react-router-dom";
import {fetchIntervention, fetchInterventionHistoric} from "../../reducers/FollowUp/followup.actions";
import {useDispatch} from "react-redux";

function HistoricSavComponent({historicElement, setSelectedHistoric, selectParcelTab}) {

    const history = useHistory();
    const dispatch = useDispatch();

    const goTo = (interventionId) => {
        dispatch(fetchIntervention(interventionId));
        dispatch(fetchInterventionHistoric(interventionId));
        history.push("/followup?intervention=" + historicElement.datas.savId)
    }

    return (

        <Message warning={true} size={"small"}>
            <Icon name='warning sign'/>Un <b>retour garantie réparation</b> a été demandé sur ce dossier, voir &nbsp;&nbsp;
            <Label style={{cursor: 'pointer'}} size='mini' basic color={"blue"} onClick={() => goTo(historicElement.datas.savId)}>
                ORG_{historicElement.datas.savId}
            </Label>
        </Message>
    );
}


export default HistoricSavComponent
