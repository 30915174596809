import statusMap from "./status/interventionStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Icon} from "semantic-ui-react";
import React from "react";

export function iconByStatus(status, shipmenType) {
    if (statusMap.get(status) && status === 'TRANSIT_ORANGE_SAVE' || status === 'TRANSIT_SAVE_ORANGE') {
        if (shipmenType) {
            if (shipmenType === "CHRONOPOST") {
                return <FontAwesomeIcon size={"2x"} color={"rgba(0,0,0,.6)"} icon="box"/>
            } else if (shipmenType === "COURSIER") {
                return <FontAwesomeIcon size={"2x"} icon="biking" color={"rgba(0,0,0,.6)"}
                                        flip={status === 'TRANSIT_ORANGE_SAVE' ? "" : "horizontal"}/>
            } else if (shipmenType === "BYSHOP") {
                return <FontAwesomeIcon style={{marginLeft: '7px'}} size={"2x"} icon="person-carry" color={"rgba(0,0,0,.6)"}
                                        flip={status === 'TRANSIT_ORANGE_SAVE' ? "" : "horizontal"}/>
            }
        } else {
            return <Icon name='question'/>
        }
    } else if (statusMap.get(status)) {
        return statusMap.get(status).icon
    } else {
        return <Icon name='question'/>
    }


}

