import {Button, Container, Divider, Grid, Header, Icon, Image, List, Segment} from 'semantic-ui-react'
import conKo from "./../../img/con_ko.png";
import conOk from "./../../img/con_ok.png";
import React from "react";
import batKo from "../../img/bat_ko.png";
import batOk from "../../img/bat_ok.png";
import {Link} from "react-router-dom";

function BTN_HOME_ManualDiag(props) {

    const diagAction = function(testOk){
        props.nextDiag(testOk)
    }


    return (

            <Segment placeholder>
                <Grid columns={2} relaxed='very' stackable>
                    <Grid.Column>
                        <h3>Le bouton home du smartphone est-il fonctionnel ?</h3>
                        <div>
                            <Segment basic>
                                <Grid centered columns={2} relaxed='very'>
                                    <Grid.Column verticalAlign='middle'>
                                        <Segment fluid className="diagchoice"  onClick={()=>diagAction(true)}>
                                            <Container style={{marginBottom: '10px'}}><Image centered alt="ecran_ok" src={conOk} style={{maxHeight: '150px'}} /></Container>
                                            <Icon size='huge' color='green' name='check circle outline'/>
                                            <br/><p>OUI</p>
                                        </Segment>
                                    </Grid.Column>

                                    <Grid.Column verticalAlign='middle' >
                                        <Segment fluid className="diagchoice" onClick={()=>diagAction(false)}>
                                            <Container style={{marginBottom: '10px'}}><Image centered alt="ecran_ok" src={conKo} style={{maxHeight: '150px'}} /></Container>
                                            <Icon size='huge' color='red' name='times circle outline'/>
                                            <br/><p>NON</p>
                                        </Segment>
                                    </Grid.Column>
                                </Grid>
                            </Segment>

                            {props.piceaEligible ?
                                <Button as={Link} to='/remoteDiag'  basic style={{marginTop: '45px'}} primary>Je souhaite faire le diagnostic avec Piceasoft</Button> : null}


                        </div>
                    </Grid.Column>
                    <Grid.Column verticalAlign='middle'>
                        <h3>Qu'est ce que cela signifie ?</h3>
                        <List bulleted>
                            <List.Item>Le bouton n'est pas cassé ou fissuré</List.Item>
                            <List.Item>Le bouton fonctionne bien lors de l'appui</List.Item>
                            <List.Item>Le bouton ne se bloque pas lors de l'appui</List.Item>
                        </List>
                    </Grid.Column>
                </Grid>
                <Divider vertical/>
            </Segment>
    );

}

export default BTN_HOME_ManualDiag
