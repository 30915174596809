import {ON_PICEASOFT_DIAG_FINISHED, ON_PICEASOFT_DIAG_STARTED, ON_PICEASOFT_NEW_DIAG} from './diagnostic.types';
import {RESET_APP} from "../GlobalUi/globalUi.types";

const INITIAL_STATE = {
    diagResult: [],
    diagStarted:false,
    diagFinished:false,
    isSmartphoneFunctional:null,
    isSmartphoneScreenDamaged:null,
    isSmartphoneBackCoverDammaged: null,
    diag:[]
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {


        case "ON_VISUAL_DIAG_UPDATE":
        {
            return {...state,...action.payload}
        }
        case ON_PICEASOFT_NEW_DIAG:
        {
            let updatedDiagResult =  [];

            if(state.diagResult.length !=0)
                updatedDiagResult  = updatedDiagResult.concat(state.diagResult);
            updatedDiagResult.push(action.result);

            return {...state,diagResult:updatedDiagResult}
        }
        case ON_PICEASOFT_DIAG_STARTED:
        {
            return {...state,diagStarted:true}
        }
        case ON_PICEASOFT_DIAG_FINISHED:
        {
            return {...state,diagFinished:true}
        }

        case RESET_APP:
            return INITIAL_STATE

        default:
            return state;
    }
};

export default reducer;
