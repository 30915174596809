import React, {useState} from "react";
import {Message} from "semantic-ui-react";
import Moment from "react-moment";

function HistoricEmailComponent({historicElement}) {

    const [expanded, setExpanded] = useState(false);

    console.log(historicElement)

    if(!expanded) {
        return (
            <Message success size={"tiny"} onClick={() => setExpanded(true)}
                     style={{cursor: 'pointer',paddingBottom: '1px', paddingTop: '1px'}}>
                NPM Email envoyé au client <a onClick={() => setExpanded(true)}>(voir détails)</a></Message>
        );
    }

    if(expanded) {
        return (
            <Message success size={"tiny"} onClick={() => setExpanded(false)}
                     style={{ cursor: 'pointer', paddingBottom: '1px', paddingTop: '1px'}}>
                <Message.Header>Email envoyé au client le <Moment format="ddd DD MMM YYYY [à] HH:mm"
                                                                  locale="fr">{historicElement.date}</Moment></Message.Header>
                <ul>
                    <li><b>template :</b> {historicElement.datas ? historicElement.datas.templateId + " - " + historicElement.datas.templateName : null}</li>
                    <li><b>destinataire :</b> {historicElement.datas ? historicElement.datas.to : null}</li>
                </ul>

                <a onClick={() => setExpanded(false)}>(moins de détails)</a></Message>
        );
    }
}

export default HistoricEmailComponent
