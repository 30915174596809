import {Grid, Image} from 'semantic-ui-react'
import step1 from '../img/step1.png';
import step2 from '../img/step2.png';
import step3 from '../img/step3.png';
import step4 from '../img/step4.png';
import React from "react";

function RemoteDiagBeforeIdentification() {

    return (
        <Grid.Column width={12} verticalAlign='middle'>
                            <Grid columns={2} divided>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Image src={step1} size='tiny' circular bordered verticalAlign='middle'/>{'     '}
                                        <span style={{marginLeft: '10px'}}>Scanner le QR avec le téléphone du client</span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image src={step2} size='tiny' circular bordered verticalAlign='middle'/>{'     '}
                                        <span style={{marginLeft: '10px'}}>Installer l'application</span>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Image src={step3} size='tiny' circular bordered verticalAlign='middle'/>{'     '}
                                        <span style={{marginLeft: '10px'}}>Lancer le diagnostic sur l'appareil</span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image src={step4} size='tiny' circular bordered verticalAlign='middle'/>{'     '}
                                        <span style={{marginLeft: '10px'}}>Résultat du diagnostic pour générer le devis</span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
    );
}

export default RemoteDiagBeforeIdentification