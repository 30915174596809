import {
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  List,
  Menu,
  Message,
  Modal, Pagination,
  Progress,
  Segment,
  Statistic, Table,
} from "semantic-ui-react";
import { useEffect } from "react";
import { fetchIntervention } from "../reducers/FollowUp/followup.actions";
import { axiosInstance } from "../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import React, { useState } from "react";
import WaitingCount from "../components/MenuSideBar/WaitingCount";
import ReactMomentCountDown from "react-moment-countdown";
import moment from "moment";
import FollowupLoader from "../components/Followup/FollowupLoader";
import happy from "../views/img/corner_sprite.png";
import { isIE } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignWacom from "./sign/RemoteDiag";
import Signature from "../components/Signature/Signature";
import { RESET_PRODUCT_INFO } from "../reducers/Product/product.types";
import { isAdmin } from "../utils/authorities-utils";
import WebQuote from "../components/WebQuote/WebQuote";
import OrangeMoodLabel from "../components/OrangeMoodLabel";
import ReactHtmlParser from "react-html-parser";
import "../content-styles.css";
import Moment from "react-moment";
//import {getToken} from "../firebase";

function HomePage() {
  const shop = useSelector((state) => state.shop);
  const user = useSelector((state) => state.user.user);
  const shopPartner = useSelector((state) => state.shop.shopPartner);
  const notificationToken = useSelector(
    (state) => state.user.notificationToken
  );

  const [interventionStatus, setInterventionStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [goInterventionId, setGoInterventionId] = useState(null);
  const [interventionsToRecover, setInterventionsToRecover] = useState([]);
  const [treatedThisMonth, setTreatedThisMonth] = useState(0);
  const [courierIsComing, setCourierIsComing] = useState(false);
  const [nextPickingDate, setNextPickingDate] = useState(null);
  const [notificationStrapi, setNotificationStrapi] = useState();
  const [adminNotifs, setAdminNotifs] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [totalPageNumber, setTotalPageNumber] = useState(1)

  const history = useHistory();
  const dispatch = useDispatch();

  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    //GetToken add update backoffice
    /*if (notificationToken === "" && !isTokenFound) {
            console.log("Set notification token")
            getToken(setTokenFound, dispatch);
        }*/
  }, [isTokenFound, notificationToken]);

  useEffect(() => {
    if (shop.shopPartner != undefined) {
      setIsLoading(true);
      axiosInstance
        .get("/home/infos")
        .then((response) => {
          setInterventionStatus(response.data.interventionStats);
          setInterventionsToRecover(response.data.phoneToReturn);
          setTreatedThisMonth(response.data.treatedThisMonth);
          setCourierIsComing(response.data.courierIsComing);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error");
          setIsLoading(false);
        });
    }
  }, [shop.shopPartner.code]);

  useEffect(() => {
    axiosInstance.get("/notifications").then((response) => {
      if (response.data.Title !== undefined) {
        setNotificationStrapi({
          title: response.data.Title,
          content: response.data.Content,
        });
      }
    });
  }, []);

  useEffect(() => {
    fetchAdminNotifs()
  }, [page]);

  const fetchAdminNotifs = () => {
    axiosInstance.get("/intervention/historic/adminNotifs/" + shopPartner.code + "?page=" + page + "&size="+size).then((response) => {
      setAdminNotifs(response.data.content);
      setTotalPageNumber(response.data.totalPages);
    });
  }

  const goToIntervention = (interventionId) => {
    dispatch({ type: RESET_PRODUCT_INFO });
    history.push("/followup?intervention=" + interventionId);
  }

  const handlePageChange = (e, activePage) => {
    setPage(activePage.activePage - 1);
  }

  useEffect(() => {
    axiosInstance
      .get("/parcel/getNextPicking?shopPartnerId=" + shop.shopPartner.id)
      .then((response) => {
        if (response.data.length === 1) {
          setNextPickingDate(response.data[0].automaticPickingDate);
        }

        if (response.data.length === 2) {
          var firstPick = moment(response.data[0].automaticPickingDate);
          setNextPickingDate(firstPick);
        }
      });
  }, []);

  const goToVisualDiag = (isSmartphoneFunctional) => {
    // dispatch({type:"ON_VISUAL_DIAG_UPDATE",payload:{isSmartphoneFunctional:isSmartphoneFunctional}})
    history.push("/prerequisite");
  };

  const displayInterventionsToRecover = (interventionList) => {
    let content = [];
    if (isLoading) {
      return <FollowupLoader />;
    }
    if (interventionList.length === 0)
      return (
        <Message success style={{ paddingTop: "2px", paddingBottom: "2px" }}>
          Aucun téléphone
        </Message>
      );
    for (var inter of interventionList) {
      if (inter.product) {
        content.push(
          <List.Item>
            <List.Content floated="left">
              <Image style={{ maxHeight: "30px" }} src={inter.product.imgUrl} />
            </List.Content>
            <List.Content floated="right">
              <Link to={"/followup?intervention=" + inter.id}>
                <Button size="small" basic fluid>
                  Voir le dossier
                </Button>
              </Link>
            </List.Content>
            <List.Content>
              <b style={{ color: "grey" }}>ORG_{inter.id}</b>
              <br />
              <b>
                {inter.customer.firstName} {inter.customer.lastName}
              </b>
              <br />
              {inter.product.brandName} {inter.product.name}
            </List.Content>
          </List.Item>
        );
      }
    }
    return content;
  };

  const handleChange = (e, { searchQuery, value }) => {
    setGoInterventionId(value);
  };

  const showIntervention = () => {
    dispatch({ type: RESET_PRODUCT_INFO });
    history.push("/followup?intervention=" + goInterventionId);
  };

  let time = nextPickingDate;
  let timeMessage = "";
  let percent = 0;

  if (nextPickingDate !== null) {
    percent = ((moment().diff(nextPickingDate, "minutes") + 240) / 240) * 100; // 1

    timeMessage = (
      <>
        Prochain enlèvement dans{" "}
        <ReactMomentCountDown
          toDate={nextPickingDate}
          targetFormatMask="HH[h]mm [min]"
        />
      </>
    );
  } else {
    time = null;
    timeMessage = <>Prochain enlèvement demain</>;
    percent = 100;
  }

  return (
    <Container fluid style={{ backgroundImage: happy }}>
      <Signature uuid={"0fTVWWqoCqpAjsHMGFKdN"} openModal={false} />
      {/* </Message> */}
      {notificationStrapi != undefined &&
      notificationStrapi.title != undefined ? (
        <Message color={"red"} positive>
          <div className={"notificationDiv"}>
            <Message.Header>
              <Header as="h2" >
                <Header.Content>{notificationStrapi.title}</Header.Content>
              </Header>
            </Message.Header>
            <div className="ck-content" style={{marginTop: '5px'} }>
              {ReactHtmlParser(notificationStrapi.content)}
            </div>
          </div>
        </Message>
      ) : (
        <Message.Header></Message.Header>
      )}

      <Grid columns={3} stretched={false}>
        <Grid.Column>
          <Container
            as={Link}
            to="/prerequisite"
            className={"home_page_container"}
          >
          </Container>

          <Container
            style={{ marginTop: "20px" }}
            as={Link}
            to="/followup?status=RECEIVED_PRODUCT_IN_SHOP"
            className={"home_page_container"}
          >
            <Header as="h2" attached="top" className="home-action">
              <Icon name="mobile alternate" color="teal" />
              <Header.Content>
                <OrangeMoodLabel label={"Téléphones"} /> réparés
              </Header.Content>
            </Header>
          </Container>

          <Segment
            attached
            className={"home-second-line-column home_page_container"}
          >
            <Message
              color={"orange"}
              attached={"top"}
              style={{ paddingBottom: "3px", paddingTop: "3px" }}
            >
              <Icon name="tag" />A réceptionner
            </Message>
            <Segment className="attached">
              <List divided>
                {displayInterventionsToRecover(
                  interventionsToRecover.filter(
                    (value) => value.status === "TRANSIT_SAVE_ORANGE"
                  )
                )}
              </List>
            </Segment>

            <Message
              color={"green"}
              attached
              style={{
                marginTop: "6px",
                paddingBottom: "3px",
                paddingTop: "3px",
              }}
            >
              <Icon name="tag" />A restituer
            </Message>
            <Segment className="attached">
              <List divided>
                {displayInterventionsToRecover(
                  interventionsToRecover.filter(
                    (value) => value.status === "PRODUCT_RECEIVED_ORANGE"
                  )
                )}
              </List>
            </Segment>
          </Segment>
          {/*<Segment attached centered className={"home_page_container"} style={{minHeight: '80px', paddingTop:'20px'}}>*/}
          {/*<Button.Group fluid>*/}
          {/*    /!*<Button  color='green' style={{background: "linear-gradient(0deg, rgba(69,159,42,1) 16%, rgba(37,158,65,1) 60%)"}} onClick={()=>{goToVisualDiag(true)}}>fonctionel</Button>*!/*/}
          {/*    /!*<Button.Or text='ou'/>*!/*/}
          {/*    /!*<Button  style={{backgroundColor:"#a71e1e",color:"white"}} onClick={()=>{goToVisualDiag(false)}}>non fonctionel</Button>*!/*/}
          {/*</Button.Group>*/}
          {/*</Segment>*/}
        </Grid.Column>

        <Grid.Column>
          <Container as={Link} to="/colisage" className={"home_page_container"}>
            <Header as="h2" attached="top" className="home-action">
              <Icon name="send" color="teal" />
              <Header.Content>
                Envoyer les <OrangeMoodLabel label={"téléphones"} />
                <Header.Subheader>
                  <WaitingCount /> éléments en attente d'envoi
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Container>

          <Segment
            attached
            style={{ minHeight: "80px" }}
            className={"home_page_container"}
          >
            {shopPartner.pickupType === "CHRONOPOST" ? (
              <Button basic fluid onClick={() => history.push("/colisage")}>
                Générer votre enlèvement
              </Button>
            ) : (
              <span>
                {courierIsComing ? (
                  <Message warning>
                    {" "}
                    <Button
                      as={Link}
                      to="/colisage"
                      color={"orange"}
                      fluid
                      size={"mini"}
                    >
                      <Icon color={"white"} name="warning sign" />
                      Un livreur arrive pour l'enlèvement du colis
                    </Button>
                  </Message>
                ) : null}

                {percent === 100 ? (
                  <Progress size="small" error percent={percent}>
                    {timeMessage}
                  </Progress>
                ) : percent < 75 ? (
                  <Progress size="small" success percent={percent}>
                    {timeMessage}
                  </Progress>
                ) : (
                  <Progress size="small" warning percent={percent}>
                    {timeMessage}
                  </Progress>
                )}
              </span>
            )}
          </Segment>

          <Container  style={{ marginTop: "20px" }} className={"home_page_container"}>
          <Segment attached={'top'}>
            <Header as="h2" >
              <Icon name="bell" color="teal" />
              <Header.Content> Notifications </Header.Content>
            </Header>
            <Table compact celled structured size='small'>
              <Table.Body>
                {adminNotifs.length !== 0 ?  adminNotifs.map(notif => (
                <Table.Row positive>
                <Table.Cell collapsing>
                  <List>
                    <Moment format="DD/MM/YYYY"
                            locale="fr">{notif.fromDate}</Moment>
                  </List>
                </Table.Cell>
                <Table.Cell>
                      {notif.type === 'COMMENT' ? 'Un commentaire a été ajouté à l\'intervention ORG_'+notif.intervention.id :'Une remise a été ajoutée à l\'intervention ORG_'+notif.intervention.id}
                </Table.Cell>
                  <Table.Cell >
                    <Link to={"/followup?intervention=" + notif.intervention.id }>
                      <Button size="small" primary size='tiny' onClick={() => goToIntervention()}>
                        Voir le dossier
                      </Button>
                    </Link>

                  </Table.Cell>
                </Table.Row>
                )) :<Message warning={true} size={"small"}>Aucune notification</Message> }
                </Table.Body>
            </Table>
            <Pagination
                activePage={page + 1}
                style={{width: "100%", float: "right"}}
                onPageChange={(e, pageEvent) => handlePageChange(e, pageEvent)}
                boundaryRange={0}
                siblingRange={1}
                defaultActivePage={1}
                firstItem={null}
                lastItem={null}
                totalPages={totalPageNumber}
                style={{marginTop: '10px'}}
            />
          </Segment>
          </Container>

        </Grid.Column>

        <Grid.Column>
          <Container
            as={Link}
            to="/colisage/history"
            className={"home_page_container"}
          >
            <Header as="h2" attached="top" className="home-action">
              <Icon name="box" color="teal" />
              <Header.Content>
                Suivre les colis
                <Header.Subheader>en transit aller ou retour</Header.Subheader>
              </Header.Content>
            </Header>
          </Container>
          <Segment
            attached
            style={{ minHeight: "80px" }}
            className={"home_page_container"}
          >
            <Button basic fluid onClick={() => history.push("/colisage")}>
              {interventionStatus !== null
                ? interventionStatus.shipmentHandled
                : "0"}{" "}
              colis en transit
            </Button>
          </Segment>

          <Container style={{ marginTop: "20px" }} as={Link} to="/followup">
            <Header as="h2" attached="top" className="home-action">
              <Icon name="folder open" color="teal" />
              <Header.Content>
                Suivi des dossiers
                <Header.Subheader>Voir tous les dossiers</Header.Subheader>
              </Header.Content>
            </Header>
          </Container>
          <Segment
              attached
              className={"home-second-line-column home_page_container"}
          >
            <Input
                fluid
                onChange={handleChange}
                value={goInterventionId}
                action={<Button onClick={showIntervention}>Voir</Button>}
                placeholder="Numéro de dossier"
                label={{ basic: true, content: "ORG_" }}
            />
            {/*<Divider horizontal>Devis Web</Divider>*/}
            {/*<WebQuote />*/}
          </Segment>

          <Header
            style={{ marginTop: "20px" }}
            as="h2"
            attached="top"
            className={"home_page_container"}
          >
            <Icon name="area graph" color="teal" />
            <Header.Content>
              Quelques chiffres
              <Header.Subheader>de votre boutique</Header.Subheader>
            </Header.Content>
          </Header>

          <Segment
            attached
            className={"home-second-line-column home_page_container"}
          >
            <Statistic.Group widths="three" size={"tiny"}>
              <Statistic size={"tiny"}>
                <Statistic.Value color="violet">
                  {interventionStatus !== null
                    ? interventionStatus.allInterventionsWithCustomer
                    : 0}
                </Statistic.Value>
                <Statistic.Label>dossiers créés</Statistic.Label>
              </Statistic>
              <Statistic size={"tiny"}>
                <Statistic.Value color="violet">
                  {interventionStatus !== null
                    ? interventionStatus.allInterventionsInRepairStatus
                    : 0}
                </Statistic.Value>
                <Statistic.Label>
                  en cours de
                  <br /> réparations
                </Statistic.Label>
              </Statistic>
              <Statistic size={"tiny"}>
                <Statistic.Value color="violet">
                  {treatedThisMonth}
                </Statistic.Value>
                <Statistic.Label>
                  traités
                  <br /> ce mois-ci
                </Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Segment>
        </Grid.Column>
      </Grid>

      {/*<h1>Accueil</h1>
            <h3>Quote created</h3>
            {interventionStatus!==null?interventionStatus.quoteCreated:""}
            <h3>Quote validated</h3>
            {interventionStatus!==null?interventionStatus.quoteValidated:""}
            <h3>Received product in shop</h3>
            {interventionStatus!==null?interventionStatus.receivedProductInShop:""}*/}
    </Container>
  );
}

export default HomePage;
