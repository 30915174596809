import {
    Button,
    Checkbox,
    Container,
    Grid,
    Header,
    Icon,
    Input,
    Label,
    List,
    Message,
    Modal,
    Placeholder,
    Segment,
    Step,
    Table
} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {axiosInstance} from "../utils/axiosInstance";
import {REFRESH_MENU} from "../reducers/GlobalUi/globalUi.types";
import Moment from "react-moment";
import shipmentStatusMap from "../utils/status/shipmentStatus"
import {toast} from "react-toastify";

function WaitingProducts() {

    const shop = useSelector(state => state.shop);

    const [waitingList, setWaitingList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [createdParcelId, setCreatedParcelId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen,setModalOpen] = useState(false);


    const [pickupStep,setPickUpStep] = useState(0);


    const shipmentType = new Map();
    shipmentType.set(
        "COURSIER", {
            "title" : "Enlèvement par coursier",
            "icon": 'suitcase',
            "type": 'la malette',
            "step2": "Insérer le bon d'enlèvement dans la malette",
            "step3Title": "Sceller la malette",
            "step3": "Sceller de manière définitive la malette",
        }
    );
    shipmentType.set(
        "CHRONOPOST", {
            "title" : "Envois par Chronopost",
            "icon": 'box',
            "type": 'le colis',
            "step2": "Insérer le bon d'enlèvement dans le colis",
            "step3Title": "Fermer le colis",
            "step3": "Fermer le colis de manière difinitive"
        }
    );
    shipmentType.set(
        "BYSHOP", {
            "title" : "Enlèvement par la boutique Save",
            "icon": 'suitcase',
            "type": 'la malette',
            "step2": "Insérer le bon d'enlèvement dans la malette",
            "step3Title": "Sceller la malette",
            "step3": "Sceller de manière définitive la malette",
        }
    );

    const closeModal = () => {
        setModalOpen(false);
        setPickUpStep(0);
    }

    const validatedStep1 = () => {
        setPickUpStep(1)
        // axiosInstance.post("/packing/create",
        //     {
        //         "interventionListId": selectedItems,
        //         "type": 'COURSIER',
        //     })
        //     .then((response) => {
        //        setCreatedParcelId(response.data);
        //
        //     })
        //     .catch((error) => {
        //     });
    }



    const generatePacking = () => {

        toast.success("Le colis a bien été pris en compte, il est visible dans le menu colisage", {
            position: "top-center"
        });
                setWaitingList([]);
                setModalOpen(false);
                setPickUpStep(0);
                refreshList();

                dispatch({type: REFRESH_MENU});
    }


    const dispatch = useDispatch();

    function refreshList() {
        setIsLoading(true);
        setWaitingList([]);
        setSelectedItems([]);
        axiosInstance.get("/waitings/")
            .then((response) => {
                setIsLoading(false);
                setWaitingList(response.data);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        refreshList();
    }, [])

    const addOrRemoveItem = (interId) => {
        if(selectedItems.includes(interId)) {
            setSelectedItems(selectedItems.filter(function(value, index, arr){
                return value !== interId;
            }));
        } else {
            let clone = selectedItems.slice()
            clone.push(interId);
            setSelectedItems(clone);
        }
    };

    return (
        <>
        <Container fluid>

            {/*<Header as='h2'>Produits en attente de colisage</Header>*/}


            <Input icon='search' placeholder='Recherche...' style={{float: 'left', marginBottom: '10px'}}/>


            <Table compact celled definition>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>Date de réception</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Client</Table.HeaderCell>
                        <Table.HeaderCell>Produit</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {isLoading ?
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Checkbox toggle />
                            </Table.Cell>
                            <Table.Cell>
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Table.Cell>
                            <Table.Cell>
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Table.Cell>
                            <Table.Cell>
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Table.Cell>
                            <Table.Cell>
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Table.Cell>
                        </Table.Row>
                        : null
                    }

                    {waitingList.length === 0 ?
                        <Table.Row>
                            <Table.Cell collapsing>

                            </Table.Cell>
                            <Table.Cell collapsing>
                                Pas d'élements à envoyer
                            </Table.Cell>
                        </Table.Row>
                        : null}

                    {waitingList.map((inter) => (
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Checkbox toggle onChange={(e,data) => addOrRemoveItem(inter.id)} toogle={selectedItems.includes(inter.id)}/>
                            </Table.Cell>
                            <Table.Cell>
                                <List>
                                    <List.Item>
                                        <List.Icon name='calendar alternate outline' size='large' verticalAlign='middle'/>
                                        <List.Content>
                                            <List.Header><Moment format="dddd DD MMMM YYYY" locale="fr">{inter.creationDate}</Moment></List.Header>
                                            <List.Description><Moment format="[à] HH:mm" locale="fr">{inter.creationDate}</Moment>, par {inter.creationLogin}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Table.Cell>
                            <Table.Cell>
                                <List>
                                    <List.Item>
                                        <List.Icon name={shipmentStatusMap.get(inter.shipmentType).iconText} size='large' verticalAlign='middle'/>
                                        <List.Content>
                                            <List.Header>Dossier ORG_{inter.id}</List.Header>
                                            <List.Description>{shipmentStatusMap.get(inter.shipmentType).text}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Table.Cell>
                            <Table.Cell>
                                <List>
                                    <List.Item>
                                        <List.Icon name='user' size='large' verticalAlign='middle'/>
                                        <List.Content>
                                            <List.Header>{inter.firstName} {inter.lastName}</List.Header>
                                            <List.Description>{inter.email}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Table.Cell>
                            <Table.Cell>
                                <List>
                                    <List.Item>
                                        <List.Icon name='mobile alternate' size='large' verticalAlign='middle'/>
                                        <List.Content>
                                            <List.Header>{inter.brandName}</List.Header>
                                            <List.Description>{inter.modelName}</List.Description>
                                            <List.Description>{inter.imei}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Table.Cell>
                        </Table.Row>
                        ))}

                </Table.Body>

                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell colSpan='5'>
                            <Button

                                icon
                                labelPosition='left'
                                primary
                                size='small'
                                disabled={selectedItems.length === 0}
                                onClick={() => setModalOpen(true)}
                            >
                                {selectedItems.length === 0 ?
                                <><Icon name='box' /> Sélectionner des éléments à envoyer</> : null}
                                {selectedItems.length === 1 ?
                                    <><Icon name='box' /> Créer un envoi avec l'éléments sélectionné</> : null}
                                {selectedItems.length > 1 ?
                                    <><Icon name='box' /> Créer un envoi avec les {selectedItems.length} éléments sélectionnés</> : null}
                            </Button>
                            {/*<Button size='small'>Tous les chronoposts</Button>*/}
                            {/*<Button size='small'>Tous les enlèvements</Button>*/}
                            {/*<Button disabled size='small'>*/}
                            {/*    Aucuns*/}
                            {/*</Button>*/}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Container>

        <Modal
    onClose={closeModal}
    onOpen={() => setModalOpen(true)}
    open={modalOpen && selectedItems.length !== 0}
>
<Modal.Header>{shipmentType.get(shop.pickupType).title}</Modal.Header>
    <Modal.Content>

        <Grid divided='vertically'>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <h2>Procédure</h2>
                    <Step.Group vertical>
                        <Step active={pickupStep === 0} completed={pickupStep>0} >
                            <Icon name='inbox' />
                            <Step.Content>
                                {selectedItems.length === 1 ?
                                    <Step.Title>Insérer le téléphone dans {shipmentType.get(shop.pickupType).type}</Step.Title> : null}
                                {selectedItems.length > 1 ?
                                    <Step.Title>Insérer les {selectedItems.length} téléphones dans {shipmentType.get(shop.pickupType).type}</Step.Title> : null}
                                <Step.Description>Lier les bons de prise en charge aux téléphones</Step.Description>
                            </Step.Content>
                        </Step>
                        <Step active={pickupStep === 1} completed={pickupStep>1} disabled={pickupStep<1}>
                            <Icon name='print' />
                            <Step.Content>
                                <Step.Title>Imprimer le bon d'enlèvement</Step.Title>
                                <Step.Description>{shipmentType.get(shop.pickupType).step2}</Step.Description>
                            </Step.Content>
                        </Step>
                        <Step active={pickupStep === 2} completed={pickupStep>2} disabled={pickupStep<2}>
                            <Icon name={shipmentType.get(shop.pickupType).icon} />
                            <Step.Content>
                                <Step.Title>{shipmentType.get(shop.pickupType).step3Title}</Step.Title>
                                <Step.Description>{shipmentType.get(shop.pickupType).step3}</Step.Description>
                            </Step.Content>
                        </Step>
                    </Step.Group>
                </Grid.Column>

                {pickupStep===0 ?
                <Grid.Column>
                    <Message>1. Pointer les téléphones</Message>
                    <Segment.Group>

                        {waitingList.filter(function(value, index, arr){
                              return selectedItems.includes(value.id);
                            }).map((item) =>

                            <>
                            <Segment>

                                <List size='mini' >
                                <List.Item size='mini'>
                                    <List.Icon name='check' size='large' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header><Label size='mini'>ORG_{item.id}</Label> {item.brandName} {item.modelName} ({item.imei})</List.Header>

                                    </List.Content>
                                </List.Item>
                                </List>

                            </Segment>
                            </>
                        )}
                    </Segment.Group>
                    <Message>2. Valider</Message>
                        <Button fluid content='C&apos;est fait !'  primary onClick={()=>validatedStep1()}/>
                </Grid.Column> : null}

                {pickupStep===1 ?
                    <Grid.Column>
                        <Message>1. Imprimer le bon d'enlèvement</Message>
                        <Message>2. Insérer le bon d'enlèvement dans {shipmentType.get(shop.pickupType).type}</Message>
                        <Button  fluid content='C&apos;est fait !'  primary onClick={()=>setPickUpStep(2)}/>
                    </Grid.Column> : null}
                {pickupStep===2 ?
                    <Grid.Column>
                        <Message>1. Sceller {shipmentType.get(shop.pickupType).type}</Message>
                        <Message>2. Valider</Message>

                        <Button fluid content='Je déclare le colis fermé'  primary onClick={generatePacking} />
                    </Grid.Column> : null}

            </Grid.Row>
        </Grid>

    </Modal.Content>
    <Modal.Actions>
    <Button color='black' onClick={closeModal}>
    Annuler
    </Button>
    </Modal.Actions>
</Modal>
    </>
    );

}

export default WaitingProducts
