import {Button, Container, Grid, Icon, Image, Label, Segment, Step} from 'semantic-ui-react'
import WorkflowStepper from "../../components/WorkflowStepper/WorkflowStepper";
import React, {useEffect, useState} from "react";
import {ON_IDENTIFICATION_PICEASOFT} from "../../reducers/Product/product.types";
import {useDispatch, useSelector} from "react-redux";
import RemoteDiagBeforeIdentification from "./RemoteDiagBeforeIdentification";
import RemoteDiagAfterIdentification from "./RemoteDiagAfterIdentification";
import {getModelAndDiag} from '../../reducers/Product/product.actions'
import {useHistory} from 'react-router-dom';
import {
    ON_PICEASOFT_DIAG_FINISHED,
    ON_PICEASOFT_DIAG_STARTED,
    ON_PICEASOFT_NEW_DIAG
} from "../../reducers/Diagnostic/diagnostic.types";
import SavHeader from "../../components/SavHeader/SavHeader";

function RemoteDiag() {

    /**
     * State definition
     */
    const [loaded, setLoaded] = useState(false);
    const [injected,setInjected] = useState(false);
    const [qrCode, setQrCode] = useState("");

    const product = useSelector(state => state.product);
    const dispatch = useDispatch();
    const history = useHistory();
    const [piceasoftIdentification, setPiceasoftIdentification] = useState(null);

    useEffect(() => {
        if(!injected) {
            const scriptTag = document.createElement("script");
            setInjected(true);
            scriptTag.src = "https://api.piceasoft.com/otf/v2/load_jsapi?transaction_id=f6e26a72-c839-426f-ac00-ff3deda1268a&product_id=425d853d-578a-4b4c-a6dc-b7c003a3a549&features=verify;dgs&checks[]=1&sets[]=1";
            scriptTag.addEventListener("load", () => setLoaded(true));
            document.body.appendChild(scriptTag);
        }

    }, [])

    useEffect(() => {
        if (!loaded) return;
        try {
            window.OTFAPI.onLinkCreated(onLinkCreated)
            window.OTFAPI.onDeviceIdentified(onDeviceIdentified)
            window.OTFAPI.diagnostics.onTestCaseFinished(onTestCaseFinished)
            window.OTFAPI.diagnostics.onOperationFinished(onOperationFinished)
            window.OTFAPI.diagnostics.onOperationStarted(onOperationStarted)

        } catch (e) {
        }
    }, [loaded])

    const onLinkCreated = (sdf) => {
        setQrCode(sdf);
    }
    //Callback with device information
    const onDeviceIdentified = function (deviceInformation) {

        dispatch({type: ON_IDENTIFICATION_PICEASOFT, result: deviceInformation})
        setPiceasoftIdentification(deviceInformation);
        dispatch(getModelAndDiag());
    };

    //Call back with test case result
    const onTestCaseFinished = function(response) {

        dispatch({type:ON_PICEASOFT_NEW_DIAG,result:response})
    };

    //Callback when all case over ( success or not )
    const onOperationFinished= function(response) {
        if(response.diagnostics_details.test_cases.length !=0) {
            dispatch({type: ON_PICEASOFT_DIAG_FINISHED, result: true})

        }
    };
    //Callback when all case over ( success or not )
    const onOperationStarted= function(response) {
        if(response.diagnostics_details.test_cases.length !=0) {
            dispatch({type: ON_PICEASOFT_DIAG_STARTED, result: true})

        }
    };


    /**
     * Launch piceasoft diag
     */
    function launchDiag () {



        window.OTFAPI.diagnostics.startOperation(product.piceasoftSetting);
        //var operationConfigDiag = operationConfigDiagDev;
        //window.OTFAPI.diagnostics.startOperation(operationConfigDiag);
    };


    const toto = function ()
    {
        if(piceasoftIdentification===null)
            return <RemoteDiagBeforeIdentification/>;
            return <RemoteDiagAfterIdentification piceasoftIdentification={piceasoftIdentification} launchDiag={launchDiag}/>;
    }

    return (
        <>
        <Container fluid>
            <SavHeader/>
            <WorkflowStepper step={2}/>

            {/*<Header as='h2'>Diagnostic Piceasoft</Header>*/}

            <Step.Group attached='top'>
                <Step active>
                    <Step.Content>
                        <Step.Title>Scan</Step.Title>
                    </Step.Content>
                </Step>
                <Step disabled>
                    <Step.Content>
                        <Step.Title>Résultat</Step.Title>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Segment placeholder>
                <Grid columns={2} divided>
                    <Grid.Column width={4}>
                        <Segment>
                            <div style={{marginTop: '20px', minHeight: '150px'}}>
                                {loaded && window.OTFAPI ? <Image src={window.OTFAPI.QRCodeBase + qrCode} size='small' centered/> : ""}
                            </div>
                            <br/><br/>


                            <Grid columns={2} style={{marginBottom: '40px'}}>
                                <Grid.Column verticalAlign='middle'>
                                    <Button icon floated={'right'} labelPosition='left' size='mini'>
                                        <Icon size='large' name='google play'/>
                                        &nbsp; Google play
                                    </Button>
                                </Grid.Column>

                                <Grid.Column verticalAlign='middle'>
                                    <Button icon floated={'left'} labelPosition='left' size='mini'>
                                        &nbsp; App store
                                        <Icon size='large' name='app store'/>
                                    </Button>
                                </Grid.Column>

                                <Label attached='bottom'>Powered by Piceasoft.</Label>
                            </Grid>
                        </Segment>


                        <p style={{justifyContent: 'center', display: 'flex', marginTop: '50px'}}>
                            <Label as='a' color='orange' onClick={()=>history.push('/manualIdentification')}>
                                <Icon name='warning circle'/>
                                Le scan du QR code est impossible ou votre mobile n’est pas compatible app store ou google play ? Cliquer ici.
                            </Label>
                        </p>

                    </Grid.Column>

                   {toto()}

                </Grid>
            </Segment>
        </Container>
            </>
    );

}

export default RemoteDiag
