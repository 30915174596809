export function isDiscountEligible(intervention, historic) {

    if (intervention.discountRate !== null) {
        return false;
    }
    if (intervention.status === "NEW_QUOTE_PROPOSAL") {
        return false;
    }
    for (const historicElement of historic) {
        if (historicElement.status === 'PRODUCT_NOT_REPAIRED' || historicElement.status === 'NEW_QUOTE_REFUSED' ||
            historicElement.status === 'CANCELED' || historicElement.status === 'FINISHED') {
            return false;
        } else if (historicElement.type === 'SAV') {
            return false;
        }
    }
    return true;
}

export function isCanInterventionBeAbandoned(intervention) {
    if (intervention && intervention.status === 'PRODUCT_RECEIVED_ORANGE') {
        return true;
    }
    return false;
}

export function isCanInterventionBeCanceled(intervention, isAdmin) {
    if (isAdmin) {
        return true;
    }
    return intervention.status === "QUOTE_VALIDATED";
}

export function hasDevisStepInHistoric(historicList) {
    return historicList.find(element => element.status === "QUOTE_SENDED"  || element.status === "QUOTE_INIT")
}

export function hasQuoteValidatedInHistoric(historicList) {
    return historicList.find(element => element.status === "QUOTE_VALIDATED")
}

export function hasNewQuoteStepInHistoric(historicList) {
    return historicList.find(element => element.status === "NEW_QUOTE_ACCEPTED")
}

export function hasRepairedNotRepariredStepInHistoric(historicList) {
    return historicList.find(element => (element.status === "PRODUCT_REPAIRED" || element.status === "PRODUCT_NOT_REPAIRED"))
}

export function hasFinishedHistoric(historicList) {
    return historicList.find(element => (element.status === "FINISHED"))
}

