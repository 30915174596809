import {CREATE_UPDATE_CUSTOMER_SUCCESS, RESET_CUSTOMER_INFO, UPDATE_CUSTOMER_INFO} from "../Customer/customer.types";
import {RESET_APP} from "../GlobalUi/globalUi.types";
import {SET_CUSTOMER_ORANGE_PHONE,LOAD_CUSTOMER} from "./customer.types";

const INITIAL_STATE = {
    id: "",
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    numVat: "",
    adressLine1: "",
    adressLine2: "",
    zipCode: "",
    city: "",
    country: "",
    type: "",
    customerOrangeType: "",
    contactPhone: "",
    orangePhone: ""
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case CREATE_UPDATE_CUSTOMER_SUCCESS:
            return action.customer

        case RESET_CUSTOMER_INFO:
            return INITIAL_STATE

        case SET_CUSTOMER_ORANGE_PHONE:
            return ({
                ...state,
                orangePhone: action.payload
            })

        case UPDATE_CUSTOMER_INFO:
            return ({
                ...state,
                firstName: action.customer.firstName,
                lastName: action.customer.lastName,
                companyName: action.customer.companyName,
                email: action.customer.email,
                contactPhone: action.customer.contactPhone,
                orangePhone: action.customer.orangePhone,
                customerOrangeType: action.customer.customerOrangeType,
            })

        case RESET_APP:
            return INITIAL_STATE

        case LOAD_CUSTOMER:{
            return action.payload
        }


        default:
            return state;
    }
};

export default reducer;
