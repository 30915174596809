import {
    Button,
    Dimmer,
    Divider, Dropdown,
    Grid,
    Header,
    Icon, Input,
    Label,
    List,
    Loader, Menu,
    Message,
    Modal, Progress, Search,
    Segment,
    Step
} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import Moment from "react-moment";
import {axiosInstance} from "../utils/axiosInstance";
import {toast} from "react-toastify";
import {
    DECREASE_PARCEL_COUNTER, INCREMENT_PARCEL_COUNTER,
    REFRESH_MENU,
    REFRESH_PARCELS, RESET_PARCEL_COUNTER
} from "../reducers/GlobalUi/globalUi.types";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import ReactMomentCountDown from "react-moment-countdown";
import {printDeliverysheet} from "../reducers/DeliverySheet/deliverysheet.actions";
import {isAdmin, isSupport} from "../utils/authorities-utils";
import {printChronopostTracking} from "../reducers/Parcel/parcel.actions";
import errorMessageMap from "../utils/status/errorMessage";
import InterventionParcelHistoric from "../components/Historics/InterventionParcelHistoric";
import {RESET_PRODUCT_INFO} from "../reducers/Product/product.types";

function WaitingProducts3() {

    const shop = useSelector(state => state.shop.shopPartner);
    const connectedUser = useSelector(state => state.user.user);

    const [waitingList, setWaitingList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [waitingListLoading, setWaitingListLoading] = useState(false);
    const [parcelLoading, setParcelLoading] = useState(false);
    const [parcel, setParcel] = useState(null);
    const [parcelItems, setParcelItems] = useState([]);
    const [createdParcelId, setCreatedParcelId] = useState(null);
    const [generatePackingLoading, setGeneratePackingLoading] = useState(false);
    const [nextPickingLabel, setNextPickingLabel] = useState([]);
    const [nextPickingDate, setNextPickingDate] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [pickupStep, setPickUpStep] = useState(0);
    const dispatch = useDispatch();

    const [value, setValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState([]);



    useEffect(() => {
        axiosInstance.get("/parcel/getNextPicking?shopPartnerId=" + shop.id)
            .then((response) => {

                if (response.data.length === 0)
                    setNextPickingLabel("Plus de ramassage aujourd'hui");
                if (response.data.length === 1) {
                    setNextPickingDate(response.data[0].automaticPickingDate)
                    var firstPick = moment(response.data[0].automaticPickingDate);
                    setNextPickingLabel(`Votre boutique est configurée pour avoir un
                     enlèvement automatique à ` + firstPick.format('H') + 'h' + firstPick.format('mm') + `min si au moins un élément est présent dans la liste suivante.`);
                }

                if (response.data.length === 2) {
                    var firstPick = moment(response.data[0].automaticPickingDate);
                    setNextPickingDate(firstPick)
                    var secondPick = moment(response.data[1].automaticPickingDate);

                    setNextPickingLabel(`Votre boutique est configurée pour avoir un
                     enlèvement automatique à ` + firstPick.format('H') + 'h' + firstPick.format('mm') + `min et à ` + secondPick.format('H') + 'h' + secondPick.format('mm') + `min si au moins un élément est présent dans la liste suivante.`);
                }

                console.log("sdfsdfsdf");

            })
    }, []);


    const shipmentType = new Map();
    shipmentType.set(
        "COURSIER", {
            "title": "Enlèvement par coursier",
            "icon": 'suitcase',
            "type": 'la malette',
            "step2": "Insérer le bon d'enlèvement dans la malette",
            "step3Title": "Sceller la malette",
            "step3": "Sceller de manière définitive la malette",
        }
    );
    shipmentType.set(
        "CHRONOPOST", {
            "title": "Envois par Chronopost",
            "icon": 'box',
            "type": 'le colis',
            "step2": "Insérer le bon d'enlèvement dans le colis",
            "step3Title": "Fermer le colis",
            "step3": "Fermer le colis de manière difinitive"
        }
    );
    shipmentType.set(
        "BYSHOP", {
            "title": "Enlèvement par la boutique Save",
            "icon": 'suitcase',
            "type": 'la malette',
            "step2": "Insérer le bon d'enlèvement dans la malette",
            "step3Title": "Sceller la malette",
            "step3": "Sceller de manière définitive la malette",
        }
    );

    const closeModal = () => {
        setModalOpen(false);
        setPickUpStep(0);
    }

    const validatedStep1 = () => {
        setPickUpStep(1)
        // axiosInstance.post("/packing/create",
        //     {
        //         "interventionListId": selectedItems,
        //         "type": 'COURSIER',
        //     })
        //     .then((response) => {
        //         setCreatedParcelId(response.data);
        //
        //     })
        //     .catch((error) => {
        //         console.log("error")
        //     });
    }


    const generatePacking = () => {
        setGeneratePackingLoading(true);
        axiosInstance.get("/parcel/" + parcel.id + "/ship")
            .then((response) => {
                toast.success("Le colis a bien été pris en compte, il est visible dans le menu colisage", {
                    position: "top-center"
                });
                setWaitingList([]);
                setModalOpen(false);
                setGeneratePackingLoading(false);
                setPickUpStep(0);
                refreshWaitingList();
                getParcel();
                dispatch({type: REFRESH_MENU});
                dispatch({type: REFRESH_PARCELS});
                dispatch({type: RESET_PARCEL_COUNTER});
                if (shop.pickupType === "CHRONOPOST") {
                    printChronopostTracking(response.data)
                }
            })
            .catch((err) => {
                if (err.response.status === 422) {
                    toast.error(errorMessageMap.get(err.response.data.title) ? errorMessageMap.get(err.response.data.title).text : err.response.data.title, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                console.log("error")
                refreshWaitingList();
                getParcel();
                closeModal()
                setGeneratePackingLoading(false);
            });


    }

    function refreshWaitingList() {
        setWaitingListLoading(true);
        axiosInstance.get("/waitings/")
            .then((response) => {
                setWaitingListLoading(false);
                setWaitingList(response.data);
                console.log(response);
            })
            .catch((error) => {
                setWaitingListLoading(false);
                console.log("error")
            });
    }

    const handleSearchChange = (e, data) => {
        setValue(data.value)
        setIsLoading(true)
        axiosInstance.post("/interventions/",
            {
                page: 0,
                size: 10,
                search: data.value,
                type: "ALL",
            })
            .then((response) => {

                let res = response.data.content.slice();
                for (let re of res) {
                    re.interventionId = re.id
                }
                setResults(res);
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("error")
                setIsLoading(false)
            });

    }

    const resultRenderer = (inter) => (
        <List>
            <List.Item as='a' onClick={() => selectItem(inter.interventionId)}>
                <List.Icon name='bullseye'/>
                <List.Content>
                    <List.Header as='a'>{inter.firstName} {inter.lastName}</List.Header>
                    <List.Description>{inter.brandName} {inter.modelName}</List.Description>
                    <List.Description>ORG_{inter.interventionId} le <Moment format="DD MMMM YYYY[,] HH:mm"
                                                                            locale="fr">{inter.creationDate}</Moment></List.Description>
                </List.Content>
            </List.Item>
        </List>
    )

    const selectItem = (interventionId) => {

        console.log(interventionId)
        setValue("");
        setResults([]);
        // dispatch({type: RESET_PRODUCT_INFO});
        // history.push("/followup?intervention=" + interventionId)

    }

    const putInParcel = (interventionId) => {
        console.log("=>>>>>>>>>>"+interventionId)
        setWaitingListLoading(true)
        axiosInstance.get("/parcel/ongoing/add/" + interventionId)
            .then((response) => {
                refreshWaitingList();
                getParcel();
                dispatch({type: INCREMENT_PARCEL_COUNTER})
            })
            .catch((error) => {
                setWaitingListLoading(false)
                console.log("error")
            });
    }
    const removeFromParcel = (interventionId, parcelItemId) => {
        console.log(interventionId)
        setParcelLoading(true);
        axiosInstance.get("/intervention/" + interventionId + "/unpacking/" + parcelItemId)
            .then((response) => {
                setParcelLoading(false);
                getParcel();
                refreshWaitingList();
                dispatch({type: DECREASE_PARCEL_COUNTER})
            })
            .catch((error) => {
                setParcelLoading(false);
                console.log("error")
            });
    }

    function getParcel() {
        setParcelLoading(true);
        axiosInstance.get("/parcel/ongoing/")
            .then((response) => {
                setParcel(response.data);
                console.log(response);
                if (response.data.id)
                    axiosInstance.get("/parcel/" + response.data.id + "/items",
                    )
                        .then((response2) => {
                            console.log(response2);
                            setParcelLoading(false);
                            //setParcels(response2.data.content)
                            setParcelItems(response2.data);
                        })
                        .catch((error) => {
                            setParcelLoading(false);
                            console.log("error")
                            console.log(error)
                        });


            })
            .catch((error) => {
                setParcelLoading(false);
                console.log("error")
            });
    }

    useEffect(() => {
        refreshWaitingList();
        getParcel();
    }, [])

    let time = nextPickingDate;
    let timeMessage = "";
    let percent = 0;

    if (time != null) {
        timeMessage = <>Prochain enlèvement
            dans <ReactMomentCountDown toDate={time} targetFormatMask='HH[h]mm [min]'/></>

    } else {
        time = null;
        timeMessage = <>Prochain enlèvement demain</>

    }

    return (
        <>
            <Header block as='h4' attached='top'>
                Votre colis en préparation
            </Header>
            <Segment attached>

                <Grid columns={2}>
                    <Grid.Column>
                        <Header block>Eligible à l'envoi</Header>
                        <Dimmer.Dimmable as={Segment} dimmed={waitingListLoading}>
                            <Dimmer active={waitingListLoading}><Loader>Chargement des produits en
                                attente</Loader></Dimmer>

                            {isAdmin(connectedUser.authorities) ?
                                <Search fluid
                                        input={{ fluid: true }}
                                        loading={isLoading}
                                        onResultSelect={(e, data) => putInParcel(data.result.id)}
                                        onSearchChange={handleSearchChange}
                                        resultRenderer={resultRenderer}
                                        results={results}
                                        value={value}
                                        placeholder='Rechercher une intervention...'
                                        noResultsMessage={isLoading ? "Recherche..." : "Aucune intervention trouvée"}
                                        aligned='left'/>
                                : null
                            }

                            {waitingList.map((inter) => (
                                <Segment className='waitingItem'>
                                    <Grid columns={2}>
                                        <Grid.Column width={12}>
                                            <List>
                                                <List.Item>

                                                    <List.Content>
                                                        <List.Header>Dossier ORG_{inter.id} du <Moment
                                                            format="ddd DD MMM YYYY [à] HH:mm"
                                                            locale="fr">{inter.creationDate}</Moment>,
                                                            par {inter.creationLogin}</List.Header>
                                                        <List.Description>{inter.firstName} {inter.lastName} - {inter.email}</List.Description>
                                                        <List.Description>{inter.brandName} {inter.modelName} - {inter.imei}</List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column width={4} stretched verticalAlign={"middle"}>
                                            <Button primary size={"mini"} onClick={() => putInParcel(inter.id)}><Icon
                                                name={"add"}/> Ajouter au colis</Button>
                                        </Grid.Column>
                                    </Grid>

                                </Segment>
                            ))}
                            {waitingList.length === 0 ? <Message info>La liste est vide</Message> : null}
                        </Dimmer.Dimmable>
                    </Grid.Column>
                    <Grid.Column>
                        <Header block>Contenu du prochain colis {parcel ? " ( PAR_" + parcel.id + " )" : null}</Header>

                        {shop.automaticPacking === true && shop.pickupType !== "CHRONOPOST" ?
                            <Message info>{nextPickingLabel} {timeMessage}.

                            </Message> : null}

                        <Dimmer.Dimmable as={Segment} dimmed={parcelLoading}>
                            <Dimmer active={parcelLoading}><Loader>Chargement du contenu du colis</Loader></Dimmer>
                            {parcelItems.filter(parcel => parcel.intervention).map((parcel) => (
                                <Segment className='waitingItem'>
                                    <Grid columns={2}>
                                        <Grid.Column width={12}>
                                            <List>
                                                <List.Item>

                                                    <List.Content>
                                                        <List.Header>Dossier ORG_{parcel.intervention.id} du <Moment
                                                            format="ddd DD MMM YYYY [à] HH:mm"
                                                            locale="fr">{parcel.intervention.creationDate}</Moment>,
                                                            par {parcel.intervention.creatorLogin}</List.Header>
                                                        <List.Description>{parcel.intervention.customer.firstName} {parcel.intervention.customer.lastName} - {parcel.intervention.customer.email}</List.Description>
                                                        <List.Description>{parcel.intervention.product.brandName} {parcel.intervention.product.name} - {parcel.intervention.product.imei}</List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column width={4} stretched verticalAlign={"middle"}>
                                            <Button primary size={"mini"}
                                                    onClick={() => removeFromParcel(parcel.intervention.id, parcel.id)}><Icon
                                                name={"minus"}/> Retirer du colis</Button>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                            ))}
                            {parcelItems.length === 0 ? <Message info>La liste est vide</Message> : null}
                        </Dimmer.Dimmable>

                        <Grid columns={2}>
                            {parcel ? <Grid.Column>
                                <Button disabled={parcelItems.length === 0} primary fluid
                                        onClick={() => printDeliverysheet(parcel.id)}>Imprimer le bon
                                    d'enlèvement</Button>
                            </Grid.Column> : null}
                            <Grid.Column>
                                {isAdmin(connectedUser.authorities) || shop.pickupType === "CHRONOPOST" ?
                                    <Button disabled={parcelItems.length === 0} primary fluid
                                            onClick={() => setModalOpen(true)}>{shop.automaticPacking === true ? "Lancer la demande d'enlèvement" : "Lancer la demande d'enlèvement"}</Button> : null}
                            </Grid.Column>
                        </Grid>


                    </Grid.Column>
                </Grid>
                <Divider vertical><Icon name={'exchange'}/></Divider>
            </Segment>

            <Modal
                onClose={closeModal}
                onOpen={() => setModalOpen(true)}
                open={modalOpen}
            >
                <Modal.Header>{shipmentType.get(shop.pickupType).title}</Modal.Header>
                <Modal.Content>

                    <Grid divided='vertically'>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <h2>Procédure</h2>
                                <Step.Group vertical>
                                    <Step active={pickupStep === 0} completed={pickupStep > 0}>
                                        <Icon name='inbox'/>
                                        <Step.Content>
                                            {selectedItems.length === 1 ?
                                                <Step.Title>Insérer le téléphone
                                                    dans {shipmentType.get(shop.pickupType).type}</Step.Title> : null}
                                            {selectedItems.length > 1 ?
                                                <Step.Title>Insérer les {selectedItems.length} téléphones
                                                    dans {shipmentType.get(shop.pickupType).type}</Step.Title> : null}
                                            <Step.Description>Lier les bons de prise en charge aux
                                                téléphones</Step.Description>
                                        </Step.Content>
                                    </Step>
                                    <Step active={pickupStep === 1} completed={pickupStep > 1}
                                          disabled={pickupStep < 1}>
                                        <Icon name='print'/>
                                        <Step.Content>
                                            <Step.Title>Imprimer le bon d'enlèvement</Step.Title>
                                            <Step.Description>{shipmentType.get(shop.pickupType).step2}</Step.Description>
                                        </Step.Content>
                                    </Step>
                                    <Step active={pickupStep === 2} completed={pickupStep > 2}
                                          disabled={pickupStep < 2}>
                                        <Icon name={shipmentType.get(shop.pickupType).icon}/>
                                        <Step.Content>
                                            <Step.Title>{shipmentType.get(shop.pickupType).step3Title}</Step.Title>
                                            <Step.Description>{shipmentType.get(shop.pickupType).step3}</Step.Description>
                                        </Step.Content>
                                    </Step>
                                </Step.Group>
                            </Grid.Column>

                            {pickupStep === 0 ?
                                <Grid.Column>
                                    <Message>1. Pointer les téléphones</Message>
                                    <Segment.Group>

                                        {parcelItems.filter(parcel => parcel.intervention).map((item) =>

                                            <>
                                                <Segment>

                                                    <List size='mini'>
                                                        <List.Item size='mini'>
                                                            <List.Icon name='check' size='large'
                                                                       verticalAlign='middle'/>
                                                            <List.Content>
                                                                <List.Header><Label
                                                                    size='mini'>ORG_{item.intervention.id}</Label> {item.intervention.product.brandName} {item.intervention.product.name} ({item.intervention.product.imei})</List.Header>
                                                            </List.Content>
                                                        </List.Item>
                                                    </List>

                                                </Segment>
                                            </>
                                        )}
                                    </Segment.Group>
                                    <Message>2. Valider</Message>
                                    <Button fluid content='C&apos;est fait !' primary onClick={() => validatedStep1()}/>
                                </Grid.Column> : null}

                            {pickupStep === 1 ?
                                <Grid.Column>
                                    <Message>1. Imprimer le bon d'enlèvement</Message>
                                    <Button disabled={parcelItems.length === 0} primary fluid
                                            onClick={() => printDeliverysheet(parcel.id)}>Imprimer le bon
                                        d'enlèvement</Button>
                                    <Message>2. Insérer le bon d'enlèvement
                                        dans {shipmentType.get(shop.pickupType).type}</Message>
                                    <Button fluid content='C&apos;est fait !' primary onClick={() => setPickUpStep(2)}/>
                                </Grid.Column> : null}
                            {pickupStep === 2 ?
                                <Grid.Column>
                                    <Message>1. Sceller {shipmentType.get(shop.pickupType).type}</Message>
                                    <Message>2. Valider</Message>
                                    <Message info>Rappel : En déclarant le colis fermé cela va déclencher la demande
                                        d'enlèvement.</Message>
                                    <Button fluid content='Je déclare le colis fermé' primary onClick={generatePacking}
                                            disabled={generatePackingLoading} loading={generatePackingLoading}/>
                                </Grid.Column> : null}

                        </Grid.Row>
                    </Grid>

                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={closeModal}>
                        Annuler
                    </Button>
                </Modal.Actions>
            </Modal>

        </>
    );

}

export default WaitingProducts3
