import {axiosInstance} from "../../utils/axiosInstance";
import {GET_DIAGNOSIS_INFORMATION} from "./product.types";
import {ON_ERROR} from "../GlobalUi/globalUi.types"

/**
 * Check if the modele exist using imei or technical name
 * Also get the specific diagnostic to do for this model
 * @param logInfo
 * @returns {Function}
 */
export function getModelAndDiag(onSuccess) {
    return async function (dispatch, getState) {
        axiosInstance.post("/pricing/diagnosis/",
            {
                "imei": getState().product.piceasoftImei,
                "technicalName": getState().product.piceasoftTechnicalName,
                "modelName":getState().product.name,
                "brandName":getState().product.brand,
                "isSmartphoneFunctional":getState().product.isSmartphoneFunctional,
                "isSmartphoneOxidized":getState().product.isSmartphoneOxidized,
                "screenOk":!getState().product.isSmartphoneScreenDamaged,
                "withAllPricing": true
            })
            .then((response) => {
                if (response.responseCode === "KO") {
                    dispatch({
                        type: ON_ERROR,
                        payload: {
                            errorCode: response.responseCode,
                            errorMessage: response.responseDetail
                        }
                    });
                } else {
                    dispatch({type: GET_DIAGNOSIS_INFORMATION, payload: response.data})
                    if(onSuccess !== undefined) {
                        onSuccess();
                    }
                }
            })
            .catch((error) => {
                dispatch({
                    type: ON_ERROR,
                    payload: {
                        errorCode: "FETCH",
                        errorMessage: "Error while fetching generic pricing"
                    }
                });
            });
        console.log("success")
    }
}



