import {
    Button,
    Card, Divider,
    Icon,
    Image,
    Item,
    ItemGroup,
    Label, List,
    Grid,
    Message,
    Popup,
    Form,
    Segment,
    Table,Input
} from 'semantic-ui-react'
import {Link, useHistory} from 'react-router-dom';

import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ModelSelector from "./setting/ModelSelector";
import {axiosInstance} from "../utils/axiosInstance";
import NumberFormat from "react-number-format";
import {
    DEFAULT_PRICING,
    ON_MANUAL_DIAG_UPDATE,
    UPDATE_PRODUCT_INFO
} from "../reducers/Product/product.types";
import {getRepairLabel, getRepairLabelByType, getRepairOrder} from "../utils/repairLabels"
import {
    getTooltipLabel,
    isCompatibleCategory, isMultiplePricing,
    isRepairNotExist,
    isSmartphone,
    isTablet,
    isWatch
} from "../utils/product-utils";
import {SHOW_ABANDON_POPUP, SHOW_BAT_POPUP} from "../reducers/GlobalUi/globalUi.types";
import {getModelAndDiag} from "../reducers/Product/product.actions";
import BatPopup from "../components/MenuSideBar/BatPopup";
import {isByPassIMEI, isIMEI} from "../utils/imei-utils";
import {isAdmin} from "../utils/authorities-utils";
import {isIrpAppleShop} from "../utils/shop-utils";
import {updateShopSaveSettingsData} from "../reducers/Shop/shop.actions";

function AlternateWorkflow({match}) {

    const [brandName, setBrandName] = useState("");
    const [modelName, setModelName] = useState("");
    const [pictureUrl, setPictureUrl] = useState("");
    const [type, setType] = useState("");
    const [imei, setImei] = useState("");

    const connectedUser = useSelector(state => state.user.user);
    const shopSave = useSelector(state => state.shop.shopSave);
    const shopPartner = useSelector(state => state.shop.shopPartner);
    const product = useSelector(state => state.product);
    const history = useHistory();
    const dispatch = useDispatch();

    const [imeiTmp, setImeiTmp] = useState(null);
    const [imeiNotExists, setImeiNotExists] = useState("");
    const [imeiOtherModel, setImeiOtherModel] = useState(false);
    const [imeiByPass, setImeiByPass] = useState(false);
    const [imeiBrand, setImeiBrand] = useState("");
    const [imeiPicture, setImeiPicture] = useState("");
    const [imeiModel, setImeiModel] = useState("");
    const [imeiModelName, setImeiModelName] = useState("");
    const [imeiFocused, setImeiFocused] = useState(false);
    const inputRef = useRef();

    const [pricings, setPricings] = useState([]);
    const [showPrice, setShowPrice] = useState(true);
    const [isDisableShowPrice, setIsDisableShowPrice] = useState(true);
    const [selectedRepairs, setSelectedRepairs] = useState([]);

    const [isSmartphoneFunctionalStep, setIsSmartphoneFunctionalStep] = useState(true);

    const backCover= {category: "BACK_COVER"
        , pricings: [{
            id :"999998",
            priceHt : "",
            priceTtc : "",
            priceB2BHt : "",
            priceB2BTtc : "",
            priceB2BHtLab : "",
            priceB2BTtcLab : "",
            repairType : "COMPATIBLE"
            }]};
    const btnHome= {category: "BTN_HOME"
        , pricings: [{
            id :"1568999",
            priceHt : "",
            priceTtc : "",
            priceB2BHt : "",
            priceB2BTtc : "",
            priceB2BHtLab : "",
            priceB2BTtcLab : "",
            repairType : "COMPATIBLE"
        }]};

    const allRepairsCat = ['BAT','CAM_FRONT','CAM_REAR','ECO','HP','LCD','MIC','PCONNECTOR'];
    const shopInShopSpecificCat = ['CLEAN_SIMPLE','CLEAN_COMPLEX','TEMPORARY_RESTORATION'];

    let allRepairsObj = [];
    for (const cat of allRepairsCat) {
        allRepairsObj.push({category: cat, withNoPrice: true
            , pricings: [{
                id :Math.floor(Math.random() * 359),
                priceHt : "",
                priceTtc : "",
                priceB2BHt : "",
                priceB2BTtc : "",
                priceB2BHtLab : "",
                priceB2BTtcLab : "",
                repairType : "COMPATIBLE"
            }]})
    }

    function sortRepairs(a, b) {
       return getRepairOrder(a.category) - getRepairOrder(b.category);
    }

    useEffect(() => {
        dispatch(updateShopSaveSettingsData(shopSave.code));
        loadPrice();
    }, [modelName])

    useEffect(() => {
        if (imeiFocused) {
            inputRef.current.focus();
        }
    }, [imeiFocused]);

    const loadPrice = () => {
        if (modelName !== "" && modelName != undefined) {
            // setShowPrice(true);
            axiosInstance.post("/pricings/byRepairType/",
                {
                    page: 0,
                    size: 100,
                    brandName: brandName,
                    modelName: modelName.name
                })
                .then((response) => {

                    if(brandName === 'Apple' && (isSmartphone(type) || isTablet(type) )) {
                        let isExistedBackCover = response.data.repairs.filter(o => o.category === 'BACK_COVER').length > 0;
                        let isExistedBtnHome = response.data.repairs.filter(o => o.category === 'BTN_HOME').length > 0;
                        if (!isExistedBackCover) {
                            response.data.repairs.push(backCover);
                        }
                        if (!isExistedBtnHome) {
                            response.data.repairs.push(btnHome);
                        }
                    }

                    for (const a of allRepairsCat) {
                        if(response.data.repairs.filter(o=>o.category===a).length === 0) {
                            response.data.repairs.push(allRepairsObj.slice().filter(o => o.category === a)[0])
                        }
                    }

                    if(!shopPartner.shopInShop) {
                        let filter = response.data.repairs.filter(o => !shopInShopSpecificCat.includes(o.category.trim()));
                        response.data.repairs = filter;
                    }

                    setPricings(response.data)
                    //TODO gérer le sort
                    // setPricings(response.data.content.sort(sortRepairs))

                })
                .catch((error) => {
                });
        }
    }

    const reset = () => {
        setModelName("");
        setBrandName("");
        setImei("");
        setImeiOtherModel(false);
        setImeiByPass(false);
        setImeiBrand("");
        setImeiModel("");
        setImeiModelName("");
        setImeiTmp(null);
        setImeiNotExists("");
        setImeiFocused(false);
        setType("");
        setPricings([]);
        setSelectedRepairs([]);
    }

    const handleRepairTypeChange = (price, category) => {
           let repairs = selectedRepairs.slice();
           repairs = repairs.filter(value => value.category !== category)
           repairs.push({category:category , repairType: price.repairType});
           setSelectedRepairs(repairs)
           dispatch({type: UPDATE_PRODUCT_INFO, payload: {name: modelName.name, brandName: brandName, imei: imei, imgUrl: pictureUrl, selectedRepairs: repairs, type: type,
                   pricingResult: []}});
          isSelectedAllRepairType(repairs) ? setIsDisableShowPrice(false) : setIsDisableShowPrice(true) ;
    }

    const isSelectedRepairType = (price, category) => {
      for(let repair of selectedRepairs) {
          if(repair.category === category && repair.repairType === price.repairType) {
              return true
          }
      }
      return false;
    }

    const selectCard = (pricings, p) => {
        setImeiFocused(false);
        if (isRepairNotExist(pricings.model, p, type)) {
            return;
        }
        let repairs = selectedRepairs.slice();
        if (!repairs.map(obj => obj.category).includes(p.category)) {
            if(isMultiplePricing(pricings, p.category)) {
                repairs.push({category:p.category , repairType: ""});
            } else {
                repairs.push({category:p.category , repairType: p.pricings[0].repairType});
            }
        } else {
            repairs = repairs.filter(value => value.category !== p.category)
        }
        dispatch({type: UPDATE_PRODUCT_INFO, payload: {name: modelName.name, brandName: brandName, imei: imei, imgUrl: pictureUrl, selectedRepairs: repairs, type: type,
                pricingResult: []}});
        setSelectedRepairs(repairs);
        isSelectedAllRepairType(repairs) ? setIsDisableShowPrice(false) : setIsDisableShowPrice(true) ;
    }

    const goToDiag = () => {
        if (imeiTmp === null) {
            setImeiTmp("");
        }
        if (imei === '') {
            setImeiFocused(true);
        } else if(isDisplayCompatibleWithOriginalPopup()) {
            let batPricings = [];
            dispatch({type: SHOW_BAT_POPUP, payload: {batPricings: batPricings}})
        } else {
            dispatch({type: UPDATE_PRODUCT_INFO, payload: {name: modelName.name, brandName: brandName, imei: imei, imgUrl: pictureUrl, selectedRepairs: selectedRepairs, type: type,
                    pricingResult: []}});

            if (isSmartphone(type)) {
                dispatch(getModelAndDiag(() => setDiag()))
            } else {
                dispatch({type: DEFAULT_PRICING});
                history.push("/quotation");
            }
            reset();
        }
    }

    const loadImeiModel = () => {
        setModelName(imeiModel);
        setBrandName(imeiModel.brand);
        setImei(imeiTmp)
        setPictureUrl(imeiPicture)
        setSelectedRepairs([])
        loadPrice()
        dispatch({type: 'ON_VISUAL_DIAG_UPDATE', payload: product.isSmartphoneFunctional})
        dispatch({type: UPDATE_PRODUCT_INFO, payload: {brandName: imeiBrand, name: imeiModelName, enabled: modelName.enabled}});
    }

    const isDisplayCompatibleWithOriginalPopup = () => {
        let isComptaibleRepair = false;
        let isOriginalRepair = false;
        for (let repair of selectedRepairs) {
            if (repair.repairType === 'COMPATIBLE') {
                isComptaibleRepair = true;
            }
            if (repair.repairType === 'ORIGINAL') {
                isOriginalRepair = true;
            }
        }
        return isOriginalRepair && isComptaibleRepair;

    }

    function setDiag() {
        for (let diag of selectedRepairs) {
            let cat = diag.repairType === 'ORIGINAL' ?diag.category.concat('_ORI') : diag.category;
            dispatch({type: ON_MANUAL_DIAG_UPDATE, category: cat, diagOk: false});
            continue;
        }
        history.push("/quotation")
    }

    function stepUpdate(data) {
        dispatch({type: 'ON_VISUAL_DIAG_UPDATE', payload: data})
        setIsSmartphoneFunctionalStep(false);
    }

    const openAbandonPopup = () => {
        dispatch({type: SHOW_ABANDON_POPUP})
    }

    function displayCheckBox(price, repair) {
        let isChecked = isSelectedRepairType(price, repair.category);
        return <><div className="ui toggle checkbox">
            <input type="checkbox" checked={isChecked} onChange={(e, data) => handleRepairTypeChange(price, repair.category)}/>
            <label>{getRepairLabel(repair.category)} {price.repairType === 'ORIGINAL' ? "d'origine" : ""}</label>
        </div></>;
    }

    const calculatePrice = (repair) => {
     for(let price of repair.pricings){
         for(let selected of selectedRepairs) {
             if(repair.category === selected.category && price.repairType === selected.repairType) {
                 return price.priceTtc
             }
         }
     }
    }

    const isSelectedAllRepairType = (repairs) => {
        if(repairs.length === 0 ) {
            return false;
        }
        for(let selected of repairs) {
                if(selected.repairType === "") {
                    return false
                }
        }
        return true;
    }

    const updateImei = (e, {value}) => {
        setImeiTmp(value);
        if (value !== "" && !isIMEI(value, isAdmin(connectedUser.authorities))) {
            setImeiOtherModel(false);
            setImeiNotExists(true);
        } else {
            axiosInstance.get("/gsma/byimei/" + value)
                .then((response) => {
                    // If default IMEI
                    if (isByPassIMEI(value)) {
                        setImeiByPass(true)
                        setImei(value)
                        // If correct IMEI
                    } else if (response.data.brandName === brandName && response.data.modelName === modelName.name) {
                        setImeiNotExists(false);
                        setImeiOtherModel(false);
                        setImei(value)
                        // If other model
                    } else if ((response.data.brandName !== undefined) && (response.data.brandName !== brandName || response.data.modelName !== modelName.name)) {
                        setImeiTmp(value);
                        setImeiNotExists(false);
                        setImeiOtherModel(true);
                        setImeiPicture(response.data.pictureUrl);
                        setImeiModelName(response.data.modelName);
                        setImeiBrand(response.data.brandName);
                        setImeiModel(response.data.model);
                        // For default message
                    } else {
                        setImeiNotExists(true);
                        setImeiOtherModel(false);
                    }
                });
        }
    }

    let needQuote = pricings.repairs && pricings.repairs.filter(repair => selectedRepairs.map(obj => obj.category).includes(repair.category)).filter(o => o.withNoPrice).length > 0;


    const repairsGroup = new Map();
    let repairsToGroup = pricings.repairs && pricings.repairs.filter(repair => selectedRepairs.map(obj => obj.category).includes(repair.category));
    if(repairsToGroup) {
        for (const rep of repairsToGroup) {
            if (!repairsGroup.has(rep.category)) {
                repairsGroup.set(rep.category, []);
            }
            for (const pricing of rep.pricings) {
                repairsGroup.get(rep.category).push({"repair": rep, "price": pricing});
            }
        }
    }

    let rows = [];

    for (const repairsGroupElement of repairsGroup.keys()) {
        let selected = selectedRepairs.filter(r => r.category === repairsGroupElement && r.repairType).length ===1;
        let count = 0;
        for (const repairsGroupElement1 of repairsGroup.get(repairsGroupElement)) {
            rows.push(
                <Table.Row positive>
                    {count === 0 && <Table.Cell collapsing rowSpan={repairsGroup.get(repairsGroupElement).length}>
                        {pricings.model.brand}
                    </Table.Cell>}
                    {count === 0 && <Table.Cell collapsing rowSpan={repairsGroup.get(repairsGroupElement).length}>
                        {pricings.model.name}
                    </Table.Cell>}
                    <Table.Cell collapsing>
                        {isMultiplePricing(pricings, repairsGroupElement1.repair.category) ?
                            <>
                                {displayCheckBox(repairsGroupElement1.price, repairsGroupElement1.repair)}
                            </>
                            :getRepairLabelByType(repairsGroupElement1.repair.category, repairsGroupElement1.price)}
                        {count === 0 && repairsGroup.get(repairsGroupElement).length > 1 && !selected && <Label style={{marginLeft: '20px'}} basic color='red' pointing='left'>
                            Choisir une qualité
                        </Label>}
                    </Table.Cell>
                    <Table.Cell collapsing textAlign={"right"}>
                        {repairsGroupElement1.price.priceHt ? <NumberFormat
                            value={repairsGroupElement1.price.priceHt}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            decimalSeparator=','
                            suffix=' €'
                        /> : <Label
                            size={"tiny"}>sur devis</Label>}
                    </Table.Cell>
                    <Table.Cell collapsing textAlign={"right"}>
                        {repairsGroupElement1.price.priceTtc ? <NumberFormat
                            value={repairsGroupElement1.price.priceTtc}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            decimalSeparator=','
                            suffix=' €'
                        /> : <Label
                            size={"tiny"}>sur devis</Label>}
                    </Table.Cell>
                </Table.Row>
            )
            count++;
        }

    }
    return (
        <>
            <Segment fluid attached>
                <Label attached='top'>1. Sélection du produit</Label>
                {!modelName ?
                    <ModelSelector setModelName={setModelName} setBrandName={setBrandName} setPictureUrl={setPictureUrl}
                                   setImei={setImei} setType={setType}/>
                    :
                    <ItemGroup>
                        <Item>
                            <Item.Image size='tiny'
                                        src={pictureUrl ? pictureUrl : "https://react.semantic-ui.com/images/wireframe/image.png"}/>
                            <Item.Content>
                                <Item.Header>{brandName}</Item.Header>
                                <Item.Meta>{modelName.name}</Item.Meta>

                                {imei !== '' ? <Item.Meta>{imei}</Item.Meta> :
                                    <Item.Meta>
                                        Imei :  <Input  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault();}}}
                                                       size={'mini'}
                                                       error={imeiFocused}
                                                        ref={inputRef}
                                                       focus={true}
                                                       onChange={(e,value)=>updateImei(e,value)}/>
                                        { !imeiByPass && imeiTmp !== null ?<Label style={{marginLeft: '20px'}}
                                               basic
                                               color='red'
                                               pointing='left'>
                                            {!imeiByPass && imeiNotExists === '' && imeiTmp === '' ? "Veuillez renseigner l'IMEI du produit"
                                                : !imeiByPass &&  imeiNotExists ? "L’IMEI saisit ne correspond à aucun modèle"
                                                : !imeiByPass && imeiOtherModel && imeiTmp !== '' ? " L’IMEI saisit correspond à " + imeiBrand + " " +imeiModelName: null
                                            }

                                            &nbsp;&nbsp; {!imeiByPass && imeiOtherModel &&  (imeiTmp !== null | imeiTmp !== '') ?
                                                <Button
                                                    icon
                                                    labelPosition='left'
                                                    size='small'
                                                    color='green'
                                                    onClick={() => loadImeiModel()}
                                                >
                                                    <Icon name='redo'/> Changer le modèle
                                                </Button>
                                                : null
                                            }
                                    </Label>: null }
                                    </Item.Meta> }

                                {/*<Item.Meta>{imei}</Item.Meta>*/}
                                <Item.Meta>type : {type}</Item.Meta>
                                <Item.Extra as='a' onClick={reset}>Modifier</Item.Extra>
                            </Item.Content>
                        </Item>
                    </ItemGroup>

                }
            </Segment>

            {(!isSmartphoneFunctionalStep || modelName && !modelName.enabled) && modelName ?
                <Segment fluid attached style={{marginTop: '20px'}}>
                    <Label attached='top'>2. Sélection des réparations disponibles</Label>


                    {modelName && !modelName.enabled ?
                        <Message style={{marginBottom: '20px'}}>Ce produit est non éligible au
                            service.</Message> : null}

                    {modelName && modelName.enabled && pricings.repairs && pricings.repairs.length === 0 ?
                        <Message style={{marginBottom: '20px'}}>Il n'y a aucun pricing pour ce modèle.</Message> : null}

                    <Card.Group centered>
                        {modelName && modelName.enabled && pricings.repairs ? pricings.repairs.filter(o => !o.withNoPrice).map(repair => (
                            <Popup
                                content={getTooltipLabel(pricings.model, repair, type)}
                                disabled={!isRepairNotExist(pricings.model, repair, type) && !isCompatibleCategory(pricings.model, repair, type)}
                                on="hover"
                                trigger={
                                    <Card style={{minWidth: '160px', maxWidth: '160px', maxHeight: '210px'}}
                                          onClick={() => selectCard(pricings, repair)}>

                                        <Image centered style={{
                                            maxWidth: '120px',
                                            maxHeight: '120px',
                                            padding: '5px',
                                            backgroundColor: 'white'
                                        }}
                                               src={"https://drpciyuuf9kdh.cloudfront.net/save/quote/quote-repair/blue/reparation_" + repair.category.split('_ORI')[0] + ".svg"}
                                               disabled={isRepairNotExist(pricings.model, repair, type)}
                                               fluid/>

                                        <Card.Content
                                            style={{
                                                backgroundColor: selectedRepairs.map(function (obj) {
                                                    return obj.category;
                                                }).includes(repair.category) ? '#D5F5E3' : 'white'
                                            }}>
                                            <Card.Meta textAlign='center'>{getRepairLabel(repair.category)}</Card.Meta>
                                        </Card.Content>
                                    </Card>
                                }
                            />
                        )) : null
                        }
                    </Card.Group>

                    {modelName && modelName.enabled && pricings.repairs && pricings.repairs.filter(o => o.withNoPrice).length > 0 ?
                        <Label as='a' color='blue' ribbon style={{marginTop: '15px'}}>
                            Réparation(s) disponible(s) uniquement sur devis
                        </Label> : null}
                    <Card.Group centered>
                        {modelName && modelName.enabled && pricings.repairs ? pricings.repairs.filter(o => o.withNoPrice).map(repair => (
                            <Popup
                                content={getTooltipLabel(pricings.model, repair, type)}
                                disabled={!isRepairNotExist(pricings.model, repair, type) && !isCompatibleCategory(pricings.model, repair, type)}
                                on="hover"
                                trigger={
                                    <Card style={{minWidth: '160px', maxWidth: '160px', maxHeight: '210px'}}
                                          onClick={() => selectCard(pricings, repair)}>
                                        <Image centered style={{
                                            maxWidth: '120px',
                                            maxHeight: '120px',
                                            padding: '5px',
                                            backgroundColor: 'white'
                                        }}
                                               src={"https://drpciyuuf9kdh.cloudfront.net/save/quote/quote-repair/blue/reparation_" + repair.category + ".svg"}
                                               disabled={isRepairNotExist(pricings.model, repair, type)}
                                               fluid/>

                                        <Card.Content
                                            style={{
                                                backgroundColor: selectedRepairs.map(function (obj) {
                                                    return obj.category;
                                                }).includes(repair.category) ? '#D5F5E3' : 'white'
                                            }}>
                                            <Card.Meta textAlign='center'>{getRepairLabel(repair.category)}</Card.Meta>
                                        </Card.Content>
                                    </Card>
                                }
                            />
                        )) : null
                        }
                    </Card.Group>

                </Segment> : null}

            {!isSmartphoneFunctionalStep && modelName && modelName.enabled ?
                <Segment fluid attached style={{marginTop: '20px'}}>
                    <Label attached='top'>3. Prix</Label>
                    {/*{selectedRepairs.map(pricing => (<p>{pricing}</p>))}*/}

                    {modelName && pricings.repairs && pricings.repairs.length === 0 ? <><Message positive
                                                                                                 style={{marginBottom: '20px'}}>
                            Un devis pourras être établi après analyse dans le centre de réparation</Message>
                            <Button
                                icon
                                labelPosition='left'
                                primary
                                size='small'
                                onClick={goToDiag}
                            >
                                <Icon name='check'/> Continuer avec une prise en charge pour devis
                            </Button>
                            <Button
                                icon
                                labelPosition='left'
                                size='small'
                                color='red'
                            >
                                <Icon name='close'/> Abandonner
                            </Button></>
                        :
                        <>

                        <Table compact celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Marque</Table.HeaderCell>
                                    <Table.HeaderCell>Modèle</Table.HeaderCell>
                                    <Table.HeaderCell>Réparation</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={"right"}>Prix HT</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={"right"}>Prix TTC</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {rows}
                                <Table.Row>
                                    <Table.Cell colSpan='4'>Total</Table.Cell>
                                    <Table.Cell textAlign={"right"}>
                                        {!needQuote ? <b><NumberFormat
                                            value={pricings.repairs && pricings.repairs.filter(repair => selectedRepairs.map(function(obj) {return obj.category;}).includes(repair.category))
                                                .map((repair) => calculatePrice(repair))
                                                // .map((repair) => (repair.pricings[0].priceTtc))
                                                .reduce((x, y) => x + y, 0)}
                                            displayType={'text'}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            decimalSeparator=','
                                            suffix=' €'
                                        /></b> : <Label
                                            size={"tiny"}>sur devis</Label>}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                        {showPrice ? <><Button
                            icon
                            labelPosition='left'
                            disabled={isDisableShowPrice}
                            primary
                            size='small'
                            onClick={goToDiag}
                        >
                            <Icon name='check'/> Continuer la prise en charge
                        </Button>
                            <Button
                                icon
                                labelPosition='left'
                                size='small'
                                color='red'
                                onClick={() => openAbandonPopup()}
                            >
                                <Icon name='close'/> Abandonner
                            </Button></> : <Button
                            icon
                            labelPosition='left'
                            primary
                            size='small'
                            onClick={() => setShowPrice(false)}
                        >
                            <Icon name='check'/> Dévoiler les prix
                        </Button>}
                    </>}
            </Segment> : null}

            {isSmartphoneFunctionalStep && modelName && modelName.enabled ? <Segment fluid attached style={{marginTop: '20px'}}>
                <Label attached='top'>Le smartphone s'allume-t-il ?</Label>
                <Segment placeholder>
                    <Grid columns={2} relaxed='very' stackable>
                        <Grid.Column>
                            <div>
                                <Segment basic>
                                    <Grid centered columns={2} relaxed='very'>
                                        <Grid.Column verticalAlign='middle'>
                                            <Segment className="diagchoice"
                                                     onClick={() => stepUpdate({isSmartphoneFunctional: true})}>
                                                <Icon size='huge' color='green' name='check circle outline'/>
                                                <br/><p>Oui</p>
                                            </Segment>
                                        </Grid.Column>

                                        <Grid.Column verticalAlign='middle'>
                                            <Segment className="diagchoice"
                                                     onClick={() => stepUpdate({isSmartphoneFunctional: false})}>
                                                <Icon size='huge' color='red' name='times circle outline'/>
                                                <br/><p>Non</p>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                            </div>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle'>
                            <h3>Qu'est ce que cela signifie ?</h3>
                            <List bulleted>
                                <List.Item>Le produit s'allume normalement</List.Item>
                                <List.Item>Le smartphone vibre ou une diode s'allume</List.Item>
                            </List>
                        </Grid.Column>
                    </Grid>
                    <Divider vertical/>
                </Segment>

                </Segment> : null}
            <BatPopup/>
        </>

    );

}

export default AlternateWorkflow
