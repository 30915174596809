import {IS_LOGGING, LOGGIN, LOGGIN_ERROR, SET_TOKEN, START_LOGGIN} from './user.types';
import {SHOP_DATA_LOADED} from '../Shop/shop.types';
import axios from "axios";
import {axiosInstance} from "../../utils/axiosInstance";

export function logUser (logInfo) {
    return async function (dispatch, getState) {

        dispatch({type: START_LOGGIN});

        axios.post(process.env.REACT_APP_BACKOFFICE_URL+"/api/authenticate",
            {
                "username": logInfo.login,
                "password": logInfo.password,
                "rememberMe": true
            })
            .then((response) => {

                if(response.status === 200) {
                    dispatch({
                        type: SET_TOKEN,
                        token: response.data.id_token,
                    });
                    localStorage.setItem('token_sav', response.data.id_token);
                }

            })
            .catch((error) => {
                if (error.response) {
                    dispatch({
                        type: LOGGIN_ERROR,
                        loginErrorMessage: "[" + error.response.status + "] - " + error.response.data.detail
                    });
                } else if (error.request)  {
                    dispatch({
                        type: LOGGIN_ERROR,
                        loginErrorMessage: "Le serveur n\'a pas répondu"
                    });
                } else {
                    dispatch({
                        type: LOGGIN_ERROR,
                        loginErrorMessage: "Une erreur est survenue"
                    });
                }
            });
    }
}



export function setToken(token) {
    return async function (dispatch, getState) {
        dispatch({
            type: SET_TOKEN,
            token: token,
        });
    }
}

export function getUser() {
    return async function(dispatch, getState) {

        let config = {
            headers: {
                'Authorization': 'Bearer ' + getState().user.token
            }
        }

        axios.get(process.env.REACT_APP_BACKOFFICE_URL+"/api/v1/user",config)
            .then((response) => {
                if(response.status === 200) {
                    dispatch({
                        type: LOGGIN,
                        user: response.data.user,
                        userAdditionalInformations: response.data.userAdditionalInformations,
                        shopPartner: response.data.shopPartner,
                        shopSave: response.data.shopSave,
                        extranetUrl:response.data.extranetUrl
                    });


                }
            })
            .catch((error) => {

            });
    }
}
