import React, {useState} from "react";
import {Button, Feed, Icon, Label, Message} from "semantic-ui-react";
import statusMap from "../../utils/status/interventionStatus";
import Moment from "react-moment";
import {getCategoryLabel} from "../../utils/repairLabels";
import NumberFormat from "react-number-format";
import {useHistory} from "react-router-dom";
import {fetchIntervention, fetchInterventionHistoric} from "../../reducers/FollowUp/followup.actions";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {iconByStatus} from "../../utils/icon-utils";
import {isAdmin} from "../../utils/authorities-utils";

function HistoricUpdateStatusComponent({historicElement, setSelectedHistoric, selectParcelTab}) {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);

    let isSav = historicElement.datas && historicElement.datas.savOriginId;
    let isControlSav = historicElement.datas && historicElement.datas.savId;

    let isGreen = historicElement.status === 'PRODUCT_REPAIRED';
    let isRed = historicElement.status === 'PRODUCT_NOT_REPAIRED';

    const goTo = (interventionId) => {
        dispatch(fetchIntervention(interventionId));
        dispatch(fetchInterventionHistoric(interventionId));
        history.push("/followup?intervention=" + historicElement.datas.savOriginId)
    }

    const goToSav = (interventionId) => {
        dispatch(fetchIntervention(interventionId));
        dispatch(fetchInterventionHistoric(interventionId));
        history.push("/followup?intervention=" + historicElement.datas.savId)
    }

    let totalAmount = 0;
    if(historicElement && historicElement.datas && historicElement.datas.diag && historicElement.datas.diag.filter((repair) => (repair.priceTtc === 0)).length === 0) {
        totalAmount = historicElement.datas.diag.map((repair) => (repair.priceTtc)).reduce((x, y) => x + y, 0);
    }

    const isDisplayHistoric = (historicElement) => {
        if (historicElement.status !== 'PRODUCT_ANOMALY') {
            return true;
        } else {
            if (isAdmin(user.authorities)) {
                return true;
            }
        }
    }

    return (

        <>

            {isSav ?
                <Message warning={true} size={"small"}>
                    <Icon name='warning sign'/>Il s'agit d'un <b>retour garantie réparation</b> du dossier&nbsp;&nbsp;
                    <Label style={{cursor: 'pointer'}} size='mini' basic color={"blue"} onClick={() => goTo(historicElement.datas.savOriginId)}>
                        ORG_{historicElement.datas.savOriginId}
                    </Label>
                </Message>
                : null}

            {isControlSav ?
                <Message warning={true} size={"small"}>
                    <Icon name='warning sign'/>Il s'agit d'un <b>contrôle retour garantie réparation</b> du dossier&nbsp;&nbsp;
                    <Label style={{cursor: 'pointer'}} size='mini' basic color={"blue"} onClick={() => goToSav(historicElement.datas.savId)}>
                        ORG_{historicElement.datas.savId}
                    </Label>
                </Message>
                : null}

            {isDisplayHistoric(historicElement) ? <Feed.Event>
                <Feed.Label style={{marginTop: '6px', textAlign:'center'}} onClick={() => setSelectedHistoric(historicElement)}>
                    {iconByStatus(historicElement.status, historicElement.shipmentType)}
                </Feed.Label>
                <Feed.Content>
                    <Feed.Date><Moment format="ddd DD MMM YYYY [à] HH:mm"
                                       locale="fr">{historicElement.date}</Moment> {historicElement.login ? 'par ' + historicElement.login : null}
                    </Feed.Date>
                    <Feed.Summary style={isGreen ? {color: 'green'} : isRed ? {color: 'red'} : null}>
                        {statusMap.get(historicElement.status) ? statusMap.get(historicElement.status).text : historicElement.status}
                    </Feed.Summary>


                    {historicElement.datas && historicElement.datas.parcelId ?
                        <Feed.Meta>
                            <Button onClick={() => selectParcelTab(historicElement.datas.parcelId, historicElement.datas.parcelCode)} basic primary
                                    size={"tiny"}>Dans le colis {historicElement.datas.parcelCode}</Button>
                        </Feed.Meta> : null}


                    {historicElement.datas && historicElement.datas.diag ? <Feed.Summary style={{marginTop: '2px'}}>
                        {historicElement.datas.diag.map((repair) => (

                            <Label basic size='small' style={{marginBottom: '2px'}}>{getCategoryLabel(repair.category)}

                                {repair.priceTtc > 0 ? <Label.Detail><NumberFormat
                                    value={repair.priceTtc}
                                    displayType={'text'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    decimalSeparator=','
                                    suffix=' €'
                                /></Label.Detail> : null}

                            </Label>


                        ))}

                        {!isSav ?
                            <Label color='teal' size='small' style={{marginBottom: '2px'}}>Total

                                <Label.Detail>
                                    {totalAmount > 0 ?
                                        <NumberFormat
                                            value={totalAmount}
                                            displayType={'text'}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            decimalSeparator=','
                                            suffix=' €'
                                        /> : <>A diagnostiquer</>}</Label.Detail>

                            </Label> :
                            <Label color='red' size='small' style={{marginBottom: '2px'}}>Garantie réparation</Label>}

                    </Feed.Summary> : null
                    }

                    {historicElement.datas && historicElement.datas.estimatedDate ?
                        <Feed.Extra>
                            <FontAwesomeIcon icon="calendar-check" color={"rgba(0,0,0,.6)"}/> prévu pour le {historicElement.datas.estimatedDate}<br/>
                        </Feed.Extra> : null}

                    {historicElement.datas && historicElement.datas.isWithPAD ?
                        <Feed.Extra>
                            <FontAwesomeIcon icon="file-signature" color={"rgba(0,0,0,.6)"}/>
                            {historicElement.datas.isWithPAD === 'MANUAL' ? " Signature manuelle" : " Signature PAD"}<br/>
                        </Feed.Extra> : null}

                    {historicElement.datas && historicElement.datas.motif ?
                        <Feed.Extra>
                            <Icon name='warning circle'/>{historicElement.datas.motif}<br/>
                        </Feed.Extra> : null}

                    {historicElement.comment && historicElement.comment !== "null" ?
                        <Feed.Meta>
                            <Feed.Like><Icon name='quote left'/>{historicElement.comment}&nbsp;<Icon
                                name='quote right'/></Feed.Like>
                        </Feed.Meta> : null}

                    {historicElement.datas && historicElement.datas.cosmeticDefaultComments ?
                        <Feed.Meta>
                            <Feed.Like><Icon name='quote left'/>{historicElement.datas.cosmeticDefaultComments}&nbsp;<Icon
                                name='quote right'/></Feed.Like>
                        </Feed.Meta> : null}

                    {historicElement.comment && (historicElement.additionalInfo && historicElement.additionalInfo !== "null") ? <br></br> : null}
                    {historicElement.additionalInfo  && historicElement.additionalInfo !== "null" && historicElement.status !== 'PRODUCT_REPAIRED' ?
                        <Feed.Meta>
                            <Feed.Like><Icon name='quote left'/>{historicElement.additionalInfo}&nbsp;<Icon
                                name='quote right'/></Feed.Like>
                        </Feed.Meta> : null}

                </Feed.Content>
            </Feed.Event> : null }
        </>
    );
}


export default HistoricUpdateStatusComponent
