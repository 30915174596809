import {Button, Form, Grid, Header, Icon, Image, Message, Segment} from 'semantic-ui-react'
import {LOGGIN} from '../reducers/User/user.types';
import {getUser, logUser} from '../reducers/User/user.actions';
import React, {useEffect, useState} from 'react';
import logoOrange from './img/logo-orange.png';
import logoMood from './img/logo-mood.png';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';

function LoginPage() {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();


    useEffect(() => {
        if (user.isLogged) {
            history.push("/");
        }
    }, [user.isLogged]);


    useEffect(() => {
        if (user.token) {
            dispatch(getUser())
        }
        // else if (localStorage.getItem('token_sav') !== undefined) {
        //     dispatch({
        //         type: SET_TOKEN,
        //         token: localStorage.getItem('token_sav'),
        //     });
        // }
    }, [user.token]);

    const logAction = () => {
        dispatch(logUser({type: LOGGIN, login: login, password: password}))
    }

    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 450}}>
                <Header as='h2' color='teal' textAlign='center'>
                    <Segment basic>
                        <Grid columns={1}>
                            <Grid.Column verticalAlign='middle'>
                                {document.location.href.indexOf('mood') !== -1 ?
                                    <Image src={logoMood}  centered/> :
                                    <Image src={logoOrange} size='tiny' centered/>}
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Header>
                <Form size='large'>
                    <Segment>
                        <Form.Input fluid icon='user' iconPosition='left' placeholder='Identifiant' value={login}
                                    onChange={(e, {name, value}) => setLogin(value)}/>
                        <Form.Input
                            fluid
                            icon='lock'
                            value={password}
                            iconPosition='left'
                            placeholder='Mot de passe'
                            type='password' onChange={(e, {name, value}) => setPassword(value)}
                        />
                        <Button disabled={user.isLoading} loading={user.isLoading} color='teal' fluid size='large'
                                onClick={logAction}>Se connecter</Button>
                    </Segment>
                </Form>

                {user.loginErrorMessage ?
                    <Message warning><Icon name='warning'/> {user.loginErrorMessage}</Message> : null}
            <span style={{float:'right',color:'grey',fontSize:'9px'}}>{process.env.REACT_APP_ENVIRONMENT !== 'production' ? process.env.REACT_APP_ENVIRONMENT : null} {process.env.REACT_APP_VERSION}</span>
            </Grid.Column>
        </Grid>
    );

}

export default LoginPage
