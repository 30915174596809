import React, {useState} from "react";
import {Message} from "semantic-ui-react";
import Moment from "react-moment";

function HistoricSmsComponent({historicElement}) {

    const [expanded, setExpanded] = useState(false);

    console.log(historicElement)

    if(!expanded) {
        return (
            <Message success size={"tiny"} onClick={() => setExpanded(true)}
                     style={{cursor: 'pointer',paddingBottom: '1px', paddingTop: '1px'}}>
                SMS envoyé au client <a onClick={() => setExpanded(true)}>(voir détails)</a></Message>
        );
    }

    if(expanded) {
        return (
            <Message success size={"tiny"} onClick={() => setExpanded(false)}
                     style={{ cursor: 'pointer', paddingBottom: '1px', paddingTop: '1px'}}>
                <Message.Header>SMS envoyé au client le <Moment format="ddd DD MMM YYYY [à] HH:mm"
                                                                  locale="fr">{historicElement.date}</Moment> par {historicElement.login} </Message.Header>
                <ul>
                    <li><b>Tel. Contact :</b> {historicElement.datas ? historicElement.datas.phone: null}</li>
                    <li><b>Contenu :</b> {historicElement.datas ? historicElement.datas.content : null}</li>

                </ul>

                <a onClick={() => setExpanded(false)}>(moins de détails)</a></Message>
        );
    }
}

export default HistoricSmsComponent
