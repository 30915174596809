import {Button, Divider, Icon, Label, List, Menu, Message, Modal, Segment} from 'semantic-ui-react'
import React, {useState} from "react";
import {Link, useHistory, useLocation} from 'react-router-dom';
import {RESET_PRODUCT_INFO} from "../../reducers/Product/product.types";
import {useDispatch, useSelector} from "react-redux";
import {isAdmin, isAllowed, isManager} from "../../utils/authorities-utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AbandonPopup from "./AbandonPopup";
import {RESET_APP, SHOW_ABANDON_POPUP} from "../../reducers/GlobalUi/globalUi.types";
import axiosInstance from "../../utils/axiosInstance";
import ParcelCounter from "../../views/parcel/ParcelCounter";
import {RESET_CUSTOMER_INFO} from "../../reducers/Customer/customer.types";
import {RESET_INTERVENTION} from "../../reducers/Intervention/intervention.types";


function MenuSideBar() {
    let location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    React.useEffect(() => {
        setMenuState({
            intervention: location.pathname.includes("/visualDiag")
                || location.pathname.includes("/manualDiag")
                || location.pathname.includes("/manualIdentification")
                || location.pathname.includes("/quotation")
                || location.pathname.includes("/prerequisite")
                || location.pathname.includes("manualIdentificationGsma"),
            interventionAlt: location.interventionAlt === "/start",
            followup: location.pathname === "/followup",
            waiting: location.pathname === "/waiting",
            colisage: location.pathname === "/colisage",
            settings: location.pathname === "/settings",
            admin: location.pathname === "/admin",
            manager: location.pathname === "/manager",
            help: location.pathname === "/help",
            home: location.pathname === "/"
        })
    }, [location]);

    const [menuState, setMenuState] = useState({
        intervention: false,
        interventionAlt: false,
        followup: false,
        waiting: false,
        colisage: false,
        settings: false,
        help: false,
        admin: false,
        manager: false,
    });

    const [showSupportModal, setShowSupportModal] = useState(false);
    const isSmartphoneFunctional = useSelector(state => state.product.isSmartphoneFunctional);
    const connectedUser = useSelector(state => state.user.user);
    const intervention = useSelector(state => state.intervention.intervention);
    const shopPartner = useSelector(state => state.shop.shopPartner);
    const product = useSelector(state => state.product);

    const isNotDiagPages = (location.pathname != '/start' ) && (location.pathname != '/visualDiag') && (location.pathname != '/quotation')
    const isSignedIntervention = (intervention && intervention.signed);

    const menuChange = (path) => {
        if (product.enabled != undefined && !product.enabled) {
            logDisabledModel();
        } else if (isSignedIntervention || (isNotDiagPages && ((isSmartphoneFunctional === null)))){
            dispatch({type: RESET_PRODUCT_INFO});
            dispatch({type: RESET_CUSTOMER_INFO});
            dispatch({type: RESET_INTERVENTION});
            history.push(path)
        } else {
            dispatch({type: SHOW_ABANDON_POPUP})
        }
    }


    const logDisabledModel = () => {
        axiosInstance.post("/intervention/cancel/",
            {
                brandName: product.brand,
                modelName: product.name,
                reason: "ABN_ADMISSIBLE",
            }).then((response) => {
            if (isAllowed) {
                history.push("/");
                dispatch({type: RESET_APP});
            }
        }).catch((error) => {
            console.log("error")
        });
    }

    let phoneNumber = shopPartner.isGdt ? "0810 699 934 (Choix 5)" : "0810 63 15 15 (Choix 5)";
    return (
        <>

            <Menu vertical borderless fluid text>

                {process.env.REACT_APP_ENVIRONMENT !== 'production' ?
                    <Message color={"olive"} size={"tiny"} style={{
                        paddingTop: '1px',
                        paddingBottom: '1px',
                        marginBottom: '0px'
                    }}>serveur {process.env.REACT_APP_ENVIRONMENT} - v.{process.env.REACT_APP_VERSION}</Message> : null
                }

                <Menu.Item name='Accueil'
                           onClick={() => menuChange('/')}
                           active={menuState.home}>
                    <Icon name='home'/>Accueil
                </Menu.Item>
                <Menu.Item name='Suivi'
                           onClick={() => menuChange('/followup')}
                           active={menuState.followup}>
                    <Icon name='folder open'/>Suivi
                </Menu.Item>
                {/*<Menu.Item name='Produits en attente'*/}
                {/*           as={Link} to='/waiting'*/}
                {/*           active={menuState.waiting}>*/}
                {/*    <Icon name='stopwatch'/><Label color='teal'><WaitingCount/></Label>En attente*/}
                {/*</Menu.Item>*/}
                <Menu.Item name='Colisage'
                           onClick={() => menuChange('/colisage')}
                           active={menuState.colisage}>
                    <Icon name='box'/>Colisage
                    {!shopPartner.shopInShop ? <ParcelCounter></ParcelCounter> : null}
                </Menu.Item>
                <Menu.Item name='Paramétrage'
                           onClick={() => menuChange('/settings')}
                           active={menuState.settings}>
                    <Icon name='settings'/>Paramétrage
                </Menu.Item>
                <Menu.Item name='Aide'
                           onClick={() => menuChange('/help')}
                           active={menuState.help}>
                    <Icon name='help circle'/> Aide
                </Menu.Item>

                {isManager(connectedUser.authorities) ?
                    <><Divider/>
                        <Menu.Item name='Manager'
                                   as={Link} to='/manager'
                                   active={menuState.manager}>
                            <FontAwesomeIcon style={{marginRight: '5px'}} icon="user-cog"/> Manager
                        </Menu.Item></> : null}
                {isAdmin(connectedUser.authorities) ?

                    <Menu.Item name='Admin'
                               as={Link} to='/admin'
                               active={menuState.admin}>
                        <FontAwesomeIcon style={{marginRight: '5px'}} icon="user-crown"/> Admin
                    </Menu.Item> : null}


            </Menu>

            <Segment onClick={() => setShowSupportModal(true)}
                     style={{
                         margin: "10px",
                         padding: "2px",
                         backgroundColor: '#00BFB2',
                         border: 'solid',
                         borderColor: 'white',
                         borderWidth: '1px',
                         cursor: 'pointer'
                     }}>
                <List>
                    <List.Item style={{marginTop: "3px", marginLeft: "3px"}}>
                        <Icon><FontAwesomeIcon icon={"headset"} color={"white"} size={"2x"}/></Icon>
                        <List.Content>
                            <List.Header style={{color: 'white'}}> Assistance</List.Header>
                            <List.Description style={{color: 'white'}}>
                                Appeler la Hotline
                            </List.Description>
                        </List.Content>
                    </List.Item>
                </List>
            </Segment>

            <Modal
                size={"tiny"}
                open={showSupportModal}
                onClose={() => setShowSupportModal(false)}
            >
                <Modal.Header>Assistance</Modal.Header>
                <Modal.Content>
                    <p>La Hotline est disponible au <b>{phoneNumber}</b> du Lundi au Samedi de 9h à 18h.</p>
                    <p>L'équipe support est aussi disponible par email à <b><a
                        href="mailto:orange@save.store">orange@save.store</a></b>.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setShowSupportModal(false)}>
                        Fermer
                    </Button>
                </Modal.Actions>
            </Modal>
            <AbandonPopup/>

        </>
    );
}


export default MenuSideBar;
