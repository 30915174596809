import {Button, Container, Header, List, Pagination, Segment, Table} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {axiosInstanceAdmin} from "../../utils/axiosInstance";

function LogManager() {

    const [level, setLevel] = useState([]);

    useEffect(() => {
        loggers("org.hibernate.SQL")
    }, [])

    function changeLogLevel(loggerName, configuredLevel) {
        const body = {configuredLevel};
        axiosInstanceAdmin.post("management/loggers/org.hibernate.SQL", body)
            .then(() => {
                loggers("org.hibernate.SQL")
            })
            .catch((error) => {
                console.log("error")
            });
    }

    const loggers = (name) => {
        axiosInstanceAdmin.get("management/loggers/" + name)
            .then((response) => {
                console.log(response)
                setLevel(response.data.configuredLevel)
            })
            .catch((error) => {
                console.log("error")
            });
    }

    return (
        <>
            <Container fluid>
                <Header block as='h2'>Configuration des logs</Header>

                <Table compact celled definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell collapsing>Loggeur</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Niveau</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Cell collapsing>
                            org.hibernate.SQL
                        </Table.Cell>
                        <Table.Cell collapsing>

                            <Button inverted color='blue' value='TRACE' active={level === 'TRACE'}
                                    onClick={e => changeLogLevel("org.hibernate.SQL", e.target.value)}>TRACE</Button>
                            <Button inverted color='green' value='DEBUG' active={level === 'DEBUG'}
                                    onClick={e => changeLogLevel("org.hibernate.SQL", e.target.value)}>DEBUG</Button>
                            <Button inverted color='orange' value='INFO' active={level === 'INFO'}
                                    onClick={e => changeLogLevel("org.hibernate.SQL", e.target.value)}>INFO</Button>
                            <Button inverted color='yellow' value='WARN' active={level === 'WARN'}
                                    onClick={e => changeLogLevel("org.hibernate.SQL", e.target.value)}>WARN</Button>
                            <Button inverted color='red' value='ERROR' active={level === 'ERROR'}
                                    onClick={e => changeLogLevel("org.hibernate.SQL", e.target.value)}>ERROR</Button>
                            <Button inverted color='grey' style={{color: '#040505'}} value='OFF'
                                    active={level === 'OFF'}
                                    onClick={e => changeLogLevel("org.hibernate.SQL", e.target.value)}>OFF</Button>
                        </Table.Cell>
                    </Table.Body>

                </Table>

            </Container>
        </>
    );

}

export default LogManager
