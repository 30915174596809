import {Button, Form, Icon, Image, Input, List, Menu, Modal, Search, Table} from 'semantic-ui-react'
import logoOrange from './logo-orange.png';
import logoMood from './logo-mood.png';
import {useDispatch, useSelector} from "react-redux";
import {LOGOUT} from "../../reducers/User/user.types";

import {useHistory} from 'react-router-dom';
import {RESET_APP} from "../../reducers/GlobalUi/globalUi.types";
import {RESET_SHOP_DATA} from "../../reducers/Shop/shop.types";
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import Moment from "react-moment";
import {isAdmin, isSuperviser, isSupport} from "../../utils/authorities-utils";
import useDebounce from "../../utils/use-debounce";
import shipmentStatusMap from "../../utils/status/shipmentStatus"
import {RESET_PRODUCT_INFO} from "../../reducers/Product/product.types";
import {RESET_CRMGP_DATAS} from "../../reducers/Crmgp/crmgp.types";
import SelectShop from "../SelectShop/SelectShop";


function HeaderMenu() {
    const shop = useSelector(state => state.shop.shopPartner);
    const user = useSelector(state => state.user);
    const interventionId = useSelector(state => state.global.interventionId);
    const crmgp = useSelector(state => state.crmgp);

    const [isLoading, setIsLoading] = useState(false);
    const [isShopFinderLoading, setIsShopFinderLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [value, setValue] = useState("");
    const [shopFinderValue, setShopFinderValue] = useState("");
    const [orgFinderValue, setOrgFinderValue] = useState("");
    const [results, setResults] = useState([]);

    const selectItem = (interventionId) => {

        setValue("");
        setResults([]);
        dispatch({type: RESET_PRODUCT_INFO});
        history.push("/followup?intervention=" + interventionId)

    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const handleSearchChange = (e, data) => {
        setValue(data.value)
        setIsLoading(true)
        axiosInstance.post("/interventions/",
            {
                page: 0,
                size: 10,
                search: data.value,
                type: "ALL",
            })
            .then((response) => {

                let res = response.data.content.slice();
                for (let re of res) {
                    re.interventionId = re.id
                }
                setResults(res);
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
            });

    }


    const resultRenderer = (inter) => (
        <List>
            <List.Item as='a' onClick={() => selectItem(inter.interventionId)}>
                <List.Icon name='bullseye'/>
                <List.Content>
                    <List.Header as='a'>{inter.firstName} {inter.lastName}</List.Header>
                    <List.Description>{inter.brandName} {inter.modelName}</List.Description>
                    <List.Description>ORG_{inter.interventionId} le <Moment format="DD MMMM YYYY[,] HH:mm"
                                                                            locale="fr">{inter.creationDate}</Moment></List.Description>
                </List.Content>
            </List.Item>
        </List>
    )


    const dispatch = useDispatch();
    const history = useHistory();
    const logout = () => {
        localStorage.removeItem('token_sav')
        dispatch({type: LOGOUT})
        dispatch({type: RESET_SHOP_DATA});
        history.push("/")
    }


    const resetApp = () => {
        dispatch({type: RESET_APP});
        history.push("/")
    }

    const resetCrmgp = () => {
        dispatch({type: RESET_CRMGP_DATAS});
    }

    return (
        <>
            <Menu size='small' fluid fixed="top">
                <Menu.Item>
                    {shop.type === 'MOOD' ?
                        <img alt="logo-mood" style={{minHeight: '43px',minWidth: '134px'}} onClick={resetApp} src={logoMood}/> :
                        <img alt="logo-orange" onClick={resetApp} src={logoOrange}/>}

                </Menu.Item>
                {isAdmin(user.user.authorities) || isSuperviser(user.user.authorities) ?
                    <Menu.Item onClick={() => setModalOpen(true)}>
                        <h2>{shop.name !== "" ? shop.name : "Erreur !"}</h2>
                    </Menu.Item> :
                    <Menu.Item>
                        <h2>{shop.name !== "" ? shop.name : "Erreur !"}</h2>
                    </Menu.Item>
                }

                {crmgp.prenomCl || crmgp.nomClient || crmgp.mailContactCl || crmgp.telContactCl ?
                    <Menu.Item>
                        <List>
                            <List.Item>
                                <List.Icon name='user' color={"orange"} size='large' verticalAlign='middle'/>
                                <List.Content>
                                    <List.Description as='a'>{crmgp.prenomCl} {crmgp.nomClient} <a
                                        onClick={() => resetCrmgp()}>(ne plus utiliser)</a></List.Description>
                                    <List.Description
                                        as='a'>{crmgp.mailContactCl} {crmgp.telContactCl ? " - " : ""}{crmgp.telContactCl}</List.Description>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Menu.Item> : null}

                {isSupport(user.user.authorities) ?
                    <Menu.Item>
                        {user.user.login === "generic_access_support_national_support" ?
                            <h2>Mode consultation national</h2> :
                            user.user.login === "admin_support" ? <h2>Mode consultation national admin</h2> :
                                <h2>Mode consultation pour DO : {shop.externalShopGroup}</h2>}
                    </Menu.Item> : ""
                }

                <Menu.Menu position='right'>
                    {!isSupport(user.user.authorities) ?
                        <Menu.Item>
                            <Search fluid

                                    loading={isLoading}
                                    onResultSelect={(e, data) =>
                                        dispatch({type: 'UPDATE_SELECTION', selection: data.result.title})
                                    }
                                    onSearchChange={handleSearchChange}
                                    resultRenderer={resultRenderer}
                                    results={results}
                                    value={value}
                                    noResultsMessage={isLoading ? "Recherche..." : "Aucun élément trouvé"}
                                    aligned='right'

                            />


                        </Menu.Item> : ""}

                    <Menu.Item onClick={logout}>
                        <Icon name='sign-out'/>
                        Quitter
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            <Modal
                onClose={closeModal}
                onOpen={() => setModalOpen(true)}
                open={modalOpen}
                size={"large"}
            >
                <Modal.Header>Changer de boutique</Modal.Header>
                <Modal.Content>

                    <SelectShop admin={isAdmin(user.user.authorities) || user.initialRole === "ROLE_ADMIN"}/>

                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={closeModal}>
                        Annuler
                    </Button>
                </Modal.Actions>
            </Modal>

        </>
    );

}

export default HeaderMenu
