import axios from "axios";
import {toast} from "react-toastify";
import {LOGOUT} from "../reducers/User/user.types";
import React from "react";
import {store} from "../index";

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKOFFICE_URL+'/api/v1/',
    timeout: 30000,
    withCredentials: true
});

export const axiosInstanceLongTreatment = axios.create({
    baseURL: process.env.REACT_APP_BACKOFFICE_URL+'/api/v1/',
    timeout: 300000,
    withCredentials: true
});

export const axiosInstanceAdmin = axios.create({
    baseURL: process.env.REACT_APP_BACKOFFICE_URL+'/',
    timeout: 30000,
    withCredentials: true
});

// Axios Interceptors, every axiosInstance call will pass here
//
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response === undefined  || (!error.config.url.includes("Validation") && error.response.status !== 400)) {
        if (error.response !== undefined) {
            let data = error.response.data;
            if (error.response.status === 401 && data !== undefined && data.message !== undefined) {
                if (data.message === "TOKEN_EXPIRED") {
                    return store.dispatch({type: LOGOUT, loginErrorMessage: "Votre session a expiré"})
                }
            }
        }
        toast.error('Une erreur est survenue', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
        return Promise.reject(error);

});

// function redirect() {
//     const history = useHistory();
//     return history.push("/");
// }

// function RedirectToLoginPage() {
//     // const LogoutUser = () => {
//     const history = useHistory();
//     const dispatch = useDispatch();
//     console.log("init deco")
//     // localStorage.removeItem('token_sav')
//     console.log("execute logout...")
//     dispatch({type: LOGOUT})
//     console.log("execute logout")
//     dispatch({type: RESET_SHOP_DATA});
//     console.log("execute reset shop")
//     history.push("/")
//     return (
//         <></>
//     );
// }

export default axiosInstance;
