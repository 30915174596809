import {
    Button,
    Container,
    Grid,
    Header,
    Icon,
    Input,
    Label,
    List,
    Message,
    Placeholder,
    Segment, Select
} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useHistory, Link} from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import {
    fetchIntervention,
    fetchInterventionHistoric,
    fetchInterventionsList
} from '../reducers/FollowUp/followup.actions'
import InterventionDetails from "../components/InterventionDetails/InterventionDetails";
import {SELECT_INTERVENTION} from "../reducers/FollowUp/followup.types";
import statusMap from "../utils/status/interventionStatus"
import NumberFormat from 'react-number-format';
import Moment from "react-moment";
import moment from 'moment';
import useDebounce from "../utils/use-debounce";
import FollowupLoader from "../components/Followup/FollowupLoader";
import {RESET_APP} from "../reducers/GlobalUi/globalUi.types";
import {getCategoryLabel} from "../utils/repairLabels"

function FollowupSupport() {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [search,setSearch] = useState(query.get("intervention") ? query.get("intervention") : "")
    const [imei,setImei] = useState(query.get("imei") ? query.get("imei") : "")
    const [firstName,setFirstName] = useState(query.get("firstName") ? query.get("firstName") : "")
    const [lastName,setLastName] = useState(query.get("lastName") ? query.get("lastName") : "")
    const [mobile,setMobile] = useState(query.get("mobile") ? query.get("mobile") : "")
    const [claimRef,setClaimRef] = useState(query.get("claimRef") ? query.get("claimRef") : "")
    const [zipCode,setZipCode] = useState(query.get("zipCode") ? query.get("zipCode") : "")
    const [email,setEmail] = useState(query.get("email") ? query.get("email") : "")
    const [type,setType] = useState(query.get("status") ? query.get("status") : "ALL")



    const debouncedSearchTerm = useDebounce(search, 500);

    const followup = useSelector(state => state.followup);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: RESET_APP})

        //return ()=>  dispatch({type: RESET_APP});
    }, []);

    useEffect(() => {
        dispatch(fetchInterventionsList(0, 10,
            {imei:imei,
                firstName:firstName,
                lastName:lastName,
                zipCode:zipCode,
                claimRef:claimRef,
                mobile:mobile,
            email:email}, type));
    }, [imei, firstName,lastName,zipCode,imei,claimRef,mobile])


    useEffect(() => {
        if(debouncedSearchTerm!=="")
        dispatch(fetchInterventionsList(0, 10, {search:debouncedSearchTerm}, type));
    }, [debouncedSearchTerm, type])


    useEffect(() => {
        if(followup.interventionList.length === 1) {
            selectIntervention(followup.interventionList[0])
        }
    }, [followup.interventionList])

    const fetchMoreData = () => {
        dispatch(fetchInterventionsList(followup.page + 1, 10, {search:search}, type));
    };

    const handleChange = (e, { name, value }) => {
        switch (name) {
            case 'search' : {setSearch(value);break;}
            case 'type' : {setType(value);break;}
        }
    }

    const selectIntervention = (intervention) => {

        if(followup.selectedIntervention && (intervention.id === followup.selectedIntervention.id)) {
            console.log("ttttt => " + intervention.id)
            dispatch(fetchIntervention(intervention.id));
            dispatch(fetchInterventionHistoric(intervention.id));
        } else if(!followup.isInterventionLoading) {
            dispatch({
                type: SELECT_INTERVENTION,
                intervention: intervention,
            })
        }
    };

    const followupByDate = new Map();
    followup.interventionList.map((inter) => {
        let dayDate = moment(inter.creationDate).format('dddd DD MMMM').split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
        if(!followupByDate.has(dayDate)) {
            followupByDate.set(dayDate, []);
        }
        followupByDate.get(dayDate).push(inter);
    })

    const options = [
        {key: 'all', text: 'Tous', value: 'ALL'},
        {key: 'canceled', text: 'Annulé', value: 'CANCELED'},
        {key: 'wait_diag', text: 'Attente diagnostic', value: 'RECEIVED_PRODUCT_IN_REPAIR_SHOP'},
        {key: 'product_received_orange', text: 'A restituer', value: 'PRODUCT_RECEIVED_ORANGE'},
        {key: 'finished', text: 'Clos', value: 'FINISHED'},
        {key: 'quote_created', text: 'Devis généré', value: 'QUOTE_CREATED' },
        {key: 'quote_sended', text: 'Devis envoyé', value: 'QUOTE_SENDED'},
        {key: 'quote_validated', text: 'Devis validé', value: 'QUOTE_VALIDATED'},
        {key: 'transit_orange_save', text: 'En transit aller', value: 'TRANSIT_ORANGE_SAVE'},
        {key: 'transit_save_orange', text: 'En transit retour', value: 'TRANSIT_SAVE_ORANGE'},
        {key: 'expired', text: 'Expiré', value: 'EXPIRED'},
        {key: 'new_quote_proposal', text: 'Nouveau devis envoyé', value: 'NEW_QUOTE_PROPOSAL'},
        {key: 'new_quote_accepted', text: 'Nouveau devis accepté', value: 'NEW_QUOTE_ACCEPTED'},
        {key: 'new_quote_refused', text: 'Nouveau devis refusé', value: 'NEW_QUOTE_REFUSED'},
        {key: 'product_received_save', text: 'Produit reçu Save', value: 'PRODUCT_RECEIVED_SAVE'},
        {key: 'repair_in_progress', text: 'Produit en réparation', value: 'REPAIR_IN_PROGRESS'},
        {key: 'product_repaired', text: 'Produit réparé', value: 'PRODUCT_REPAIRED'},
        {key: 'product_not_repaired', text: 'Produit non réparé', value: 'PRODUCT_NOT_REPAIRED'},
    ]

    return (
        <>
            <Container fluid>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Input icon='search' fluid size='huge' name="search" type='text' placeholder='Recherche...' action onChange={handleChange} value={search}>
                                <input icon='search' />
                                <Select compact options={options}  name="type"  defaultValue={type} onChange={handleChange} />
                                <Button icon onClick={()=>dispatch(fetchInterventionsList(0, 10, {search:debouncedSearchTerm},type))} ><Icon name='refresh' /></Button>
                            </Input>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>



                            {/*<Input  icon='search'*/}
                            {/*        iconPosition='left'*/}
                            {/*        name="search"*/}
                            {/*        placeholder='Recherche...'*/}
                            {/*        onChange={handleChange}*/}
                            {/*        value={search}*/}
                            {/*        fluid*/}
                            {/*        action={<Button icon onClick={()=>dispatch(fetchInterventionsList(0, 10, debouncedSearchTerm))}><Icon name='refresh' /></Button>}/>*/}


                            <Segment.Group>
                                <InfiniteScroll
                                    dataLength={followup.interventionList.length}
                                    next={fetchMoreData}
                                    style={{display: 'flex', flexDirection: 'column',background: "linear-gradient(0deg, rgb(246 246 246) 0%, rgba(255,255,255,1) 100%)"}} //To put endMessage and loader to the top.
                                    hasMore={followup.hasMoreItems}
                                    loader={<FollowupLoader/>}
                                    scrollableTarget="scrollableDiv"
                                    height={600}
                                >


                                    {Array.from(followupByDate.keys()).length === 0 ?
                                        !followup.isFollowupLoading ?
                                        <Message warning>
                                            <Message.Header>Aucun résultat</Message.Header>
                                        </Message> : <FollowupLoader/> : null
                                    }

                                    {Array.from(followupByDate.keys()).map((titleDate) => (
                                        <>
                                            <Container fluid style={{
                                                backgroundColor: '#00BFB2',
                                                padding: '1px',
                                                //margin: '1px',
                                                color: 'white',
                                                fontWeight: 'bold',
                                                border: "solid rgb(0, 191, 178) 7px"
                                            }}>
                                                    <span style={{marginLeft: '3px', marginTop: '4px'}}>
                                                        {titleDate}
                                                    </span>
                                            </Container>


                                            {followupByDate.get(titleDate).map((inter) => (

                                                <span style={{borderBottom: '1px solid #d3d3d3', borderColor: '#d3d3d3' +
                                                        '',marginBottom: '0px', cursor: 'pointer'}}
                                                      onClick={() => selectIntervention(inter)}>
                                            <Container fluid style={{backgroundColor: '#F2F2F2', padding: '3px'}}>
                                                <span
                                                    style={{marginLeft: '3px', marginTop: '4px'}}>ORG_{inter.id}</span>
                                                <Label color={inter.status === 'QUOTE_CREATED' ? 'red':'green'} size='mini'
                                                       style={{float: 'right'}}>{statusMap.get(inter.status) ? statusMap.get(inter.status).label : inter.status}</Label>
                                                {inter.interventionSavOriginId!==null &&  inter.interventionSavId == null?
                                                <Label color='red' size='mini'
                                                       style={{float: 'right'}}>Retour garantie réparation</Label>:""}

                                                {inter.interventionSavOriginId !== null &&  inter.interventionSavId !== null?
                                                    <Label color='orange' size='mini'
                                                           style={{float: 'right'}}>Retour contrôle garantie réparation</Label> : ""}
                                            </Container>
                                        <Container style={{
                                            padding: '5px',
                                            backgroundColor: followup.selectedIntervention && followup.selectedIntervention.id === inter.id ? '#E0EAFC' : 'white'
                                        }}>
                                            <List style={{padding: '0px'}}>
                                                <List.Item style={{padding: '0px', marginLeft: '3px'}}>
                                                    <List.Icon style={{}} name='mobile alternate' size='large'
                                                               verticalAlign='middle'/>
                                                    <List.Content style={{padding: '0px'}}>
                                                        <List.Header>{inter.brandName} {inter.modelName}</List.Header>
                                                        <List.Description>{inter.firstName} {inter.lastName} </List.Description>
                                                        <List.Description>

                                                            {inter.repairs.map((repair) => (repair.priceTtc)).reduce((x, y) => x + y, 0) === 0 ?
                                                                <Label color='teal' size='mini'>
                                                                    A diagnostiquer
                                                                </Label>
                                                                :
                                                                <Label color='teal' size='mini'>
                                                                    <Icon name='euro'/>
                                                                    <NumberFormat
                                                                        value={inter.repairs.map((repair) => (repair.priceTtc)).reduce((x, y) => x + y, 0)}
                                                                        displayType={'text'}
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                        decimalSeparator=','
                                                                    />
                                                                </Label>
                                                            }


                                                            {inter.repairs.map((repair) => (
                                                                    repair.category !== 'SMA_KO' || repair.category !== 'DOX' ?
                                                                        <Label
                                                                            size='mini'>{getCategoryLabel(repair.category)}</Label> : null
                                                            ))}
                                                        </List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            </List>
                                        </Container>
                                    </span>

                                            ))}
                                        </>
                                    ))}


                                </InfiniteScroll>

                            </Segment.Group>

                        </Grid.Column>
                        <Grid.Column width={12}>
                            {followup.selectedIntervention ?
                                <InterventionDetails supportMode={true} interventionId={followup.selectedIntervention.id} setSearch={setSearch}/> :
                                <Message fluid>Sélectionner une intervention</Message>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>


            </Container>

        </>
    );

}

export default FollowupSupport
