import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import {RESET_PARCEL_COUNTER, SET_PARCEL_COUNTER, SHOW_ABANDON_POPUP} from "../../reducers/GlobalUi/globalUi.types";

function WaitingCount() {

    const [count,setCount] = useState(0)
    const global = useSelector(state => state.global);

    const dispatch = useDispatch();

    useEffect(() => {

        axiosInstance.get("/waitings/count")
            .then((response) => {
                let count = response.data.nbrInterventionsToSend + response.data.nbrInterventionsInParcel;
                setCount(count)
                dispatch({type: SET_PARCEL_COUNTER, payload: {parcelCounter: response.data.nbrInterventionsInParcel}})
            })
            .catch((error) => {
                console.log("error"+error)
            });

    }, [global.refreshMenu])

    return (
        <>{count}</>
    );

}

export default WaitingCount
