import {Button, Form, Input, Label, Message, Placeholder, Segment} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {useHistory} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {isAdmin} from "../../utils/authorities-utils";
import {axiosInstance} from "../../utils/axiosInstance";
import {LOGGIN} from "../../reducers/User/user.types";
import {fetchIntervention, fetchInterventionHistoric} from "../../reducers/FollowUp/followup.actions";

function ClientDetails({interventionId, customer}) {

    const followup = useSelector(state => state.followup);
    const connectedUser = useSelector(state => state.user.user);

    const dispatch = useDispatch();
    const history = useHistory();

    const {register, handleSubmit, setValue} = useForm();
    const [editMode, setEditMode] = useState(false);

    let admin = isAdmin(connectedUser.authorities);

    const onSubmit = data => {

        data.id = customer.id;
        axiosInstance.patch("/intervention/"+interventionId+"/customer/", data)
            .then((response) => {
                dispatch(fetchIntervention(interventionId));
                dispatch(fetchInterventionHistoric(interventionId));
                setEditMode(false);
            })
            .catch((error) => {
            })
    };

    return (<Segment>

            {customer ?
                <Label attached='top'>Client
                    <span style={{float: 'right'}}>
                                                {customer.type === 'CUSTOMER' ? "Particulier" : null}
                        {customer.type === 'COMPANY' ? "Professionel" : null}
                        {customer.customerOrangeType === 'NO' ? ", Non client Orange" : null}
                        {customer.customerOrangeType === 'PUBLIC' ? ", Orange Grand Public" : null}
                        {customer.customerOrangeType === 'PRO' ? ", Client Orange Entreprise" : null}
                                            </span>

                </Label>
                :
                <Label attached='top'>Client</Label>
            }

            {followup.isInterventionLoading || !customer ?
                <Placeholder>
                    <Placeholder.Header image>
                        <Placeholder.Line length='medium'/>
                        <Placeholder.Line length='full'/>
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                        <Placeholder.Line length='full'/>
                        <Placeholder.Line length='medium'/>
                    </Placeholder.Paragraph>
                </Placeholder>
                :
                <>
                    <Form size={'tiny'}>
                        {customer.firstName ?
                            <Form.Field>
                                {admin && editMode ?
                                    <Form.Field><label htmlFor="firstName">Prénom</label>
                                        <input label='Prénom' name="firstName" ref={register} defaultValue={customer.firstName}/></Form.Field> :
                                    <Input as={Input} label='Prénom' name='firstName' ref={register} fluid value={customer.firstName}/>}
                            </Form.Field> : null}
                        {customer.lastName ?
                            <Form.Field>
                                {admin && editMode ?
                                    <Form.Field><label htmlFor="lastName">Nom</label>
                                        <input label='Nom' name='lastName' ref={register} fluid defaultValue={customer.lastName}/></Form.Field> :
                                    <Input label='Nom' name='lastName' ref={register} fluid value={customer.lastName}/>}
                            </Form.Field> : null}
                        {customer.companyName ?
                            <Form.Field>
                                {admin && editMode ?
                                    <Form.Field><label htmlFor="companyName">Société</label>
                                    <input label='Société' name='companyName' ref={register} fluid defaultValue={customer.companyName}/></Form.Field> :
                                    <Input label='Société' name='companyName' ref={register} fluid value={customer.companyName}/>}
                            </Form.Field> : null}
                        {customer.email ?
                            <Form.Field>
                                {admin && editMode ?
                                    <Form.Field><label htmlFor="email">Email</label>
                                        <input label='Email' name='email' ref={register} fluid defaultValue={customer.email}/></Form.Field> :
                                    <Input label='Email' name='email' ref={register} fluid value={customer.email}/>}
                            </Form.Field> : null}
                        <Form.Group widths='equal'>
                            {customer.contactPhone ?
                                <Form.Field>
                                    {admin && editMode ?
                                        <Form.Field><label htmlFor="phone">Tel. Contact</label>
                                        <input label='Tel. Contact' name='contactPhone' ref={register} fluid
                                               defaultValue={customer.contactPhone}/> </Form.Field>:
                                        <Input label='Tel. Contact' name='contactPhone' ref={register} fluid value={customer.contactPhone}/>}
                                </Form.Field> : null}
                            {customer.orangePhone ?
                                <Form.Field>
                                    {admin && editMode ?
                                        <Form.Field><label htmlFor="orangePhone">Tel. Orange</label>
                                        <input label='Tel. Orange' name='orangePhone' ref={register} fluid
                                               defaultValue={customer.orangePhone}/></Form.Field> :
                                        <Input label='Tel. Orange' name='orangePhone' ref={register} fluid value={customer.orangePhone}/>}
                                </Form.Field> : null}
                        </Form.Group>
                    </Form>
                </>
            }
            {admin && !editMode ?
                <Button compact size={"mini"} primary onClick={() => setEditMode(true)}>Editer</Button> : null}
            {admin && editMode ?
                <Button color={"red"} compact size={"mini"} onClick={() => setEditMode(false)}>Annuler</Button> : null}
            {admin && editMode ?
                <Button color={"green"} compact size={"mini"} onClick={handleSubmit(onSubmit)}>Enregistrer</Button> : null}
        </Segment>
    );

}

export default ClientDetails
