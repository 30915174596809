import {
    Button, Container, Dropdown,
    Form,
    Grid,
    Icon,
    Image,
    Input,
    Item,
    Label,
    List,
    Message,
    Modal,
    Popup,
    Segment
} from 'semantic-ui-react'
import React, {useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    ADD_REMOVE_FROM_QUOTE,
    ADD_REMOVE_FROM_SAV,
    ADD_REMOVE_SERVICE_FROM_QUOTE, UPDATE_COLOR,
    UPDATE_GEOLOC,
    UPDATE_IMEI
} from "../../reducers/Product/product.types";
import {isIMEI} from "../../utils/imei-utils";
import {ImeiContext, GeolocContext} from "./Quotation";
import NumberFormat from "react-number-format";
import geolocTuto from '../img/courrier-geoloc-1.jpg';
import {getRepairLabel} from "../../utils/repairLabels"
import {isAdmin} from "../../utils/authorities-utils";
import axiosInstance from "../../utils/axiosInstance";
import {isSmartphone} from "../../utils/product-utils";
import colorStatusMap from "../../utils/status/colorStatus";

function QuotationDiagRecap() {


    const product = useSelector(state => state.product);
    const connectedUser = useSelector(state => state.user.user);
    const dispatch = useDispatch();
    const intervention = useSelector(state => state.intervention.intervention);
    //const [imeiError,setImeiError] = useState(false)
    const context = useContext(ImeiContext);
    const {imeiError,setImeiError,imeiInputRef} =context;
    const [imei,setImei] =useState(product.imei);
    const [imeiConsistency, setImeiConsistency] = useState(true);
    const [imeiConsistencyBrand, setImeiConsistencyBrand] = useState(true);
    const [imeiConsistencyModel, setImeiConsistencyModel] = useState(true);
    const [geolocModal,setGeolocModal] =useState(false);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedColorName, setColorCode] = useState(null);
    const addRemoveAction = function (addToQuote,category) {
        dispatch({type:ADD_REMOVE_FROM_QUOTE,addToQuote:addToQuote,category:category,brand:product.brand})
    }
    const addSav = function (category) {
        dispatch({type:ADD_REMOVE_FROM_SAV, category:category})
    }

    const [confirmGeolocCheckbox, setConfirmGeolocCheckbox] = useState(false);

    const addService = function (serviceIndex) {
        dispatch({type:ADD_REMOVE_SERVICE_FROM_QUOTE, serviceIndex:serviceIndex})
    }

    const updateImei = (e,{value})=>{
        setImeiConsistency(true);
        setImei(value);
        if(value!=="" && !isIMEI(value,isAdmin(connectedUser.authorities))) {
            setImeiError(true)
        }
        else {
            setImeiError(false)
            dispatch({type:UPDATE_IMEI,payload:value})

            axiosInstance.get("/gsma/byimei/"+value)
                .then((response) => {
                    setImeiConsistencyBrand(response.data.brandName);
                    setImeiConsistencyModel(response.data.modelName);
                    if(!response.data.brandName || !response.data.modelName || response.data.brandName.toUpperCase() !== product.brand.toUpperCase() || response.data.modelName.toUpperCase() !== product.name.toUpperCase()) {
                        setImeiConsistency(false);
                        console.log(response.data.brandName + " != " + product.brand + " || " + response.data.modelName + " != " + product.name)
                    }
                });
        }
    }

    const updateIsGeolocDisabled = (e,{value})=>{

        const updateValue = true
        console.log("confirmer..."+updateValue)
        setConfirmGeolocCheckbox(updateValue)
        dispatch({type:UPDATE_GEOLOC,payload:updateValue})
        setGeolocModal(false)

    }

    const openModalOrDisableCheckBox = () => {
        if(confirmGeolocCheckbox) {
            setConfirmGeolocCheckbox(false)
        } else {
            setGeolocModal(true)
        }
    }

    const openGeolocSite = () => {
        window.open('https://www.imeipro.info/free-icloud-check.html', '_blank')
    }

    const resetImei = () => {
        dispatch({type:UPDATE_IMEI,payload:""})
        setImeiConsistencyBrand("");
        setImeiConsistencyModel("");
        setImeiConsistency(true);
        setImei("");
    }

    const resetColor = () => {
        setSelectedColor(null);
        dispatch({type:UPDATE_COLOR,payload:null})
    }

    const handleColorChange = (event) => {
        if(selectedColor === null) {
            let selectedColor = event.target.style.backgroundColor;
            let colorCode = event.target.id;
            if(colorCode !== null && colorCode !== "") {
                let backgroundColor = colorStatusMap.get(colorCode).color;
                setSelectedColor(backgroundColor);
                setColorCode(colorCode)
                dispatch({type:UPDATE_COLOR,payload:colorCode})
            }
        } else {
            setSelectedColor(null);
        }
    }

    const imeiConsistencyDetails = <>L'imei <b>{imei}</b> semble correspondre à un <b>{imeiConsistencyBrand} {imeiConsistencyModel}</b>, vérifiez que vous avez sélectionné le bon modèle. Ignorez ce message, modifiez l'imei ou relancez une prise en charge.</>;

    return (
<>
                    <Grid.Column width={4}>
                        <Segment>
                            <Label as='a' color='teal' ribbon>
                                Eléments {intervention.interventionSavOriginId != null ? "du retour garantie réparation" : "du devis"}
                            </Label>

                            <Grid  style={{marginTop: '7px'}}>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <Container style={{height: '80px'}}><Image centered  src={product.imgUrl?product.imgUrl:"https://s3-eu-west-1.amazonaws.com/trepidai-fr/model/default.png"} style={{maxHeight: '100%',maxWidth:'100%'}} /></Container>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <Container style={{fontSize: '18px', fontWeight: 'bold'}}>{product.brand}</Container>
                                        <Container style={{fontSize: '16px', marginBottom: '3px'}}>{product.name}</Container>
                                        <Container>{product.imei ? <>imei : <b>{product.imei}</b></> :
                                            <Form>
                                                <Form.Field error={imeiError}>
                                                    <Form.Input iconPosition='left' placeholder='Imei' >
                                                        <Icon name='barcode' /> <div ref={imeiInputRef}></div>
                                                        <Input placeholder="IMEI"  error={imeiError} focus={imeiError} value={imei} onChange={(e,value)=>updateImei(e,value)}/><span style={{color:'red'}}>*</span>
                                                    </Form.Input>
                                                </Form.Field>
                                            </Form> }
                                        </Container>
                                        {!imeiConsistency && (!intervention.customer || !intervention.customer.type) ? <Container><Message style={{padding:'2px'}}warning>
                                            L'imei saisit ne semble pas correspondre au modèle.&nbsp;&nbsp;
                                            <Popup
                                                trigger={<a style={{cursor:'pointer'}}><Icon name='info circle'/>détails</a>}
                                                content={imeiConsistencyDetails}
                                                basic
                                                on='click'
                                                pinned
                                            />
                                            <br/><a style={{cursor:'pointer'}} onClick={() => resetImei()}>modifier l'imei</a>
                                        </Message></Container> : null}
                                        <Container>
                                            {product.color || selectedColor !== null ? <>
                                                    Couleur : &nbsp;&nbsp;
                                                        <button style={{
                                                            backgroundColor: product.color ? product.color : selectedColor,
                                                            color: 'black',
                                                            paddingTop: '22px',
                                                            paddingRight: '22px',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            borderColor: '#E4EDED'
                                                        }} onClick={resetColor}></button>
                                                    </>
                                                    :
                                                    <Form.Field >
                                                    <Form.Dropdown style={{backgroundColor: "#fff6f6"}} label={<> Couleur {selectedColor === null ? <><p style={{color: "red", display: 'inline'}}>* </p> :</>: <>:</> }</>} openOnFocus placeholder='Sélectionner une couleur'
                                                              options={Array.from(colorStatusMap.keys()).map((key) => (
                                                                  <><button id = {key}
                                                                          color={'4CAF50'} style={{
                                                                      backgroundColor: colorStatusMap.get(key).color,
                                                                      marginRight: '3px',
                                                                      marginBottom: '3px',
                                                                      paddingTop: '18px',
                                                                      paddingRight: '18px',
                                                                      border: 'none',
                                                                      borderRadius: '18px',
                                                                      verticalAlign: 'middle',
                                                                      lineHeight: '24px',
                                                                      cursor: 'pointer',
                                                                      borderColor: '#E4EDED'
                                                                  }}></button>
                                                                      <button  id = {key} style={{width:"150px", border:"none",backgroundColor: '#FFFFFF'}} onClick={handleColorChange}>{colorStatusMap.get(key).iconText}</button>
                                                                      <br/></>
                                                                  ))}
                                                              clearable
                                                              circular
                                                              selection
                                                              onClick={handleColorChange}>
                                                    </Form.Dropdown>
                                                        <label style={{color: "hsl(341, 100%, 30%)"}}>La couleur est obligatoire</label>
                                                    </Form.Field>
                                            }</Container>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {(product.brand === 'APPLE' || product.brand === 'Apple') && (!intervention.customer || !intervention.customer.type) ?
                            <Message fluid warning >
                                <Icon name='warning sign' />Le produit est un iPhone, il est nécessaire de désactiver la géolocalisation avant envoi.
                            <br/><Form>
                                <Form.Checkbox
                                    inline
                                    checked={confirmGeolocCheckbox}
                                    onChange={() => openModalOrDisableCheckBox()}
                                    label='Je confirme la désactivation de la géolocalisation de l&apos;iPhone'
                                />
                            </Form>
                            </Message>
                                : null}
                            <List divided relaxed>


                                { isSmartphone(product.type) && (intervention.status == "QUOTE_INIT" || intervention.status == "QUOTE_CREATED") && intervention.interventionSavOriginId != null ?

                                    product.pricingResult.slice().map(
                                            (diag) => (
                                                <List.Item>

                                                    {diag.status ?
                                                        <List.Icon name='remove' size='large' verticalAlign='middle' color={'red'} /> :
                                                        <List.Icon name='check' size='large' verticalAlign='middle' color={'green'}/>
                                                    }

                                                    <List.Content>
                                                        <List.Header>{getRepairLabel(diag.category)}
                                                            <Label as='a' color='teal' size={'mini'} style={{float: 'right'}}>0 € (SAV)</Label>
                                                        </List.Header>
                                                        {intervention.status == "QUOTE_INIT" && product.pricingResult.length > 1 ?
                                                            diag.status ?
                                                                <List.Description><a  onClick={()=>addSav(diag.category)} style={{fontSize: 'x-small'}}>Sélectionner pour la demande de retour garantie réparation</a></List.Description> :
                                                                <List.Description><a onClick={()=>addSav(diag.category)} style={{fontSize: 'x-small'}}>Supprimer de la demande de retour garantie réparation</a></List.Description>
                                                            : null
                                                        }
                                                    </List.Content>
                                                </List.Item>
                                            )
                                        )

                                    :
                                    null}






                                {isSmartphone(product.type) && intervention.interventionSavOriginId === null ?
                                    product.pricingResult.slice()
                                        .filter(diag => ((intervention.status == "QUOTE_INIT" || intervention.status == "QUOTE_CREATED") && !intervention.customer.email) || (intervention.status == "QUOTE_SENDED" && !intervention.customer.email) || !diag.allSubDiagOk)
                                        .sort(function(a,b){return (a.allSubDiagOk === b.allSubDiagOk)? 0 : a.allSubDiagOk? 1 : -1}).map(
                                        (diag) => (
                                            <List.Item>

                                                {diag.allSubDiagOk ?
                                                    <List.Icon name='remove' size='large' verticalAlign='middle' color={'red'} /> :
                                                    <List.Icon name='check' size='large' verticalAlign='middle' color={'green'}/>
                                                }

                                                <List.Content>
                                                    <List.Header>{getRepairLabel(diag.category)}
                                                        {diag.amountTTC!==0?
                                                            <Label as='a' color='teal' size={'mini'} style={{float: 'right'}} >
                                                                <NumberFormat
                                                                    value={diag.amountTTC}
                                                                    displayType={'text'}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    decimalSeparator=','
                                                                    suffix=' €'
                                                                /></Label>:<Label as='a' color='teal' size={'mini'} style={{float: 'right'}} >
                                                                sur devis</Label>}
                                                    </List.Header>
                                                    {(intervention.status === "QUOTE_INIT" || intervention.status === "QUOTE_CREATED") && !intervention.customer.email ?
                                                        diag.allSubDiagOk ?
                                                            <List.Description><a  onClick={()=>addRemoveAction(false,diag.category)} style={{fontSize: 'x-small'}}>Ajouter au devis</a></List.Description> :
                                                            <List.Description><a onClick={()=>addRemoveAction(true,diag.category)} style={{fontSize: 'x-small'}}>Supprimer du devis</a></List.Description>
                                                        : null
                                                    }
                                                </List.Content>
                                            </List.Item>
                                        )
                                    ) : null
                                }


                            </List>

                            {product.services!==undefined?product.services.map((service) => (
                                service.selected && intervention.status === "QUOTE_CREATED" ? <Segment className={"additionalService selected"} onClick={()=>addService(service.id)}>
                                    <List divided relaxed>
                                        <List.Item>
                                            <List.Icon name='minus square' size='large' verticalAlign='middle' color={'red'} />
                                            <List.Content>
                                                <List.Header>
                                                    {service.label}
                                                    <Label as='a' color='teal' size={'mini'} style={{float: 'right'}} >
                                                        <NumberFormat
                                                            value={service.price}
                                                            displayType={'text'}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            decimalSeparator=','
                                                            suffix=' €'
                                                        /></Label>
                                                </List.Header>
                                                <List.Description>
                                                    en savoir plus
                                                </List.Description>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Segment> : null
                            )):""}

                            {/*<Label as='a' color='teal' ribbon>*/}
                            {/*    Services aditionnels*/}
                            {/*</Label>*/}

                            {/*{product.services.map((service) => (*/}
                            {/*    !service.selected  && (intervention.status === "QUOTE_CREATED") ? <Segment className={"additionalService"} onClick={()=>addService(service.id)}>*/}
                            {/*        <List divided relaxed>*/}
                            {/*            <List.Item>*/}
                            {/*                <List.Icon name='add square' size='large' verticalAlign='middle' color={'green'} />*/}
                            {/*                <List.Content>*/}
                            {/*                    <List.Header>*/}
                            {/*                        {service.label}*/}
                            {/*                        <Label as='a' color='teal' size={'mini'} style={{float: 'right'}} >*/}
                            {/*                            <NumberFormat*/}
                            {/*                                value={service.price}*/}
                            {/*                                displayType={'text'}*/}
                            {/*                                fixedDecimalScale={true}*/}
                            {/*                                decimalScale={2}*/}
                            {/*                                decimalSeparator=','*/}
                            {/*                                suffix=' €'*/}
                            {/*                            /></Label>*/}
                            {/*                    </List.Header>*/}
                            {/*                    <List.Description>*/}
                            {/*                        en savoir plus*/}
                            {/*                    </List.Description>*/}
                            {/*                </List.Content>*/}
                            {/*            </List.Item>*/}
                            {/*        </List>*/}
                            {/*    </Segment> : null*/}
                            {/*))}*/}

                            {product.services.map((service) => (
                                intervention.status !== "QUOTE_CREATED" && service.selected ?

                                    <List divided relaxed>

                                            <List.Item>

                                                {!service.selected ?
                                                    <List.Icon name='remove' size='large' verticalAlign='middle' color={'red'} /> :
                                                    <List.Icon name='check' size='large' verticalAlign='middle' color={'green'}/>
                                                }

                                            <List.Content>
                                                <List.Header>
                                                    {service.label}
                                                    <Label as='a' color='teal' size={'mini'} style={{float: 'right'}} >
                                                        <NumberFormat
                                                            value={service.price}
                                                            displayType={'text'}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            decimalSeparator=','
                                                            suffix=' €'
                                                        /></Label>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                 : null
                            ))}



                        </Segment>

                    </Grid.Column>

                    <Modal
                        open={geolocModal}
                        closeOnDimmerClick={true}
                        onClose={() => setGeolocModal(false)}
                        onOpen={() => setGeolocModal(true)}
                        size='large'
                    >
                        <Modal.Header>Désactiver la géolocalisation sur iPhone</Modal.Header>
                        <Modal.Content image scrolling>
                            <Modal.Description>
                                <Image centered
                                       src={geolocTuto} size='massive'
                                />
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button floated="left" onClick={()=>openGeolocSite()}>
                                <Icon name='warning sign' /> Vous pouvez vérifier la géolocalisation du produit en
                                <a style={{cursor:'pointer'}} target="_blank"> cliquant ici</a>
                                <br/>
                            </Button>
                            <Button primary onClick={(e,value)=>updateIsGeolocDisabled(e,value)}>
                                Confirmer
                            </Button>
                        </Modal.Actions>
                    </Modal>
    </>

    );

}

export default QuotationDiagRecap
