import 'semantic-ui-css/semantic.min.css'
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import Error404Page from "./views/Error404Page"
import LoginPage from "./views/LoginPage"
import StandardLayout from "./layouts/StandardLayout";
import {useDispatch, useSelector} from "react-redux";
import {axiosInstance, axiosInstanceAdmin, axiosInstanceLongTreatment} from "./utils/axiosInstance";
import 'moment/locale/fr';
import React, {useEffect} from "react";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {library, config} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/pro-solid-svg-icons'
import {far} from '@fortawesome/pro-regular-svg-icons'
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import AdvLoginPage from "./views/AdvLoginPage";
import {isSuperviser, isSupport} from "./utils/authorities-utils";
import SupportLayout from "./layouts/SupportLayout";
import AdvSupportLoginPage from "./views/AdvSupportLoginPage";
import SuperviserPage from "./views/SuperviserPage";

function App({match}) {

    const user = useSelector(state => state.user);
    const location = useLocation();
    const dispatch = useDispatch();

    library.add(fas)
    library.add(far)

    useEffect(() => {
        if(document.location.href.indexOf('mood') !== -1  ) {
            document.title = "mood réparations";
            document.getElementById("favicon").href = "https://www.mood.com/img/favicon.ico";
        }
    }, []);

    //Sentry integration
    if (process.env.ENABLE_SENTRY !== undefined && process.env.ENABLE_SENTRY)
        Sentry.init({
            dsn: "https://8eed6380f08f475f8c34feb243717931@o501065.ingest.sentry.io/5752492",
            integrations: [new Integrations.BrowserTracing()],
            release: "orange-front-" + process.env.REACT_APP_ENVIRONMENT + "@" + process.env.npm_package_version,
            environment: "orange-front-" + process.env.REACT_APP_ENVIRONMENT + "@" + process.env.npm_package_version,


            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: process.env.TRACES_SAMPLE_RATE,
        });


    /*onMessageListener().then(payload => {

          toast.success(payload.notification.body,{
            position: "top-right",
                autoClose: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
        });
        console.log(payload);
    }).catch(err => console.log('failed: ', err));*/


    if (!location.pathname.startsWith("/advlogin") && !location.pathname.startsWith("/advsupportlogin")) {
        if (!user.token || !user.isLogged)
            return <LoginPage/>

        if (user.token) {
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            axiosInstanceLongTreatment.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            axiosInstanceAdmin.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
        } else {
            axiosInstance.defaults.headers.common['Authorization'] = null;
            axiosInstanceAdmin.defaults.headers.common['Authorization'] = null;
        }
    }


    if (user.user !== null && isSupport(user.user.authorities)) {
        return (
            <>
                <ToastContainer/>
                <Switch>
                    <Route path="/" exact render={() => <SupportLayout className={"homeaa"}/>}/>
                    <Route path="/advsupportlogin" exact component={AdvSupportLoginPage}/>
                </Switch>
            </>)
    } else if (user.user !== null && isSuperviser(user.user.authorities) && !user.superviserHasSelectedShop) {
        return (
            <>
                <ToastContainer/>
                <Route path="/" exact component={SuperviserPage}/>
            </>)
    } else
        return (
            <>
                <ToastContainer/>
                <Switch>
                    <Route path="/" exact render={() => <StandardLayout className={"homeaa"}/>}/>
                    <Route path="/etape/*" exact component={StandardLayout}/>
                    <Route path="/something" component={StandardLayout}/>
                    <Route path="/waiting" render={() => <StandardLayout title={"Produits en attente de colisage"}/>}/>
                    <Route path="/colisage" render={() => <StandardLayout title={"Suivi des colis"}/>}/>
                    <Route path="/colisage/:tab" render={() => <StandardLayout title={"Suivi des colis"}/>}/>
                    <Route path="/followup" render={() => <StandardLayout title={"Suivi des dossiers"}/>}/>
                    <Route path="/followup/:interventionId"
                           render={() => <StandardLayout title={"Suivi des dossiers"}/>}/>
                    <Route path="/login" component={LoginPage}/>
                    <Route path="/visualDiag" render={() => <StandardLayout title={"Diagnostic visuel"}/>}/>
                    <Route path="/prerequisite" render={() => <StandardLayout title={"Eligibilité du cas client"}/>}/>
                    <Route path="/visualDiag/:step" render={() => <StandardLayout title={"Diagnostic visuel"}/>}/>
                    <Route path="/remoteDiag" render={() => <StandardLayout title={"Diagnostic Piceasoft"}/>}/>
                    <Route path="/manualDiag" render={() => <StandardLayout title={"Diagnostic manuel"}/>}/>
                    <Route path="/quotation" component={StandardLayout}/>
                    <Route path="/manualIdentificationGsma"
                           render={() => <StandardLayout title={"Identification du produit"}/>}/>
                    <Route path="/manualIdentification"
                           render={() => <StandardLayout title={"Identification du produit"}/>}/>
                    <Route path="/help" render={() => <StandardLayout title={"Aide"}/>}/>
                    <Route path="/settings" render={() => <StandardLayout title={"Paramétrage"}/>}/>
                    <Route path="/admin" render={() => <StandardLayout title={"Administration"}/>}/>
                    <Route path="/manager" render={() => <StandardLayout title={"Gestion de la boutique"}/>}/>
                    <Route path="/pdf" component={StandardLayout}/>
                    <Route path="/start"
                           render={() => <StandardLayout title={"Eligibilité du téléphone et de la réparation"}/>}/>
                    <Route path="/advlogin" exact component={AdvLoginPage}/>
                    <Route path="/advsupportlogin" exact component={AdvSupportLoginPage}/>
                    <Route path='/home' render={() => <Redirect to="/"/>}/>
                    <Route component={Error404Page}/>
                </Switch>
            </>
        );
}

export default App;
