import {Button, Container, Icon, Input, Menu, Pagination, Table} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {axiosInstance} from "../../utils/axiosInstance";
import UserItem from "./UserItem";
import UserDetail from "./UserDetail";
import {isAdmin, isManager} from "../../utils/authorities-utils";
import {useSelector} from "react-redux";
import useDebounce from "../../utils/use-debounce";

function User() {

    const [showDetail, setShowDetail] = useState(false);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [totalPageNumber, setTotalPageNumber] = useState(1)
    const [search, setSearch] = useState();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const connectedUser = useSelector(state => state.user);

    const [modalOpen, setModalOpen] = useState(true);
    const debouncedSearchTerm = useDebounce(search, 500);


    const fetchUsers = () => {
        axiosInstance.post("/users/",
            {
                page: page,
                size: size,
                search: debouncedSearchTerm,
                shopPartnerCode: "ALL"

            })
            .then((response) => {
                setTotalPageNumber(response.data.totalPages)
                setUsers(response.data.content)
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        fetchUsers();
    }, [page, debouncedSearchTerm, selectedUser])


    const onSuccess = () => {
        fetchUsers();
        setSelectedUser(null);
    }

    const onClose = () => {
        setSelectedUser(null);
    }

    const handlePageChange = (e, activePage) => {
        setPage(activePage.activePage - 1);
    }

    const handleSearchChange = (e, {name, value}) => {
        setSearch(value);
    }

    return (
        <>
            <Container fluid>

                <Menu>
                    <Menu.Item>
                        <h2>Liste des utilisateurs</h2>
                    </Menu.Item>

                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Input className='icon' icon='search' placeholder='Recherche...' value={search}
                                   onChange={handleSearchChange}/>
                        </Menu.Item>
                        <Menu.Item>
                            <Button primary
                                    disabled={!isAdmin(connectedUser.user.authorities) && !isManager(connectedUser.user.authorities)}
                                    onClick={() => setSelectedUser({user: {authorities: []}})}>
                                <Icon name="add"/>Créer un utilisateur</Button>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>


                <Table compact celled definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Login</Table.HeaderCell>
                            <Table.HeaderCell>Boutique</Table.HeaderCell>
                            <Table.HeaderCell>Prénom/Nom</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell collapsing>Actif</Table.HeaderCell>
                            <Table.HeaderCell>Role</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <UserItem users={users} setSelectedUsers={setSelectedUser}/>
                    </Table.Body>


                </Table>

                {selectedUser !== null ? <UserDetail selectedUser={selectedUser} onSuccess={onSuccess} onClose={onClose}
                                                     forceShop={false}/> : null}

            </Container>

            <Pagination
                activePage={page + 1}
                style={{width: "100%", float: "right"}}
                onPageChange={(e, pageEvent) => handlePageChange(e, pageEvent)}
                defaultActivePage={1}
                totalPages={totalPageNumber}
                style={{marginTop: '10px'}}/>
        </>
    );

}

export default User
