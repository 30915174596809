import {Grid, Icon, Message} from "semantic-ui-react";
import React from "react";

import {useSelector} from "react-redux";

function DiagnosticResult() {

    const product = useSelector(state => state.product);

    return <Grid container>

        {product.pricingResult.map(
            (pricing) => (
                pricing.diagnosticSettingDtos.map(
                    (diag) => (
                        <Grid.Column mobile={16} tablet={16} computer={8}>

                            {diag.sucess === undefined ?
                                <Message icon info attached size='mini'>
                                    <Icon name='hourglass two'/>
                                    <Message.Content>
                                        <Message.Header>{diag.testName}</Message.Header>
                                        En attente d'exécution
                                    </Message.Content>
                                </Message> : null}

                            {diag.sucess !== undefined && diag.sucess ?
                                <Message icon success attached size='mini'>
                                    <Icon name='check'/>
                                    <Message.Content>
                                        <Message.Header>{diag.testName}</Message.Header>
                                        {diag.testSuccessLabel}
                                    </Message.Content>
                                </Message> : null}

                            {diag.sucess !== undefined && !diag.sucess ?
                                <Message icon error attached size='mini'>
                                    <Icon name='warning sign'/>
                                    <Message.Content>
                                        <Message.Header>{diag.testName}</Message.Header>
                                        {diag.testErrorLabel}
                                    </Message.Content>
                                </Message> : null}

                        </Grid.Column>
                    )
                )
            )
        )}
    </Grid>
}

export default DiagnosticResult
