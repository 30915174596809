import {Button, Container, Divider, Dropdown, Grid, Header, Icon, Segment, Step} from 'semantic-ui-react'
import WorkflowStepper from "../../components/WorkflowStepper/WorkflowStepper";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import {ON_MANUEL_MODEL_SELECT} from "../../reducers/Product/product.types";
import {getModelAndDiag} from "../../reducers/Product/product.actions";
import {useHistory} from 'react-router-dom';
import SavHeader from "../../components/SavHeader/SavHeader";

function ManualIdentificationGsma() {

    const [brandSearchResults,setBrandSearchResults] = useState([]);
    const [modelSearchResults,setModelSearchResults] = useState([]);
    const [brandSearchQuery,setBrandSearchQuery] = useState("");
    const [selectedBrand,setSelectedBrand] = useState("");
    const [selectedModel,setSelectedModel] = useState("");
    const [isBrandLoading,setIsBrandLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const product = useSelector(state => state.product);


    useEffect(()=>{
        if(product.pricingResult.length!==0 && product.name !=="" && product.brand !=="") {
            history.push("/manualDiag");
        }
    },[product.pricingResult])

    const handleBrandChange = (e, { searchQuery, value }) => {
        setBrandSearchQuery(e.name);
        setSelectedBrand(value);

        axiosInstance.get("/brand/" + value.id + "/models")
            .then((response) => {
                setModelSearchResults(response.data);
            });
    }

    const handleModelChange = (e, { searchQuery, value }) => {
        setSelectedModel(value);
    }
    const validModel = () => {
        dispatch({
            type: ON_MANUEL_MODEL_SELECT,
            brandName: selectedBrand.name,
            modelName: selectedModel.name
        });
        dispatch(getModelAndDiag())

    }

    const resetBrand = () => {
        setSelectedBrand("");
        setSelectedModel("");
        setBrandSearchResults([]);
        setModelSearchResults([]);
    }

    const resetModel = () => {
        setSelectedModel("");
    }

    const handleBrandSearchChange = (e) => {
        setBrandSearchQuery(e.target.value);
        if (e.target.value === "" || e.target.value === undefined) {
            setBrandSearchResults([]);
            return;
        }
        setIsBrandLoading(true);
        axiosInstance.get("/brands?search=" + e.target.value)
            .then((response) => {
                setBrandSearchResults(response.data);
                setIsBrandLoading(false);
            })
            .catch((error) => {
                setIsBrandLoading(false);
        });
    };

    const brandOptions = brandSearchResults.map(brand => {
        return {key: brand.id, text: brand.name, value: brand}
    });

    const modelOptions = modelSearchResults.map(model => {
        return {key: model.id, text: model.name, value: model}
    });

    return (
        <>
        <Container fluid>
            <SavHeader/>
            <WorkflowStepper step={2} smaKO={true} />

            {/*<Header as='h2'>Identification du produit</Header>*/}

            <Step.Group attached='top'>
                <Step completed={selectedBrand ? true : false} active={!selectedBrand ? true : false}>
                    <Step.Content>
                        <Step.Title>Marque</Step.Title>
                    </Step.Content>
                </Step>
                <Step completed={!selectedBrand ? true : false} active={selectedBrand ? true : false}>
                    <Step.Content>
                        <Step.Title>Modèle</Step.Title>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Segment placeholder>

                <Header as='h3'>Recherche d'un modèle</Header>

                <Segment placeholder>
                    <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>

                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                {selectedBrand ? <><h2>{selectedBrand.name}</h2><a onClick={resetBrand}>modifier</a></> :
                                    <p><Header icon>
                                        Sélectionner une marque
                                    </Header>
                                        <br/>
                                    <Dropdown
                                        search
                                        clearable
                                        options={brandOptions}
                                        selection
                                        onChange={handleBrandChange}
                                        onSearchChange={handleBrandSearchChange}
                                        searchQuery={brandSearchQuery}
                                        noResultsMessage={brandSearchQuery ? "Aucun résultat trouvé" : "Commencez à taper"}
                                        loading={isBrandLoading}
                                    /></p>}
                            </Grid.Column>

                            <Grid.Column>
                                {selectedModel? <><h2>{selectedModel.name}</h2><a onClick={resetModel}>modifier</a></> :
                                    <p><Header icon>
                                        Sélectionner un modèle
                                    </Header>
                                        <br/>
                                        <Dropdown
                                            search
                                            clearable
                                            options={modelOptions}
                                            selection
                                            disabled={!selectedBrand ? true : false}
                                            noResultsMessage={brandSearchQuery ? "Aucun résultat trouvé" : "Commencez à taper"}
                                            onChange={handleModelChange}
                                        /></p>}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>

                {selectedModel ?
                <Button primary fluid icon labelPosition='left' onClick={validModel} >
                    <Icon  name='check' />
                    Valider
                </Button> :
                    <Button primary fluid disabled  >
                        <p>Valider</p>
                    </Button>
                }
            </Segment>
        </Container>
            </>
    );

}

export default ManualIdentificationGsma
