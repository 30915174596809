export const GET_PRICING = 'GET_PRICING';
export const CREATE_INTERVENTION_SUCCESS = 'CREATE_INTERVENTION_SUCCESS';
export const INIT_INTERVENTION_SUCCESS = 'INIT_INTERVENTION_SUCCESS';
export const START_SAV = 'START_SAV';
export const CREATE_INTERVENTION = 'CREATE_INTERVENTION';
export const LOAD_INTERVENTION = 'LOAD_INTERVENTION';
export const SIGNED = 'SIGNED';
export const RESET_INTERVENTION = 'RESET_INTERVENTION';



