import {Button, Container, Grid, Icon, Image, Label, Segment, Step} from 'semantic-ui-react'
import WorkflowStepper from "../../components/WorkflowStepper/WorkflowStepper";
import React, {useEffect, useState} from "react";
import {ON_IDENTIFICATION_PICEASOFT} from "../../reducers/Product/product.types";
import {useDispatch, useSelector} from "react-redux";
import {getModelAndDiag} from '../../reducers/Product/product.actions'
import {useHistory} from 'react-router-dom';
import {
    ON_PICEASOFT_DIAG_FINISHED,
    ON_PICEASOFT_DIAG_STARTED,
    ON_PICEASOFT_NEW_DIAG
} from "../../reducers/Diagnostic/diagnostic.types";
import SavHeader from "../../components/SavHeader/SavHeader";

function SignWacom({onValidation,notFound}) {

    /**
     * State definition
     */
    const [loaded1, setLoaded1] = useState(false);
    const [loaded2, setLoaded2] = useState(false);
    const [loaded3, setLoaded3] = useState(false);
    const [loaded4, setLoaded4] = useState(false);
    const [loaded5, setLoaded5] = useState(false);
    const [injected,setInjected] = useState(false);
    const [done,setDone] = useState(false);
    const [qrCode, setQrCode] = useState("");

    const product = useSelector(state => state.product);
    const dispatch = useDispatch();
    const history = useHistory();
    const [piceasoftIdentification, setPiceasoftIdentification] = useState(null);


    let tabletDemo = ()=>{
        console.log("sdfsdf");
    }

    useEffect(() => {
        if(!injected) {
            const scriptTagQ = document.createElement("script");
            setInjected(true);
            scriptTagQ.src = "/lib/stusdk/q.js";
            scriptTagQ.addEventListener("load", () => setLoaded1(true));
            document.body.appendChild(scriptTagQ);
            const scriptTagBigInt = document.createElement("script");
            setInjected(true);
            scriptTagBigInt.src = "/lib/stusdk/BigInt.js";
            scriptTagBigInt.addEventListener("load", () => setLoaded2(true));
            document.body.appendChild(scriptTagBigInt);
            const scriptTagdemoButtons_encryption = document.createElement("script");
            setInjected(true);
            scriptTagdemoButtons_encryption.src = "/lib/stusdk/demoButtons_encryption.js";
            scriptTagdemoButtons_encryption.addEventListener("load", () => setLoaded3(true));
            document.body.appendChild(scriptTagdemoButtons_encryption);
            const scriptTagwgssStuSdk = document.createElement("script");
            setInjected(true);
            scriptTagwgssStuSdk.src = "/lib/stusdk/wgssStuSdk.js";
            scriptTagwgssStuSdk.addEventListener("load", () => setLoaded4(true));
            document.body.appendChild(scriptTagwgssStuSdk);
        }

    }, [])

    useEffect(() => {
        if (!loaded1 || !loaded2 ||!loaded3 ||!loaded4 ) return;
        try {


            const scriptTagwgssStuSdk = document.createElement("script");
            setInjected(true);
            scriptTagwgssStuSdk.src = "/lib/stusdk/sample.js";
            scriptTagwgssStuSdk.addEventListener("load", () => setLoaded5(true));
            document.body.appendChild(scriptTagwgssStuSdk);


        } catch (e) {
            console.log(e);
        }
        console.log("Piceau soft loaded")
    }, [loaded1,loaded2,loaded3,loaded4])

    useEffect(() => {
        if (!loaded5) return;
        try {


         window.toto = ()=>{onValidation()}
            window.nodevicecallback = () =>{notFound()}
            window.tabletDemo()

        } catch (e) {
            console.log(e);
        }
        console.log("Piceau soft loaded")
    }, [loaded5])


    return (
        <Container fluid>
            <div>
                <div id="signatureDiv">
                    <img id="signatureImage" src="#" style={{"display":"none"}} />
                </div>
                {/*<div>
                    Actions:<br />
                    <input type="button" id="signButton" value="Start demo" onClick={()=>window.tabletDemo()} />
                    {done?"gooo":"nogooo"}
                </div>*/}
            </div>
        </Container>
    );

}

export default SignWacom
