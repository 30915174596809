import React from "react";
import {Feed, Icon} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Moment from "react-moment";

function HistoricNewRepairDateComponent({historicElement}) {

    return (

        <Feed.Event>
            <Feed.Label style={{marginTop: '6px', textAlign:'center'}}>
                <FontAwesomeIcon icon="calendar-check" size={"2x"} color={"rgba(0,0,0,.6)"}/>
            </Feed.Label>
            <Feed.Content>
                <Feed.Date><Moment format="ddd DD MMM YYYY [à] HH:mm"
                                   locale="fr">{historicElement.date}</Moment> {historicElement.login ? 'par ' + historicElement.login : null}
                </Feed.Date>

                {historicElement.datas && historicElement.datas.motif && historicElement.datas.motif === "Retard logistique" ?
                    <Feed.Summary>
                        La date de restitution de votre téléphone a changé, le retour est reporté au {historicElement.datas.newPrevisionalReturnDate}
                    </Feed.Summary> : historicElement.comment && historicElement.comment ?
                        <Feed.Summary>
                            {historicElement.comment}<br/>
                        </Feed.Summary> : null
                }

                {historicElement.datas && historicElement.datas.motif ?
                    <Feed.Extra>
                        <Icon name='warning circle'/>{historicElement.datas.motif}<br/>
                    </Feed.Extra> : null}
            </Feed.Content>
        </Feed.Event>
    );
}


export default HistoricNewRepairDateComponent
