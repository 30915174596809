import {Container, Divider, Grid, Icon, Label, Loader, Message, Segment, Statistic} from 'semantic-ui-react'
import WorkflowStepper from "../../components/WorkflowStepper/WorkflowStepper";
import {axiosInstance} from "../../utils/axiosInstance";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import QuotationDiagRecap from "./QuotationDiagRecap";
import { Prompt } from 'react-router'
import QuotationCustomer from "./QuotationCustomer";
import {
    CREATE_INTERVENTION,
    CREATE_INTERVENTION_SUCCESS,
    INIT_INTERVENTION_SUCCESS
} from "../../reducers/Intervention/intervention.types";
import QuotationResume from "./QuotationResume";
import shipmentStatusMap from "../../utils/status/shipmentStatus";
import SavHeader from "../../components/SavHeader/SavHeader";
import {REFRESH_MENU, RESET_APP} from "../../reducers/GlobalUi/globalUi.types";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {isSmartphone} from "../../utils/product-utils";

export const ImeiContext = React.createContext();

function Quotation({match}) {

    const {ko} = match.params;
    const product = useSelector(state => state.product);
    const shop = useSelector(state => state.shop);
    const crmgp = useSelector(state => state.crmgp);
    const intervention = useSelector(state => state.intervention);
    const customer = useSelector(state => state.customer);
    const [totalTTC, setTotalTTC] = useState("")
    const [shippementInfo, setShippementInfo] = useState("")
    const [imeiError, setImeiError] = useState(false)
    const [noDiagSelected, setNoDiagSelected] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory();


    useEffect(() => {
            initIntervention()
        }
        , [])



    useEffect(() => {

        let totalRepairsAmount = product.pricingResult
            .filter(function (diag) {
                return !diag.allSubDiagOk
            })
            .map((diag) => (diag.amountTTC))
            .reduce((x, y) => x + y, 0);

        let totalServicesAmount = product.services
            .filter(function (service) {
                return service.selected
            })
            .map((service) => (service.price))
            .reduce((x, y) => x + y, 0);

        setTotalTTC(totalRepairsAmount + totalServicesAmount)

        if(product.pricingResult.filter(diag => !diag.allSubDiagOk && diag.amountTTC === 0).length > 0) {
            setTotalTTC(0)
        }


        let selectedDiag = product.pricingResult
            .filter(function (diag) {
                return !diag.allSubDiagOk
            });
        if (selectedDiag.length === 0 && isSmartphone(product.type))
            setNoDiagSelected(true);
        else
            setNoDiagSelected(false);


    }, [product.pricingResult, product.services])


    useEffect(() => {
        let request = {
            modelName: product.name,
            brandName: product.brand,
            shopPartnerId: shop.shopPartner.id,
            isSmartphoneOxidized: product.isSmartphoneOxidized
        }

        axiosInstance.post("/shipment/estimation",
            request).then((response) => {
                setShippementInfo(response.data);
            }
        ).catch(
        );
    }, [])


    const createIntervention = (customerId, comments, additonalData, cosmeticDefaultComments) => {

        // add service to princingResult
        let repairs = product.pricingResult.slice();
        if (product.services.length > 0) {
            product.services.map((service) => (repairs.push(
                {
                    category: service.code,
                    categoryLabel: service.label,
                    amountTTC: service.price,
                    amountHT: service.price,
                    allSubDiagOk: !service.selected,
                    isAllSubDiagDone: true
                }
            )))
        }

        let request = {
            interventionId: intervention.intervention.id,
            interventionSavOriginId: (intervention.interventionSav !== "" ? intervention.interventionSav.id : null),
            customerId: customerId,
            modelName: product.name,
            modelBrand: product.brand,
            modelImei: product.imei,
            imgUrl: product.imgUrl,
            pricingDetails: repairs,
            isIdentifiedByPiceasoft: product.isIdentifiedByPiceasoft,
            shopCode: shop.shopCode,
            comments: comments,
            color: product.color,
            cosmeticDefaultComments: cosmeticDefaultComments

        }

        if (additonalData !== undefined)
            request = {...request, isQuote: additonalData.isQuote}

        dispatch({type: CREATE_INTERVENTION, isQuote: additonalData !== undefined && additonalData.isQuote});
        axiosInstance.post("/intervention/" + intervention.intervention.id,
            request).then((result) => {
                dispatch({type: CREATE_INTERVENTION_SUCCESS, intervention: result.data})
                dispatch({type: REFRESH_MENU})

            if (additonalData !== undefined && additonalData.isQuote) {
                history.push("/");
                dispatch({type: RESET_APP});
                toast.success("Devis enregistré", {
                    position: "top-center"
                });
            }

            }
        ).catch(
        );

    }

    const initIntervention = (customerId) => {
        if (intervention.intervention.id === undefined) {
            let request = {
                modelName: product.name,
                modelBrand: product.brand,
                modelImei: product.imei,
                shopCode: shop.shopCode,
                customerOrangePhoneNumber: customer.orangePhone,
                cuid:crmgp.cuid
            }


            axiosInstance.post("/intervention",
                request).then((result) => {
                    dispatch({type: INIT_INTERVENTION_SUCCESS, intervention: result.data})
                }
            ).catch(
            );
        }

    }

    const isSmaKo =() =>
    {
        for (const item of product.pricingResult) {
            if(item.category === "SMA_KO" && !item.allSubDiagOk)
                return  true;
        }
        return false
    }

    let isSav = intervention.intervention.interventionSavOriginId != null && intervention.intervention.interventionSavId === null;
    let isSavControl = intervention.intervention.interventionSavId != null;

    const getTotalAmount = () => {
        if (isSav) {
            return <Statistic.Value text  style={{fontSize: '22px !important'}}>Retour <br/>garantie réparation</Statistic.Value>
        } else if (totalTTC === 0 || isSmaKo(intervention)) {
            return (<Statistic.Value text  style={{fontSize: '22px !important'}}>Devis à réaliser <br/>par le centre<br/>de réparation</Statistic.Value>) }
        else if (isSavControl) {
            return <Statistic.Value text  style={{fontSize: '22px !important'}}>Contrôle<br/>garantie réparation</Statistic.Value>
        }
        else
            return <Statistic.Value  style={{fontSize: '22px !important'}}>{parseFloat(totalTTC).toFixed(2)} €</Statistic.Value>
    }

    const imeiInputRef = useRef(null)


    return (

        <ImeiContext.Provider value={{imeiError, setImeiError, imeiInputRef, noDiagSelected}}>
            {/*<Prompt
                when={true}
                message='You have unsaved changes, are you sure you want to leave?'
            />*/}
            <Container fluid>
                <SavHeader/>

                {!isSav ? <WorkflowStepper step={intervention.intervention.status === "QUOTE_CREATED" ? 5 : 4}
                                 smaKO={ko === 'ko'}/> : null}

                <Grid columns={2} divided>
                    <Grid.Row stretched>
                        <QuotationDiagRecap/>
                        <Grid.Column width={12}>
                            <Grid columns={1} divided>
                                <Grid.Row stretched>
                                    <Grid.Column>
                                        <Segment>
                                            <Label as='a' color='teal' ribbon>
                                                {isSav ? "Retour garantie réparation" : isSavControl ? "Contrôle garantie réparation": "Devis"}
                                            </Label>

                                            <Statistic.Group
                                                widths={intervention.intervention.status === "QUOTE_VALIDATED" ? 'four' : 'three'}>


                                                {intervention.intervention.status === "QUOTE_VALIDATED" ?
                                                    <Statistic color='green'>
                                                        <Statistic.Value text  style={{fontSize: '22px !important'}}>
                                                            {isSav ? "Retour garantie réparation" : "Devis"}
                                                            <br/>
                                                            enregistré
                                                        </Statistic.Value>
                                                        <Statistic.Label>ORG_{intervention.intervention.id}</Statistic.Label>
                                                    </Statistic> : ""}

                                                <Statistic>
                                                    {getTotalAmount()}
                                                </Statistic>


                                                {shippementInfo === "" ? <Statistic><Loader active/></Statistic> :
                                                    <Statistic style={{"borderLeft": "solid #D3D3D3 0.1em"}}>
                                                        <Statistic.Value text style={{fontSize: '22px !important'}}>
                                                            {shippementInfo.shipmentDelayReadableDate}
                                                            <br/>
                                                            <span
                                                                style={{fontSize: '18px'}}>
                                                                J{shippementInfo.shipmentDelay !== 0 ? ("+" + shippementInfo.shipmentDelay) : ""}
                                                            </span>
                                                        </Statistic.Value>
                                                    </Statistic>
                                                }

                                                {shippementInfo === "" ? <Statistic><Loader active/></Statistic> :
                                                    <Statistic style={{"borderLeft":"solid #D3D3D3 0.1em"}}>

                                                        <Statistic.Value>
                                                            {shipmentStatusMap.get(shippementInfo.shipmentType).icon}
                                                        </Statistic.Value>
                                                        <Statistic.Label>{shipmentStatusMap.get(shippementInfo.shipmentType).text}</Statistic.Label>

                                                    </Statistic>
                                                }


                                            </Statistic.Group>
                                            {product.brand !== undefined && product.brand.toUpperCase() === 'APPLE' && product.isSmartphoneBackCoverDammaged ?
                                                <Message error><Icon name='warning'/>
                                                    {noDiagSelected ? "La prestation de remplacement du capot arrière n'est pas éligible à la réparation pour ce modèle, le devis ne peut donc pas être validé." :
                                                        "La prestation de remplacement du capot arrière n'est pas éligible à la réparation pour ce modèle, elle n'a donc pas été incluse au devis."}
                                                </Message>
                                                : null}
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row stretched>
                                    {intervention.intervention.customer && intervention.intervention.customer.type ? <QuotationResume/> :
                                        <QuotationCustomer createIntervention={createIntervention} totalTTC={totalTTC}/>}
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>


            </Container>
        </ImeiContext.Provider>
    );

}

export default Quotation
