import {Grid, Header, Segment} from 'semantic-ui-react'

import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import MenuSideBar from "../components/MenuSideBar/MenuSideBar";
import HomePage from "../views/HomePage";
import {Route, Switch} from "react-router-dom";
import WaitingProducts from "../views/WaitingProducts";
import Followup from "../views/Followup";
import React from "react";
import VisualDiagPage from "../views/visualdiag/VisualDiagPage";
import RemoteDiag from "../views/remoteDiag/RemoteDiag";
import ManualDiag from "../views/manualDiag/ManualDiag";
import Quotation from "../views/quote/Quotation";
import ManualIdentificationGsma from "../views/manualIdentificationGsma/ManualIdentificationGsma";
import HelpPage from "../views/HelpPage";
import Footer from "../components/Footer/Footer";
import ManualIdentification from "../views/manualIdentification/ManualIdentification";
import ParcelPage from "../views/parcel/ParcelPage";
import SettingPage from "../views/setting/SettingPage";
import AdminPage from "../views/setting/AdminPage";
import ManagerPage from "../views/setting/ManagerPage";
import AlternateWorkflow from "../views/AlternateWorkflow";
import PrerequisitePage from "../views/prerequisite/PrerequisitePage";


function StandardLayout({className,title}) {



    return (

        <>
            <Grid padded>
                <HeaderMenu/>
            </Grid>

            <Grid padded>
                <Grid.Column width={2} id="sidebar">
                    <MenuSideBar/>
                </Grid.Column>
                <Grid.Column width={14} id="content" floated="right" >
                    {title!==undefined?<Header className={"titleHeader"} as='h2' attached='top'>
                        {title}
                    </Header>:""}
                    <Segment attached id={title===undefined?"main-segment":"main-segment-with-attached"} className={className!==undefined?className:"homeaa"}>
                        <Switch>
                            <Route path="/" exact component={HomePage}/>
                            <Route path="/waiting" exact component={WaitingProducts}/>
                            <Route path="/colisage" exact component={ParcelPage}/>
                            <Route path="/colisage/:tab" exact component={ParcelPage}/>
                            <Route path="/followup"  component={Followup}/>
                            <Route path="/followup/:interventionId" exact component={Followup}/>
                            <Route path="/prerequisite" exact component={PrerequisitePage}/>
                            <Route path="/visualDiag" exact component={VisualDiagPage}/>
                            <Route path="/visualDiag/:step" exact component={VisualDiagPage}/>
                            <Route path="/remoteDiag" component={RemoteDiag}/>
                            <Route path="/manualDiag" component={ManualDiag}/>
                            <Route path="/quotation" component={Quotation}/>
                            <Route path="/manualIdentificationGsma" component={ManualIdentificationGsma}/>
                            <Route path="/manualIdentification" component={ManualIdentification}/>
                            <Route path="/help" component={HelpPage}/>
                            <Route path="/settings" component={SettingPage}/>
                            <Route path="/admin" component={AdminPage}/>
                            <Route path="/start" component={AlternateWorkflow}/>
                            <Route path="/manager" component={ManagerPage}/>
                        </Switch>
                    </Segment>
                </Grid.Column>
            </Grid>

            <Footer/>
        </>
    );

}

export default StandardLayout
