import {Grid, Icon, Label} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {LOGOUT} from "../../reducers/User/user.types";
import {RESET_SHOP_DATA} from "../../reducers/Shop/shop.types";
import {useHistory} from "react-router-dom";
import OrangeMoodLabel from "../OrangeMoodLabel";

function Footer() {

    const user = useSelector(state => state.user);
    const crmgp = useSelector(state => state.crmgp);
    const history = useHistory();
    const dispatch = useDispatch();


    const logout = () => {
        localStorage.removeItem('token_sav')
        dispatch({type: LOGOUT})
        dispatch({type: RESET_SHOP_DATA});
        history.push("/")
    }

    return (
        <Grid padded>
            <div id='footer'>
                    <span style={{floated: 'right'}}>
                        <Icon name='user'/>{crmgp.cuid ? <span><b>{crmgp.cuid}</b> connecté en tant que </span> : null}{user.user.firstName} {user.user.lastName} ({user.user.login}) <Icon
                        name='log out' style={{marginLeft: '8px', cursor: 'pointer'}} onClick={logout}/>
                        <Label size='mini' style={{float: 'right'}}>
                        Plateforme de gestion hors-garantie <OrangeMoodLabel label={"Orange"}/> &nbsp;

                        version <Label.Detail>{process.env.REACT_APP_VERSION}</Label.Detail>
                        </Label>
                    </span>
                    
            </div>
        </Grid>
    );

}

export default Footer
