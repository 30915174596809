import {
    Accordion,
    Button,
    Divider,
    Feed,
    Form,
    Icon,
    Label,
    Message,
    Modal,
    Placeholder, Progress,
    Segment
} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    addInterventionHistoric,
    fetchIntervention,
    fetchInterventionHistoric
} from "../../reducers/FollowUp/followup.actions";
import Moment from "react-moment";
import statusMap from "../../utils/status/interventionStatus"
import {useForm} from "react-hook-form";
import NumberFormat from "react-number-format";
import moment from "moment";
import axiosInstance from "../../utils/axiosInstance";
import {DELETED_INTER} from "../../reducers/FollowUp/followup.types";
import {getCategoryLabel} from "../../utils/repairLabels"
import {isAdmin, isSupport} from "../../utils/authorities-utils";
import {useHistory} from "react-router";
import {
    CREATE_UPDATE_CUSTOMER_SUCCESS,
    LOAD_CUSTOMER,
    UPDATE_CUSTOMER_INFO
} from "../../reducers/Customer/customer.types";
import {INIT_INTERVENTION_SUCCESS, LOAD_INTERVENTION, SIGNED} from "../../reducers/Intervention/intervention.types";
import {LOAD_PRODUCT, UPDATE_PRODUCT_INFO} from "../../reducers/Product/product.types";
import SignaturePad from "react-signature-canvas";
import Signature from "../Signature/Signature";
import HistoricEmailComponent from "./HistoricEmailComponent";
import HistoricUpdateStatusComponent from "./HistoricUpdateStatusComponent";
import HistoricCommentComponent from "./HistoricCommentComponent";
import HistoricUpdateCustomerComponent from "./HistoricUpdateCustomerComponent";
import HistoricUpdateProductComponent from "./HistoricUpdateProductComponent";
import HistoricSmsComponent from "./HistoricSmsComponent";
import HistoricNewRepairDateComponent from "./HistoricNewRepairDateComponent";
import HistoricSavComponent from "./HistoricSavComponent";
import HistoricCegidComponent from "./HistoricCegidComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HistoricDiscountComponent from "./HistoricDiscountComponent";
import HistoricSavControlComponent from "./HistoricSavControlComponent";
import HistoricNpsEmailComponent from "./HistoricNpsEmailComponent";

function InterventionHistoric({interventionId, supportMode, selectParcelTab}) {

    const followup = useSelector(state => state.followup);
    const connectedUser = useSelector(state => state.user.user);
    const shop = useSelector(state => state.shop);

    const {register, handleSubmit} = useForm();
    const {register: register2, handleSubmit: handleSubmit2} = useForm();

    supportMode = supportMode === undefined ? false : true;

    const [selectedHistoric, setSelectedHistoric] = useState(null);
    const [actionLoading, setActionLoading] = useState(false);
    const [sigModalOpen, setSigModalOpen] = useState(false);
    const [useWacom, setUseWacom] = useState(shop.shopPartner.useWacomSignPad !== undefined ? shop.shopPartner.useWacomSignPad : false);
    const [sigPad, setSigPad] = useState(null);
    const [sigBase64, setSigBase64] = useState(null);
    const [isEmpty, setIsEmpty] = useState(true);
    const [signatureModal, setSignatureModal] = useState(true);
    const [confirmSav, setConfirmSav] = useState(false);
    const [confirmControlSav, setConfirmControlSav] = useState(false);
    const [loading, setLoading] = useState(false);

    const history = useHistory();


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchInterventionHistoric(interventionId));
    }, [interventionId])

    const onSubmit = data => {
        dispatch(addInterventionHistoric(interventionId, data.comment));
    };

    const initSigPad = (ref) => {
        setSigPad(ref);
    }

    const onDraw = () => {
        setIsEmpty(sigPad.isEmpty())
    }

    const erase = () => {
        sigPad.clear();
        setIsEmpty(true)
    }

    const forceNormalSign = () => {
        setUseWacom(false);
        setSignatureModal(false);
        setSigBase64(null);
    }

    const forceWacom = () => {
        setUseWacom(true);
        setSignatureModal(false);
        setSigBase64(null);
    }

    const signatureValidationHAndler = () => {
        setActionLoading(true);
        axiosInstance.post("/intervention/" + interventionId + "/cloture",
            {withPAD: true}).then((response) => {
                setSigModalOpen(false);
                dispatch(fetchIntervention(interventionId));
                dispatch(fetchInterventionHistoric(interventionId));
            }
        ).catch(
            console.log("Not saved")
        ).finally(() => {
                setActionLoading(true);
            }
        );
    }

    const validSignature = () => {
        setLoading(true);
        axiosInstance.post("/intervention/" + interventionId + "/cloture",{
            sigBase64 : sigPad == null ? null : sigPad.toDataURL(),
            withPAD: false
        }).then((response) => {
                setSigBase64(sigPad.toDataURL());
                sigPad.off();
                setSigModalOpen(false);
                dispatch(fetchIntervention(interventionId));
                dispatch(fetchInterventionHistoric(interventionId));
                setLoading(false);
            }
        ).catch(() => {
                setLoading(false)
            }
        );
    }

    const goToQuote = () => {
        dispatch({type: LOAD_INTERVENTION, intervention: followup.selectedIntervention})
        dispatch({type: LOAD_CUSTOMER, payload: followup.selectedIntervention.customer})

        var pricingResult = [];
        for (const followupElement of followup.selectedIntervention.items) {
            pricingResult.push({
                amountTTC: followupElement.priceTtc,
                allSubDiagOk: followupElement.status ? 1 : 0,
                category: followupElement.category
            });
        }


        var newProduct = {
            ...followup.selectedIntervention.product,
            brand: followup.selectedIntervention.product.brandName,
            pricingResult: pricingResult,
            services: []
        }
        //newProduct.brand=newProduct.brandName;
        dispatch({type: LOAD_PRODUCT, payload: newProduct})

        history.push("/quotation")
    }

    const handleStartSAV = () => {
        setLoading(true);
        axiosInstance.get("/intervention/" + followup.selectedIntervention.id + '/sav')
            .then((response) => {
                setLoading(false);
                setConfirmSav(false);
                setConfirmControlSav(false);

                for (const item of response.data.items) {
                    item.categoryLabel = getCategoryLabel(item.category);
                    item.allSubDiagOk = item.status;
                }

                dispatch({
                    type: UPDATE_PRODUCT_INFO, payload: {
                        color: followup.selectedIntervention.product.color,
                        name: followup.selectedIntervention.product.name,
                        brandName: followup.selectedIntervention.product.brandName,
                        imei: followup.selectedIntervention.product.imei,
                        type: followup.selectedIntervention.product.type,
                        pricingResult: response.data.items
                    }
                });

                dispatch({
                    type: UPDATE_CUSTOMER_INFO, customer: followup.selectedIntervention.customer
                });

                dispatch({type: INIT_INTERVENTION_SUCCESS, intervention: response.data})
                dispatch({type: "ON_VISUAL_DIAG_UPDATE", isSmartphoneFunctional: true})

                history.push("/quotation");

            });

    }

    const onSubmit2 = data => {
        axiosInstance.post("/intervention/historic/" + selectedHistoric.id + '/update',
            {
                date: data.date,
                login: data.login,
                comment: data.comment,
                additionalInformation: data.additionalInformation
            })
            .then((response) => {
                setSelectedHistoric(null);
                dispatch(fetchInterventionHistoric(interventionId));
            });
    };

    const deleteHistoric = (historicId) => {
        axiosInstance.post("/intervention/historic/" + historicId + '/delete')
            .then((response) => {
                setSelectedHistoric(null);
                dispatch(fetchInterventionHistoric(interventionId));
            });
    };

    const simu = (type) => {

        setActionLoading(true);
        axiosInstance.get("/intervention/" + followup.selectedIntervention.id + '/simu/' + type)
            .then((response) => {
                if (type !== 'delete') {
                    dispatch(fetchIntervention(interventionId));
                    dispatch(fetchInterventionHistoric(interventionId));
                } else {
                    dispatch({type: DELETED_INTER, interventionId: interventionId})
                }

            }).finally(() => setActionLoading(false));


    }

    const cloture = (type) => {

        setActionLoading(true);
        axiosInstance.get("/intervention/" + followup.selectedIntervention.id + '/cloture')
            .then((response) => {
                dispatch(fetchIntervention(interventionId));
                dispatch(fetchInterventionHistoric(interventionId));
            }).finally(() => setActionLoading(false));


    }

    let interventionReportDocumentUuid = null;
    let repairedPhone = false;
    let isSav = followup.selectedIntervention.interventionSavOriginId != null && followup.selectedIntervention.interventionSavId === null;
    let hasSav = false;
    let hasSavControl = false;
    for (const historicElement of followup.historic) {
        if (historicElement.status === 'PRODUCT_RECEIVED_ORANGE') {
            if (historicElement && historicElement.datas) {
                if (historicElement.datas.documentUid) {
                    interventionReportDocumentUuid = historicElement.datas.documentUid;
                } else if (historicElement.datas.documentId) {
                    interventionReportDocumentUuid = historicElement.datas.documentId;
                }
            }
        } else if (historicElement.status === 'PRODUCT_REPAIRED') {
            repairedPhone = true;
        } else if (historicElement.type === 'SAV') {
            hasSav = true;
        } else if (historicElement.type === 'SAV_CONTROL') {
            hasSavControl = true;
        }
    }

    return (
        <>

            <Feed>

                {followup.historic.map((historicElement) => (
                    <>

                        {historicElement.type === 'UPDATE_STATUS' && statusMap.get(historicElement.status) ?
                            <HistoricUpdateStatusComponent historicElement={historicElement}
                                                           setSelectedHistoric={setSelectedHistoric}
                                                           selectParcelTab={selectParcelTab}/> : null}

                        {historicElement.type === 'EMAIL' ?
                            <HistoricEmailComponent historicElement={historicElement} /> : null}

                        {historicElement.type === 'NPS_EMAIL' ?
                            <HistoricNpsEmailComponent historicElement={historicElement}/> : null}

                        {historicElement.type === 'COMMENT' ?
                            <HistoricCommentComponent historicElement={historicElement} setSelectedHistoric={setSelectedHistoric}/> : null}

                        {historicElement.type === 'DISCOUNT' ?
                            <HistoricDiscountComponent historicElement={historicElement}/> : null}

                        {historicElement.type === 'UPDATE_CUSTOMER' ?
                            <HistoricUpdateCustomerComponent historicElement={historicElement}/> : null}

                        {historicElement.type === 'UPDATE_PRODUCT' ?
                            <HistoricUpdateProductComponent historicElement={historicElement}/> : null}

                        {historicElement.type === 'SMS' ?
                            <HistoricSmsComponent historicElement={historicElement}/> : null}

                        {historicElement.type === 'NEW_REPAIR_DATE' ?
                            <HistoricNewRepairDateComponent historicElement={historicElement}/> : null}

                        {historicElement.type === 'SAV' ?
                            <HistoricSavComponent historicElement={historicElement}/> : null}

                        {historicElement.type === 'SAV_CONTROL' ?
                            <HistoricSavControlComponent historicElement={historicElement}/> : null}

                        {(historicElement.type === 'TRANSFERT_CEGID_TICKET_SUCCESS' ||
                            historicElement.type === 'TRANSFERT_CEGID_TICKET_ERROR') ?
                            <HistoricCegidComponent historicElement={historicElement}/> : null}

                    </>
                ))}

                {(!supportMode &&
                    followup.selectedIntervention.status === 'TRANSIT_SAVE_ORANGE' ||
                    followup.selectedIntervention.status === 'PRODUCT_REPAIRED' ||
                    followup.selectedIntervention.status === 'PRODUCT_NOT_REPAIRED' ||
                    followup.selectedIntervention.status === 'SAV_CONTROL_REFUSED') ?
                    <Feed.Event>

                        <Feed.Content>
                            <Button fluid primary onClick={() => simu("orangereception")} loading={actionLoading}
                                    disabled={actionLoading}>Confirmer la réception du produit</Button>
                        </Feed.Content>
                    </Feed.Event>
                    : null}

                {(!supportMode && followup.selectedIntervention.status === 'QUOTE_SENDED') ?
                    <Feed.Event>

                        <Feed.Content>
                            <Button fluid primary onClick={() => goToQuote()}>Valider le devis</Button>
                        </Feed.Content>
                    </Feed.Event>
                    : null}

                {(!supportMode && followup.selectedIntervention.status === 'QUOTE_CREATED') ?
                    <Feed.Event>

                        <Feed.Content>
                            <Button fluid primary onClick={() => goToQuote()}>Signer le devis</Button>
                        </Feed.Content>
                    </Feed.Event>
                    : null}


                {!supportMode && followup.selectedIntervention.status === 'PRODUCT_RECEIVED_ORANGE' ?
                    <Feed.Event>
                        <Feed.Label>

                        </Feed.Label>
                        <Feed.Content>
                            <Button fluid primary onClick={() => setSigModalOpen(true)}>Restituer le produit au client
                                (Signature obligatoire)</Button>
                            {/*<Button fluid primary disabled={actionLoading} loading={actionLoading} onClick={() => signatureValidationHAndler()}>Restituer le produit au client</Button>*/}
                        </Feed.Content>
                    </Feed.Event>
                    : null}

                {!supportMode && followup.selectedIntervention.status === 'FINISHED' && repairedPhone && !hasSav && !isSav ?
                    <Feed.Event>
                        <Feed.Label>

                        </Feed.Label>
                        <Feed.Content>
                            <Button color={"orange"} basic fluid onClick={() => setConfirmSav(true)}>Déclarer une
                                demande de retour garantie réparation</Button>
                        </Feed.Content>
                    </Feed.Event>
                    : null}

                {!supportMode && followup.selectedIntervention.status === 'FINISHED' && repairedPhone && followup.selectedIntervention.interventionSavOriginId && !hasSavControl  ? /* with sav close */
                    <Feed.Event>
                        <Feed.Label>

                        </Feed.Label>
                        <Feed.Content>
                            <Button color={"red"} basic fluid onClick={() => setConfirmControlSav(true)}>Déclarer un contrôle
                                 de retour garantie réparation</Button>
                        </Feed.Content>
                    </Feed.Event>
                    : null}

                {followup.isHistoricLoading ?
                    <Placeholder>
                        <Placeholder.Header image>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder.Header>
                        <Placeholder.Header image>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder.Header>
                        <Placeholder.Header image>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder.Header>
                    </Placeholder> : null}

                {followup.isAddHistoricLoading && !followup.isHistoricLoading ?
                    <Placeholder>
                        <Placeholder.Header image>
                            <Placeholder.Line/>
                            <Placeholder.Line/>
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <Feed.Event>
                        <Feed.Label style={{marginTop: '6px', textAlign:'center'}}>
                            <FontAwesomeIcon icon="quote-left" size={"2x"} color={"rgba(0,0,0,.6)"}/>
                        </Feed.Label>
                        <Feed.Content>
                            <Form reply as='form' onSubmit={handleSubmit(onSubmit)}>
                                <Form.Field>
                                    <textarea name="comment" ref={register} rows="2"/>
                                </Form.Field>
                                {!supportMode ?
                                    <Button type="submit" content='Ajouter un commentaire' labelPosition='left'
                                            icon='edit' primary size='tiny'/> : ""}
                            </Form>
                        </Feed.Content>
                    </Feed.Event>
                }
            </Feed>

            <Modal
                onClose={() => setSelectedHistoric(null)}
                open={selectedHistoric !== null && isAdmin(connectedUser.authorities)}
            >
                <Modal.Header>{selectedHistoric ? selectedHistoric.status : ""} - {selectedHistoric ? selectedHistoric.id : ""}</Modal.Header>
                <Modal.Content>

                    <Modal.Description>
                        <Form reply as='form' onSubmit={handleSubmit2(onSubmit2)}>
                            <Form.Field>
                                <label htmlFor="date">Date (JJ/MM/AAAA HH:MM)</label>
                                <input name="date" ref={register2}
                                       defaultValue={selectedHistoric ? moment(selectedHistoric.date).format('DD/MM/YYYY HH:mm') : null}/>
                            </Form.Field>
                            <Form.Field>
                                <label htmlFor="comment">Commentaire</label>
                                <textarea name="comment" ref={register2} rows="2"
                                          defaultValue={selectedHistoric ? selectedHistoric.comment : null}/>
                            </Form.Field>
                            <Form.Field>
                                <label htmlFor="additionalInformation">Infos additionnelles</label>
                                <textarea name="additionalInformation" ref={register2} rows="2"
                                          defaultValue={selectedHistoric ? JSON.stringify(selectedHistoric.datas) : null}/>
                            </Form.Field>
                            <Form.Field>
                                <label htmlFor="login">Login</label>
                                <input name="login" ref={register2}
                                       defaultValue={selectedHistoric ? selectedHistoric.login : null}/>
                            </Form.Field>
                            <Button type="submit" fluid content='Modifier' labelPosition='left'
                                    icon='edit' primary size='tiny'/>
                        </Form>

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button floated={"left"} color={"red"} size={"tiny"} content='Supprimer cet évènement' labelPosition='left'
                            icon='remove'  size='tiny' onClick={() => deleteHistoric(selectedHistoric.id) } />
                    <Button color='black' onClick={() => setSelectedHistoric(null)}>
                        Annuler
                    </Button>
                </Modal.Actions>
            </Modal>


            <Signature openModal={useWacom && sigModalOpen} uuid={interventionReportDocumentUuid}
                       validationHandler={signatureValidationHAndler}
                       onClose={() => setSigModalOpen(false)} cancelHandler={forceNormalSign}
                       type="INTERVENTION_REPORT"></Signature>

            <Modal
                onClose={() => setSigModalOpen(false)}
                open={!useWacom && sigModalOpen}
            >
                <Modal.Header>Restitution du produit au client</Modal.Header>
                <Modal.Content>

                    <Modal.Description>
                        <Segment style={{backgroundColor: 'grey'}}>
                            {<SignaturePad onEnd={onDraw} ref={(ref) => {
                                initSigPad(ref)
                            }} canvasProps={{className: 'sigPad2', width: 220, height: 100}}/>}
                        </Segment>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' onClick={erase}>
                        Effacer
                    </Button>
                    <Button basic color='red' onClick={forceWacom}>
                        Utiliser le Pad
                    </Button>
                    <Button primary onClick={() => validSignature()} loading={loading} disabled={isEmpty || loading}>
                        Je confirme la restitution du produit
                    </Button>
                    <Button color='black' onClick={() => setSigModalOpen(false)}>
                        Annuler
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                onClose={() => setConfirmSav(false)}
                open={confirmSav}
            >
                <Modal.Header>Déclarer une demande de retour garantie réparation</Modal.Header>
                <Modal.Content>
                    {!loading ?
                        <p>En confirmant la prise en charge sous garantie réparation, vous certifiez que la demande concerne une panne relative à la/les réparations initiale(s)
                            et avoir réinitialisé l'appareil en paramétrage d'usine</p> :
                        <Progress percent={100} indicating><p>Enregistrement en cours...</p></Progress>}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setConfirmSav(false)} disabled={loading}>
                        Annuler
                    </Button>
                    <Button primary onClick={handleStartSAV} disabled={loading}>
                        Déclarer une demande de retour garantie réparation
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                onClose={() => setConfirmControlSav(false)}
                open={confirmControlSav}
            >
                <Modal.Header>Déclarer une demande de contrôle retour garantie réparation</Modal.Header>
                <Modal.Content>
                    {!loading ?
                        <p>En confirmant la prise en charge sous garantie réparation, vous certifiez que la demande concerne une panne relative à la/les réparations initiale(s)
                            et avoir réinitialisé l'appareil en paramétrage d'usine</p> :
                        <Progress percent={100} indicating><p>Enregistrement en cours...</p></Progress>}
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setConfirmControlSav(false)} disabled={loading}>
                        Annuler
                    </Button>
                    <Button primary onClick={handleStartSAV} disabled={loading}>
                        Déclarer une demande de contrôle retour garantie réparation
                    </Button>
                </Modal.Actions>
            </Modal>

        </>
    );

}

export default InterventionHistoric
