import React from "react";
import {useSelector} from "react-redux";
import {Button, Divider, Form, Grid, Header, Message, Segment} from "semantic-ui-react";
import {useForm} from "react-hook-form";
import {axiosInstance} from "../../utils/axiosInstance";
import {toast} from "react-toastify";
import {isByShop, isChronoShop, isCoursierShop} from "../../utils/shop-utils";

function RestockComponent() {

    const shopPartner = useSelector(state => state.shop.shopPartner);
    const pickupType = useSelector(state => state.shop.shopPartner.pickupType);


    const {register, handleSubmit, watch, setError, errors, clearErrors} = useForm({
        defaultValues:
            {
                malette: false,
                carton: false,
                scelle: false,
                comment: ""
            }
    });

    const onSubmit = (data) => {
        console.log(data)
        data.shopPartnerId = shopPartner.id;

        axiosInstance.post("/parcel/logistic/order", data)
            .then((response) => {
                toast.success("Commande effectuée")
            })
            .catch((error) => {
                console.log("error")
            });
    }

    return (
        <>
            <Header block as='h4' attached='top'>
                Commande logistique
            </Header>

            <Form onSubmit={handleSubmit(onSubmit)}>

                <Segment attached>
                    <Grid basic columns={2}>
                        <Grid.Column verticalAlign='top'>
                            <Header as='h3' dividing>
                                Elements à commander
                            </Header>

                            {isChronoShop(pickupType) ? <Form.Field>
                                <div className="ui toggle checkbox">
                                    <input type="checkbox" name="carton" ref={register()}/>
                                    <label>Commander des cartons </label>
                                </div>
                            </Form.Field> : null}

                            {isByShop(pickupType) || isCoursierShop(pickupType) ?
                                <Form.Field>
                                    <div className="ui toggle checkbox">
                                        <input type="checkbox" name="malette" ref={register()}/>
                                        <label>Commander une mallette</label>
                                    </div>
                                </Form.Field> : null}

                            {isByShop(pickupType) || isCoursierShop(pickupType) ?
                            <Form.Field>
                                <div className="ui toggle checkbox">
                                    <input type="checkbox" name="scelle" ref={register()}/>
                                    <label>Commander des scellés</label>
                                </div>
                            </Form.Field> :null}
                            {/*<Form.Field>*/}
                            {/*    <div className="ui toggle checkbox">*/}
                            {/*        <input type="checkbox" name="chronopost" ref={register()}/>*/}
                            {/*        <label>Commander des étiquettes Chronopost</label>*/}
                            {/*    </div>*/}
                            {/*</Form.Field>*/}
                        </Grid.Column>
                        <Grid.Column verticalAlign='top'>
                            <Header as='h3' dividing>
                                Message (facultatif)
                            </Header>
                            <Form.Field>
                            <textarea name="comment" ref={register()} rows="3"
                                      placeholder='Vous pouvez laisser un message (facultatif)'/>
                            </Form.Field>

                            <Button type="submit" fluid content='Valider la demande' primary/>
                        </Grid.Column>
                    </Grid>
                    <Divider vertical/>
                </Segment>
            </Form>
        </>
    )
}

export default RestockComponent