import {Icon} from "semantic-ui-react";
import React from "react";
import logoStuart from '../../components/HeaderMenu/logo-stuart.png';
import logoChrono from '../../components/HeaderMenu/logo-chronopost.png';
import logoSave from '../../components/HeaderMenu/logo-save-mini.png';

const shipmentStatusMap = new Map();
shipmentStatusMap.set(
    "COURSIER", {
        icon: <Icon name='bicycle'/>,
        logo: logoStuart,
        iconText: 'bicycle',
        text: 'par enlèvement Coursier',
        textHistoric: 'Transporteur Stuart',
    }
);
shipmentStatusMap.set(
    "CHRONOPOST", {
        icon: <Icon name='box'/>,
        logo: logoChrono,
        iconText: 'box',
        text: 'par Chronopost',
        textHistoric: 'Transporteur Chronopost',
    }
);
shipmentStatusMap.set(
    "BYSHOP", {
        icon: <Icon name='street view'/>,
        logo: logoSave,
        iconText: 'shipping fast',
        text: 'par enlèvement Coursier',
        textHistoric: 'Transporteur Save',
    }
);

export default shipmentStatusMap;
