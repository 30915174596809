import {Button, Grid, Icon, Label, Segment, Message} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import {LOGOUT} from "../../reducers/User/user.types";

import {useHistory} from 'react-router-dom';
import {RESET_APP} from "../../reducers/GlobalUi/globalUi.types";
import React, {useState} from "react";

function SavHeader() {
    const shop = useSelector(state => state.shop);
    const dispatch = useDispatch();
    const intervention = useSelector(state => state.intervention);

    const [fileName,setFileName] = useState("");

    const logout = () => {
        dispatch({type: LOGOUT})
    }

    const history = useHistory();

    const resetApp = () => {
        dispatch({type: RESET_APP});
        history.push("/exrtanet")
    }

    const upload = (e) => {
        console.log(e);
        setFileName(e.target.files[0].name);

    }



    const content = function () {
        if (intervention.interventionSav !== "")
            return <Segment size='tiny' inverted color='red' tertiary style={{paddingTop: '4px', paddingBottom:'4px'}}>
                <Grid columns='equal' streched>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment size='mini'>Retour SAV du dossier <b>ORG_{intervention.interventionSav.id}</b></Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment size='mini'><b>Diagnostic initial :</b> {intervention.interventionSav.items.map((repair) => (
                                repair.category !== 'SMA_KO' && repair.category !== 'DOX' && repair.status === false ?
                                    <Label
                                        size='mini'>{repair.category}</Label> : null
                            ))}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment size='mini'><b>Modèle :</b> {intervention.interventionSav.product.brandName} {intervention.interventionSav.product.name}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment size='mini' style={{padding: '10px'}}>

                                    <Button icon size='mini' as="label" htmlFor={"file"+intervention.interventionSav.id} type="button" style={{padding: '5px'}}>
                                        <Icon name="upload"/> Upload la facture d'achat
                                    </Button> {fileName ? <Icon name="check" bordered color='green'/> :  <Icon name="remove" bordered color='red'/>}
                                <input type="file" id={"file"+intervention.interventionSav.id} style={{ "display": "none" }}
                                       onChange={(e)=>upload(e)} />

                            </Segment>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Segment>;
        return "";


    }


    return(
        content())



}

export default SavHeader
