import {Button, Icon, Label, List, Table} from 'semantic-ui-react'
import React from "react";
import {isAllowed} from "../../utils/authorities-utils";
import {useSelector} from "react-redux";

function UserItem({users, setSelectedUsers}) {

    const currentUser = useSelector(state => state.user.user);


    return users.map((user) =>
        <Table.Row positive={user.user.activated} negative={!user.user.activated}>
            <Table.Cell>
                <List>
                    {user.userLogin}
                </List>
            </Table.Cell>
            <Table.Cell>
                <List>
                    {user.shopPartnerCode}
                </List>
            </Table.Cell>
            <Table.Cell>
                <List>
                    {user.user.firstName} {user.user.lastName}
                </List>
            </Table.Cell>
            <Table.Cell>
                <List>
                    {user.user.email}
                </List>
            </Table.Cell>
            <Table.Cell textAlign='center'>
                {user.user.activated === true ? <Icon name="check"/> : <Icon name="close"/>}
            </Table.Cell>
            <Table.Cell>
                {user.user.authorities.map(role => <Label>{role.name}</Label>)}
            </Table.Cell>
            <Table.Cell collapsing textAlign="center">

                <Button primary compact size='small'
                        disabled={!isAllowed(user.user.authorities, currentUser.authorities)} icon
                        onClick={() => setSelectedUsers(user)}>
                    <Icon name={"edit outline"}/> Editer
                </Button>

            </Table.Cell>
        </Table.Row>);
}

export default UserItem
