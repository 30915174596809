import {Button, Feed, Form, Header, Icon, Input, Label, Message, Segment} from 'semantic-ui-react'
import {useHistory} from 'react-router-dom';
import logoOrange from '../img/logo-orange.png';
import logoSave from '../img/logo-save-mini.png';

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {SET_CUSTOMER_ORANGE_PHONE} from "../../reducers/Customer/customer.types";
import {RESET_APP_WITHOUT_PARCEL_COUNTER} from "../../reducers/GlobalUi/globalUi.types";
import {isAdmin} from "../../utils/authorities-utils";
import OrangeMoodLabel from "../../components/OrangeMoodLabel";

function PrerequisitePage() {


    const history = useHistory();
    const dispatch = useDispatch();
    const shop = useSelector(state => state.shop.shopPartner);
    const connectedUser = useSelector(state => state.user.user);
    const crmgp = useSelector(state => state.crmgp);

    const [mobileNumber, setMobileNumber] = useState(null);
    const [mobileNumberEdit, setMobileNumberEdit] = useState(crmgp.telContactCl ? crmgp.telContactCl : "");
    const [panneOrCasse, setPanneOrCasse] = useState(null);
    const [isInsured, setIsInsured] = useState(null);
    const [isOrangeRebound, setIsOrangeRebound] = useState(null);
    const [isProductOrangeLessThan24Month, setIsProductOrangeLessThan24Month] = useState(null);
    const [isOrangeWarrantyCovered, setIsOrangeWarrantyCovered] = useState(null);
    const [isRepairWarrantyCovered, setIsRepairWarrantyCovered] = useState(null);

    useEffect(() => {
        dispatch({type: RESET_APP_WITHOUT_PARCEL_COUNTER})
    }, []);

    const reset = () => {
        setMobileNumber(null);
        setMobileNumberEdit("");
        setPanneOrCasse(null);
        setIsInsured(null);
        setIsOrangeRebound(null);
        setIsProductOrangeLessThan24Month(null);
        setIsOrangeWarrantyCovered(null);
        setIsRepairWarrantyCovered(null);
    }

    const setMobile = () => {
        setMobileNumber(mobileNumberEdit);
        dispatch({type: SET_CUSTOMER_ORANGE_PHONE, payload:  mobileNumberEdit});
    }

    const startPEC = () => {
        dispatch({type: SET_CUSTOMER_ORANGE_PHONE, payload: mobileNumber})

        if (shop.workflowType === null || shop.workflowType === undefined || shop.workflowType === 0) {
            history.push("/visualDiag");
        } else {
            history.push("/start");
        }
    }

    const goToFollowup = () => {
        history.push("/followup");
    }

    const eligibleFinalMessage = <Message success>
        <Icon name='check'/>
        Le client est éligible à la prise en charge
        <br/><br/>
        <Button type="submit" content='Démarrer la prise en charge' primary size='tiny'
                onClick={startPEC}/>
    </Message>;

    const savEligibleFinalMessage = <Message positive>
        <Icon name='check'/>
        Le client est éligible à la prise en charge sous garantie.
        Pour cela retrouvez le dossier initial dans le suivi, et lancer une procédure de SAV
        <br/><br/>
        <Button type="submit" content='Aller à la page de suivi' primary size='tiny'
                onClick={goToFollowup}/>
    </Message>;

    const savOrangeFinalMessage = <Message warning>
        <Icon name='warning sign'/>
        Mobile pris en charge au titre du SAV : Appeler la HLS et /ou envoyer le clic service SAV Mobile
    </Message>;

    const customerInsuranceFinalMessage = <Message warning>
        <Icon name='warning sign'/>
        Nous invitons le client à se rapprocher de son assureur
    </Message>

    let feeds = [];

    if (!mobileNumber) {
        feeds.push(<Feed.Event>
            <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
            <Feed.Content>

                <Form reply as='form'>
                    <Form.Field>
                        <Input label='Numéro de mobile' placeholder='Ecrire ici...' onChange={(e, {name, value}) => setMobileNumberEdit(value)}
                               value={mobileNumberEdit}/>
                    </Form.Field>
                    <Button type="submit" content='Valider' primary size='tiny'
                            onClick={() => setMobile()}/>
                </Form>
            </Feed.Content>
        </Feed.Event>)
    } else {
        feeds.push(<Feed.Event>
            <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
            <Feed.Content>
                <Message size='tiny'>
                    Le numéro de mobile du client est <Feed.User>{mobileNumber}</Feed.User>
                </Message>
            </Feed.Content>
        </Feed.Event>)

        feeds.push(<Feed.Event>
            <Icon size={'big'} style={{marginTop: "6px"}} name='question circle outline' color={"teal"}/>
            <Feed.Content>
                <Message info size='tiny'>
                    Le produit du client est-il cassé ou en panne ?
                </Message>
            </Feed.Content>
        </Feed.Event>);
    }

    if (mobileNumber) {
        if (!panneOrCasse) {
            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                <Feed.Content>
                    <Message size='tiny'>
                    <Button type="submit" content='Une casse' primary size='tiny'
                            onClick={() => setPanneOrCasse('CASSE')}/>
                    <Button type="submit" content='Une panne' primary size='tiny'
                            onClick={() => setPanneOrCasse('PANNE')}/>
                    </Message>
                </Feed.Content>
            </Feed.Event>);
        } else {
            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                <Feed.Content>
                    <Message size='tiny'>
                        Le produit est <Feed.User>{panneOrCasse === 'PANNE' ? 'en panne' : "cassé"}</Feed.User>
                    </Message>
                </Feed.Content>
            </Feed.Event>);
        }
    }

    if (panneOrCasse === 'CASSE') {
        feeds.push(<Feed.Event>
            <Icon size={'big'} style={{marginTop: "6px"}} name='question circle outline' color={"teal"}/>
            <Feed.Content>
                <Message info size='tiny'>
                    Le produit du client est-il assuré ?
                </Message>
            </Feed.Content>
        </Feed.Event>);

        if (isInsured === null) {
            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                <Feed.Content>
                    <Message size='tiny'>
                    <Button type="submit" content='Oui' primary size='tiny' onClick={() => setIsInsured(true)}/>
                    <Button type="submit" content='Non' primary size='tiny' onClick={() => setIsInsured(false)}/>
                    </Message>
                </Feed.Content>
            </Feed.Event>)
        }

        if (isInsured !== null || isInsured != undefined) {
            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                <Feed.Content>
                    <Message size='tiny'>
                        {isInsured ? <Feed.User>Oui</Feed.User> : <Feed.User>Non</Feed.User>}
                    </Message>
                </Feed.Content>
            </Feed.Event>);
            if (isInsured) {
                feeds.push(<Feed.Event>
                    <Icon size={'big'} style={{marginTop: "6px"}} name='warning sign' color={"teal"}/>
                    <Feed.Content>
                        {customerInsuranceFinalMessage}
                    </Feed.Content>
                </Feed.Event>);
            } else {
                feeds.push(<Feed.Event>
                    <Icon size={'big'} style={{marginTop: "6px"}} name='check' color={"teal"}/>
                    <Feed.Content>
                        {eligibleFinalMessage}
                    </Feed.Content>
                </Feed.Event>);
            }
        }
    }

    if (panneOrCasse === 'PANNE') {
        feeds.push(<Feed.Event>
            <Icon size={'big'} style={{marginTop: "6px"}} name='question circle outline' color={"teal"}/>
            <Feed.Content>
                <Message info size='tiny'>
                    La panne concerne t'elle une réparation payante déjà effectuée en boutique <OrangeMoodLabel label={"Orange"}/> ?
                </Message>
            </Feed.Content>
        </Feed.Event>);

        if (isOrangeRebound === null) {
            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                <Feed.Content>
                    <Message size='tiny'>
                    <Button type="submit" content='Oui' primary size='tiny' onClick={() => setIsOrangeRebound(true)}/>
                    <Button type="submit" content='Non' primary size='tiny' onClick={() => setIsOrangeRebound(false)}/>
                    </Message>
                </Feed.Content>
            </Feed.Event>)
        }

        if (isOrangeRebound !== null || isOrangeRebound != undefined) {
            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                <Feed.Content>
                    <Message size='tiny'>
                        {isOrangeRebound ? <Feed.User>Oui</Feed.User> : <Feed.User>Non</Feed.User>}
                    </Message>
                </Feed.Content>
            </Feed.Event>);

            if (!isOrangeRebound) {
                feeds.push(<Feed.Event>
                    <Icon size={'big'} style={{marginTop: "6px"}} name='question circle outline' color={"teal"}/>
                    <Feed.Content>
                        <Message info size='tiny'>
                            Le produit est-il un produit <OrangeMoodLabel label={"Orange"}/> et de <OrangeMoodLabel label={"moins de 24 mois"}/> ?
                        </Message>
                    </Feed.Content>
                </Feed.Event>);

                if (isProductOrangeLessThan24Month === null) {
                    feeds.push(<Feed.Event>
                        <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                        <Feed.Content>
                            <Message size='tiny'>
                            <Button type="submit" content='Oui' primary size='tiny'
                                    onClick={() => setIsProductOrangeLessThan24Month(true)}/>
                            <Button type="submit" content='Non' primary size='tiny'
                                    onClick={() => setIsProductOrangeLessThan24Month(false)}/>
                            </Message>
                        </Feed.Content>
                    </Feed.Event>)
                }
            } else {
                feeds.push(<Feed.Event>
                    <Icon size={'big'} style={{marginTop: "6px"}} name='question circle outline' color={"teal"}/>
                    <Feed.Content>
                        <Message info size='tiny'>
                            La réparation est-elle couverte par la garantie ? (&lt;12mois et même panne)
                        </Message>
                    </Feed.Content>
                </Feed.Event>);

                if (isRepairWarrantyCovered === null) {
                    feeds.push(<Feed.Event>
                        <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                        <Feed.Content>
                            <Message size='tiny'>
                            <Button type="submit" content='Oui' primary size='tiny'
                                    onClick={() => setIsRepairWarrantyCovered(true)}/>
                            <Button type="submit" content='Non' primary size='tiny'
                                    onClick={() => setIsRepairWarrantyCovered(false)}/>
                            </Message>
                        </Feed.Content>
                    </Feed.Event>)
                }
            }
        }

        if (isRepairWarrantyCovered !== null || isRepairWarrantyCovered != undefined) {
            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                <Feed.Content>
                    <Message size='tiny'>
                        {isRepairWarrantyCovered ? <Feed.User>Oui</Feed.User> : <Feed.User>Non</Feed.User>}
                    </Message>
                </Feed.Content>
            </Feed.Event>);

            if (!isRepairWarrantyCovered) {
                feeds.push(<Feed.Event>
                    <Icon size={'big'} style={{marginTop: "6px"}} name='check' color={"teal"}/>
                    <Feed.Content>
                        <Feed.Summary>
                            {eligibleFinalMessage}
                        </Feed.Summary>
                    </Feed.Content>
                </Feed.Event>);
            } else {
                feeds.push(<Feed.Event>
                    <Feed.Label>
                        <Icon size={'big'} style={{marginTop: "6px"}} name='check' color={"teal"}/>
                    </Feed.Label>
                    <Feed.Content>
                        {savEligibleFinalMessage}
                    </Feed.Content>
                </Feed.Event>);
            }
        }

        if (isProductOrangeLessThan24Month !== null || isProductOrangeLessThan24Month != undefined) {
            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                <Feed.Content>
                    <Message size='tiny'>
                        {isProductOrangeLessThan24Month ? <Feed.User>Oui</Feed.User> : <Feed.User>Non</Feed.User>}
                    </Message>
                </Feed.Content>
            </Feed.Event>);

            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='question circle outline' color={"teal"}/>
                <Feed.Content>
                    <Message info size='tiny'>
                        La panne est-elle couverte par la garantie mobile <OrangeMoodLabel label={"Orange"}/> ?
                    </Message>
                </Feed.Content>
            </Feed.Event>);

            if (isOrangeWarrantyCovered === null) {
                feeds.push(<Feed.Event>
                    <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                    <Feed.Content>
                        <Message size='tiny'>
                        <Button type="submit" content='Oui' primary size='tiny'
                                onClick={() => setIsOrangeWarrantyCovered(true)}/>
                        <Button type="submit" content='Non' primary size='tiny'
                                onClick={() => setIsOrangeWarrantyCovered(false)}/>
                        </Message>
                    </Feed.Content>
                </Feed.Event>)
            }
        }

        if (isOrangeWarrantyCovered !== null || isOrangeWarrantyCovered != undefined) {
            feeds.push(<Feed.Event>
                <Icon size={'big'} style={{marginTop: "6px"}} name='user' color={"orange"}/>
                <Feed.Content>
                    <Feed.Summary>
                        <Message size='tiny'>
                            {isOrangeWarrantyCovered ?
                                <Feed.User>Oui</Feed.User> : <Feed.User>Non</Feed.User>}
                        </Message>
                    </Feed.Summary>
                </Feed.Content>
            </Feed.Event>);

            if (isOrangeWarrantyCovered) {
                feeds.push(<Feed.Event>
                    <Icon size={'big'} style={{marginTop: "6px"}} name='warning sign' color={"teal"}/>
                    <Feed.Content>
                        <Feed.Summary>
                            {savOrangeFinalMessage}
                        </Feed.Summary>
                    </Feed.Content>
                </Feed.Event>);
            } else {
                feeds.push(<Feed.Event>
                    <Icon size={'big'} style={{marginTop: "6px"}} name='check' color={"teal"}/>
                    <Feed.Content>
                        <Feed.Summary>
                            {eligibleFinalMessage}
                        </Feed.Summary>
                    </Feed.Content>
                </Feed.Event>)
            }
        }
    }

    return (
        <>
            <Segment fluid>
                <Header block>Les question suivantes vont permettre de vérifier l'éligibilité du client

                    <Header.Subheader><a onClick={reset}>Remettre le questionnaire à zéro</a></Header.Subheader>
                </Header>
                <Feed>
                    <Feed.Event>

                            <Icon size={'big'} style={{marginTop: "6px"}} name='question circle outline' color={"teal"}/>

                        <Feed.Content>

                            <Message info size='tiny'>Quel est le numéro de mobile du client ?</Message>

                        </Feed.Content>
                    </Feed.Event>

                    {/*{eligibleFinalMessage}*/}
                    {/*{savEligibleFinalMessage}*/}
                    {/*{savOrangeFinalMessage}*/}
                    {/*{customerInsuranceFinalMessage}*/}

                    {feeds}


                </Feed>
            </Segment>
            {isAdmin(connectedUser.authorities) ? <Segment fluid>
                <Label as='a' color='red' ribbon>
                    Administrateur
                </Label>
                <Button basic type="submit" content='Aller au workflow DIAG to PRICE' primary size='tiny'
                        onClick={() => history.push("/visualDiag")}/>
                <Button basic type="submit" content='Aller au workflow PRICE to DIAG' primary size='tiny'
                        onClick={() => history.push("/start")}/>
                <Button basic type="submit" content='Aller au workflow configuré pour la boutique' primary size='tiny'
                        onClick={startPEC}/>
            </Segment> : null}
        </>

    );

}

export default PrerequisitePage
