import {Button, Container, Form, Icon, Message, Segment} from 'semantic-ui-react'
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {isAdmin, isManager} from "../../utils/authorities-utils";
import {axiosInstance} from "../../utils/axiosInstance";
import {LOGGIN} from "../../reducers/User/user.types";

function MyAccount() {

    const user = useSelector(state => state.user);
    const {register, handleSubmit} = useForm();
    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = data => {
        setResponse(null);
        setLoading(true);
        axiosInstance.patch("/user/", data
        )
            .then((response) => {
                dispatch({
                    type: LOGGIN,
                    user: response.data.user,
                    userAdditionalInformations: response.data.userAdditionalInformations,
                    shopPartner: response.data.shopPartner,
                    shopSave: response.data.shopSave,
                });
                setResponse(<Message positive>Votre compte a bien été mis à jour</Message>)
                setLoading(false);
            })
            .catch((error) => {
                setResponse(<Message negative>Une erreur est survenue</Message>)
                setLoading(false)
            })
    };

    let roleMsg = <span>Vous avez le rôle d'<b>utilisateur</b>, pour toute augmentation de privilèges contactez votre manager</span>;
    if (isManager(user.user.authorities))
        roleMsg = <span>Vous avez le rôle de <b>manager</b> de la boutique</span>
    if (isAdmin(user.user.authorities))
        roleMsg = <span>Vous avez le rôle d'<b>administrateur</b></span>

    return (
        <>
            <Container fluid>

                <Message icon>
                    <Icon name='user'/>
                    <Message.Content>
                        <Message.Header>Configuration du compte</Message.Header>
                        {roleMsg}
                    </Message.Content>
                </Message>

                <Segment>
                {response}

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Input label='Login' name="login" ref={register} placeholder='Login' value={user.user.login} readOnly/>

                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label htmlFor="firstName">Prénom</label>
                            <input name="firstName" ref={register} defaultValue={user.user.firstName}/>
                        </Form.Field>
                        <Form.Field>
                            <label htmlFor="lastName">Nom</label>
                            <input name="lastName" ref={register} defaultValue={user.user.lastName}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label htmlFor="email">Email</label>
                        <input name="email" ref={register} defaultValue={user.user.email}/>
                    </Form.Field>

                    <Button primary loading={loading}>Mettre à jour mon compte</Button>
                </Form>
                </Segment>
            </Container>

        </>
    );

}

export default MyAccount
